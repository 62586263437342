import {
  TArrayRepresentedGridCoordinates,
  ChartDrawerParameters,
} from '../utils/types';

export const drawFluids = ({
  chart,
  chartData,
  drawUtils,
}: ChartDrawerParameters) => {
  const { gridLine, mirror } = drawUtils;
  const fluidGroup = chart.append('g').attr('class', 'fluids');

  chartData.sections.forEach((section) =>
    section?.fluids?.forEach((fluidSection) => {
      const { color, rectangles } = fluidSection ?? {};

      if (!rectangles?.length) {
        return;
      }

      const fluidSectionGroup = fluidGroup
        .append('g')
        .attr('class', 'fluidSection');

      const drawFluid = (coordinates: TArrayRepresentedGridCoordinates) =>
        fluidSectionGroup
          .append('path')
          .attr('class', 'fluid')
          .attr('d', () => gridLine(coordinates))
          .attr('fill', color)
          .attr('stroke', color)
          .attr('stroke-width', 0.1)
          .attr('shape-rendering', 'crispEdges');

      for (const fluidRect of rectangles) {
        drawFluid(fluidRect);
        drawFluid(mirror(fluidRect));
      }
    }),
  );
};
