/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-properties */
/* eslint-disable prefer-const */
import { transformLocationCoordinate } from '~common/gis/transform-location-coordinate';
import { areAllArgumentsNumeric } from '~common/gis/helpers';
import {
  getDatumEPSG,
  isOffsetType,
  getLocationInfoByEpsg,
} from '~common/gis/gis-library-utils';

function principalCurvatures(latitude, a, esq) {
  const s = Math.sin(latitude);
  const ssq = s * s;
  let nu, rho;
  nu = a / (1 - esq * ssq);
  rho = (Math.pow(nu, 3) * (1 - esq)) / (a * a);
  return { nu, rho };
}

function eccentricitySquared(f) {
  return f * (2 - f);
}

export function calculateConvergence(
  longitude,
  latitude,
  longitude0,
  ellipsoid,
) {
  /*
   * Calculates the grid convergence at a point longitude, latitude.
   * longitude is the longitude (RADIANS)
   * latitude is the latitude (RADIANS)
   * longitude0 is the longitude of the central meridian of the utm zone. (RADIANS)
   * ellipsoid is of the form { semiMajorAxis: semi-major axis, inverseFlattening: inverse flattening }
   */

  longitude -= longitude0;
  let t = Math.tan(latitude);

  let { semiMajorAxis, inverseFlattening, b } = ellipsoid;
  if (inverseFlattening === null)
    inverseFlattening = semiMajorAxis / (semiMajorAxis - b);
  const f = 1 / inverseFlattening;
  const esq = eccentricitySquared(f);

  const { nu, rho } = principalCurvatures(latitude, semiMajorAxis, esq);
  let beta = nu / rho;

  // Coefficients are calculated from equation 7.55 from (Osborne, 2013)
  let a2, a4, a6bar;
  a2 = (2 * beta * beta - beta + t * t) / 3;
  a4 =
    (Math.pow(beta, 4) * (11 - 24 * t * t) -
      Math.pow(beta, 3) * (11 - 36 * t * t) +
      Math.pow(beta, 2) * (2 - 4 * t * t) -
      4 * beta * t * t +
      2 * Math.pow(t, 4)) /
    15;
  a6bar =
    (17 + Math.pow(t, 2) * 51 + Math.pow(t, 4) * 51 + Math.pow(t, 6) * 17) /
    315;

  // Calculate using equation 7.58 from (Osborne, 2013)
  let longitudeTilde = longitude * Math.cos(latitude);
  let tanGamma =
    longitudeTilde *
    t *
    (1 +
      a2 * Math.pow(longitudeTilde, 2) +
      a4 * Math.pow(longitudeTilde, 4) +
      a6bar * Math.pow(longitudeTilde, 6));
  let gamma = Math.atan(tanGamma);
  return gamma;
}

/**
 * Returns convergence in radians
 *
 * @param {import('common/interfaces/dataset-db.interface').ICoordinates} coordinates - absolute coordinate (coordinate in offset format is not allowed)
 * @returns {number}
 */
export const getConvergence = (coordinates) => {
  const isInvalidInput =
    !coordinates?.epsgCode ||
    isOffsetType(coordinates?.epsgCode) ||
    !areAllArgumentsNumeric(coordinates?.x, coordinates?.y);
  if (isInvalidInput) {
    return 0;
  }
  const [latitude, longitude] = transformLocationCoordinate(
    coordinates.epsgCode,
    getDatumEPSG(coordinates.epsgCode),
    [coordinates.x, coordinates.y],
  );
  const epsgData = getLocationInfoByEpsg(coordinates.epsgCode);

  const lambda = (latitude * Math.PI) / 180; // latitude in radians
  const phi = (longitude * Math.PI) / 180; // longitude in radians

  const lambda0 = (epsgData.centralMeridian * Math.PI) / 180; // central meridian in radians
  const ellipsoid = epsgData.spheroid;

  const convergence = calculateConvergence(lambda, phi, lambda0, ellipsoid);

  return convergence;
};
