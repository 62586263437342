import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Page,
  Heading,
  Loader,
  Spinner,
  Divider,
  Row,
  Column,
  Button,
  Text,
  toast,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import {
  getWellDetails,
  linkProject,
  timeAgo,
  wellDetailsCleanup,
} from '~store/entities/well-details/well-details';
import { useParams } from 'react-router';
import { LinkProjectTable } from '~views/well-details/link-project-table';
import { DetailsForm } from '~views/well-details/details.form';
import { SchematicDeleteModal } from '~views/well-details/schematic-delete.modal';
import { WellDetailsDrawer } from '~views/well-details/drawer/well-details.drawer';
import { selectIsPageDisabled } from '~src/store/entities/projects/selectors';
import { projectPages } from '~src/enums/projects';
import { isEmpty } from 'lodash';
import { selectProjectInfo } from '~src/store/entities/well-details/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const WellDetails = ({
  isFetching,
  isPageDisabled,
  getWellDetails,
  well,
  linkProject,
  projectInfo,
  withWelldesignLicense,
  wellDetailsCleanup,
}) => {
  const { project } = useParams();
  const { t } = useTranslation();
  const [schematicModalVisible, setSchematicModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [linkProjectModalVisible, setLinkProjectModalVisible] = useState(false);

  useEffect(() => {
    getWellDetails(project);

    return () => wellDetailsCleanup();
  }, []);

  const onClickDelete = (e, id) => {
    setSelectedId(e.shiftKey ? null : id);
    setSchematicModalVisible(true);
  };

  const onClickLinkProject = () => {
    if (withWelldesignLicense) {
      setLinkProjectModalVisible(true);
    } else {
      toast({
        message: {
          type: 'Error',
          content: t(translations.thisFeatureRequiresAWellDesignLicense),
        },
      });
    }
  };

  const onClickUnlinkProject = () => {
    linkProject({ datasetId: 0, projectId: project });
  };

  const onClickUpdateProject = () => {
    linkProject({
      datasetId: projectInfo.datasetid,
      projectId: project,
    });
  };

  return (
    <Page padding={0} scroll={false}>
      {isFetching && (
        <Loader text={t(translations.fetching)} theme="light" cover>
          <Spinner dark />
        </Loader>
      )}
      <Row spacing={0} height="100%">
        <WellDetailsDrawer />
        <Column padding={true} scroll>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Heading top>{t(translations.wellDetails_wellDetails)}</Heading>
          </Flex>
          {isEmpty(projectInfo) && (
            <Button
              label={t(translations.wellDetails_linkToWellDesignProject)}
              onClick={onClickLinkProject}
            />
          )}
          {!isEmpty(projectInfo) && (
            <Flex gap="var(--padding)" alignItems="center">
              <Button
                label={`${projectInfo.country} / ${projectInfo.sort2} / ${projectInfo.sort4} / ${projectInfo.name}`}
                onClick={onClickLinkProject}
              />
              <Button
                label={t(translations.wellDetails_unlink)}
                onClick={onClickUnlinkProject}
              />
              <Button
                active={true}
                label={t(translations.wellDetails_fetchUpdatedData)}
                onClick={onClickUpdateProject}
              />
              <Text muted>
                Last fetched{' '}
                {well?.lastSynced && timeAgo(well?.lastSynced).toLowerCase()}
              </Text>
            </Flex>
          )}
          <Divider />
          <DetailsForm
            well={well}
            onClickDelete={onClickDelete}
            isPageDisabled={isPageDisabled}
          />
          {schematicModalVisible && (
            <SchematicDeleteModal
              setModalVisible={setSchematicModalVisible}
              schematicId={selectedId}
              projectId={project}
            />
          )}
          {linkProjectModalVisible && !isFetching && (
            <LinkProjectTable
              projectId={project}
              isPageDisabled={isPageDisabled}
              setLinkProjectModalVisible={setLinkProjectModalVisible}
              linkProject={linkProject}
            />
          )}
        </Column>
      </Row>
    </Page>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    wellDetails: { isFetching, well },
    authorization: { withWelldesignLicense },
  } = entities;

  return {
    isFetching,
    well,
    isPageDisabled: selectIsPageDisabled(entities, projectPages.WELL_DETAILS),
    projectInfo: selectProjectInfo({ entities }),
    withWelldesignLicense,
  };
};

const mapDispatchToProps = {
  getWellDetails,
  linkProject,
  wellDetailsCleanup,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(WellDetails),
  { isPage: true },
);

export { Container as WellDetails };
