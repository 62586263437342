export const typeQuantityMap = Object.freeze({
  'quantity-length': {
    type: 'quantity',
    quantity: 'length',
  },
  'quantity-volume': {
    type: 'quantity',
    quantity: 'volume',
  },
  lumpSum: { type: 'lumpSum', quantity: 'length' },
  dayRate: { type: 'dayRate', quantity: 'length' },
});
