/**
 * Compute grid position of local point using offset (conversion from offset [m] to grid [m] position)
 * @param {number[]} siteGridPos - position of site in grid coordinates.
 * @param {number} scaleFactor - scale factor of grid at site position.
 * @param {number} convergence - grid convergence at site. Set to 0 if using grid north. (radians)
 * @param {number[]} localPoint - local coordinate.
 * @returns {number[]} gridPos - grid coordainte of localPoint (absolute coordinate)
 */
export const fromLocal = (
  siteGridPos,
  scaleFactor,
  convergence,
  localPoint,
) => {
  const [E0, N0, H0] = siteGridPos;
  const [n, e, v] = localPoint;
  const gn = Math.cos(convergence) * n + Math.sin(convergence) * e;
  const ge = Math.cos(convergence) * e - Math.sin(convergence) * n;
  const gridPos = [E0 + ge * scaleFactor, N0 + gn * scaleFactor, H0 - v];
  return gridPos;
};

/**
 * Compute grid position of local point using offset (conversion from grid [m] to offset [m] position)
 * @param {number[]} siteGridPos - position of site in grid coordinates.
 * @param {number} scaleFactor - scale factor of grid at site position.
 * @param {number} convergence - grid convergence at site. Set to 0 if using grid north. (radians)
 * @param {number[]} gridPoint - grid coordinate.
 * @returns {number[]} localPos - local coordinate of gridPoint (offset coordinate).
 */
export const toLocal = (siteGridPos, scaleFactor, convergence, gridPoint) => {
  const [E0, N0, H0] = siteGridPos;
  const [E, N, H] = gridPoint;
  const ge = (E - E0) / scaleFactor;
  const gn = (N - N0) / scaleFactor;
  const n = gn * Math.cos(convergence) - ge * Math.sin(convergence);
  const e = ge * Math.cos(convergence) + gn * Math.sin(convergence);
  const v = H0 - H;
  const localPos = [n, e, v];
  return localPos;
};
