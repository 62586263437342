import { toast } from '@oliasoft-open-source/react-ui-library';

export const uploadTemplate = (onClickUpload) => {
  const input = document.createElement('input');
  input.type = 'file';

  input.onchange = (fileEvent) => {
    const file = fileEvent.target.files[0];

    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');

    reader.onload = (readEvent) => {
      try {
        const content = JSON.parse(readEvent.target.result);
        onClickUpload(content);
      } catch (error) {
        toast({
          message: {
            type: 'Error',
            content: 'Invalid file',
          },
        });
      }
    };
  };
  input.click();
};

export const downloadTemplate = (template) => {
  const templateAsText = JSON.stringify(template);
  const blob = new Blob([templateAsText], { type: 'text/plain' });
  const name = template.highLevel.name || 'ruleset';
  const fileName = name.concat('.json');

  const a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
