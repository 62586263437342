import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Column, Row } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { ResultChartGrid } from './result-chart-grid';
import { ResultOverviewDrawer } from './drawer/result-overview.drawer';
import './style.less';

const ResultOverview = () => {
  const { t } = useTranslation();
  return (
    <Page padding={false} scroll={false}>
      <Row spacing={0} flex>
        <ResultOverviewDrawer />
        <Column scroll>
          <div className="results-wrapper">
            <ResultChartGrid
              header={t(translations.simulations_resultOverview)}
              simulateButtons
              showResultHeader
            />
          </div>
        </Column>
      </Row>
    </Page>
  );
};

export default withErrorBoundary(ResultOverview, { isPage: true });
