export const actualDepthArr = (operation) => {
  const depthsArr = operation.activities.reduce((acc, activity, idx) => {
    if (idx === 0) {
      acc.push('');
    } else if (
      idx > 0 &&
      activity.plannedDepth === operation.activities[idx - 1].plannedDepth &&
      activity.actual
    ) {
      if (operation.activities[idx - 1].actualDepth) {
        acc.push(operation.activities[idx - 1].actualDepth);
      } else {
        acc.push(acc[idx - 1]);
      }
    } else {
      acc.push('');
    }
    return acc;
  }, []);
  return depthsArr;
};
