export const decimalSeparator = Object.freeze({
  DOT: 'dot',
  COMMA: 'comma',
});

export const HOURS_PER_DAY = 24;

export const GroupOrderType = Object.freeze({
  FIRST: 'first',
  MIDDLE: 'middle',
  LAST: 'last',
});
