import { sum } from 'lodash';

export const chartKeys = ['chartOne', 'chartTwo', 'chartThree', 'chartFour'];

export const getMean = (risk, tasks) => {
  const {
    distribution = 'None',
    min = 0,
    most = 0,
    max = 0,
    withBranch = false,
  } = risk;

  if (withBranch) {
    // Calculate mean from child branches
    const branches = tasks.filter(
      (task) => task.isBranch && task.parentId === risk.taskId,
    );
    return branches.reduce(
      (mean, branch) => mean + branch.certainty * 0.01 * getMean(branch),
      0,
    );
  }
  switch (distribution) {
    case 'spike':
      return most;
    case 'uniform':
      return (max + min) / 2;
    case 'pert':
      return (max + 4 * most + min) / 6;
    default:
      return 0;
  }
};

export const sectionObjectKeys = Object.freeze({
  DURATIONS: 'durations',
  COSTS: 'costs',
});
export const getSimulationDataForWaterfall = (
  simulationsResult,
  selectedDataEntity,
  HOURS_PER_DAY,
) => {
  const dataType =
    selectedDataEntity === 0
      ? sectionObjectKeys.DURATIONS
      : sectionObjectKeys.COSTS;
  const sections =
    selectedDataEntity === 0
      ? simulationsResult?.section_durations
      : simulationsResult?.section_costs;

  const values = sections?.[dataType]?.mean?.map((item) =>
    dataType === sectionObjectKeys.DURATIONS ? item / HOURS_PER_DAY : item,
  );

  const totalSum = sum(values);
  const threshold = totalSum > 10 ** 6;

  return { dataType, sections, values, totalSum, threshold };
};

export const normalizeValue = (value, threshold) => {
  return threshold ? parseFloat(value) / 1_000_000 : parseFloat(value);
};

export const getPercentiles = (simulationResult, percentile, entity) => {
  const percentileIdx = simulationResult?.percentiles?.findIndex(
    (value) => value === percentile,
  );
  const entityMap = {
    time: 'cumulative_percentiles',
    cost: 'cumulative_cost_percentiles',
  };
  return simulationResult?.[entityMap[entity]]?.[percentileIdx];
};
