import { Text } from '@oliasoft-open-source/react-ui-library';
import styles from '../notes.module.less';

export const MetaText = ({ children }: { children: string }) => (
  <div className={styles.metaText}>
    <Text small muted>
      {children}
    </Text>
  </div>
);
