import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Table, Card, Heading } from '@oliasoft-open-source/react-ui-library';
import { getLogs, logsCleanup } from '~store/entities/audit-log/audit-log';
import { LogsType } from '~src/enums/audit-log';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const AuditLog = ({ getLogs, logs, logsCleanup }) => {
  const { project } = useParams();
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    getLogs(project);

    return () => logsCleanup();
  }, []);

  const logTypesMap = {
    [LogsType.CreateEstimate]: t(translations.projects_estimateCreated),
    [LogsType.SyncWellDetails]: t(translations.projects_wellDetailsSynced),
    [LogsType.AddWellSchematicRow]: t(
      translations.projects_wellSchematicRowAdded,
    ),
    [LogsType.DeleteWellSchematicRow]: t(
      translations.projects_wellSchematicRowDeleted,
    ),
    [LogsType.AddOperation]: t(translations.projects_operationAdded),
    [LogsType.UpdateOperation]: t(translations.projects_operationUpdated),
    [LogsType.DeleteOperation]: t(translations.projects_operationDeleted),
    [LogsType.AddActivity]: t(translations.projects_activityAdded),
    [LogsType.AddActivityBulk]: t(translations.projects_activityAddedBulk),
    [LogsType.DeleteActivity]: t(translations.projects_activityDeleted),
    [LogsType.RunSimulation]: t(translations.projects_simulationRun),
    [LogsType.ConfirmModule]: t(translations.projects_moduleStatusUpdated),
    [LogsType.AddCostCategory]: t(translations.projects_costCategoryAdded),
    [LogsType.UpdateCostCategory]: t(translations.projects_costCategoryUpdated),
    [LogsType.DeleteCostCategory]: t(translations.projects_costCategoryDeleted),
    [LogsType.AddCostItem]: t(translations.projects_costItemAdded),
    [LogsType.UpdateCostItem]: t(translations.projects_costItemUpdated),
    [LogsType.DeleteCostItem]: t(translations.projects_costItemDeleted),
    [LogsType.AddRisk]: t(translations.projects_riskAdded),
    [LogsType.UpdateRisk]: t(translations.projects_riskUpdated),
    [LogsType.DeleteRisk]: t(translations.projects_riskDeleted),
  };

  const rowsPerPageOptions = [
    {
      label: `5 / ${t(translations.page)}`,
      value: 5,
    },
    {
      label: `10 / ${t(translations.page)}`,
      value: 10,
    },
    {
      label: `20 / ${t(translations.page)}`,
      value: 20,
    },
    {
      label: `50 / ${t(translations.page)}`,
      value: 50,
    },
    {
      label: t(translations.showAll),
      value: 0,
    },
  ];

  const firstVisibleRow = (selectedPage - 1) * rowsPerPage;
  const lastVisibleRow =
    rowsPerPage === 0 ? logs.length - 1 : firstVisibleRow + rowsPerPage;

  const table = {
    bordered: false,
    headers: [
      {
        cells: [
          {
            value: t(translations.operation),
          },
          {
            value: t(translations.details),
          },
          {
            value: t(translations.user),
          },
          {
            value: t(translations.time),
          },
        ],
      },
    ],
    rows: logs.slice(firstVisibleRow, lastVisibleRow).map((row) => {
      return {
        cells: [
          { value: logTypesMap[row.type] || '' },
          { value: row.details },
          { value: row.userName },
          { value: row.created },
        ],
      };
    }),
    footer: {
      pagination: {
        rowCount: logs.length,
        selectedPage,
        rowsPerPage: {
          onChange: (evt) => {
            const { value } = evt.target;
            setRowsPerPage(Number(value));
          },
          options: rowsPerPageOptions,
          value: rowsPerPage,
        },
        onSelectPage: (evt) => setSelectedPage(evt),
        small: true,
      },
    },
  };

  return logs.length === 0 ? null : (
    <Card heading={<Heading>{t(translations.changes)}</Heading>} padding={0}>
      <Table table={table} />
    </Card>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    auditLog: { logs },
  } = entities;

  return {
    logs,
  };
};

const mapDispatchToProps = { getLogs, logsCleanup };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(AuditLog),
);

export { Container as AuditLog };
