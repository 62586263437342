import translationEN from './langs/en/translation.json';
import translationRU from './langs/ru/translation.json';
import translationPT from './langs/pt/translation.json';

export const languageList = [
  {
    label: 'English',
    value: 'en',
    translation: translationEN,
    isoCode: 'EN',
  },
  {
    label: 'Русский язык',
    value: 'ru',
    translation: translationRU,
    isoCode: 'RU',
  },
  {
    label: 'Português',
    value: 'pt',
    translation: translationPT,
    isoCode: 'PT',
  },
];
