import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import {
  Input as GuiInput,
  Select,
  Text,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { onChangeInput } from './helpers';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const RawTemplateInput = ({
  name,
  control,
  errors,
  type = 'string',
  groupOrder = null,
  width = null,
  onChange = null,
  disabled = false,
  placeholder = '',
  templateVariables,
  clickOnTemplate = false,
  tags = '%',
  description = '',
}) => {
  const { t } = useTranslation();

  const getVariableWithTags = (text) => {
    if (typeof tags === 'string') {
      return `${tags}${text}${tags}`;
    } else if (Array.isArray(tags) && tags.length >= 2) {
      return `${tags[0]}${text}${tags[1]}`;
    } else if (Array.isArray(tags) && tags.length === 1) {
      return `${tags[0]}${text}${tags[0]}`;
    } else {
      return `${tags}${text}${tags}`;
    }
  };

  const renderText = () => {
    if (description && description.length > 1) {
      return (
        <Text small muted>
          {description}
        </Text>
      );
    }
    return null;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange: fieldOnChange, ...props } = field;

        const onClickVariable = (text) => {
          if (clickOnTemplate) {
            fieldOnChange(`${props.value} ${getVariableWithTags(text)}`);
          } else {
            navigator.clipboard.writeText(`${getVariableWithTags(text)}`);
          }
        };

        return (
          <>
            <GuiInput
              {...props}
              onChange={(e) => {
                onChangeInput(e, type, fieldOnChange, onChange);
              }}
              error={
                translations[errors[name]]
                  ? t(errors[name])
                  : errors[name] || null
              }
              groupOrder={groupOrder}
              width={width}
              disabled={disabled}
              placeholder={placeholder}
            />
            {renderText()}
            <Select
              small
              width="auto"
              placeholder="List of variables"
              options={templateVariables}
              onChange={(e) => {
                onClickVariable(e.target.value);
              }}
            />
          </>
        );
      }}
    />
  );
};

export const TemplateInput = withErrorBoundary(RawTemplateInput);
