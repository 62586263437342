import React from 'react';
import { useTranslation } from 'react-i18next';
import { timeCostView } from '~src/enums/simulations';
import { HOURS_PER_DAY } from '~src/enums/general';
import {
  LineChart,
  BarChart,
  ScatterChart,
} from '@oliasoft-open-source/charts-library';
import { connect } from 'react-redux';
import { selectPrimaryCurrency } from '~store/entities/company-settings/selectors';
import { simulationChartType } from '~src/enums/compare-estimates';
import translations from '~src/internationalisation/translation-map.json';
import { displayNumber, round } from '@oliasoft-open-source/units';
import { getLineColor } from '~src/views/simulations/simulations';

const CombinedChart = ({
  chartType,
  probabilityType,
  timeCostType,
  estimates,
  primaryCurrency,
  controlsPortalId,
}) => {
  const { t } = useTranslation();
  const handleOnHover = (ev, di, i, gd) => {
    const { chart } = ev;
    const { x, y } = gd[di].data[i];
    const value = t(
      timeCostType === timeCostView.TIME
        ? `${t(translations.days)}`
        : `${primaryCurrency}`,
    );

    const tooltipText = `P${y}: ${displayNumber(round(x, 2), {
      scientific: false,
    })} ${value}`;

    chart.config.options.plugins.tooltip.callbacks.label = (ctx) => {
      let label = ctx.dataset.label || '';
      if (label) {
        label = tooltipText;
      }
      return label;
    };

    chart.config.options.plugins.tooltip.callbacks.title = (ctx) => {
      let label = ctx[0].label || '';
      if (label) {
        label = '';
      }
      return label;
    };
  };

  const calculateCombinedCost = (data) => {
    if (data) {
      const sum = (arr) => {
        const allZeros = arr.every((num) => num === 0);
        if (allZeros) {
          return null;
        }
        return arr.reduce((acc, curr) => acc + curr, 0);
      };

      return round(sum(data), 2);
    } else return null;
  };

  const totalCosts = estimates
    .map((estimate) => {
      const costs =
        estimate.simulationResult?.cumulative_costs?.costs?.[probabilityType] ||
        [];
      const totalCost = costs.reduce((acc, cost) => Math.max(acc, cost), 0);
      return {
        cost: totalCost,
        label: estimate.name,
      };
    })
    .sort((a, b) => a.cost - b.cost);

  const isLowestCost = (cost) => {
    if (!cost || cost === 0) return false;
    const lowestCost = estimates.reduce((lowest, estimate) => {
      const newCost = calculateCombinedCost(
        estimate.simulationResult?.operation_costs?.costs[probabilityType],
      );
      return newCost ? Math.min(lowest, newCost) : lowest;
    }, Number.MAX_VALUE);
    return cost <= lowestCost;
  };
  const isLowestDuration = (duration) => {
    if (duration === 0) return false;
    const lowestDuration = estimates.reduce((lowest, estimate) => {
      const newDuration = calculateCombinedCost(
        estimate.simulationResult?.operation_durations?.durations[
          probabilityType
        ],
      );
      return newDuration ? Math.min(lowest, newDuration) : lowest;
    }, Number.MAX_VALUE);
    return duration <= lowestDuration;
  };

  const datasets =
    chartType === simulationChartType.TOTAL_COST
      ? [
          {
            data: totalCosts.map((totalCost) => totalCost.cost),
            backgroundColor: totalCosts.map((_totalCost, index) =>
              index === 0 ? getLineColor(3) : getLineColor(0),
            ),
            borderColor: getLineColor(0),
          },
        ]
      : estimates
          .map((estimate, index) => {
            if (
              !estimate?.simulationResult?.[
                simulationChartType.OPERATION_VS_DEPTH
              ] ||
              !estimate?.simulationResult?.[
                simulationChartType.OPERATION_COST_VS_DEPTH
              ]
            ) {
              return false;
            } else if (
              chartType === simulationChartType.SIMULATION_TOTAL_TIME_AND_COST
            ) {
              const x = calculateCombinedCost(
                estimate.simulationResult?.operation_costs?.costs[
                  probabilityType
                ],
              );
              const y = calculateCombinedCost(
                estimate.simulationResult?.operation_durations?.durations[
                  probabilityType
                ],
              );
              const color =
                isLowestCost(x) || isLowestDuration(y)
                  ? getLineColor(3)
                  : getLineColor(0);

              return {
                borderColor: color,
                pointBackgroundColor: color,
                label: estimate.name,
                pointRadius: 10,
                pointHoverRadius: 12,
                data: [{ x, y }],
              };
            }
            let data = [];
            if (chartType === simulationChartType.OPERATION_VS_DEPTH) {
              const chartData =
                estimate.simulationResult[
                  timeCostType === timeCostView.TIME
                    ? simulationChartType.OPERATION_VS_DEPTH
                    : simulationChartType.OPERATION_COST_VS_DEPTH
                ];

              data = chartData[probabilityType].depth.map((item, i) => ({
                y: item,
                x:
                  timeCostType === timeCostView.TIME
                    ? chartData[probabilityType][timeCostView.TIME][i] /
                      HOURS_PER_DAY
                    : chartData[probabilityType].costs[i],
              }));
            } else {
              const chartData =
                estimate.simulationResult[
                  timeCostType === timeCostView.TIME
                    ? simulationChartType.CUMULATIVE_PROBABILITY
                    : simulationChartType.CUMULATIVE_PROBABILITY_COST
                ];
              data = chartData[timeCostType].map((item, i) => ({
                y: i,
                x:
                  timeCostType === timeCostView.TIME
                    ? item / HOURS_PER_DAY
                    : item,
              }));
            }

            const color = getLineColor(index);

            return {
              borderColor: color,
              pointBackgroundColor: color,
              label: estimate.name,
              data,
            };
          })
          .filter((item) => item);

  const getXLabel = () => {
    if (chartType === simulationChartType.TOTAL_COST) {
      return '';
    }
    if (chartType === simulationChartType.SIMULATION_TOTAL_TIME_AND_COST) {
      return `${t(translations.costSetup_totalCost)} [${primaryCurrency}]`;
    }
    return timeCostType === timeCostView.TIME
      ? `${t(translations.time)} [${t(translations.days)}]`
      : `${t(translations.cost)} [${primaryCurrency}]`;
  };

  const getYLabel = () => {
    if (chartType === simulationChartType.TOTAL_COST) {
      return `${t(translations.cost)} [${primaryCurrency}]`;
    }
    if (chartType === simulationChartType.SIMULATION_TOTAL_TIME_AND_COST) {
      return `${t(translations.simulations_totalTime)} [${t(
        translations.days,
      )}]`;
    }
    return chartType === simulationChartType.CUMULATIVE_PROBABILITY ||
      chartType === simulationChartType.CUMULATIVE_PROBABILITY_COST
      ? ''
      : t(translations.depth);
  };

  let labels;
  if (chartType === simulationChartType.TOTAL_COST) {
    labels = totalCosts.map((totalCost) => totalCost.label);
  }

  let content;

  let chartOptions = {
    controlsPortalId,
    data: {
      labels,
      datasets,
    },
    options: {
      chartStyling: {
        height: 650,
      },
      additionalAxesOptions: {
        reverse:
          chartType !== simulationChartType.TOTAL_COST &&
          chartType !== simulationChartType.SIMULATION_TOTAL_TIME_AND_COST,
      },
      axes: {
        x: [
          {
            label: getXLabel(),
          },
        ],
        y: [
          {
            label: getYLabel(),
          },
        ],
      },
      scales: {
        x: {
          ticks: {
            callback: (val) => {
              return displayNumber(val, { scientific: false });
            },
          },
        },
      },
      tooltip: {
        scientificNotation: false,
      },
    },
  };

  switch (chartType) {
    case simulationChartType.CUMULATIVE_PROBABILITY:
      content = (
        <LineChart
          chart={{
            ...chartOptions,
            options: {
              ...chartOptions.options,
              interactions: {
                onHover: handleOnHover,
              },
            },
          }}
        />
      );

      break;

    case simulationChartType.OPERATION_VS_DEPTH:
      content = <LineChart chart={chartOptions} />;

      break;

    case simulationChartType.TOTAL_COST:
      content = <BarChart chart={chartOptions} />;

      break;
    case simulationChartType.SIMULATION_TOTAL_TIME_AND_COST:
      chartOptions = {
        ...chartOptions,
        options: {
          ...chartOptions.options,
          chartStyling: {
            ...chartOptions.options.chartStyling,
            width: '80%',
          },
        },
      };

      content = <ScatterChart chart={chartOptions} />;

      break;

    default:
      content = <LineChart chart={chartOptions} />;

      break;
  }

  return content;
};

const mapStateToProps = ({ entities }) => {
  const primaryCurrency = selectPrimaryCurrency(entities);

  return {
    primaryCurrency,
  };
};

const Container = connect(mapStateToProps)(CombinedChart);

export { Container as CombinedChart };
