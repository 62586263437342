import { Icon, Text, Spacer } from '@oliasoft-open-source/react-ui-library';

export const CellWithExpandingIcon = ({ cellContent, index, isExpanded }) => {
  const isFirstColumn = index === 0;
  return (
    <>
      {isFirstColumn && (
        <>
          <Icon icon={isExpanded ? 'chevron down' : 'chevron right'} />
          <Spacer width="12px" />
        </>
      )}
      <Text>{cellContent}</Text>
    </>
  );
};
