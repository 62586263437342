import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Field,
  InputGroup,
  Select,
  DisabledContext,
  // InputGroupAddon
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import {
  isGCSLocation,
  getAllLocationItems,
  getMapSystemKey,
  getDatumKeyByEPSG,
  getFirstDatumKeyByMapSystem,
  getAllMapSystemOptionsSorted,
  getDatumOptions,
  getLocationOptions,
  getUnitOptions,
  getEpsgCode,
} from '~common/gis/gis-library-utils';
import { DEFAULT_LOCATION_ITEM } from '~common/gis/constants';

const LocationSystemSelectors = ({
  locationEpsg = DEFAULT_LOCATION_ITEM.code,
  onEpsgChange,
  isReferenceLocation = false, // true if current location is reference for another OFFSET location
  disableLocationSelectors,
}) => {
  const { t } = useTranslation();
  const [mapSystem, setMapSystem] = useState(getMapSystemKey(locationEpsg));
  const [datum, setDatum] = useState(getDatumKeyByEPSG(locationEpsg));
  const [locationOption, setLocationOption] = useState(null);
  const allLocationItems = getAllLocationItems(mapSystem, datum);
  const [locationUnit, setLocationUnit] = useState(null);

  useEffect(() => {
    const updatedMapSystemKey = getMapSystemKey(locationEpsg);
    setMapSystem(updatedMapSystemKey);
    const updatedDatumKey = getDatumKeyByEPSG(locationEpsg);
    setDatum(updatedDatumKey);
    const allLocationItems = getAllLocationItems(
      updatedMapSystemKey,
      updatedDatumKey,
    );
    const selectedLocationItem =
      allLocationItems?.find((el) => el.code === locationEpsg) || {};
    const [selectedLocationOption = {}] = getLocationOptions([
      selectedLocationItem,
    ]);
    setLocationOption(selectedLocationOption);
    const locationUnitOptions = getUnitOptions(
      [selectedLocationItem],
      selectedLocationOption,
    );
    setLocationUnit(locationUnitOptions?.[0] ?? null);
  }, [locationEpsg]);

  if (!mapSystem || !datum) {
    return null;
  }

  const mapSystemOptions = getAllMapSystemOptionsSorted();
  const datumOptions = getDatumOptions(mapSystem);
  const locationOptions = getLocationOptions(allLocationItems);
  const locationUnitOptions =
    getUnitOptions(allLocationItems, locationOption) || [];
  const selectedLocationEpsg =
    getEpsgCode(allLocationItems, locationOption, locationUnit) || locationEpsg;
  const isDDInput = isGCSLocation(selectedLocationEpsg);

  const handleMapSystemChange = (e) => {
    const { value } = e.target || {};
    const updatedDatumKey = getFirstDatumKeyByMapSystem(value);
    const firstLocationItem =
      getAllLocationItems(value, updatedDatumKey)?.[0] || null;
    const [updatedLocationOption] = getLocationOptions([firstLocationItem]) || [
      null,
    ];
    const updatedUnitOption =
      getUnitOptions([firstLocationItem], updatedLocationOption)?.[0] || null;
    const updatedLocationEpsg = getEpsgCode(
      [firstLocationItem],
      updatedLocationOption,
      updatedUnitOption,
    );
    onEpsgChange(updatedLocationEpsg);
  };

  const handleDatumChange = (e) => {
    const { value } = e.target || {};
    const firstLocationItem =
      getAllLocationItems(mapSystem, value)?.[0] || null;
    const [updatedLocationOption] = getLocationOptions([firstLocationItem]) || [
      null,
    ];
    const updatedUnitOption =
      getUnitOptions([firstLocationItem], updatedLocationOption)?.[0] || null;
    const updatedLocationEpsg = getEpsgCode(
      [firstLocationItem],
      updatedLocationOption,
      updatedUnitOption,
    );
    onEpsgChange(updatedLocationEpsg);
  };

  const handleLocationChange = (e) => {
    const { value } = e.target || {};
    const allLocationItems = getAllLocationItems(mapSystem, datum);
    const relatedLocationItem = allLocationItems.find((el) => {
      return getLocationOptions([el])?.[0]?.value === value;
    });
    const [updatedLocationOption = null] =
      getLocationOptions([relatedLocationItem]) || [];
    const updatedUnitOption =
      getUnitOptions([relatedLocationItem], updatedLocationOption)?.[0] || null;
    const updatedLocationEpsg = getEpsgCode(
      [relatedLocationItem],
      updatedLocationOption,
      updatedUnitOption,
    );
    onEpsgChange(updatedLocationEpsg);
  };

  const handleUnitChange = (e) => {
    const { value } = e.target || {};
    //update location unit option
    const allLocationItems = getAllLocationItems(mapSystem, datum);
    const [relatedLocationItem = null] = allLocationItems.filter((el) => {
      //must fit location and unit option
      const [elAsLocationOption = null] = getLocationOptions([el]);
      const fitsLocation = elAsLocationOption?.value === locationOption.value;
      if (fitsLocation) {
        const [elAsUnitOption = null] = getUnitOptions(
          [el],
          elAsLocationOption,
        );
        return elAsUnitOption?.value === value;
      }
      return false;
    });
    const [updatedUnitOption = null] = getUnitOptions(
      [relatedLocationItem],
      locationOption,
    );
    const updatedLocationEpsg = getEpsgCode(
      [relatedLocationItem],
      locationOption,
      updatedUnitOption,
    );
    onEpsgChange(updatedLocationEpsg);
  };

  return (
    <DisabledContext.Provider value={disableLocationSelectors}>
      <Field label="Map System">
        <Select
          name="mapSystem"
          value={mapSystem}
          options={mapSystemOptions}
          onChange={handleMapSystemChange}
          width="200px"
          autoLayerWidth
          disabled={isReferenceLocation || !(mapSystemOptions?.length > 1)}
        />
      </Field>

      <Field label="Datum">
        <Select
          value={datum}
          options={datumOptions}
          onChange={handleDatumChange}
          width="200px"
          disabled={
            isReferenceLocation || !(datumOptions?.length > 1) || isDDInput
          }
        />
      </Field>

      <Field label={t(translations.location)} info={locationEpsg}>
        <InputGroup width="200px">
          <Select
            value={locationOption}
            options={locationOptions}
            onChange={handleLocationChange}
            width="100%"
            autoLayerWidth
            disabled={
              isReferenceLocation || !(locationOptions?.length > 1) || isDDInput
            }
          />
          <Select
            value={locationUnit}
            options={locationUnitOptions}
            onChange={handleUnitChange}
            width="auto"
            autoLayerWidth
            disabled={
              isReferenceLocation ||
              !(locationUnitOptions?.length > 1) ||
              isDDInput
            }
          />
        </InputGroup>
      </Field>
    </DisabledContext.Provider>
  );
};

export default LocationSystemSelectors;

LocationSystemSelectors.defaultProps = {
  onEpsgChange: (updatedEpsg) => updatedEpsg,
  isReferenceLocation: false,
};

LocationSystemSelectors.propTypes = {
  locationEpsg: PropTypes.string.isRequired,
  onEpsgChange: PropTypes.func,
  isReferenceLocation: PropTypes.bool,
};
