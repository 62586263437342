import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
} from '@oliasoft-open-source/react-ui-library';
import { saveProjectHierarchy } from '~store/entities/projects/projects';
import { Input } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { fieldsSchemaValidator } from '~schemas/ajv-validators';
import { project } from '~src/enums/projects';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { isNameIdentical } from '../utils';

const FieldModal = ({
  setModalVisible,
  saveProjectHierarchy,
  activeCountry,
  editItem,
  setEditItem,
  isAdding,
  fields,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      country: activeCountry?.name,
      projectCountryId: activeCountry?.projectCountryId,
      name: editItem?.name || '',
      projectFieldId: editItem?.projectFieldId || null,
    },
    resolver: getResolver(fieldsSchemaValidator),
  });
  const onSubmit = handleSubmit(async (data) => {
    if (
      isNameIdentical(
        data,
        fields,
        project.FIELDS_LIST,
        activeCountry,
        setError,
        'name',
      )
    ) {
      return;
    }
    delete data.country;
    await saveProjectHierarchy({ field: { ...data }, companyId: company });
    setModalVisible(false);
    setEditItem(null);
  });
  const onClose = () => {
    if (!isAdding) {
      setModalVisible(false);
      setEditItem(null);
    }
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: editItem
            ? t(translations.projects_editField)
            : t(translations.projects_addNewField),
          width: '600px',
          content: (
            <form>
              <Field
                label={t(translations.country)}
                labelLeft
                labelWidth="80px"
              >
                <Input
                  name="country"
                  control={control}
                  errors={errors}
                  disabled={true}
                />
              </Field>
              <Field label={t(translations.field)} labelLeft labelWidth="80px">
                <Input
                  name="name"
                  control={control}
                  errors={errors}
                  disabled={isAdding}
                />
              </Field>
            </form>
          ),
          footer: (
            <>
              <Button
                label={t(translations.done)}
                colored
                onClick={onSubmit}
                loading={isAdding}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => ({
  isAdding: entities.projects.isAdding,
  fields: entities.projects.lists.fields,
});
const mapDispatchToProps = { saveProjectHierarchy };
const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(FieldModal),
  { isModal: true },
);

export { Container as FieldModal };
