import { HOST_BASE_URL } from '~common/urls/urls';

const LOCALHOST = 'localhost';

interface IClientConfig {
  baseApiUrl: string;
  idpServerUrl: string;
  dashboardUrl: string;
  hostBaseUrl: string;
  applicationId: string;
  serverVersion: string;
  enableSessionMonitor: boolean;
}

const isLocalHost = window.location.hostname === LOCALHOST;

export default class ClientConfig {
  static get config(): IClientConfig {
    const runtimeConfig = window.__runtimeConfigStatic;
    const apiBaseUrl = runtimeConfig?.api_base_url;
    const idpBaseUrl = runtimeConfig?.authentication_server_base_url;
    const dashboardUrl = runtimeConfig?.dashboard_url;
    const serverVersion = runtimeConfig?.app_version;

    let enableSessionMonitor = true;
    if (window.Cypress) {
      enableSessionMonitor = false;
    }
    if (isLocalHost && !idpBaseUrl?.includes(LOCALHOST)) {
      enableSessionMonitor = false;
    }

    return {
      baseApiUrl: apiBaseUrl,
      idpServerUrl: idpBaseUrl,
      dashboardUrl,
      hostBaseUrl: HOST_BASE_URL,
      applicationId: 'forecast-api',
      serverVersion,
      enableSessionMonitor,
    };
  }
}
export const autoSaveWait = 1500;

export const autoSaveWaitShort = 200;
