import { useState } from 'react';
import {
  Column,
  Text,
  Spacer,
  ButtonGroup,
  Divider,
  Flex,
  Select,
  Grid,
  Card,
  Row,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { useTranslation } from 'react-i18next';
import { simulationChartType } from '~src/enums/simulations';
import { SimulationChart } from '~views/compare-estimates/simulation-chart';
import CombinedResult from '~views/compare-estimates/combined-result/combined-result';
import { resultViewMode } from '~src/enums/compare-estimates';
import { viewModeUpdated } from '~src/store/entities/compare-estimates/compare-estimates';
import { ConnectedProps, connect } from 'react-redux';
import { ICompareEstimates } from '~src/common/interfaces/compare-estimates.interfaces';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import type { TRootState } from '~src/store/store-types';

interface CompareEstimatesResults extends PropsFromRedux {
  visibleEstimates: ICompareEstimates[];
  estimateSelected?:boolean
}

type ResultViewMode = 'separate' | 'combined';

const CompareEstimatesResultsTab = ({
  viewModeUpdated,
  viewMode,
  visibleEstimates,
  estimateSelected,
}: CompareEstimatesResults) => {
  const { t } = useTranslation();

  const [dataEntity, setDataEntity] = useState(0);
  const [noChart, setNoChart] = useState(false);
  const [selectedItem, setSelectedItem] = useState(2);
  const [showTable, setShowTable] = useState(0);

  const selectOptions = [
    {
      label: t(translations.simulations_cumulativeProbability),
      value: simulationChartType.CUMULATIVE_PROBABILITY,
    },
    {
      label: t(
        dataEntity === 0
          ? translations.simulations_operationDurations
          : translations.simulations_operationCosts,
      ),
      value: simulationChartType.OPERATION_DURATIONS,
    },

    {
      label: t(
        dataEntity === 0
          ? translations.simulations_timeDepthPlot
          : translations.simulations_costDepthPlot,
      ),
      value: simulationChartType.TIME_DEPTH,
    },
    {
      label: t(translations.simulations_activityDurations),
      value: simulationChartType.ACTIVITY_DURATIONS,
    },
  ];

  return (
    <>
      <Spacer height={15} />
      <ButtonGroup
        items={[
          {
            key: resultViewMode.SEPARATE,
            label: t(translations.separate),
            testId: 'compare-estimation-separate-group-button',
          },
          {
            key: resultViewMode.COMBINED,
            label: t(translations.combined),
            testId: 'compare-estimation-combined-group-button',
          },
        ]}
        small
        onSelected={(key: ResultViewMode) => viewModeUpdated(key)}
        value={viewMode}
      />
      <Divider />
      {(viewMode as ResultViewMode) === resultViewMode.SEPARATE && (
        <>
          <Flex gap justifyContent="space-between">
            <Flex gap="12px">
              <Select
                name="result-overview-select"
                width="auto"
                options={selectOptions}
                small
                onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                  const { value } = ev.target;
                  const index = selectOptions.findIndex(
                    (el) => el.value === value,
                  );
                  setNoChart(value === simulationChartType.ACTIVITY_DURATIONS);
                  setShowTable(
                    value === simulationChartType.ACTIVITY_DURATIONS
                      ? 1
                      : showTable,
                  );
                  setDataEntity(
                    value === simulationChartType.ACTIVITY_DURATIONS
                      ? 0
                      : dataEntity,
                  );
                  setSelectedItem(index);
                }}
                value={selectOptions[selectedItem]}
              />
              <ButtonGroup
                items={[
                  { key: 0, icon: 'chart', disabled: noChart },
                  {
                    key: 1,
                    icon: 'table',
                    testId: 'compare-estimation-table-button',
                  },
                ]}
                small
                onSelected={(key: number) => setShowTable(key)}
                value={showTable ? 1 : 0}
              />
              <ButtonGroup
                items={[
                  {
                    key: 0,
                    label: t(translations.time),
                    value: 'time',
                  },
                  {
                    key: 1,
                    label: t(translations.cost),
                    value: 'cost',
                    disabled: noChart,
                  },
                ]}
                small
                onSelected={(key: number) => setDataEntity(key)}
                value={dataEntity}
              />
            </Flex>
          </Flex>
          <Spacer />
          <Grid columns="1fr 1fr">
            {visibleEstimates.map((estimate, i) => (
              <Card key={i} bordered={false}>
                <Column borderRight={i % 2 === 0} spacing={0}>
                  <Divider margin={0} />
                  <Spacer />
                  <Row justifyContent="center">
                    <Text muted>{estimate.name}</Text>
                  </Row>
                  <SimulationChart
                    estimate={estimate}
                    chartKey={i}
                    showTable={showTable}
                    selectOptions={selectOptions}
                    selectedItem={selectedItem}
                    dataEntity={dataEntity}
                  />
                </Column>
              </Card>
            ))}
          </Grid>
        </>
      )}
      {viewMode === resultViewMode.COMBINED && (
        <CombinedResult estimates={visibleEstimates} estimateSelected={estimateSelected}/>
      )}
    </>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const { viewMode } = entities.compareEstimates;
  return { viewMode };
};

const mapDispatchToProps = {
  viewModeUpdated,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(CompareEstimatesResultsTab));

export { Container as CompareEstimatesResultsTab };
