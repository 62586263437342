import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Select,
  Row,
  Button,
  Spacer,
} from '@oliasoft-open-source/react-ui-library';
import {
  addInitialCodesList,
  activateCodesList,
  activeFormUpdated,
  cloneCodesList,
} from '~store/entities/operation-codes/operation-codes';
import translations from '~src/internationalisation/translation-map.json';
import { CodesListDeleteModal } from '~views/settings/company-settings/operation-codes/codes-lists/codes-list-delete.modal';

const CodesLists = ({
  codesListsOptions,
  activeCodesList,
  addInitialCodesList,
  activateCodesList,
  activeFormUpdated,
  cloneCodesList,
}) => {
  const { t } = useTranslation();
  const [codesListModalVisible, setCodesListModalVisible] = useState(false);
  const onClickAdd = () => {
    addInitialCodesList(t(translations.settings_newCodesSet));
    activeFormUpdated(null);
  };
  const onClickClone = (codesListId) => {
    cloneCodesList(codesListId, t(translations.settings_newCodesSet));
    activeFormUpdated(null);
  };

  return (
    <Row spacing={0}>
      <Spacer width="var(--padding-sm)" />
      <Select
        width="200px"
        options={codesListsOptions}
        value={activeCodesList?.codesListId}
        onChange={(evt) => {
          activateCodesList({ codesListId: evt.target.value });
        }}
        disabled
      />
      {!activeCodesList?.default && (
        <>
          <Button
            name="clone"
            onClick={() => onClickClone(activeCodesList?.codesListId)}
            basic
            colored="muted"
            icon="clone"
          />
          <Button
            name="delete"
            onClick={() => setCodesListModalVisible(true)}
            basic
            colored="muted"
            icon="delete"
          />
          <Button
            name="add"
            onClick={onClickAdd}
            basic
            colored="muted"
            icon="add"
          />
        </>
      )}
      {codesListModalVisible && (
        <CodesListDeleteModal
          codesListId={activeCodesList?.codesListId}
          setModalVisible={setCodesListModalVisible}
        />
      )}
    </Row>
  );
};

const mapStateToProps = ({ entities }, ownProps) => {
  const { isFetching } = entities.operationCodes;
  const codesListsOptions = ownProps.codesLists.map((codesList) => ({
    label: codesList.name,
    value: codesList.codesListId,
  }));

  return { codesListsOptions, isFetching };
};

const mapDispatchToProps = {
  addInitialCodesList,
  activateCodesList,
  activeFormUpdated,
  cloneCodesList,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(CodesLists);

export { Container as CodesLists };
