import { round } from '@oliasoft-open-source/units';

/**
 * use function to make rounding consistent over LocationInput component
 * @param {string|number} value - cartesian coordinate single value of offset east, offset north, easting or northing
 * @return {string|number} - rounded value
 */
export const roundCartesianInput = (value) => {
  return round(value, 3);
};
