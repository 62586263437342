import { wellConfiguration } from '~src/enums/settings';
import {
  IBackgroundElement,
  IBackground,
  IMeta,
  TWellDetails,
  casingRenderStyle,
} from '../utils/types';

export const calculateLithology = (
  wellDetails: TWellDetails,
  meta: IMeta,
): IBackground => {
  const backgrounds: IBackgroundElement[] = [];

  const { airColor, waterColor, rockColor } = casingRenderStyle;

  backgrounds.push({
    chartId: 'lithology-background-air',
    top: 0,
    bottom: meta.drillFloorHeight,
    color: airColor,
  });

  if (wellDetails.wellConfiguration === wellConfiguration.OFFSHORE) {
    backgrounds.push({
      chartId: 'lithology-background-water',
      top: meta.drillFloorHeight,
      bottom: meta.seabed,
      color: waterColor,
    });
  }

  backgrounds.push({
    chartId: 'lithology-background-ground',
    top: meta.seabed,
    bottom: meta.totalDepth,
    color: rockColor,
  });

  const lithology: IBackground = {
    backgrounds,
  };

  return lithology;
};
