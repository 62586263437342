import { ListSubheading, List } from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { connect, ConnectedProps } from 'react-redux';
import { sectionSelected } from '~src/store/entities/sections-data-structure/sections-data-structure';
import { ISection } from '~src/common/interfaces/sections-data-structure.interfaces';

interface SectionListProps extends PropsFromRedux {
  sections: ISection[];
}

const SectionList = ({ sections, sectionSelected }: SectionListProps) => {
  const { t } = useTranslation();
  const sectionsList = sections.map((section) => ({
    id: section.sectionId,
    name: section.name,
    active: section.active,
    onClick: () => sectionSelected(section.sectionId),
    metaCount: section.metaCount,
  }));
  return (
    <>
      <ListSubheading
        item={{
          name: t(translations.sections),
        }}
      />
      <List
        list={{
          items: sectionsList,
        }}
        noHeader
      />
    </>
  );
};

const mapDispatchToProps = { sectionSelected };

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = connector(SectionList);

export { Container as SectionList };
