import { useState, useEffect, useRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import {
  Heading,
  Column,
  Table,
  Spacer,
  Flex,
} from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { validateString } from '~src/validation/common/validate-string';
import { validateNumber } from '~src/validation/common/validate-number';
import { getCurrencies } from '~src/store/entities/company-settings/company-settings';
import {
  addPriceBook,
  getPriceBook,
  priceBookCellValueUpdated,
  updatePriceBook,
  removePriceBookItem,
  initialPriceBook,
  importPriceBook
} from '~src/store/entities/price-book/price-book';
import { getSelectOptions } from '~src/common/lists/lists';
import { IPriceBookItem } from '~src/common/interfaces/price-book.interfaces';
import { debounce } from 'lodash';
import { autoSaveWait } from '~src/config/config';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { toNum } from '@oliasoft-open-source/units';
import type { TRootState } from '~src/store/store-types';
import { dataHeaders, downloadPriceBookExcel, filterAndSortDataRows, headings, typeList, uploadPriceBookExcel } from './utils';



const PriceBook = ({
  currenciesList,
  priceBook,
  isAdding,
  isUpdating,
  getCurrencies,
  addPriceBook,
  getPriceBook,
  priceBookCellValueUpdated,
  updatePriceBook,
  removePriceBookItem,
  importPriceBook
}: PropsFromRedux) => {
  const { t } = useTranslation();

  const debounceUpdatePriceBook = useRef(
    debounce(updatePriceBook, autoSaveWait),
  );

  const [filters, setFilters] = useState({});
  const [sorts, setSorts] = useState({});

  useEffect(() => {
    getCurrencies();
    getPriceBook();
  }, []);

  const { dataSortCells, dataFilterCells } = dataHeaders(
    headings,
    filters,
    setFilters,
    sorts,
    setSorts,
  );

  const filteredAndSortedData = filterAndSortDataRows(
    priceBook,
    filters,
    sorts,
  );



  const fieldConfigs: { [key: string]: any } = {
    name: { type: 'Input', errorFn: validateString },
    vendor: { type: 'Input', errorFn: validateString },
    price: {
      type: 'NumberInput',
      errorFn: (val: string) => validateNumber(+val),
    },
    currency: {
      type: 'Select',
      options: currenciesList,
      autoLayerWidth: true,
    },
    priceType: {
      type: 'Select',
      options: typeList,
      autoLayerWidth: true,
    },
  };

  const dataRows = [
    ...filteredAndSortedData.map((dataRow) => {
      const rowsCells = Object.entries(dataRow)
        .filter(([key]) => key !== 'priceBookId' && key !== 'companyId')
        .map(([key, value]) => ({
          key,
          value,
          ...fieldConfigs[key],
          onChange: (
            ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
          ) => {
            onChangePriceBookList(dataRow, ev, key);
          },
        }));
      return {
        cells: rowsCells,
        actions: [
          {
            label: 'Delete',
            icon: 'minus',
            onClick: () => removePriceBookItem(dataRow.priceBookId as string),
          },
        ],
      };
    }),
  ];

  const onAddPriceBook = () => addPriceBook(initialPriceBook);

  const onChangePriceBookList = (
    priceBook: IPriceBookItem,
    ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    name: string,
  ) => {
    const newValue =
      name === 'price' ? toNum(ev.target.value) : ev.target.value;
    priceBookCellValueUpdated({
      id: priceBook.priceBookId as string,
      value: newValue,
      field: name as keyof IPriceBookItem,
    });

    if (newValue) {
      debounceUpdatePriceBook.current(priceBook.priceBookId as string, {
        ...priceBook,
        [name]: newValue,
      });
    }
  };

  const columnWidths = ['35%', '20%', '15%', '15%', '15%'];
  const columnAlignment = ['left', 'left', 'left', 'left', 'left'];

  const headerActions = [
    {
      icon: 'add',
      label: 'Add',
      onClick: onAddPriceBook,
      primary: true,
      disabled: isAdding || isUpdating,
    },
  ];

  const tableConfig = {
    columnWidths,
    columnAlignment,
    actions: [
      {
        icon: 'download',
        tooltip: t(translations.timeTracker_download),
        onClick: () => downloadPriceBookExcel({ priceBook, currenciesList }),
      },
      {
        icon: 'upload',
        tooltip: t(translations.timeTracker_upload),
        onClick: () => uploadPriceBookExcel(importPriceBook),
      },
    ],
    actionsRight: true,
    headers: [
      {
        actions: headerActions,
        cells: dataSortCells,
      },
      {
        cells: dataFilterCells,
      },
    ],
    rows: dataRows,
  };

  return (
    <Column padding spacing={0}>
      <Flex justifyContent="space-between" gap>
        <Heading top>{t(translations.priceBook)}</Heading>
      </Flex>
      <Table table={tableConfig} />
      <Spacer />
    </Column>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const { currenciesList } = entities.companySettings;
  const { priceBookItems, isAdding, isUpdating } = entities.priceBook;
  return {
    currenciesList: getSelectOptions(currenciesList, 'code', 'code'),
    priceBook: priceBookItems,
    isAdding,
    isUpdating,
  };
};

const mapDispatchToProps = {
  getCurrencies,
  addPriceBook,
  getPriceBook,
  priceBookCellValueUpdated,
  updatePriceBook,
  removePriceBookItem,
  importPriceBook
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(PriceBook));

export { Container as PriceBook };
