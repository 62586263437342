import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import { navigateToRoute } from '~src/store/navigation/navigation';
import { routes } from '~views/navigation/routes/routes';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CreateModelErrorsModal = ({
  clearCreateModelFlags,
  isSchematicEmpty,
  isTemplatesEmpty,
  navigateToRoute,
  company,
  project,
}) => {
  const { t } = useTranslation();
  const onClose = () => clearCreateModelFlags();
  let heading = '';
  let content = '';
  if (isSchematicEmpty) {
    heading += t(translations.wellSchematicNotDefined);
    content += t(translations.weRequireMoreInformationAboutYourWell);
  }
  if (isTemplatesEmpty) {
    heading += t(translations.rulesetsNotDefined);
    content += t(translations.weRequireDefinedRulesets);
  }
  content += t(translations.toAutomaticallyGenerateOperations);

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading,
          content,
          footer: (
            <>
              {!isSchematicEmpty && (
                <Button
                  label="Edit well details"
                  colored
                  onClick={() => {
                    clearCreateModelFlags();
                    navigateToRoute(routes.wellConfiguration, {
                      project,
                      company,
                    });
                  }}
                />
              )}
              {!isTemplatesEmpty && (
                <Button
                  label="Edit templates"
                  colored
                  onClick={() => {
                    clearCreateModelFlags();
                    navigateToRoute(routes.userSettings.nested.templates);
                  }}
                />
              )}
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = { navigateToRoute };
const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(CreateModelErrorsModal),
  { isModal: true },
);

export { Container as CreateModelErrorsModal };
