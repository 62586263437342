import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Page,
  Row,
  Column,
  List,
  Text,
  Drawer,
} from '@oliasoft-open-source/react-ui-library';
import { routes } from '~routes/routes';
import { isActiveRoute, navigateToRoute } from '~store/navigation/navigation';
import { userSettings } from '~src/enums/settings';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const UserSettings = ({ navigateToRoute, pathname }) => {
  const { t } = useTranslation();
  const items = userSettings.map((item) => ({
    ...item,
    name:
      item.id === 'kpi' ? (
        <Column>
          <Row>
            <Text>{t(item.name)}</Text>
          </Row>
        </Column>
      ) : (
        t(item.name)
      ),
    active: isActiveRoute(routes.userSettings.nested[item.id], pathname),
    onClick: () => navigateToRoute(routes.userSettings.nested[item.id]),
  }));

  const settingsList = {
    name: t(translations.settings_userSettings),
    items,
  };
  const [drawerWidth, setDrawerWidth] = useState(300);
  return (
    <Page left={0} padding={false}>
      <Row spacing={0} height="100%">
        <Drawer
          width={drawerWidth}
          closedWidth={50}
          open
          button
          border
          onResize={(width) => setDrawerWidth(width)}
        >
          <Column scroll width="250px" borderRight spacing={0}>
            <List list={settingsList} drawer />
          </Column>
        </Drawer>
        <Outlet />
      </Row>
    </Page>
  );
};

const mapStateToProps = ({ router }) => ({
  pathname: router.location.pathname,
});
const mapDispatchToProps = { navigateToRoute };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(UserSettings),
  { style: { position: 'absolute', top: '50px' } },
);

export { Container as UserSettings };
