import { ReactNode } from 'react';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { curveBasis, line } from 'd3-shape';
import 'd3-transition';
import FauxDOM from 'react-faux-dom';
import { drawBackground } from './background';
import { drawAxes } from './axes';
import { drawSections, drawSpaceInsideSections } from './sections';
import { drawFluids } from './fluids';
import { drawHoles } from './holes';
import { drawDepthLabels } from './depth-labels';
import { IChartData, TDrawUtils } from '../utils/types';

type TGenerateChartParams = {
  chartData: IChartData;
};

export const generateChart = ({
  chartData,
}: TGenerateChartParams): ReactNode => {
  const { height, seabed, width } = chartData.meta;
  const {
    height: gridHeight,
    maxDepth,
    maxX,
    minDepth,
    minX,
    paddingLeft: gridPaddingLeft,
    paddingTop: gridPaddingTop,
    width: gridWidth,
  } = chartData.grid;

  const diameterToGridOffset = scaleLinear()
    .domain([minX, maxX])
    .range([gridPaddingLeft, gridPaddingLeft + gridWidth]);

  const seabedY = minDepth < seabed ? gridPaddingTop + 40 : gridPaddingTop;
  const depthToGridOffset = scaleLinear()
    .domain([minDepth, seabed, maxDepth])
    .range([gridPaddingTop, seabedY, gridPaddingTop + gridHeight]);

  // Used to display the grid.
  // NOTE: Use depthToGridOffset for other purposes - it should calculate correct point over and below seabed.
  const depthBelowSeabedToGridOffset = scaleLinear()
    .domain([seabed, maxDepth])
    .range([seabedY, gridPaddingTop + gridHeight]);

  // Common tools that drawers may use when drawing something to the chart
  const drawUtils: TDrawUtils = {
    // Tools for converting trajectory points to their pixel position in the chart
    depthBelowSeabedToGridOffset,
    diameterToGridOffset,
    depthToGridOffset,

    // Used before gridLine to calculate points at the other/left side of the trajectory
    mirror: (arr) => arr.map(([radius, depth]) => [-radius, depth]),
    gridMirror: (arr) => {
      const midX = diameterToGridOffset(0);
      return arr.map(([x, y]) => [2 * midX - x, y]);
    },

    // Takes an array of points [inches, depth] and creates a line through these points
    gridLine: line()
      .x((point) => diameterToGridOffset(point[0]))
      .y((point) => depthToGridOffset(point[1])) as TDrawUtils['gridLine'],

    curvedGridLine: line()
      .x((point) => diameterToGridOffset(point[0]))
      .y((point) => depthToGridOffset(point[1]))
      .curve(curveBasis) as TDrawUtils['curvedGridLine'],

    // Takes an array of pixel points [x, y] and creates a line through these points
    diagramLine: line()
      .x((point) => point[0])
      .y((point) => point[1]) as TDrawUtils['diagramLine'],

    curvedDiagramLine: line()
      .x((point) => point[0])
      .y((point) => point[1])
      .curve(curveBasis) as TDrawUtils['curvedDiagramLine'],
  };

  // Create chart
  const chartContainer = select(FauxDOM.createElement('div'))
    .style('position', 'relative')
    .style('width', `${Math.max(0, width)}px`)
    .style('height', `${Math.max(0, height)}px`);

  const chart = chartContainer
    .append('svg')
    .attr('viewBox', `0 0 ${Math.max(0, width)} ${Math.max(0, height)}`)
    .style('overflow', 'visible');

  const drawParameters = { chart, chartData, drawUtils };

  drawBackground(drawParameters);
  drawSpaceInsideSections(drawParameters);
  drawFluids(drawParameters);
  drawHoles(drawParameters);
  drawSections(drawParameters);
  drawAxes(drawParameters);
  drawDepthLabels(drawParameters);

  return chartContainer.node()?.toReact() ?? null;
};
