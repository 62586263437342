import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  List,
  ListHeading,
  ListSubheading,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import {
  operationSelected,
  reorderOperations,
} from '~store/entities/activity-model/activity-model';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import { selectOperationListByActiveSection } from '~src/store/entities/activity-model/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const OperationsList = ({
  operations,
  activeSection,
  operationSelected,
  reorderOperations,
  setActiveForm,
  setOperationModalVisible,
  onClickAddOperation,
  setSelectedId,
  isAdding,
  expandAllItems,
  isPageDisabled,
  activeOperations,
  sectionItems,
  operationsByActiveSession,
}) => {
  useEffect(() => {
    if (Array.isArray(operations) && operations.length > 1) {
      reorderOperations(null, operations, sectionItems);
    }
  }, [sectionItems]);

  const onClickDelete = (e, id) => {
    setSelectedId(e.shiftKey ? null : id);
    setOperationModalVisible(true);
  };

  const onClickItem = async (id) => {
    await operationSelected(id);
    await setActiveForm('operation');
    await expandAllItems();
  };
  const operationName = activeOperations.filter(
    (op) => op.projectSectionId === activeSection.projectSectionId,
  )[0]?.name;

  const disabled = isAdding || isPageDisabled;

  const items = operations
    .filter(
      (operation) =>
        operation.projectSectionId === activeSection.projectSectionId,
    )
    .map((operation) => ({
      id: operation.operationId,
      name: operation.name,
      draggable: true,
      active: operation.active,
      onClick: () => onClickItem(operation.operationId),
      actions: [
        {
          label: 'Delete',
          icon: 'delete',
          onClick: (e) => onClickDelete(e, operation.operationId),
          disabled,
        },
      ],
    }));

  return (
    <>
      <ListHeading name={activeSection.name} />
      <ListSubheading
        item={{
          name: 'Operations',
          actions: [
            {
              label: 'Add',
              icon: isAdding ? <Spinner tiny dark /> : 'add',
              disabled,
              primary: true,
              onClick: () =>
                onClickAddOperation(
                  activeSection.projectSectionId,
                  null,
                  operationName,
                ),
            },
          ],
        }}
      />
      <List
        list={{
          items,
        }}
        draggable={!isPageDisabled}
        noHeader
        onListReorder={({ from, to }) => {
          if (from === undefined || to === undefined) {
            return;
          }
          reorderOperations(
            { from, to },
            operations,
            sectionItems,
            operationsByActiveSession,
            activeSection.projectSectionId,
          );
        }}
      />
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const { operations: activeOperations } =
    selectSectionsDataStructure(entities);

  return {
    operations: entities.activityModel.operations,
    operationsByActiveSession: selectOperationListByActiveSection({ entities }),
    isFetching: entities.wellDetails.isFetching,
    activeOperations,
    sectionItems: entities.activityModel.sectionItems,
  };
};

const mapDispatchToProps = {
  operationSelected,
  reorderOperations,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(OperationsList),
);

export { Container as OperationsList };
