import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  toast,
} from '@oliasoft-open-source/react-ui-library';
import {
  addEstimates,
  updateLearningCurveFlag,
} from '~store/entities/campaigns/campaigns';
import { getResolver } from '~src/validation/resolver';
import { estimatesSchemaValidator } from '~schemas/ajv-validators';
import translations from '~src/internationalisation/translation-map.json';
import { HierarchyTable } from '~src/views/campaigns/hierarchy-table/hierarchy-table';

const EstimateModal = ({
  campaign,
  estimateModalUpdated,
  isAdding,
  estimatesOptions,
  addEstimates,
  updateLearningCurveFlag,
}) => {
  const { t } = useTranslation();

  const existingEstimates = campaign?.estimates.map((item) => ({
    projectId: item.projectId,
  }));

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      estimates: existingEstimates,
    },
    resolver: getResolver(estimatesSchemaValidator),
    mode: 'onChange',
  });
  useEffect(() => {
    reset({ estimates: [] });
  }, []);

  const { append, remove } = useFieldArray({
    control,
    name: 'estimates',
  });

  const watchEstimates = watch('estimates');

  const onSubmit = handleSubmit((data) => {
    addEstimates(campaign.campaignId, data);
    if (campaign.learningCurve) {
      updateLearningCurveFlag(campaign.campaignId);
      toast({
        message: {
          type: 'Warning',
          content: t(
            translations.campaigns_anAdjustmentNeedsToBeAppliedForAllEstimatesToApplyTheLearningCurveToYourCampaign,
          ),
        },
      });
    }
  });

  const onClose = () => estimateModalUpdated(false);

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.campaigns_chooseWellbores),
          width: 'auto',
          content: (
            <div>
              <HierarchyTable
                estimatesList={estimatesOptions}
                watchEstimates={watchEstimates}
                append={append}
                remove={remove}
              />
            </div>
          ),
          footer: (
            <>
              <Button
                label={t(translations.add)}
                colored
                onClick={onSubmit}
                disabled={isAdding}
                loading={isAdding}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }, ownProps) => {
  const { isAdding, estimatesList } = entities.campaigns;
  const existingEstimates = ownProps.campaign.estimates.map(
    (estimate) => estimate.projectId,
  );
  const estimatesOptions = estimatesList
    .filter((estimate) => !existingEstimates.includes(estimate.projectId))
    .map((estimate) => ({
      name: estimate.name,
      projectId: estimate.projectId,
      wellboreName: estimate.wellboreName,
      wellName: estimate.wellName,
      siteName: estimate.siteName,
      countryName: estimate.countryName,
    }));

  return {
    isAdding,
    estimatesOptions,
  };
};

const mapDispatchToProps = {
  addEstimates,
  updateLearningCurveFlag,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(EstimateModal);

export { Container as EstimateModal };
