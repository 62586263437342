import React from 'react';
import { connect } from 'react-redux';
import { ButtonGroup } from '@oliasoft-open-source/react-ui-library';
import { updateNumberOfCharts } from '~store/entities/ui/simulations/chart-controls';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const plotMenu = [
  { key: 1, label: null, value: 'one', icon: 'layout single' },
  { key: 2, label: null, value: 'two', icon: 'layout columns' },
  { key: 4, label: null, value: 'four', icon: 'layout grid' },
];

const ChartLayoutSwitcher = ({ numberOfCharts, updateNumberOfCharts }) => {
  return (
    <ButtonGroup
      items={plotMenu}
      onSelected={(key) => {
        updateNumberOfCharts(key);
      }}
      value={numberOfCharts}
      small
    />
  );
};

const mapStateToProps = ({ entities }) => {
  const { numberOfCharts } = entities.ui.chartControls.charts;
  return {
    numberOfCharts,
  };
};

const mapDispatchToProps = {
  updateNumberOfCharts,
};

const ConnectedChartLayoutSwitcher = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(ChartLayoutSwitcher),
);
export { ConnectedChartLayoutSwitcher as ChartLayoutSwitcher };
