import { createSelector } from '@reduxjs/toolkit';

const selectTimeTracker = (entities) => entities.timeTracker;

export const selectIsTimeTrackerLatest = createSelector(
  [selectTimeTracker],
  ({ isLatestVersion }) => isLatestVersion,
);

export const selectTimeTrackerFetching = createSelector(
  [selectTimeTracker],
  ({ isFetching }) => isFetching,
);
