import React from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Heading, Table, Card } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { createInitialBranch } from '~store/entities/user-settings/user-settings';
import { distributionTypes, estimateTypes } from '~src/enums/tasks';

const BranchesTable = ({
  branches,
  setBranches,
  branchesErros,
  riskId,
  estimateType,
  distributionType,
}) => {
  const { t } = useTranslation();

  const onAddBranch = (parentId) => {
    const newBranch = createInitialBranch(parentId);
    setBranches((prev) => [...prev, newBranch]);
  };

  const onDeleteBranch = (id) => {
    setBranches((prev) => prev.filter((risk) => risk.id !== id));
  };

  const onChangeBranch = (value, field, branchId) => {
    setBranches((prev) => {
      const branchIndex = prev.findIndex((branch) => branch.id === branchId);
      const updatedBranches = cloneDeep(prev);
      if (branchIndex !== -1) {
        updatedBranches[branchIndex][field] = value;
      }
      return updatedBranches;
    });
  };

  const quantity =
    estimateType === estimateTypes.OPERATION_TIME ? 'hr' : 'm/hr';
  const distributionFieldMap = {
    [distributionTypes.PERT]: [
      {
        key: 'min',
        name: t(translations.minimum),
        quantity,
      },
      {
        key: 'most',
        name: t(translations.mostLikely),
        quantity,
      },
      {
        key: 'max',
        name: t(translations.maximum),
        quantity,
      },
    ],
    [distributionTypes.UNIFORM]: [
      {
        key: 'min',
        name: t(translations.minimum),
        quantity,
      },
      {
        key: 'max',
        name: t(translations.maximum),
        quantity,
      },
    ],
    [distributionTypes.SPIKE]: [
      {
        key: 'most',
        name: t(translations.mostLikely),
        quantity,
      },
    ],
  };

  const rowsParams = [
    {
      key: 'name',
      name: t(translations.name),
    },
    {
      key: 'certainty',
      name: t(translations.probability),
      quantity: '%',
    },
    ...distributionFieldMap[distributionType],
    ...(estimateType === estimateTypes.OPERATION_SPEED
      ? [
          {
            key: 'from',
            name: t(translations.from),
            quantity: 'm',
          },
          {
            key: 'to',
            name: t(translations.to),
            quantity: 'm',
          },
        ]
      : []),
  ];

  const headerRow = rowsParams.map(({ name }) => ({
    value: name,
  }));

  const unitRow = rowsParams.map(({ quantity }) => {
    if (!quantity) return {};
    return {
      value: quantity,
    };
  });

  const actions = [
    {
      label: t(translations.add),
      primary: true,
      icon: 'add',
      onClick: () => onAddBranch(riskId),
    },
  ];

  const tableRows = branches.map((branch, index) => {
    const errors = t(branchesErros?.[index]);
    const cells = rowsParams.map(({ key }) => {
      const value = branch[key];
      const error = errors?.[key];

      return {
        type: key === 'name' ? 'Input' : 'NumberInput',
        name: key,
        value,
        error,
        onChange: (e) => {
          let replaceComasToDotValue = e.target.value.replace(/,/, '.');
          if (key !== 'name') {
            replaceComasToDotValue =
              replaceComasToDotValue.endsWith('.') ||
              (replaceComasToDotValue.includes('.') &&
                replaceComasToDotValue.endsWith('0'))
                ? replaceComasToDotValue
                : parseFloat(replaceComasToDotValue) || 0;
          }
          onChangeBranch(replaceComasToDotValue, key, branch.id);
        },
      };
    });

    return {
      cells,
      actions: [
        {
          label: t(translations.delete),
          icon: 'minus',
          onClick: () => onDeleteBranch(branch.id),
        },
      ],
    };
  });

  const table = {
    bordered: false,
    headers: [{ cells: headerRow, actions }, { cells: unitRow }],
    rows: tableRows,
    footer: {},
  };

  return (
    <>
      <Card
        heading={
          <Heading>
            {t(translations.branches)}
            {branches.length ? ` (${branches.length})` : null}
          </Heading>
        }
        padding={0}
      >
        <Table table={table} />
      </Card>
    </>
  );
};

export { BranchesTable };
