import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Select,
  ButtonGroup,
  Flex,
  OptionDropdown,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { simulationChartType, timeCostView } from '~src/enums/simulations';
import {
  getOperations,
  operationsCleared,
  getSections,
} from '~store/entities/activity-model/activity-model';
import { useParams } from 'react-router';
import {
  dataTypeUpdated,
  viewTypeUpdated,
  timeCostTypeUpdated,
} from '~src/store/entities/ui/simulations/chart-controls';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import ResultChart from './result-chart';
import ResultTable from './result-table';
import { chartKeys } from './result-utils';
import './style.less';

const ResultContent = ({
  simulationsResult,
  getOperations,
  getSections,
  chartKey,
  dataTypeUpdated,
  viewTypeUpdated,
  timeCostTypeUpdated,
  charts,
  operations,
  sectionItems,
  operationsCleared,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isOverviewPage = pathname?.includes('/overview');
  const { project } = useParams();

  useEffect(() => {
    if (project) {
      getOperations(project);
      getSections(project);
    }
    return () => operationsCleared();
  }, [project]);

  useEffect(() => {
    if (isOverviewPage) {
      dataTypeUpdated({ key: chartKeys[0], values: selectOptions[8] });
      dataTypeUpdated({ key: chartKeys[1], values: selectOptions[0] });
      timeCostTypeUpdated({ key: chartKeys[0], values: 1 });
    } else {
      dataTypeUpdated({ key: chartKeys[0], values: selectOptions[1] });
      dataTypeUpdated({ key: chartKeys[1], values: selectOptions[3] });
      timeCostTypeUpdated({ key: chartKeys[0], values: 0 });
    }
  }, [isOverviewPage]);

  const selectOptions = [
    {
      label: t(translations.simulations_cumulativeProbability),
      value: simulationChartType.CUMULATIVE_PROBABILITY,
    },
    {
      label: t(
        charts[chartKey].timeCostType === 0
          ? translations.simulations_timeDepth
          : translations.simulations_costDepth,
      ),
      value: simulationChartType.TIME_DEPTH,
    },
    {
      label: t(translations.simulations_sectionDurations),
      value: simulationChartType.SECTION_DURATIONS,
    },
    {
      label: t(
        charts[chartKey].timeCostType === 0
          ? translations.simulations_operationDurations
          : translations.simulations_operationCosts,
      ),
      value: simulationChartType.OPERATION_DURATIONS,
    },
    {
      label: t(translations.simulations_activityDurations),
      value: simulationChartType.ACTIVITY_DURATIONS,
    },
    {
      label: t(translations.simulations_costItemOverview),
      value: simulationChartType.COST_ITEM_OVERVIEW,
    },
    {
      label: t(translations.risks_risks),
      value: simulationChartType.RISKS,
    },
    {
      label: t(translations.simulations_modifiers),
      value: simulationChartType.MODIFIERS,
    },
    {
      label: t(translations.simulations_sectionWaterfall),
      value: simulationChartType.SECTION_WATERFALL,
    },
  ];

  const riskOperationOptions = operations.map((operation) => ({
    value: operation.operationId,
    label: operation.name,
    selected: true,
  }));
  const riskSectionOptions = sectionItems.map((section) => ({
    value: section.projectSectionId,
    label: section.name,
    selected: true,
  }));
  const riskFilterOptions = [
    {
      type: 'Heading',
      label: t(translations.operations),
    },
    ...riskOperationOptions,
    {
      type: 'Divider',
    },
    {
      type: 'Heading',
      label: t(translations.sections),
    },
    ...riskSectionOptions,
  ];

  const [riskFilter, setRiskFilter] = useState(riskFilterOptions);

  useEffect(() => {
    setRiskFilter(riskFilterOptions);
  }, [operations, sectionItems]);

  const controlsChartPortalId = `result-chart-controls-${chartKey}`;
  const controlsTablePortalId = `result-table-controls-${chartKey}`;

  const chartTypeIndices = {
    [simulationChartType.ACTIVITY_DURATIONS]: 0,
    [simulationChartType.SECTION_DURATIONS]: 0,
    [simulationChartType.COST_ITEM_OVERVIEW]: 1,
    [simulationChartType.MODIFIERS]: 0,
    [simulationChartType.SECTION_WATERFALL]: 0,
  };

  const viewTypeIndices = {
    [simulationChartType.ACTIVITY_DURATIONS]: 1,
    [simulationChartType.MODIFIERS]: 0,
    [simulationChartType.SECTION_WATERFALL]: 0,
  };

  const showTime =
    charts[chartKey]?.dataType?.value !==
      simulationChartType.COST_ITEM_OVERVIEW &&
    charts[chartKey]?.dataType?.value !== simulationChartType.RISKS;

  const showCost =
    charts[chartKey]?.dataType?.value !==
      simulationChartType.ACTIVITY_DURATIONS &&
    charts[chartKey]?.dataType?.value !==
      simulationChartType.SECTION_DURATIONS &&
    charts[chartKey]?.dataType?.value !== simulationChartType.RISKS &&
    charts[chartKey]?.dataType?.value !== simulationChartType.MODIFIERS;

  const showChart =
    charts[chartKey]?.dataType?.value !==
    simulationChartType.ACTIVITY_DURATIONS;

  const showTable =
    charts[chartKey]?.dataType?.value !== simulationChartType.MODIFIERS;

  const showFilter =
    charts[chartKey]?.dataType?.value === simulationChartType.RISKS;

  const updateDataType = (value) => {
    const index = selectOptions.findIndex((el) => el.value === value);
    const viewTypeValue =
      viewTypeIndices[value] !== undefined
        ? viewTypeIndices[value]
        : charts[chartKey].viewType;
    const chartTypeValue =
      chartTypeIndices[value] !== undefined
        ? chartTypeIndices[value]
        : charts[chartKey].timeCostType;
    viewTypeUpdated({
      key: chartKey,
      values: viewTypeValue,
    });
    timeCostTypeUpdated({
      key: chartKey,
      values: chartTypeValue,
    });
    dataTypeUpdated({ key: chartKey, values: selectOptions[index] });
  };
  return (
    <div className="content-container">
      <Flex gap justifyContent="space-between">
        <Flex gap="12px" alignItems="flex-start">
          <Select
            testId="result-overview-select-menu"
            name="result-overview-select"
            width="auto"
            options={selectOptions}
            small
            onChange={(ev) => updateDataType(ev.target.value)}
            value={
              selectOptions[
                selectOptions.findIndex(
                  (item) => item.value === charts[chartKey].dataType.value,
                )
              ]
            }
          />
          <ButtonGroup
            items={[
              ...(showChart
                ? [
                    {
                      key: 0,
                      icon: 'chart',
                      disabled:
                        charts[chartKey].dataType.value ===
                        simulationChartType.ACTIVITY_DURATIONS,
                    },
                  ]
                : []),
              ...(showTable ? [{ key: 1, icon: 'table' }] : []),
            ]}
            small
            onSelected={(value) =>
              viewTypeUpdated({ key: chartKey, values: value })
            }
            value={charts[chartKey].viewType ? 1 : 0}
          />
          <ButtonGroup
            items={[
              ...(showTime
                ? [
                    {
                      key: 0,
                      label: t(translations.time),
                      value: timeCostView.TIME,
                    },
                  ]
                : []),
              ...(showCost
                ? [
                    {
                      key: 1,
                      label: t(translations.cost),
                      value: timeCostView.COST,
                    },
                  ]
                : []),
            ]}
            small
            onSelected={(value) =>
              timeCostTypeUpdated({ key: chartKey, values: value })
            }
            value={charts[chartKey].timeCostType}
          />
          {showFilter && (
            <OptionDropdown
              small
              label="Filter"
              options={riskFilter}
              onChange={(event) => setRiskFilter(event.target.value)}
              showHeader={false}
            />
          )}
        </Flex>
        {charts[chartKey].viewType ? (
          <div id={controlsTablePortalId} />
        ) : (
          <div id={controlsChartPortalId} />
        )}
      </Flex>
      <div className="content-inner">
        {charts[chartKey].viewType ? (
          <div className="content-inner-table">
            <ResultTable
              simulationsResult={simulationsResult}
              selectedItem={charts[chartKey].dataType}
              selectedDataEntity={charts[chartKey].timeCostType}
              controlsPortalId={controlsTablePortalId}
              riskFilter={riskFilter}
            />
          </div>
        ) : (
          <div className="content-inner-chart">
            <ResultChart
              key={`${charts[chartKey].dataType.value}${charts[chartKey].timeCostType}`}
              selectedItem={charts[chartKey].dataType}
              selectedDataEntity={charts[chartKey].timeCostType}
              simulationsResult={simulationsResult}
              controlsPortalId={controlsChartPortalId}
              riskFilter={riskFilter}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    activityModel: { operations, sectionItems },
  } = entities;

  return {
    operations,
    sectionItems,
  };
};

const mapDispatchToProps = {
  getOperations,
  getSections,
  dataTypeUpdated,
  viewTypeUpdated,
  timeCostTypeUpdated,
  operationsCleared,
};

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(ResultContent),
);
