import { isCloseTo } from '@oliasoft-open-source/units';
import { TFunction } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { NoteModule } from '../../../enums/notes-enums';

/**
 * Compare if two date strings are closer for less than 1 second;
 */
export const sameDate = (date1: string, date2: string): boolean => {
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();

  return isCloseTo(d1, d2, { absoluteDiff: 990 });
};

export const getNoteModuleName = (noteModule: string, t: TFunction) => {
  switch (noteModule) {
    case NoteModule.Overview:
      return t(translations.projects_overview);
    case NoteModule.WellDetails:
      return t(translations.wellDetails_wellDetails);
    case NoteModule.ActivityModel:
      return t(translations.activityModel_title);
    case NoteModule.Risks:
      return t(translations.projectModifiers_title);
    case NoteModule.CostSetup:
      return t(translations.costSetup_costSetup);
    case NoteModule.ResultOverview:
      return t(translations.simulations_resultOverview);
    case NoteModule.TimeTracker:
      return t(translations.timeTracker_timeTracker);
    default:
      return '';
  }
};
