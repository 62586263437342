import { createSelector } from '@reduxjs/toolkit';

const sectionsDataStructure = (entities: any) => entities.sectionsDataStructure;

export const selectSectionsDataStructure = createSelector(
  [sectionsDataStructure],
  ({ sections, operations, activities, isFetching }) => {
    return { sections, operations, activities, isFetching };
  },
);
