import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Dialog,
  Table,
  useKeyboardEvent,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import {
  timeAgo,
  filterAndSortDataRows,
  getWellDetails,
} from '~store/entities/well-details/well-details';
import { selectWellDetailsProjects } from '~store/entities/well-details/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const LinkProjectTable = ({
  projectId,
  linkProject,
  projects,
  setLinkProjectModalVisible,
}) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [sorts, setSorts] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [dataSetId, setDataSetId] = useState(null);

  // close modal on escape key press
  const closeModal = () => setLinkProjectModalVisible(false);
  useKeyboardEvent('Escape', closeModal);

  const onSubmit = () => {
    linkProject({
      datasetId: dataSetId,
      projectId,
    });
    setLinkProjectModalVisible(false);
  };

  const onCancel = () => {
    setLinkProjectModalVisible(false);
  };

  const handleOnChangeInput = (ev, identifier) => {
    setSelectedPage(1);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [identifier]: ev.target.value,
    }));
  };

  const headerLabels = [
    { label: t(translations.design), identifier: 'name' },
    { label: t(translations.country), identifier: 'country' },
    { label: t(translations.site), identifier: 'sort2' },
    { label: t(translations.wellbore), identifier: 'sort4' },
    { label: t(translations.lastAccessed), identifier: 'accessed' },
    { label: t(translations.lastModified), identifier: 'updated' },
    { label: t(translations.user), identifier: 'userName' },
  ];

  const headerRow = headerLabels.map(({ label, identifier }) => ({
    value: label,
    width: 150,
    hasSort: true,
    onSort: () => {
      const sort = sorts[identifier] ?? '';
      const newSort = sort === 'asc' ? 'desc' : 'asc';
      setSorts({ [identifier]: newSort });
    },
    sort: sorts[identifier] === 'asc' ? 'down' : 'up',
  }));

  const headerFilter = headerLabels.map((header) => {
    const { identifier } = header;
    const filterValue = filters[identifier] ?? '';
    return {
      key: identifier,
      value: filterValue,
      type: 'Input',
      placeholder: t(translations.search),
      onChange: (ev) => handleOnChangeInput(ev, identifier),
    };
  });

  // need length of array before slicing
  const filteredData = filterAndSortDataRows(projects, filters, sorts);

  const startIndex = (selectedPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const dataRows = [
    ...filteredData.map((data, index) => {
      // Format time since formatted value cant be sorted correctly
      const formattedData = {
        ...data,
        accessed: timeAgo(data.accessed),
        updated: timeAgo(data.updated),
      };

      const rowCells = Object.entries(formattedData).map(([key, value]) => ({
        key,
        value,
      }));

      // remove datasetid from rendering in table
      const { value } = rowCells.pop();

      return {
        cells: rowCells,
        active: index === activeIndex,
        onRowClick: () => {
          setDataSetId(value);
          setActiveIndex(index);
        },
      };
    }),
  ].slice(startIndex, endIndex);

  const table = {
    maxHeight: '400px',
    fixedWidth: '900px',
    headers: [{ cells: headerRow }, { cells: headerFilter }],
    rows: dataRows,
    footer: {
      pagination: {
        rowCount: filteredData.length,
        selectedPage,
        rowsPerPage: {
          onChange: (evt) => setRowsPerPage(Number(evt.target.value)),
          options: [
            { label: `10 / ${t(translations.page)}`, value: 10 },
            { label: `20 / ${t(translations.page)}`, value: 20 },
            { label: `50 / ${t(translations.page)}`, value: 50 },
          ],
          value: rowsPerPage,
        },
        onSelectPage: setSelectedPage,
        small: true,
      },
    },
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          width: 'auto',
          height: 'auto',
          scroll: true,
          heading: t(translations.wellDetails_linkToWellDesignProject),
          content: <Table table={table} />,
          footer: (
            <>
              <Button
                label={t(translations.wellDetails_linkToSelectedProject)}
                colored
                disabled={activeIndex === null}
                onClick={onSubmit}
              />
              <Button label={t(translations.cancel)} onClick={onCancel} />
            </>
          ),
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const projects = selectWellDetailsProjects({ entities });
  return {
    projects,
  };
};

const mapDispatchToProps = { getWellDetails };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(LinkProjectTable),
  { isModal: true },
);

export { Container as LinkProjectTable };
