import { casingStringType } from '~src/enums/well-details';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import {
  IDepthLabel,
  IDepthLabels,
  IMeta,
  IWellSection,
  casingRenderStyle,
} from '../utils/types';

export const calculateDepthLabels = (
  sections: IWellSection[],
  meta: IMeta,
): IDepthLabels => {
  const labels: IDepthLabel[] = [];

  const {
    depthColor,
    descriptionColor,
    lineColor,
    lineHeight,
    lineLengthPart1,
    lineLengthPart2,
    lineLengthPart3,
    maxScreenSpaceItems,
    minSpacing,
    totalLineLength,
    fontSize,
  } = casingRenderStyle;

  sections.forEach((section, index) => {
    const radius = section.holesize || section.casingSize;

    if (section.bottom && section.casingSize > 0) {
      labels.push({
        chartId: `section-${index}-shoe-label`,
        depth: section.bottom,
        depthLabel: `${section.bottom} ${meta.depthUnit}`,
        description:
          section.sectionTypeId === casingStringType.TUBING
            ? i18n.t(translations.wellDetails_TD)
            : `${section.casingSizePretty} ${i18n.t(
                translations.wellDetails_Shoe,
              )}`,
        radius,
        depthColor,
        descriptionColor,
        fontSize,
        lineColor,
      });

      if (section.holeDepth > section.bottom) {
        labels.push({
          chartId: `section-${index}-td`,
          depth: section.holeDepth,
          depthLabel: `${section.holeDepth} ${meta.depthUnit}`,
          description: i18n.t(translations.wellDetails_TD),
          radius,
          depthColor,
          descriptionColor,
          fontSize,
          lineColor,
        });
      }
    } else if (
      section.sectionTypeId === casingStringType.OPEN_HOLE &&
      section.holesize > 0
    ) {
      labels.push({
        chartId: `section-${index}-td`,
        depth: section.holeDepth,
        depthLabel: `${section.holeDepth} ${meta.depthUnit}`,
        description: i18n.t(translations.wellDetails_TD),
        radius,
        depthColor,
        descriptionColor,
        fontSize,
        lineColor,
      });
    }
  });

  const depthLabels = {
    labels,
    meta: {
      lineHeight,
      lineLengthPart1,
      lineLengthPart2,
      lineLengthPart3,
      maxScreenSpaceItems,
      minSpacing,
      totalLineLength,
    },
  };

  return depthLabels;
};
