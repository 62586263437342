import { ListSubheading, List } from '@oliasoft-open-source/react-ui-library';
import { IActivity } from '~src/common/interfaces/sections-data-structure.interfaces';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';

interface ActivitiesListProps {
  operationActivities: IActivity[];
}

const ActivitiesList = ({ operationActivities }: ActivitiesListProps) => {
  const { t } = useTranslation();
  const activitiesList = operationActivities.map((activity: IActivity) => ({
    id: activity.sectionsOperationActivityId,
    name: activity.name,
  }));
  return (
    <>
      <ListSubheading
        item={{
          name: t(translations.activities),
        }}
      />
      <List
        list={{
          items: activitiesList,
        }}
        noHeader
      />
    </>
  );
};

export { ActivitiesList };
