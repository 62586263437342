import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import { removeProjectHierarchy } from '~store/entities/projects/projects';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const DeleteModal = ({
  setModalVisible,
  removeProjectHierarchy,
  selectedId,
  selectedList,
}) => {
  const { t } = useTranslation();
  const onClose = () => setModalVisible(false);
  const onDelete = () => {
    removeProjectHierarchy(selectedList, selectedId);
    setModalVisible(false);
    localStorage.removeItem('activeProject');
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: `${t(translations.delete)} ${selectedList}`,
          content: (
            <>
              {t(translations.projects_areYouSureYouWantToDeleteThis, {
                selectedList,
              })}
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
                testId="confirm-delete"
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = { removeProjectHierarchy };
const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(DeleteModal),
  { isModal: true },
);

export { Container as DeleteModal };
