/* eslint-disable no-continue */
import { round } from '@oliasoft-open-source/units';

const FRACTION_REGEX = /(\d+ )?(\d+\/\d)/;

/**
 * Appends unit to radius value. If inches, '' is used instead of ' in' radius valu
 */
export const formatRadiusValue = (
  value: string | number,
  unit: string,
): string => {
  // Fractions such as '13 5/8' should be formatted as '13 5/8"' (Other units are uncommon, and decimals will suffice)
  if (
    typeof value === 'string' &&
    value?.match(FRACTION_REGEX) &&
    unit === 'in'
  ) {
    return `${value}"`;
  }

  let formattedValue;
  try {
    formattedValue = round(value, 3);
  } catch (_err) {
    formattedValue = value;
  }

  if (unit === 'in') {
    return `${formattedValue}"`;
  }

  return `${formattedValue} ${unit}`;
};
