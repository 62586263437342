import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import { removeCostCategory } from '~store/entities/cost-setup/cost-setup';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CostCategoryDeleteModal = ({
  setModalVisible,
  removeCostCategory,
  costCategoryId,
  setActiveForm,
  projectId,
}) => {
  const { t } = useTranslation();
  const onClose = () => setModalVisible(false);
  const onDelete = () => {
    removeCostCategory(costCategoryId, { projectId });
    setModalVisible(false);
    setActiveForm(null);
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.costSetup_deleteCostCategory),
          content: (
            <>
              {t(translations.costSetup_areYouSureYouWantToDeleteThisCategory)}
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = { removeCostCategory };
const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(CostCategoryDeleteModal),
  { isModal: true },
);

export { Container as CostCategoryDeleteModal };
