import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormRow,
  Heading,
  Field,
  Spacer,
  Flex,
  Button,
  Loader,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import {
  addOperation,
  initialOperation,
  activitiesCodesNullified,
} from '~store/entities/activity-model/activity-model';
import {
  convertToInputUnits,
  convertToStorageUnits,
} from '~common/units/units';
import { getResolver } from '~src/validation/resolver';
import { operationsSchemaValidator } from '~schemas/ajv-validators';
import translations from '~src/internationalisation/translation-map.json';
import {
  Input,
  TextArea,
  UnitInputConvertable,
  Select,
} from '~common/form-inputs';
import { useAutoSave } from '~common/auto-save/use-auto-save';
import {
  selectExtendedLinkFields,
  selectActivityModelLoaders,
  selectActiveSectionId,
  selectOperationList,
} from '~store/entities/activity-model/selectors';
import { autoSaveWaitShort } from '~src/config/config';
import { getSectionsDataStructure } from '~src/store/entities/sections-data-structure/sections-data-structure';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import { selectUserUnits } from '~src/store/entities/user-settings/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const OperationForm = ({
  operation,
  addOperation,
  projectId,
  unitSettings,
  linkFields,
  isPageDisabled,
  setOperationModalVisible,
  setSelectedId,
  getSectionsDataStructure,
  sectionOperations,
  isFetchingSectionsData,
  activitiesCodesNullified,
  isDeleting,
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'all',
    defaultValues: initialOperation,
    resolver: getResolver(operationsSchemaValidator),
  });

  const [isEditing, setIsEditing] = useState(true);
  const { depthToLink, depthFromLink, sectionsOperationId } = watch();

  useEffect(() => {
    getSectionsDataStructure();
  }, []);

  useEffect(() => {
    const operationData = operation || initialOperation;
    const convertedOperation = convertToInputUnits(
      operationData,
      ['from', 'to'],
      'm',
      unitSettings.length,
      true,
    );
    reset(convertedOperation);
  }, [operation?.operationId, reset]);

  const debounceAddOperation = useRef(
    debounce(addOperation, autoSaveWaitShort),
  );

  const onSubmit = handleSubmit((data) => {
    data.projectId = projectId;
    const convertedOperation = convertToStorageUnits(data, ['from', 'to'], {
      from: unitSettings.length,
      to: 'm',
    });
    debounceAddOperation.current(convertedOperation);
  });

  useAutoSave(onSubmit, watch);

  const onClickDelete = (id) => {
    setSelectedId(id);
    setOperationModalVisible(true);
  };

  useEffect(() => {
    if (sectionsOperationId && !isEditing) {
      const selectedOption = sectionOperations.find(
        (option) => option.value === sectionsOperationId,
      );
      setValue('name', selectedOption.label);
      setIsEditing(true);
    }
  }, [sectionsOperationId]);

  const handleOperationChange = (ev) => {
    activitiesCodesNullified(operation?.operationId);
    setIsEditing(false);
    setValue('sectionsOperationId', ev.target.value);
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start">
        {isFetchingSectionsData && (
          <Loader text={t(translations.fetching)} theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Heading top>
          {watch('name') || t(translations.activityModel_newOperation)}
        </Heading>
        <Button
          colored="danger"
          icon="delete"
          loadind={isDeleting}
          disabled={isDeleting || isPageDisabled}
          label={t(translations.activityModel_deleteOperation)}
          onClick={() => onClickDelete(operation.operationId)}
          small
        />
      </Flex>
      <form style={{ maxWidth: '935px' }}>
        <FormRow>
          <Field label={t(translations.operation)}>
            <Select
              name="sectionsOperationId"
              control={control}
              errors={errors}
              options={sectionOperations}
              width={250}
              onChange={handleOperationChange}
              placeholder={t(translations.activityModel_selectOperation)}
            />
          </Field>
          <Field label={t(translations.name)}>
            <Input
              name="name"
              control={control}
              errors={errors}
              disabled={isPageDisabled}
              width={250}
            />
          </Field>
        </FormRow>
        <Heading>{t(translations.depth)}</Heading>
        <Spacer />
        <FormRow>
          <Field label={t(translations.from)}>
            <UnitInputConvertable
              name="from"
              unitkey={'length'}
              control={control}
              errors={errors}
              predefinedOptions={linkFields}
              selectedPredefinedOptionKey={depthFromLink}
              onPredefinedChange={setValue}
              disabled={isPageDisabled}
              unitTemplate={unitSettings}
            />
          </Field>
          <Field label={t(translations.to)}>
            <UnitInputConvertable
              name="to"
              unitkey={'length'}
              control={control}
              errors={errors}
              predefinedOptions={linkFields}
              selectedPredefinedOptionKey={depthToLink}
              onPredefinedChange={setValue}
              disabled={isPageDisabled}
              unitTemplate={unitSettings}
            />
          </Field>
        </FormRow>
        <Field label={t(translations.comments)}>
          <TextArea
            name="comments"
            control={control}
            errors={errors}
            rows={5}
            disabled={isPageDisabled}
          />
        </Field>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  const { isDeleting } = selectActivityModelLoaders(state);
  const { operations, isFetching: isFetchingSectionsData } =
    selectSectionsDataStructure(state.entities);
  const activeSectionId = selectActiveSectionId(state);

  const sectionOperations = operations
    .filter((operation) => operation?.sectionId === activeSectionId)
    .map((operation) => ({
      label: operation.name,
      value: operation.sectionsOperationId,
    }));

  return {
    unitSettings: selectUserUnits(state.entities),
    linkFields: selectExtendedLinkFields(state),
    operations: selectOperationList(state),
    sectionOperations,
    isFetchingSectionsData,
    isDeleting,
  };
};

const mapDispatchToProps = {
  addOperation,
  getSectionsDataStructure,
  activitiesCodesNullified,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(OperationForm),
);

export { Container as OperationForm };
