import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';

const ActivityDeleteModal = ({
  setModalVisible,
  settings,
  selectedId,
  removeActivity,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();

  const onClose = () => setModalVisible({ selectedId: null, visible: false });
  const onDelete = () => removeActivity(selectedId, settings, companyId);

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.activityModel_deleteActivity),
          content: (
            <>
              {t(
                translations.activityModel_areYouSureYouWantToDeleteThisActivity,
              )}
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

export { ActivityDeleteModal };
