import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getProjects } from '~store/entities/projects/projects';
import {
  TopBar as TopBarBase,
  Text,
  Spacer,
} from '@oliasoft-open-source/react-ui-library';
import { toggleChatOpen } from '~src//store/entities/zendesk/zendesk';
import {
  isActiveRoute,
  generatePath,
  navigateToPath,
  navigateToRoute,
} from '~store/navigation/navigation';
import { routes } from '~views/navigation/routes/routes';
import ClientConfig from '~src/config/config';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import UserMenu from './user-menu';
// import LanguagePicker from './language-picker'; TODO: activate when translations are in place
import Logo from './forecast-logo-text.svg';
import Logomark from './oliasoft-logomark.svg';
import HelpMenu from './help-menu/HelpMenu';

const TopBar = ({
  navigateToPath,
  navigateToRoute,
  router,
  isFetching,
  estimates,
  getProjects,
  isProjectFetching,
}) => {
  const { t } = useTranslation();
  const { company, project } = useParams();
  const [activeProject, setActiveProject] = useState(null);
  const version = __VERSION__ || ''; /* global __VERSION__ */
  const activeProjectFromLocalStorage =
    JSON.parse(localStorage.getItem('activeProject')) || {};
  useEffect(() => {
    if (company) {
      getProjects(company, project);
    }
  }, [getProjects, company, project]);

  useEffect(() => {
    if (!isFetching && !isProjectFetching) {
      const estimate = estimates.find((e) => e.projectId === project);
      setActiveProject(estimate || activeProjectFromLocalStorage);
    } else {
      setActiveProject(null);
    }
  }, [estimates, project, isProjectFetching, isFetching]);

  // const langPicker = { TODO: activate when translations are in place
  //   type: 'Component',
  //   component: <LanguagePicker />,
  // };

  const userMenu = {
    type: 'Component',
    component: (
      <UserMenu
        navigateToRoute={navigateToRoute}
        version={version}
        serverVersion={ClientConfig.config.serverVersion}
      />
    ),
  };
  const content = [
    {
      type: 'Link',
      label: <Text bold>{t(translations.estimates)}</Text>,
      active: isActiveRoute(routes.projects, router.location.pathname),
      onClick: () =>
        navigateToPath(
          generatePath(routes.projects, {
            company: company || activeProject?.companyId,
            project: activeProject?.projectId,
          }),
        ),
      disabled: isFetching,
    },
    {
      type: 'Link',
      label: <Text bold>{t(translations.comparisons)}</Text>,
      testId: 'top-bar-comparisons-link',
      active: isActiveRoute(routes.compare, router.location.pathname),
      onClick: () =>
        navigateToPath(
          generatePath(routes.compare, {
            company: company || activeProject?.companyId,
            project: activeProject?.projectId,
          }),
        ),
      disabled: isFetching,
    },
    {
      type: 'Link',
      label: <Text bold>{t(translations.campaigns)}</Text>,
      active: isActiveRoute(routes.campaigns, router.location.pathname),
      onClick: () =>
        navigateToPath(
          generatePath(routes.campaigns, {
            company: company || activeProject?.companyId,
            project: activeProject?.projectId,
          }),
        ),
      disabled: isFetching,
    },
  ];

  const contentRight = [
    ...(activeProject
      ? [
          {
            type: 'Link',
            label: activeProject.name,
            active: isActiveRoute(routes.project, router.location.pathname),
            testId: 'active-project',
            onClick: () =>
              navigateToRoute(routes.overview, {
                company: activeProject.companyId,
                project: activeProject.projectId,
              }),
          },
        ]
      : []),
    {
      type: 'Component',
      component: <Spacer />,
    },
    { type: 'Component', component: <HelpMenu /> },
    // langPicker, TODO:  activate when translations are in place
    userMenu,
  ];

  return (
    <TopBarBase
      appSwitcher={{
        icon: Logomark,
        url: ClientConfig.config.dashboardUrl,
      }}
      title={{
        logo: (
          <img
            src={Logo}
            alt="logo"
            style={{ height: 21, cursor: 'pointer' }}
          />
        ),
        onClick: () => navigateToRoute(routes.companies),
      }}
      contentRight={contentRight}
      content={company || activeProject?.companyId ? content : []}
    />
  );
};

const mapStateToProps = ({ router, entities: { projects } }) => ({
  router,
  estimates: projects.lists?.estimates || [],
  isProjectFetching: projects?.isFetching,
});

const mapDispatchToProps = {
  navigateToPath,
  navigateToRoute,
  toggleChatOpen,
  getProjects,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(TopBar),
  { style: { padding: '1px' } },
);

export { Container as TopBar };
