import { itemFields } from '~src/enums/time-tracker';
import { getFields } from './get-tt-fields';

const columnWidthGroup = {
  minimum: [
    itemFields.AFE,
    itemFields.ESTIMATE,
    itemFields.P90,
    itemFields.ACTUAL,
    itemFields.NPT,
    itemFields.WOW,
    itemFields.VARIANCE,
    itemFields.PLANNED_DEPTH,
    itemFields.ACTUAL_DEPTH,
  ],
  medium: [itemFields.SECTION, itemFields.OPERATION, itemFields.ACTIVITY],
  maximum: [itemFields.NAME, itemFields.START, itemFields.FINISH],
};

const getFieldWidth = (field) => {
  if (columnWidthGroup.minimum.includes(field)) {
    return 1;
  }
  if (columnWidthGroup.medium.includes(field)) {
    return 1.5;
  }
  if (columnWidthGroup.maximum.includes(field)) {
    return 2;
  }
};

export const getColumnWidth = (disabledFields) => {
  const fields = getFields(disabledFields);
  const minFieldWidth = Math.floor(
    100 / fields.reduce((acc, field) => acc + getFieldWidth(field), 0),
  );
  return fields.map((field) =>
    field === itemFields.NAME
      ? 'auto'
      : `${minFieldWidth * getFieldWidth(field)}%`,
  );
};
