import React from 'react';
import { Controller } from 'react-hook-form';
import { RadioButton as GuiRadioButton } from '@oliasoft-open-source/react-ui-library';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const RawRadioButton = ({
  name,
  control,
  disabled = false,
  onChange = null,
  inline = false,
  options = [],
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => {
      const { onChange: fieldOnChange, ...props } = field;
      return (
        <GuiRadioButton
          {...props}
          disabled={disabled}
          inline={inline}
          options={options}
          onChange={(e) => {
            fieldOnChange(e);
            if (onChange) {
              onChange(e);
            }
          }}
        />
      );
    }}
  />
);

export const RadioButton = withErrorBoundary(RawRadioButton);
