import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import { removeUserKpi } from '~store/entities/user-settings/user-settings';
import { removeCompanyKpi } from '~store/entities/company-settings/company-settings';
import { deleteKpi } from '~store/entities/campaigns/campaigns';
import translations from '~src/internationalisation/translation-map.json';
import { settingEntity } from '~src/enums/settings';

const KpiDeleteModal = ({
  entity,
  setModalVisible,
  removeUserKpi,
  removeCompanyKpi,
  kpiId,
  settings,
  deleteKpi,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const onClose = () => setModalVisible(false);

  const removeKpi =
    entity === settingEntity.USER ? removeUserKpi : removeCompanyKpi;

  const onDelete = () => {
    removeKpi(kpiId, settings, company);
    deleteKpi(kpiId, null);
    setModalVisible(false);
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.settings_removeKpi),
          content: (
            <>{t(translations.settings_areYouSureYouWantToDeleteThisKpi)}</>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = { removeUserKpi, removeCompanyKpi, deleteKpi };
const Container = connect(null, mapDispatchToProps)(KpiDeleteModal);

export { Container as KpiDeleteModal };
