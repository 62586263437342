import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  Input,
  InputGroup,
  InputGroupAddon,
  DisabledContext,
} from '@oliasoft-open-source/react-ui-library';
import { isNumeric, getValue } from '@oliasoft-open-source/units';
import {
  isOffsetType,
  isGCSLocation,
  getLocationInfoByEpsg,
  getCartesianInputLimits,
} from '~common/gis/gis-library-utils';
import { roundCartesianInput } from '~common/gis/ui-helpers';

const LocationCoordinate = ({
  locationEpsg,
  referenceLocationEpsg,
  eastingNorthing,
  onEastingNorthingChange,
  isReferenceCoordinate,
  disabled,
}) => {
  const isDDInput = locationEpsg && isGCSLocation(locationEpsg); // is input in legacy decimal degree format
  const isLocationOffsetType = locationEpsg && isOffsetType(locationEpsg);
  const epsgCode =
    isLocationOffsetType || isReferenceCoordinate
      ? referenceLocationEpsg
      : locationEpsg;
  const locationInfo = getLocationInfoByEpsg(epsgCode);
  const currentLocationUnitLabel = locationInfo?.unit?.label ?? null;
  const isDisabled =
    isReferenceCoordinate || isLocationOffsetType || isDDInput || disabled;
  const { eastingLimit, northingLimit } = getCartesianInputLimits(epsgCode);

  const handleEastingNorthingChange = (e) => {
    const { name, value } = e.target || {};
    const map = { easting: 0, northing: 1 };
    const updatedEastingNorthingValues = [...eastingNorthing];
    updatedEastingNorthingValues[map[name]] = Number(getValue(value));

    onEastingNorthingChange(updatedEastingNorthingValues);
  };

  const getCoordinateValidationMessage = (inputName, value) => {
    const lessThanWarningMsg = (upperLimit) => {
      return isNumeric(value) && value > upperLimit
        ? `Value should be less than ${upperLimit}`
        : null;
    };

    const greaterThanWarningMsg = (lowerLimit) => {
      return isNumeric(value) && value < lowerLimit
        ? `Value should be greater than ${lowerLimit}`
        : null;
    };

    const numericValueErrorMsg = () => {
      return value && !isNumeric(value) ? 'Must be numeric value' : null;
    };

    switch (inputName) {
      case 'easting':
        return (
          greaterThanWarningMsg(eastingLimit[0]) ||
          lessThanWarningMsg(eastingLimit[1])
        );
      case 'northing':
        return (
          greaterThanWarningMsg(northingLimit[0]) ||
          lessThanWarningMsg(northingLimit[1])
        );
      default:
        return numericValueErrorMsg();
    }
  };
  return (
    <DisabledContext.Provider value={isDisabled}>
      <Field label="Easting">
        <InputGroup width="200px">
          <Input
            name="easting"
            value={roundCartesianInput(eastingNorthing[0])}
            onChange={handleEastingNorthingChange}
            initUnit={currentLocationUnitLabel ?? (isDDInput && 'deg') ?? null}
            unitkey="location"
            testId="location-coordinate-easting-input"
            disabledUnit
            noConversion
            warning={getCoordinateValidationMessage(
              'easting',
              eastingNorthing[0],
            )}
          />
          <InputGroupAddon>m</InputGroupAddon>
        </InputGroup>
      </Field>

      <Field label="Northing">
        <InputGroup width="200px">
          <Input
            name="northing"
            value={roundCartesianInput(eastingNorthing[1])}
            onChange={handleEastingNorthingChange}
            initUnit={currentLocationUnitLabel ?? (isDDInput && 'deg') ?? null}
            unitkey="location"
            testId="location-coordinate-northing-input"
            disabledUnit
            noConversion
            warning={getCoordinateValidationMessage(
              'northing',
              eastingNorthing[1],
            )}
          />
          <InputGroupAddon>m</InputGroupAddon>
        </InputGroup>
      </Field>
    </DisabledContext.Provider>
  );
};

export default LocationCoordinate;

LocationCoordinate.defaultProps = {
  onEastingNorthingChange: () => {},
  onLongLatChange: () => {},
  referenceLocationEpsg: null,
};

LocationCoordinate.propTypes = {
  locationEpsg: PropTypes.string,
  referenceLocationEpsg: PropTypes.string,
  eastingNorthing: PropTypes.array.isRequired,
  onEastingNorthingChange: PropTypes.func,
  dmsRepresentation: PropTypes.array.isRequired,
  onLongLatChange: PropTypes.func,
};
