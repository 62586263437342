/* eslint-disable import/no-cycle */
import proj from 'proj4';
import { isEpsgCodeValid } from '~common/gis/validate-location';
import { getProjString } from '~common/gis/gis-library-utils';
import {
  areAllArgumentsNumeric,
  getFallbackCoordinate,
} from '~common/gis/helpers';

/**
 * @typedef {`EPSG:${number}` | `EPSG:OFFSET` | `EPSG:OFFSETPOLAR`} EPSGIndex
 */

/**
 * Transforms location coordinate from one CRS to another
 *
 * @param {EPSGIndex} from EPSG code of "from" coordinate
 * @param {EPSGIndex} to EPSG code of "to" coordinate
 * @param {number[]} input
 *
 * @return {import('common/gis/gis.interfaces').TCartesianCoordinate | import('common/gis/gis.interfaces').TDDCoordinate }
 */
export const transformLocationCoordinate = (from, to, input) => {
  const fromProj4String = getProjString(from);
  const toProj4String = getProjString(to);
  const numberInput = input?.map((el) => Number(el)); //proj4 requires inputs as number
  if (
    isEpsgCodeValid(from) &&
    isEpsgCodeValid(to) &&
    areAllArgumentsNumeric(...numberInput)
  ) {
    return proj(fromProj4String, toProj4String, numberInput);
  } else {
    return getFallbackCoordinate();
  }
};
