import { parse, getMinutes, getHours, addMilliseconds } from 'date-fns';
import { format } from 'date-fns-tz';

const datePickerFormat = 'yyyy-MM-dd';
const timePickerFormat = 'HH:mm';
const dateTimeApiFormat = 'yyyy-MM-dd HH:mm:ss';
const uiTimeFormat = 'dd-MMM-yyyy HH:mm';
const excelTimeFormat = 'dd.MM.yyyy HH:mm';
const secondsInHour = 3600;
const millisecondsInSecond = 1000;

export const formatTimeToUI = (value) =>
  value ? format(new Date(value), uiTimeFormat) : '';

export const formatTimeToExcel = (value) =>
  value ? format(new Date(value), excelTimeFormat) : '';

export const addHours = (timeString, hours) =>
  format(
    addMilliseconds(
      new Date(timeString),
      hours * secondsInHour * millisecondsInSecond,
    ),
    dateTimeApiFormat,
  );

export const formatFromApiDate = (value) => {
  return value ? format(new Date(value), datePickerFormat) : '';
};

export const formatFromApiTime = (value) => {
  return value ? format(new Date(value), timePickerFormat) : '';
};

export const formatFromApiHours = (value) => {
  return value ? getHours(new Date(value)) : '';
};

export const formatFromApiMinutes = (value) => {
  return value ? getMinutes(new Date(value)) : '';
};

export const formatToApiDateTime = (date, time) => {
  const parsedTime = parse(
    `${date} ${time}:00`,
    dateTimeApiFormat,
    new Date(),
  ).toISOString();
  return parsedTime;
};

const fillArray = (n) => Array.from(Array(n).keys());
export const hourOptions = fillArray(24).map((option) => ({
  label: option < 10 ? `0${option}` : option,
  value: option < 10 ? `0${option}` : option,
}));
export const minutesOptions = fillArray(60).map((option) => ({
  label: option < 10 ? `0${option}` : option,
  value: option < 10 ? `0${option}` : option,
}));
