import {
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
  MouseEvent,
  useState,
} from 'react';
import {
  Flex,
  Loader,
  Spinner,
  Button,
  Field,
  Heading,
  FormRow,
} from '@oliasoft-open-source/react-ui-library';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { useForm } from 'react-hook-form';
import { Select, Input } from '~common/form-inputs';
import { getResolver } from '~src/validation/resolver';
import { sectionSchemaValidator } from '~src/schemas/ajv-validators';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import { getSectionsDataStructure } from '~src/store/entities/sections-data-structure/sections-data-structure';
import { useParams } from 'react-router';
import {
  addSection,
  operationsCodesNullified,
} from '~src/store/entities/activity-model/activity-model';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { DEFAULT_SECTION_INDEX, Sections } from '~src/enums/sections';
import { ISection } from '~src/common/interfaces/sections-data-structure.interfaces';
import { debounce } from 'lodash';
import { autoSaveWaitShort } from '~src/config/config';
import { useAutoSave } from '~common/auto-save/use-auto-save';
import type { TRootState } from '~src/store/store-types';

type CreateSectionModalProps = PropsFromRedux & {
  setActiveForm: Dispatch<SetStateAction<string | null>>;
  setSectionsDeleteModalVisible: Dispatch<SetStateAction<boolean>>;
  setSelectedId: Dispatch<SetStateAction<string | null>>;
};

const SectionForm = ({
  sections,
  getSectionsDataStructure,
  addSection,
  wellEvents,
  isFetching,
  section,
  setSectionsDeleteModalVisible,
  setSelectedId,
  operationsCodesNullified,
  sectionTypes,
}: CreateSectionModalProps) => {
  const { project } = useParams();

  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(true);

  const initialSection = {
    sectionId: (sections[DEFAULT_SECTION_INDEX] as ISection)?.sectionId,
    sectionTypeId: null,
    wellEventId: null,
    eventName: null,
    projectId: project,
    projectSectionId: null,
    name: t(translations.activityModel_newSection),
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: 'all',
    defaultValues: initialSection,
    resolver: getResolver(sectionSchemaValidator),
  });

  const { sectionId, sectionTypeId } = watch();

  useEffect(() => {
    getSectionsDataStructure();
  }, []);

  useEffect(() => {
    const sectionData = section || initialSection;
    reset(sectionData);
  }, [(section as unknown as ISection)?.projectSectionId, reset]);

  const debounceAddSection = useRef(debounce(addSection, autoSaveWaitShort));

  const onSubmit = handleSubmit((data) => {
    const section = {
      ...data,
    };
    if (project && section) {
      debounceAddSection.current(project, section);
    }
  });

  useAutoSave(onSubmit, watch);

  const sectionList = sections.map((section: any) => ({
    label: section.name,
    value: section.sectionId,
  }));

  const sectionTypesList = sectionTypes.map((sectionType: any) => ({
    label: sectionType.name,
    value: sectionType.sectionTypeId,
  }));

  const sectionTypeExcludeList: string[] = [
    Sections.RigMove,
    Sections.Interface,
    Sections.LowerCompletion,
    Sections.MiddleCompletion,
    Sections.UpperCompletion,
    Sections.XMT,
    Sections.Custom,
  ];

  const excludedSectionIds = sections
    .filter((section: ISection) =>
      sectionTypeExcludeList.includes(section.name),
    )
    .map((section: ISection) => section.sectionId);

  const wellEventList = wellEvents.map((wellEvent: any) => ({
    label: wellEvent.name,
    value: wellEvent.wellEventId,
  }));

  const onClickDelete = (e: MouseEvent<HTMLButtonElement>, id: string) => {
    setSelectedId(e.shiftKey ? null : id);
    setSectionsDeleteModalVisible(true);
  };

  const handleOperationChange = () => {
    if (section) {
      operationsCodesNullified((section as ISection)?.projectSectionId);
    }
    setIsEditing(false);
  };

  useEffect(() => {
    if (sectionId && !isEditing) {
      const selectedOption = sectionList.find(
        (option) => option.value === sectionId,
      );
      if (selectedOption) {
        if (excludedSectionIds.includes(sectionId)) {
          setValue('sectionTypeId', null);
        }
        const { label } = selectedOption;
        const sectionType = sectionTypesList.find(
          (item) => item.value === getValues('sectionTypeId'),
        )?.label;
        setValue('name', sectionType ? `${label} ${sectionType}` : label);
        setIsEditing(true);
      }
    }
  }, [sectionId, sectionTypeId]);

  return (
    <>
      <Flex justifyContent="space-between" alignItem="flex-start">
        {isFetching && (
          <Loader text={t(translations.fetching)} theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Heading top>
          {watch('name') || t(translations.activityModel_newSection)}
        </Heading>
        <Button
          colored="danger"
          icon="delete"
          label={t(translations.activityModel_deleteSection)}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            if (section) {
              onClickDelete(e, (section as ISection)?.projectSectionId);
            }
          }}
          small
        />
      </Flex>
      <form style={{ padding: 'var(--padding)' }}>
        <FormRow>
          <Field label={t(translations.section)}>
            <Select
              name="sectionId"
              options={sectionList}
              control={control}
              errors={errors}
              width={250}
              onChange={handleOperationChange}
            />
          </Field>
          {!excludedSectionIds.includes(sectionId) && (
            <Field label={t(translations.wellDetails_sectionType)}>
              <Select
                name="sectionTypeId"
                options={sectionTypesList}
                control={control}
                errors={errors}
                clearable
                width={250}
                onChange={handleOperationChange}
              />
            </Field>
          )}
        </FormRow>
        <Field label={t(translations.name)}>
          <Input name="name" control={control} errors={errors} width={250} />
        </Field>
        <Field label={t(translations.activityModel_event)}>
          <Select
            name="wellEventId"
            options={wellEventList}
            control={control}
            errors={errors}
            clearable
            width={250}
          />
        </Field>
      </form>
    </>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const { operations: activeOperations } =
    selectSectionsDataStructure(entities);
  const {
    activityModel: {
      sections,
      isFetching,
      sectionItems,
      activeSection,
      sectionTypes,
    },
    wellDetails: { wellEvents },
  } = entities;

  const section: ISection | undefined = sectionItems.find(
    (item: ISection) =>
      item.projectSectionId ===
      (activeSection as unknown as ISection)?.projectSectionId,
  );

  return {
    sections,
    activeOperations,
    wellEvents,
    isFetching,
    section,
    sectionTypes,
  };
};

const mapDispatchToProps = {
  getSectionsDataStructure,
  addSection,
  operationsCodesNullified,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(SectionForm));

export { Container as SectionForm };
