import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  Heading,
  Row,
  Column,
  Spacer,
  ListHeading,
  Loader,
  Spinner,
  Drawer,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { OperationCodesList } from '~views/settings/company-settings/operation-codes/operation-codes.list';
import { CodesLists } from '~views/settings/company-settings/operation-codes/codes-lists/codes-lists';
import {
  getCodeName,
  getCodes,
  deleteCodeClicked,
  addSubCodeClicked,
  addActivityCodeClicked,
  editCodeClicked,
} from '~store/entities/operation-codes/operation-codes';
import { operationCodesTypes, operationCodesForms } from '~src/enums/settings';
import { SubCodesList } from '~views/settings/company-settings/operation-codes/sub-codes.list';
import { CodesListForm } from '~views/settings/company-settings/operation-codes/codes-lists/codes-list.form';
import { OperationCodesForm } from '~views/settings/company-settings/operation-codes/operation-codes.form';
import { OperationCodeDeleteModal } from '~views/settings/company-settings/operation-codes/operation-codes-delete.modal';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const OperationCodes = ({
  getCodes,
  codesLists,
  activeCodesList,
  operationCodes,
  subOperationCodes,
  activityCodes,
  activeOperationCode,
  activeForm,
  deleteCodeClicked,
  addSubCodeClicked,
  addActivityCodeClicked,
  selectedId,
  codeType,
  activeSubCode,
  editCodeClicked,
  isFetching,
}) => {
  const { t } = useTranslation();
  const [codeModalVisible, setCodeModalVisible] = useState(false);
  useEffect(() => {
    getCodes();
  }, []);

  const onClickDelete = (id) => {
    setCodeModalVisible(true);
    deleteCodeClicked(id);
  };

  let operationCode = null;
  if (codeType) {
    operationCode =
      codeType === operationCodesTypes.OPERATION
        ? activeOperationCode
        : activeSubCode;
  }

  const forms = {
    [operationCodesForms.CODES_LISTS]: (
      <CodesListForm codesList={activeCodesList} />
    ),
    [operationCodesForms.OPERATION_CODES]: (
      <OperationCodesForm
        operationCode={operationCode}
        codesList={activeCodesList}
        parentId={
          codeType === operationCodesTypes.OPERATION
            ? null
            : activeOperationCode?.codeId
        }
      />
    ),
  };

  let form = null;
  if (activeForm) {
    form = forms[activeForm];
  }
  const [drawerWidth, setDrawerWidth] = useState(300);

  return (
    <>
      <Column scroll spacing={0}>
        {isFetching && (
          <Loader text={t(translations.fetching)} theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Spacer />
        <Row spacing={0}>
          <Spacer width="var(--padding-sm)" />
          <Heading top marginBottom={0}>
            {t(translations.settings_manageOperationCodes)}
          </Heading>
        </Row>
        <Divider />
        <CodesLists codesLists={codesLists} activeCodesList={activeCodesList} />
        <Spacer />
        <Divider margin={0} />
        <Row spacing={0} height="100%">
          <Drawer
            width={drawerWidth}
            closedWidth={50}
            open
            button
            border
            onResize={(width) => setDrawerWidth(width)}
          >
            <Column scroll width="350px" borderRight spacing={0}>
              <OperationCodesList
                activeCodesList={activeCodesList}
                operationCodes={operationCodes}
                onClickDelete={onClickDelete}
              />
            </Column>
          </Drawer>
          {activeOperationCode && (
            <Drawer
              width={drawerWidth}
              closedWidth={50}
              open
              button
              border
              onResize={(width) => setDrawerWidth(width)}
            >
              <Column scroll width="350px" borderRight spacing={0}>
                <ListHeading
                  name={getCodeName(activeOperationCode)}
                  actions={
                    activeCodesList?.default
                      ? []
                      : [
                          {
                            label: 'Edit',
                            icon: 'edit',
                            onClick: () => editCodeClicked(),
                          },
                        ]
                  }
                />
                <SubCodesList
                  title={t(translations.settings_subOperation)}
                  codes={subOperationCodes}
                  onClickAdd={addSubCodeClicked}
                  onClickDelete={onClickDelete}
                  activeCodesList={activeCodesList}
                />
                <SubCodesList
                  title={t(translations.settings_activity)}
                  codes={activityCodes}
                  onClickAdd={addActivityCodeClicked}
                  onClickDelete={onClickDelete}
                  activeCodesList={activeCodesList}
                />
              </Column>
            </Drawer>
          )}
          <Column scroll padding>
            {form}
          </Column>
        </Row>
      </Column>
      {codeModalVisible && (
        <OperationCodeDeleteModal
          setModalVisible={setCodeModalVisible}
          codeId={selectedId}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    codesLists,
    codes,
    subCodes,
    activeCodesList,
    activeForm,
    selectedId,
    codeType,
    isFetching,
  } = entities.operationCodes;

  const operationCodes = codes.filter(
    (code) => code.codesListId === activeCodesList.codesListId,
  );
  const activeOperationCode =
    operationCodes.find((code) => code.active) || null;
  const activeSubCode = subCodes.find((code) => code.active) || null;

  const subOperationCodes = subCodes.filter(
    (code) =>
      code.codesListId === activeCodesList.codesListId &&
      code.parentId === activeOperationCode?.codeId &&
      code.type === operationCodesTypes.SUB_OPERATION,
  );
  const activityCodes = subCodes.filter(
    (code) =>
      code.codesListId === activeCodesList.codesListId &&
      code.parentId === activeOperationCode?.codeId &&
      code.type === operationCodesTypes.ACTIVITY,
  );

  return {
    codesLists,
    operationCodes,
    subOperationCodes,
    activityCodes,
    activeCodesList,
    activeOperationCode,
    activeForm,
    selectedId,
    codeType,
    activeSubCode,
    isFetching,
  };
};

const mapDispatchToProps = {
  getCodes,
  deleteCodeClicked,
  addSubCodeClicked,
  addActivityCodeClicked,
  editCodeClicked,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(OperationCodes),
);

export { Container as OperationCodes };
