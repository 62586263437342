import { createSlice } from '@reduxjs/toolkit';
import { ability } from '@oliasoft/authorization-ui';
import { apiCallBegan } from '~api/api';

const authorization = createSlice({
  name: 'authorization',
  initialState: {
    isFetching: false,
    withWelldesignLicense: false,
    subjectId: '',
  },
  reducers: {
    authRequested: (authorization) => {
      authorization.isFetching = true;
    },
    authFetched: (authorization, action) => {
      authorization.isFetching = false;
      authorization.withWelldesignLicense =
        action.payload.withWelldesignLicense;
      authorization.subjectId = action.payload.subjectId;
    },
  },
});

export const { authRequested, authFetched } = authorization.actions;
export default authorization.reducer;

export const getAbility = (companyId) =>
  apiCallBegan({
    url: `/api/auth/${companyId}`,
    method: 'GET',
    onStart: authRequested.type,
    onSuccess: (response) => {
      ability.update(response.ability);

      return {
        type: authFetched.type,
        payload: response,
      };
    },
  });
