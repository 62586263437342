import * as Sentry from '@sentry/react';
import { version as appVersion } from './package.json';

/**
 * Fetches the environment based on the current browser URL
 *
 * Currently supports local development, prod/test/reviews.
 * Should be extended if we ever add more environments such as QA or staging.
 *
 * Note: If the environment cannot be mapped, 'unknown' is returned.
 */
const getEnvironment = () => {
  const { hostname } = window.location;

  if (hostname === 'localhost') {
    return 'development';
  }

  if (hostname === 'forecast.oliasoft.com') {
    return 'production';
  }

  if (hostname === 'forecast.test.oliasoft.com') {
    return 'test';
  }

  if (hostname?.includes('review')) {
    return 'review';
  }

  return 'unknown';
};

export const initializeSentry = () => {
  const environment = getEnvironment();

  // We only log to Sentry for PROD/TEST for now...
  if (environment !== 'production' && environment !== 'test') {
    return;
  }

  Sentry.init({
    dsn: "https://ed3befc7ef8552b0a7546a0a14c63b2d@o324733.ingest.us.sentry.io/4507809132183552",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
        workerUrl: 'https://oliasoftstaticcontent.blob.core.windows.net/assets/scripts/worker.min.js',
      }),
    ],
    release: `forecast-${appVersion}`,
    environment,

    // Add error message to filter errors that we don't want to report to Sentry
    ignoreErrors: [],

    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/forecast.test.oliasoft.com/],

    // Session Replay
    replaysSessionSampleRate: 0, // Do not capture user session unless an error occurs
    replaysOnErrorSampleRate: 1.0, // Capture entire user session upon error
  });
};
