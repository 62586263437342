import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Page,
  Heading,
  Select,
  Spacer,
  Table,
  Toggle,
  Slider,
  Flex,
  Button,
} from '@oliasoft-open-source/react-ui-library';
import { routes } from '~routes/routes';
import { getSimulations } from '~store/entities/simulations/simulations';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const tableRowName = (name) => {
  return <span style={{ fontWeight: 'bold' }}>{name}</span>;
};

const tableSubHeaderName = (name) => {
  return <span style={{ fontWeight: 'normal', opacity: '0.5' }}>{name}</span>;
};

const generateRows = (simulations) => {
  let result = [];

  if (simulations.simulationsResult) {
    result = simulations.simulationsResult.operations.map((el) => {
      return {
        cells: [
          { value: tableRowName(el.name) },
          { value: el.average },
          { value: el.percentiles[10] },
          { value: el.percentiles[20] },
          { value: el.percentiles[30] },
          { value: el.percentiles[40] },
          { value: el.percentiles[50] },
          { value: el.percentiles[60] },
          { value: el.percentiles[70] },
          { value: el.percentiles[80] },
          { value: el.percentiles[90] },
        ],
      };
    });
  }
  return result;
};

const Statistics = ({ getSimulations, projectId, simulations }) => {
  const { t } = useTranslation();
  const [checkbox, setCheckbox] = useState(false);

  useEffect(() => {
    if (projectId) {
      getSimulations(projectId);
    }
  }, [projectId]);

  const table = {
    headers: [
      {
        cells: [
          { value: 'Operation' },
          { value: 'Mean' },
          { value: 'P10' },
          { value: 'P20' },
          { value: 'P30' },
          { value: 'P40' },
          { value: 'P50' },
          { value: 'P60' },
          { value: 'P70' },
          { value: 'P80' },
          { value: 'P90' },
        ],
      },
      {
        cells: [
          { value: '' },
          { value: tableSubHeaderName('days') },
          { value: tableSubHeaderName('days') },
          { value: tableSubHeaderName('days') },
          { value: tableSubHeaderName('days') },
          { value: tableSubHeaderName('days') },
          { value: tableSubHeaderName('days') },
          { value: tableSubHeaderName('days') },
          { value: tableSubHeaderName('days') },
          { value: tableSubHeaderName('days') },
          { value: tableSubHeaderName('days') },
        ],
      },
    ],
    rows: generateRows(simulations),
  };

  return (
    <Page>
      <Heading top>{routes.resultOverview.title}</Heading>
      <Flex alignItems="center" gap>
        <Select
          name="result-overview-select"
          width="auto"
          options={[
            {
              label: 'Operations spread',
              value: 'operations-spread',
            },
          ]}
          onChange={() => {}}
          value={{
            label: 'Operations spread',
            value: 'operations-spread',
          }}
        />
        <Toggle
          name="example"
          label="Select percentile"
          onChange={() => {
            setCheckbox(!checkbox);
          }}
          checked={checkbox}
        />
        <span>P5</span>
        <Slider
          name="example"
          value={20}
          label={'P95'}
          min={0}
          max={100}
          step={1}
          width={'300px'}
          disabled={!checkbox}
          onChange={() => {}}
        />
      </Flex>

      <Spacer />

      <Button
        name="Simulate"
        label={t(translations.simulate)}
        onClick={() => {
          console.log('clicked');
        }}
        colored
        disabled
        width="100px"
      />

      <Spacer />

      {simulations?.simulationsResult?.operations ? (
        <Table table={table} />
      ) : (
        <Heading top>
          No data to display. Run simulate one more time or load example data.
        </Heading>
      )}
    </Page>
  );
};

const mapStateToProps = ({ entities }) => {
  const { simulations, activityModel } = entities;

  return {
    simulations,
    operations: activityModel.operations,
  };
};

const mapDispatchToProps = {
  getSimulations,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(Statistics),
  { isPage: true },
);

export { Container as Statistics };
