import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import { removeCode } from '~store/entities/operation-codes/operation-codes';
import translations from '~src/internationalisation/translation-map.json';

const OperationCodeDeleteModal = ({ setModalVisible, removeCode, codeId }) => {
  const { t } = useTranslation();
  const onClose = () => setModalVisible(false);
  const onDelete = () => {
    removeCode(codeId);
    setModalVisible(false);
  };

  return (
    <Modal visible centered>
      <Dialog
        dialog={{
          heading: t(translations.settings_deleteCode),
          content: (
            <>{t(translations.settings_areYouSureYouWantToDeleteThisCode)}</>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = { removeCode };
const Container = connect(null, mapDispatchToProps)(OperationCodeDeleteModal);

export { Container as OperationCodeDeleteModal };
