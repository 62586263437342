/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import * as Sentry from '@sentry/react';
import { Icon, Message, Spacer, Page } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import styles from './error-boundary.module.less';

/**
 * Type notes:
 * - Would be great with proper type restrictions, but proved hard in JSDoc to support generics.
 * - Component/ErrorComponent should support all types of redux components, react functional and class components, etc.
 * - For now they are very generic
 */

type TErrorBoundaryOptions = {
  ErrorComponent?: React.Component | React.FC; // Fallback component upon error
  isModal?: boolean;
  isSideBar?: boolean; // Separate styling for side bar error
  isRightSideBar?: boolean;
  isPage?: boolean; // Wrap error message in page (Used for top modules)
  preventLogToSentry?: boolean;
  content?: string;
  style?: React.CSSProperties;
};

type TErrorBoundaryProps = {
  children: any;
  options?: TErrorBoundaryOptions;
};

const ErrorBoundaryFallback = ({
  options,
}: { options: TErrorBoundaryOptions | undefined }) => {
  const { t } = useTranslation();
  const { content, ErrorComponent, isModal, isPage, isRightSideBar, isSideBar, style } = options ?? {};

  if (ErrorComponent) {
    // @ts-expect-error: TODO: Find a better type for the ErrorComponent
    return <ErrorComponent />;
  }

  if (isSideBar || isRightSideBar) {
    return (
      <div
        className={cx(
          isSideBar ? styles.sidebarErrorBoundary : '',
          isRightSideBar ? styles.rightSidebarErrorBoundary : '',
        )}
        style={style}
      >
        <Icon icon="error" size="16px" />
        <Spacer height="7px" />
        {t(translations.unexpectedError)}
      </div>
    );
  }

  const errorMessage = (
    <div
      className={cx(
        styles.errorBoundary,
        isModal ? styles.modalErrorBoundary : null,
      )}
      style={style}
    >
      <Message
        message={{
          icon: true,
          type: 'Error',
          content: content ?? `${t(translations.unexpectedError)} - ${t(translations.oliasoftHasLoggedTheIssue)}`,
          visible: true,
          width: '100%',
        }}
      />
    </div>
  );

  if (isPage) {
    return (
      <Page scroll={false} padding={0}>
        {errorMessage}
      </Page>
    );
  }

  return errorMessage;
};

const beforeCapture = (scope: Sentry.Scope) => scope.setLevel('fatal');

export const ErrorBoundary = ({
  children,
  options,
}: TErrorBoundaryProps) => (
  <Sentry.ErrorBoundary
    beforeCapture={beforeCapture}
    fallback={<ErrorBoundaryFallback options={options} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

/**
 * Applies error boundary to React component.
 */
export const withErrorBoundary = <TComponent,>(
  Component: TComponent,
  options: TErrorBoundaryOptions = {},
): TComponent => {
  // @ts-expect-error: TODO: Resolve type issues
  return Sentry.withErrorBoundary(
    // @ts-expect-error: TODO: Resolve type issues
    Component,
    {
      beforeCapture,
      fallback: <ErrorBoundaryFallback options={options} />,
    },
  );
};
