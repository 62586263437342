/**
 * @readonly
 * @enum {string}
 */
export const LocationFormatTypes = Object.freeze({
  DD: 'dd',
  DMS: 'dms',
});

/**
 * @readonly
 * @enum {string}
 */
export const LocationValidationErrorMessageType = Object.freeze({
  NotWithinRange: 'Value out of range',
  InvalidNumberFormat: 'Invalid number format',
});

/**
 * @readonly
 * @enum {string}
 */
export const LocationFieldType = Object.freeze({
  DistanceToReferenceLocation: 'generalInputs_distanceToReferenceLocation',
  ReferenceLocationFormat: 'generalInputs_referenceLocationFormat',
  ReferenceLocationCoordinates: 'trajectories_referenceLocationCoordinates',
  GeodeticDatum: 'trajectories_geodeticDatum',
  InputType: 'trajectories_inputType',
  FormatType: 'trajectories_formatType',
  GridScale: 'trajectories_gridScale',
  NorthReference: 'generalInputs_northReference',
});

/**
 * @readonly
 * @enum {string}
 */
export const NorthReferenceType = Object.freeze({
  TrueNorth: 'tn',
  GridNorth: 'gn',
  MagneticNorth: 'mn',
});

/**
 * @readonly
 * @enum {string}
 */
export const NorthReferenceLabelType = Object.freeze({
  TrueNorth: 'True North',
  GridNorth: 'Grid North',
  MagneticNorth: 'Magnetic North',
});

/**
 * @readonly
 * @enum {string}
 */
export const NorthReferenceLabelTranslationKeysNew = Object.freeze({
  TrueNorth: 'generalInputs_true',
  GridNorth: 'generalInputs_grid',
  MagneticNorth: 'generalInputs_magnetic',
});

/**
 * @readonly
 * @enum {string}
 */
export const WorldDirectionType = Object.freeze({
  N: 'n',
  S: 's',
  E: 'e',
  W: 'w',
});
