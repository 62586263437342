import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';

const projectSettings = createSlice({
  name: 'projectSettings',
  initialState: {
    isAdding: false,
    isFetching: false,
    showProjectSettingsModal: false,
    currencies: [],
    settings: {},
  },
  reducers: {
    projectSettingsRequested: (projectSettings) => {
      projectSettings.isFetching = true;
    },
    projectSettingsReceived: (projectSettings, action) => {
      projectSettings.isFetching = false;
      projectSettings.settings = action.payload.settings;
      projectSettings.currencies = action.payload.currencies;
    },
    projectSettingsSaveRequested: (projectSettings) => {
      projectSettings.isAdding = true;
    },
    projectSettingsSaved: (projectSettings, action) => {
      projectSettings.settings = action.payload;
      projectSettings.isAdding = false;
      projectSettings.showProjectSettingsModal = false;
    },
    showProjectSettingsModalUpdated: (projectSettings, action) => {
      projectSettings.showProjectSettingsModal = action.payload;
    },
    projectCategoryUpdated: (projectSettings, action) => {
      projectSettings.isFetching = false;
      projectSettings.settings.category = action.payload;
    },
  },
});

export const {
  projectSettingsRequested,
  projectSettingsReceived,
  projectSettingsSaveRequested,
  projectSettingsSaved,
  showProjectSettingsModalUpdated,
  projectCategoryUpdated,
} = projectSettings.actions;
export default projectSettings.reducer;

/**
 * Get settings
 *
 * @param projectId
 */
export const getProjectSettings = (projectId) =>
  apiCallBegan({
    url: `/api/project-settings/${projectId}`,
    method: 'GET',
    onStart: projectSettingsRequested.type,
    onSuccess: projectSettingsReceived.type,
  });

/**
 * Save settings
 *
 * @param projectId
 * @param data
 */
export const saveProjectSettings = (projectId, data) =>
  apiCallBegan({
    url: `/api/project-settings/${projectId}`,
    method: 'POST',
    data,
    onStart: projectSettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: projectSettingsSaved.type,
      payload: response,
    }),
  });

/**
 * Update project category
 *
 * @param projectId
 * @param data
 */
export const updateProjectCategory = (projectId, data) =>
  apiCallBegan({
    url: `/api/project-settings/category/${projectId}`,
    method: 'POST',
    data,
    onStart: projectSettingsRequested.type,
    onSuccess: projectCategoryUpdated.type,
  });
