import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Heading,
  Column,
  Loader,
  Spinner,
  Tabs,
  Row,
  Spacer,
  Drawer,
} from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import translations from '~src/internationalisation/translation-map.json';
import {
  getOperationCodes,
  saveUserSettings,
  getSectionTypes as getUserSectionTypes,
} from '~store/entities/user-settings/user-settings';
import { selectUserSettings } from '~store/entities/user-settings/selectors';
import { selectCompanyTemplates } from '~store/entities/company-settings/selectors';
import { TemplatesList } from '~views/settings/templates/templates.list';
import { HighLevelForm } from '~views/settings/templates/high-level.form';
import { TemplateDeleteModal } from '~views/settings/templates/template-delete.modal';
import { ActivityModel } from '~views/settings/templates/activity-model/activity-model';
import { CostSetup } from '~views/settings/templates/cost-setup/cost-setup';
import { ImportFromModal } from '~views/settings/user-settings/import-from.modal';
import { getSectionTypes as getCompanySectionTypes } from '~store/entities/company-settings/company-settings';
import { getSectionsDataStructure } from '~src/store/entities/sections-data-structure/sections-data-structure';
import { settingEntity } from '~src/enums/settings';

const Templates = ({
  entitySettings,
  entity,
  templateActions,
  getOperationCodes,
  saveUserSettings,
  userSettings,
  companyTemplatesList,
  getSectionTypes,
  getSectionsDataStructure,
}) => {
  const { t } = useTranslation();
  const { settings, isAdding, isFetching, showTemplatesForm } = entitySettings;
  const {
    addInitialTemplate,
    showTemplatesFormUpdated,
    duplicateTemplate,
    templateSelected,
    reorderTemplate,
    removeTemplate,
    updateTemplate,
    addTemplateFromUpload,
  } = templateActions;

  const activeTemplate = settings.templates?.find(
    (template) => template?.active,
  )?.highLevel;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [templateModalVisible, setTemplateModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [showCopyModal, setShowCopyModal] = useState(false);

  useEffect(() => {
    getOperationCodes();
    getSectionTypes();
    getSectionsDataStructure();
  }, []);

  const onConfirmCopyFromCompanySettings = ({ items }) => {
    const selectedTemplates = companyTemplatesList
      .filter((template, idx) => items.includes(idx))
      .map((template) => ({ ...template, templateId: uuidv4() }));
    const updatedSettings = {
      ...userSettings,
      templates: [...userSettings.templates, ...selectedTemplates],
    };
    setShowCopyModal(false);
    saveUserSettings(updatedSettings);
  };

  const onClickDelete = (id) => {
    setSelectedId(id);
    setTemplateModalVisible(true);
    showTemplatesFormUpdated(false);
  };

  const onClickDuplicate = (index, companyId) => {
    duplicateTemplate(settings, index, companyId);
  };

  const onClickUpload = (template, companyId) => {
    addTemplateFromUpload?.(settings, template, companyId);
  };
  const [drawerWidth, setDrawerWidth] = useState(300);

  return (
    <>
      <Column scroll spacing={0}>
        {isFetching && (
          <Loader text={t(translations.fetching)} theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Row spacing={0} height="100%">
          <Drawer
            width={drawerWidth}
            closedWidth={50}
            open
            button
            border
            onResize={(width) => setDrawerWidth(width)}
          >
            <Column scroll width="250px" borderRight spacing={0}>
              <TemplatesList
                entity={entity}
                settings={settings}
                isAdding={isAdding}
                addInitialTemplate={addInitialTemplate}
                onClickDelete={onClickDelete}
                onClickDuplicate={onClickDuplicate}
                templateSelected={templateSelected}
                reorderTemplate={reorderTemplate}
                onShowCopyModal={() => setShowCopyModal(true)}
                onClickUpload={onClickUpload}
              />
            </Column>
          </Drawer>
          <Column scroll padding>
            {showTemplatesForm && activeTemplate && (
              <>
                <Heading top>{activeTemplate?.name}</Heading>
                <Tabs
                  value={activeTabIndex}
                  onChange={(ev) => setActiveTabIndex(ev.target.value)}
                  margin={0}
                  options={[
                    {
                      value: 0,
                      label: t(translations.settings_highLevelInputs),
                    },
                    { value: 1, label: t(translations.settings_activityModel) },
                    { value: 2, label: t(translations.settings_costSetup) },
                  ]}
                />
                {activeTabIndex === 0 && (
                  <>
                    <Spacer height="var(--padding-xs)" />
                    <HighLevelForm
                      activeTemplate={activeTemplate}
                      settings={settings}
                      updateTemplate={updateTemplate}
                    />
                  </>
                )}
                {activeTabIndex === 1 && (
                  <ActivityModel
                    entitySettings={entitySettings}
                    entity={entity}
                  />
                )}
                {activeTabIndex === 2 && (
                  <CostSetup entitySettings={entitySettings} entity={entity} />
                )}
              </>
            )}
          </Column>
        </Row>
      </Column>
      {templateModalVisible && (
        <TemplateDeleteModal
          templateId={selectedId}
          setModalVisible={setTemplateModalVisible}
          settings={settings}
          removeTemplate={removeTemplate}
        />
      )}
      {showCopyModal && (
        <ImportFromModal
          isVisible={showCopyModal}
          onCloseModal={() => setShowCopyModal(false)}
          onConfirmModal={onConfirmCopyFromCompanySettings}
          importType={'TEMPLATES'}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => ({
  companyTemplatesList: selectCompanyTemplates(entities),
  userSettings: selectUserSettings(entities),
});

const mapDispatchToProps = (dispatch, { entity }) =>
  bindActionCreators(
    {
      getOperationCodes,
      saveUserSettings,
      getSectionsDataStructure,
      getSectionTypes:
        entity === settingEntity.USER
          ? getUserSectionTypes
          : getCompanySectionTypes,
    },
    dispatch,
  );

const Container = connect(mapStateToProps, mapDispatchToProps)(Templates);

export { Container as Templates };
