import styles from './Renderers.module.less';

const LinkRenderer = (text: string, href: string) => {
  return (
    <a href={href} target="_blank" rel="noreferrer" className={styles.link}>
      {text}
    </a>
  );
};

export default LinkRenderer;
