import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Outlet, useLocation } from 'react-router-dom';
import { Loader, Spinner } from '@oliasoft-open-source/react-ui-library';
import { TopBar } from '~views/navigation/top-bar/top-bar';
import { getUserSettings } from '~store/entities/user-settings/user-settings';
import { getCompanySettings } from '~store/entities/company-settings/company-settings';
import { getCompanies } from '~store/entities/companies/companies';
import { getAbility } from '~store/entities/authorization/authorization';
import {
  useSocket,
  WEBSOCKET_EVENTS,
} from '~src/common/socket-contex/socket-context';
import { ability, ActionMap } from '@oliasoft/authorization-ui';
import { NotFound } from '~views/not-found/not-found';
import { caslEnums } from '~src/enums/casl-enums';

const Main = ({
  getUserSettings,
  getCompanySettings,
  getCompanies,
  getAbility,
  isFetching,
  companies,
}) => {
  const { socket, isConnected } = useSocket();
  const { company: companyId } = useParams();
  const { pathname } = useLocation();
  const isUserSettings = pathname?.includes('/user-settings');

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (companyId) {
      const company = companies.find((company) => company.id === companyId);
      if (company) {
        localStorage.setItem('activeCompany', JSON.stringify(company));
        window.dispatchEvent(new Event('storage'));

        getCompanySettings(companyId);
        getAbility(companyId);
      }
    }
    getUserSettings();
  }, [companyId, companies]);

  useEffect(() => {
    if (isFetching) {
      return;
    }
    const payload = {
      scope: 'GROUP',
      value: companyId,
    };

    if (isConnected) {
      socket.emit(WEBSOCKET_EVENTS.change_scope, payload);
    }
  }, [isConnected, isFetching]);

  const settingsLoader =
    isFetching || companies.length === 0 ? (
      <Loader fullViewPortSize text="Loading...">
        <Spinner />
      </Loader>
    ) : null;

  const hasPermissionToSeeContent = ability.can(
    ActionMap.READ,
    caslEnums.access,
  );

  return (
    <>
      {settingsLoader || (
        <>
          <TopBar />
          {hasPermissionToSeeContent || isUserSettings ? (
            <Outlet />
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => ({
  isFetching:
    entities.userSettings.isFetching || entities.authorization.isFetching,
  companies: entities.companies.list,
});

const mapDispatchToProps = {
  getUserSettings,
  getCompanySettings,
  getAbility,
  getCompanies,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Main);

export { Container as Main };
