import translations from '~src/internationalisation/translation-map.json';

export const lengthUnits = ['m', 'ft'];
export const timeUnits = ['d', 'h', 'min'];

export const userSettings = [
  { id: 'units', name: translations.units },
  { id: 'templates', name: translations.automationRulesets },
  { id: 'kpi', name: translations.kpis },
];

export const operationCodesTypes = Object.freeze({
  OPERATION: 'operation',
  SUB_OPERATION: 'subOperation',
  ACTIVITY: 'activity',
});

export const operationCodesForms = Object.freeze({
  CODES_LISTS: 'codesLists',
  OPERATION_CODES: 'operationCodes',
});

export const wellConfiguration = Object.freeze({
  OFFSHORE: 'offshore',
  ONSHORE: 'onshore',
});

export const drillingUnit = Object.freeze({
  // Offshore
  SEMI_SUBMERSIBLE: 'semi_submersible',
  PLATFORM_JACKUP: 'platform_jackup',
  TLP_HYBRID: 'tlp_hybrid',
  // Onshore
  LAND_RIG: 'land_rig',
});

export const depthInequality = Object.freeze({
  LESS_OR_EQUAL: 'lessOrEqual',
  MORE_THAN: 'moreThan',
  MORE_THAN_OR_EQUAL: 'moreThanOrEqual',
});

export const sectionTypes = Object.freeze({
  CONDUCTOR_CASING: 'conductorCasing',
  SURFACE_CASING: 'surfaceCasing',
  INTERMEDIATE_CASING: 'intermediateCasing',
  PRODUCTION_CASING: 'productionCasing',
  SURFACE_LINER: 'surfaceLiner',
  INTERMEDIATE_LINER: 'intermediateLiner',
  PRODUCTION_LINER: 'productionLiner',
  INTERMEDIATE_TIEBACK: 'intermediateTieback',
  PRODUCTION_TIEBACK: 'productionTieback',
  PRODUCTION_OPEN_HOLE: 'productionOpenHole',
  PRODUCTION_TUBING: 'productionTubing',
  RESERVOIR_OPEN_HOLE: 'reservoirOpenHole',
  PILOT_OPEN_HOLE: 'pilotOpenHole',
  MOBILIZATION: 'mobilization',
  DEMOBILIZATION: 'demobilization',
  LOWER_COMPLETION: 'lowerCompletion',
  UPPER_COMPLETION: 'upperCompletion',
  MIDDLE_COMPLETION: 'middleCompletion',
  XMT: 'XMT',
});

export const AUTO_SELECT_TEMPLATE = 'auto-select';
export const NO_TEMPLATE = 'no-template';
export const ANY_OPTION = 'any-option';

export const linkFields = Object.freeze({
  CUSTOM: 'Custom',
  RIG_FLOOR: 'Rig Floor',
  WELLHEAD_DEPTH: 'Wellhead Depth',
  PREVIOUS_CASING_SHOE: 'Previous Casing Shoe',
  SECTION_TD: 'Section TD',
  CASING_SHOE: 'Casing Shoe',
  PREVIOUS_SECTION_TD: 'Previous Section TD',
  MUDLINE_DEPTH: 'Mudline Depth',
});

export const VolumeLinkFieldsType = Object.freeze({
  CUSTOM: 'Custom',
  ANNULUS_VOLUME: 'Section annulus volume',
});

export const AllLinkFieldsType = Object.freeze({
  CUSTOM: 'Custom',
  RIG_FLOOR: 'Rig Floor',
  WELLHEAD_DEPTH: 'Wellhead Depth',
  PREVIOUS_CASING_SHOE: 'Previous Casing Shoe',
  SECTION_TD: 'Section TD',
  CASING_SHOE: 'Casing Shoe',
  PREVIOUS_SECTION_TD: 'Previous Section TD',
  MUDLINE_DEPTH: 'Mudline Depth',
  ANNULUS_VOLUME: 'Section annulus volume',
});

export const valueTypes = Object.freeze({
  CALCULATED: 'calculated',
  SINGLE: 'single',
});

export const kpiFractionTypes = Object.freeze({
  COST: 'cost',
  TIME: 'time',
  DEPTH: 'depth',
  COMPLETION_DAYS: 'completionDays',
});

export const settingEntity = Object.freeze({
  USER: 'user',
  COMPANY: 'company',
});

export const InitDepthType = Object.freeze({
  WaterDepth: 200,
  WellheadDepth: 225,
  DrillfloorHeight: 25,
});

export const PLATFORM_JACKUP = 'PlatformJackup';

export const SEMI_SUBMERSIBLE = 'SemiSubmersible';

export const TLP_HYBRID = 'TlpHybrid';

export const InitDrillingUnitType = Object.freeze({
  [PLATFORM_JACKUP]: 0,
  [SEMI_SUBMERSIBLE]: InitDepthType.WaterDepth,
  [TLP_HYBRID]: InitDepthType.WaterDepth,
});

export const TemplateEvents = Object.freeze({
  DrillingAndCompletion: 'Drilling & Completion',
  Workover: 'Workover',
  PAndA: 'P&A',
});
