/**
 * proj4 alias that indicates projection, can be find in proj string as +proj= parameter
 */
export const ProjAliasType = Object.freeze({
  Tmerc: 'tmerc', // Transverse Mercator, Transverse Mercator South Orientated
  Utm: 'utm', // Transverse Mercator
  Lcc: 'lcc', //Lambert Conformal Conic 2SP, Lambert Conformal Conic 1SP
  Longlat: 'longlat', // geographic projection, Geographic CRS
  Geocent: 'geocent', // geocentric projection, Geographic CRS
  Cass: 'cass', // Cassini Soldner
  Stere: 'stere', // Polar Stereographic
  Omerc: 'omerc', // Hotine Oblique Mercator, Hotine Oblique Mercator Azimuth Center
  Aea: 'aea', // Albers Conic Equal Area
  Merc: 'merc', // Mercator 1SP, Mercator 2SP
  Sterea: 'sterea', // Oblique Stereographic
  Krovak: 'krovak', // Krovak
  Poly: 'poly', // Polyconic
  Eck1: 'eck1', // Eckert I
  Eck2: 'eck2', // Eckert II
  Eck3: 'eck3', // Eckert III
  Eck4: 'eck4', // Eckert IV
  Eck5: 'eck5', // Eckert V
  Eck6: 'eck6', // Eckert VI
  Moll: 'moll', // Mollweide
  Nzmg: 'nzmg', // New Zealand Map Grid
});
