import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  List,
  Drawer,
  ListHeading,
  ListSubheading,
  toast,
} from '@oliasoft-open-source/react-ui-library';
import {
  addInitialEstimate,
  compareSelected,
} from '~store/entities/compare-estimates/compare-estimates';
import translations from '~src/internationalisation/translation-map.json';
import { selectCompanyNameById } from '~store/entities/companies/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CompareList = ({
  companyId,
  list,
  compareSelected,
  addInitialEstimate,
  onClickDeleteCompare,
  onClickEditCompare,
  withWelldesignLicense,
  setEditItem,
  estimates,
  companyName,
  setEstimateSelected,
}) => {
  const { t } = useTranslation();
  const [estimatesExpanded, setEstimatesExpanded] = useState(true);
  const [designsExpanded, setDesignsExpanded] = useState(true);
  const buildItems = (list, isConcept, listName) =>
    list
      .filter((item) => item.isConcept === isConcept)
      .map((compare) => ({
        id: compare.compareId,
        name: compare.name,
        metadata: `${
          estimates.filter((item) => item.compareId === compare.compareId)
            .length
        }  ${
          isConcept ? t(translations.designs) : t(translations.estimates)
        } - ${
          compare.isPublic ? companyName : t(translations.projects_onlyMe)
        }`,
        active: compare.active,
        onClick: () => {
          compareSelected(compare.compareId);
          setEditItem(compare);
          setEstimateSelected(!isConcept);
        },
        actions: [
          {
            label: t(translations.more),
            testId: `list-more-${listName}-button`,
            subActions: [
              {
                label: t(translations.edit),
                testId: `list-edit-${listName}-button`,
                icon: 'edit',
                onClick: () => onClickEditCompare(compare),
              },
              {
                label: t(translations.delete),
                testId: `list-delete-${listName}-button`,
                icon: 'delete',
                onClick: () => onClickDeleteCompare(compare.compareId),
              },
            ],
          },
        ],
      }));

  const estimateItems = buildItems(list, false, 'forecast');
  const conceptItems = buildItems(list, true, 'well-design');

  return (
    <Drawer border button closedWidth="100px" open width="320px">
      <div data-testid="list-heading">
        <ListHeading name={t(translations.compare)} />
      </div>
      <ListSubheading
        item={{
          name: t(translations.compare_compareForecastEstimates),
          metaCount: estimateItems.length,
          expanded: estimatesExpanded,
          onClick: () => setEstimatesExpanded(!estimatesExpanded),
          actions: [
            {
              label: t(translations.add),
              testId: 'list-add-forecast-button',
              primary: true,
              icon: 'add',
              onClick: () => addInitialEstimate(companyId),
            },
          ],
        }}
      />
      {estimatesExpanded && (
        <List
          testId="list-forecast-wrapper"
          noHeader
          list={{ items: estimateItems }}
        />
      )}
      <ListSubheading
        item={{
          name: t(translations.compare_compareWellDesignDesigns),
          metaCount: conceptItems.length,
          expanded: designsExpanded,
          onClick: () => setDesignsExpanded(!designsExpanded),
          actions: [
            {
              label: t(translations.add),
              testId: 'list-add-well-design-button',
              primary: true,
              icon: 'add',
              onClick: () =>
                withWelldesignLicense
                  ? addInitialEstimate(companyId, true)
                  : toast({
                      message: {
                        type: 'Error',
                        content: t(
                          translations.thisFeatureRequiresAWellDesignLicense,
                        ),
                      },
                    }),
            },
          ],
        }}
      />
      {designsExpanded && (
        <List
          testId="list-welldesign-wrapper"
          noHeader
          list={{ items: conceptItems }}
        />
      )}
    </Drawer>
  );
};

const mapStateToProps = ({ entities }, { companyId }) => {
  const {
    authorization: { withWelldesignLicense },
  } = entities;

  return {
    companyName: selectCompanyNameById(entities, companyId),
    withWelldesignLicense,
  };
};

const mapDispatchToProps = {
  compareSelected,
  addInitialEstimate,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CompareList),
  { style: { width: '320px' } },
);

export { Container as CompareList };
