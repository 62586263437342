import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Button,
  Flex,
  Spacer,
  Text,
} from '@oliasoft-open-source/react-ui-library';
import emptyIcon from '~src/assets/empty.svg';
import translations from '~src/internationalisation/translation-map.json';

export const NoRisks = ({ onClickAdd, isPageDisabled }) => {
  const { t } = useTranslation();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      direction="column"
      height="100%"
    >
      <Icon icon={emptyIcon} />
      <Spacer height="var(--padding-xs)" />
      <Text faint>{t(translations.risks_noRisksHaveBeenAddedYet)}</Text>
      <Spacer height="var(--padding-xs)" />
      <Button
        name="addRisk"
        label={t(translations.risks_addRisk)}
        onClick={onClickAdd}
        colored
        disabled={isPageDisabled}
      />
    </Flex>
  );
};
