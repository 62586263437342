import ReactMarkdown from 'react-markdown';
import renderers from './Renderers/index';

export const NoteMarkDown = ({ note }: { note: string }) => (
  <ReactMarkdown
    components={{
      code: ({ children }) => renderers.CodeRenderer(children as string),
      text: ({ children }) => renderers.TextRenderer(children as string),
      link: ({ children, href = '' }) =>
        renderers.LinkRenderer(children as string, href),
      sub: 'sub',
      sup: 'sup',
    }}
  >
    {note}
  </ReactMarkdown>
);
