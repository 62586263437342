import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import { removeCodesList } from '~store/entities/operation-codes/operation-codes';
import translations from '~src/internationalisation/translation-map.json';

const CodesListDeleteModal = ({
  setModalVisible,
  removeCodesList,
  codesListId,
}) => {
  const { t } = useTranslation();
  const onClose = () => setModalVisible(false);
  const onDelete = () => {
    removeCodesList(codesListId);
    setModalVisible(false);
  };

  return (
    <Modal visible centered>
      <Dialog
        dialog={{
          heading: t(translations.settings_deleteCodesSet),
          content: (
            <>
              {t(translations.settings_areYouSureYouWantToDeleteThisCodesSet)}
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = { removeCodesList };
const Container = connect(null, mapDispatchToProps)(CodesListDeleteModal);

export { Container as CodesListDeleteModal };
