import { ChartDrawerParameters } from '../utils/types';

export const drawBackground = ({
  chart,
  chartData,
  drawUtils,
}: ChartDrawerParameters) => {
  const { paddingLeft: gridPaddingLeft, width: gridWidth } = chartData.grid;
  const { depthToGridOffset, diagramLine } = drawUtils;

  const backgroundGroup = chart.append('g').attr('class', 'background');

  chartData?.background?.backgrounds?.forEach((backgroundElement) => {
    const { bottom, chartId, color, top } = backgroundElement;

    const bottomY = depthToGridOffset(bottom);
    const topY = depthToGridOffset(top);

    backgroundGroup
      .append('path')
      .attr('class', chartId)
      .attr(
        'd',
        diagramLine([
          [gridPaddingLeft, topY],
          [gridPaddingLeft + gridWidth, topY],
          [gridPaddingLeft + gridWidth, bottomY],
          [gridPaddingLeft, bottomY],
        ]),
      )
      .attr('fill', color)
      .attr('stroke', color)
      .attr('stroke-width', 0.1)
      .attr('shape-rendering', 'crispEdges');
  });
};
