/* eslint-disable camelcase */
/**
 * Helper function to increase readability.
 * Evaluate a polynomial with coefficients coeffs.
 * @param {number[]} coeffs - coefficients of the polynomial with index corresponding to degree.
 * @param {number} x - point to evaluate polynomial at.
 * @returns {number} - valut of polynomial at x.
 */
const polyval = (coeffs, x) =>
  coeffs.reverse().reduce((sum, c) => x * sum + c, 0);

/**
 * Compute scale factor for a transverse mercator projection, such as UTM,
 * in the ellipsoid case.
 * @param {number} lon - longitude in radians.
 * @param {number} lat - latitude in radians.
 * @param {number} lon_0 - longitude of central meridian in radians.
 * @param {number} k_0 - initial scale factor along central meridian.
 * @param {SpheroidObject} ellipsoid
 * @returns {number} k - scale factor at (lon, lat).
 */
const transverseScaleFactor = (lon, lat, lon_0, k_0, ellipsoid) => {
  const { inverseFlattening } = ellipsoid;
  const f = 1 / inverseFlattening;
  const esq = (2 - f) * f;
  const epsq = esq / (1 - esq);
  const T = Math.tan(lat) ** 2;
  const C = epsq * Math.cos(lat) ** 2;
  const A = (lon - lon_0) * Math.cos(lat);
  const k =
    k_0 *
    polyval(
      [
        1,
        (1 + C) / 2,
        (5 - 4 * T + 42 * C + 13 * C * C - 28 * epsq) / 24,
        (61 - 148 * T + 16 * T * T) / 720,
      ],
      A * A,
    );
  return k;
};

export default transverseScaleFactor;
