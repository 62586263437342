import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import {
  Modal,
  Dialog,
  Button,
  Spacer,
  Flex,
  Text,
  FileInput,
} from '@oliasoft-open-source/react-ui-library';

import {
  downloadExampleExcel,
  importOperationsFromExcel,
} from '~store/entities/activity-model/activity-model';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const ImportEstimateModal = ({
  projectId,
  importOperationsFromExcel,
  setEstimateImportModalOpen,
  downloadExampleExcel,
}) => {
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleDownloadExampleExcel = async () => {
    await downloadExampleExcel();
  };

  const handleImportExcel = async () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    await importOperationsFromExcel(formData, projectId);

    setEstimateImportModalOpen(false);
  };

  const handleFileChange = (evt) => {
    setSelectedFile(evt.target.value);
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          width: '450px',
          heading: 'Import Excel File',
          content: (
            <div style={{ position: 'relative' }}>
              <form>
                <Text>
                  {t(
                    translations.activityModel_activityModelImportModalDownloadThe,
                  )}{' '}
                  <Text link onClick={() => handleDownloadExampleExcel()}>
                    {t(
                      translations.activityModel_activityModelImportModalExcelActivityModelTemplate,
                    )}
                  </Text>
                </Text>
                <br />
                <Text>
                  {t(
                    translations.activityModel_activityModelImportModalOpenThefile,
                  )}
                </Text>
                <br />

                <Text>
                  {t(
                    translations.activityModel_activityModelImportModalUploadTheExcelFile,
                  )}
                </Text>
                <Spacer />
                <Flex justifyContent="space-between">
                  <FileInput
                    width="400px"
                    file={selectedFile}
                    onChange={handleFileChange}
                  />
                </Flex>
                <Spacer height="var(--padding-xs)" />
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label="Import"
                colored
                onClick={handleImportExcel}
                disabled={!selectedFile}
              />
              <Button
                label="Cancel"
                onClick={() => setEstimateImportModalOpen(false)}
              />
            </>
          ),
          onClose: () => setEstimateImportModalOpen(false),
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = {
  importOperationsFromExcel,
  downloadExampleExcel,
};
const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(ImportEstimateModal),
  { isModal: true },
);

export { Container as ImportEstimateModal };
