import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
} from '@oliasoft-open-source/react-ui-library';
import { saveProjectHierarchy } from '~store/entities/projects/projects';
import { Select } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { countriesSchemaValidator } from '~schemas/ajv-validators';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CountryModal = ({
  setModalVisible,
  countriesOptions,
  saveProjectHierarchy,
  isAdding,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { country: null },
    resolver: getResolver(countriesSchemaValidator),
  });
  const onSubmit = handleSubmit(async (data) => {
    await saveProjectHierarchy({ ...data, companyId: company });
    setModalVisible(false);
  });
  const onClose = () => {
    if (!isAdding) {
      setModalVisible(false);
    }
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.projects_addNewCountry),
          width: '600px',
          content: (
            <form>
              <Field label={t(translations.country)} labelLeft>
                <Select
                  testId="show-list"
                  options={countriesOptions}
                  name="country"
                  control={control}
                  errors={errors}
                  disabled={isAdding}
                />
              </Field>
            </form>
          ),
          footer: (
            <>
              <Button
                label={t(translations.done)}
                colored
                testId="country-added"
                onClick={onSubmit}
                loading={isAdding}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => ({
  isAdding: entities.projects.isAdding,
});
const mapDispatchToProps = { saveProjectHierarchy };
const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CountryModal),
  { isModal: true },
);

export { Container as CountryModal };
