import { casingStringType } from '~src/enums/well-details';
import { toNum } from '@oliasoft-open-source/units';
import { wellConfiguration } from '~src/enums/settings';
import {
  IMeta,
  ISectionType,
  TCasingRendererDimensions,
  TWellDetails,
  TWellSchematic,
} from '../utils/types';

export const calculateMeta = (
  wellSchematics: TWellSchematic[],
  wellDetails: TWellDetails,
  units: { [unit: string]: any },
  dimensions: TCasingRendererDimensions,
  sectionTypes: ISectionType[],
): IMeta => {
  const { densityUnit, depthUnit, diameterUnit, depthReference } = units;

  const sectionTypeMap = new Map(
    sectionTypes.map((section) => [section.name, section.sectionTypeId]),
  );

  const { airGap, waterDepth, wellheadDepth } = wellDetails;

  const drillFloorHeight = airGap;
  const seabed =
    wellDetails.wellConfiguration === wellConfiguration.OFFSHORE
      ? airGap + waterDepth
      : airGap;

  const totalDepth = wellSchematics.reduce(
    (acc, schematic) => Math.max(schematic.sectiontd, acc),
    0,
  );

  const totalDiameter =
    wellSchematics.reduce(
      (acc, schematic) => Math.max(toNum(schematic.holeSize), acc),
      0,
    ) + 4;

  const totalDepthCasings = wellSchematics.reduce(
    (acc, schematic) =>
      schematic.sectionTypeId === sectionTypeMap.get(casingStringType.CASING)
        ? Math.max(schematic.sectiontd, acc)
        : acc,
    0,
  );

  const meta: IMeta = {
    densityUnit,
    depthReference,
    depthUnit,
    diameterUnit,
    drillFloorHeight,
    height: dimensions.height,
    seabed,
    totalDepth,
    totalDepthCasings,
    totalDiameter,
    waterDepth,
    wellheadDepth,
    width: dimensions.width,
  };

  return meta;
};
