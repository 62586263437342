import { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Dialog,
  Table,
  useKeyboardEvent,
} from '@oliasoft-open-source/react-ui-library';
import { ConnectedProps, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import {
  importBulkCostItems,
  initialCostItem,
} from '~store/entities/cost-setup/cost-setup';
import { getPriceBook } from '~src/store/entities/price-book/price-book';
import {
  ICostItem,
  IPriceBookItem,
} from '~src/common/interfaces/price-book.interfaces';
import { useParams } from 'react-router';
import { typeQuantityMap } from '~src/enums/price-book';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import type { TRootState } from '~src/store/store-types';

type TImportFromPriceBookModal = PropsFromRedux & {
  showImportFromPriceBookModalUpdated: (param: boolean) => void;
  parentId?: string;
};

const ImportFromPriceBookModal = ({
  priceBook,
  importBulkCostItems,
  parentId,
  showImportFromPriceBookModalUpdated,
  isAdding,
  getPriceBook,
}: TImportFromPriceBookModal) => {
  const { t } = useTranslation();

  useEffect(() => {
    getPriceBook();
  }, []);

  const { project: projectId } = useParams();
  const onClose = () => showImportFromPriceBookModalUpdated(false);
  useKeyboardEvent('Escape', onClose);
  const [priceBookList, setPriceBookList] = useState<IPriceBookItem[]>([]);
  const handleSubmit = (parentId: string | null) => {
    const costItems = priceBookList.map((priceBook) => {
      let newObject: ICostItem = {
        ...initialCostItem,
        name: priceBook.name,
        cost: priceBook.price,
        currency: priceBook.currency,
        vendor: priceBook.vendor,
        parentId,
      };

      const typeQuantity = typeQuantityMap[priceBook.priceType];

      newObject = {
        ...newObject,
        type: typeQuantity.type,
        quantity: typeQuantity.quantity,
      };

      // Set unitCost if the type is 'quantity'
      if (typeQuantity.type === 'quantity') {
        newObject.unitCost = priceBook.price;
      }

      return newObject;
    });
    importBulkCostItems({ costItems, projectId });
  };

  const rows = priceBook?.map((priceBookItem: IPriceBookItem) => {
    const checked = priceBookList.some(
      (item: IPriceBookItem): boolean =>
        item.priceBookId === priceBookItem.priceBookId,
    );
    return {
      cells: [
        {
          onChange: () => {
            if (checked) {
              setPriceBookList((prev) =>
                prev.filter(
                  (item) => item.priceBookId !== priceBookItem.priceBookId,
                ),
              );
            } else {
              setPriceBookList([...priceBookList, priceBookItem]);
            }
          },
          type: 'CheckBox',
          checked,
        },
        {
          name: 'name',
          value: priceBookItem.name,
        },
        {
          name: 'vendor',
          value: priceBookItem.vendor,
        },
      ],
    };
  });

  return (
    <Modal centered visible>
      <Dialog
        dialog={{
          heading: t(translations.priceBook_importFromPriceBook),
          content: (
            <div
              style={{
                position: 'relative',
                margin: 'calc(var(--padding) * -1)',
              }}
            >
              <form style={{ padding: 'var(--padding)' }}>
                <Table
                  table={{
                    fixedWidth: '100%',
                    headers: [
                      {
                        cells: [
                          {},
                          {
                            value: t(translations.name),
                          },
                          {
                            value: t(translations.priceBook_vendor),
                          },
                        ],
                      },
                    ],
                    rows,
                  }}
                />
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label={t(translations.priceBook_importItems, {
                  count: priceBookList.length,
                })}
                colored
                disabled={!priceBookList.length || isAdding}
                onClick={() => handleSubmit(parentId ?? null)}
                loading={isAdding}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const { priceBookItems: priceBook } = entities.priceBook;
  const { isAdding } = entities.costSetup;
  return {
    priceBook,
    isAdding,
  };
};

const mapDispatchToProps = {
  importBulkCostItems,
  getPriceBook,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(
  connector(ImportFromPriceBookModal),
  { isModal: true },
);

export { Container as ImportFromPriceBookModal };
