import { INote } from '~src/common/interfaces/notes.interfaces';

export const getVisibleNotesSortered = (
  noteItems: INote[],
  currentModule: string,
  showAll: boolean,
) => {
  if (!noteItems.length) return [];
  if (!showAll) {
    return noteItems.filter((note) => note.noteModule === currentModule);
  }
  return noteItems;
};
