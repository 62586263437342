import { useEffect } from 'react';
import {
  Heading,
  Row,
  Column,
  Spacer,
  Divider,
  Loader,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { getSectionsDataStructure } from '~src/store/entities/sections-data-structure/sections-data-structure';
import { ConnectedProps, connect } from 'react-redux';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import {
  ISection,
  IOperation,
  IActivity,
} from '~src/common/interfaces/sections-data-structure.interfaces';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { SectionList } from './sections.list';
import type { TRootState } from '~src/store/store-types';
import { OperationsList } from './operations.list';
import { ActivitiesList } from './activities.list';

const SectionsDataStructure = ({
  getSectionsDataStructure,
  sections,
  isFetching,
  activeSection,
  sectionOperations,
  activeOperation,
  operationActivities,
}: PropsFromRedux) => {
  const { t } = useTranslation();

  useEffect(() => {
    getSectionsDataStructure();
  }, []);

  return (
    <Column scroll={false} spacing={0}>
      {isFetching && (
        <Loader text={t(translations.fetching)} theme="light" cover>
          <Spinner dark />
        </Loader>
      )}
      <Spacer />
      <Row spacing={0}>
        <Spacer width="var(--padding-sm)" />
        <Heading top marginBottom={16}>
          {t(translations.dataStructure)}
        </Heading>
      </Row>
      <Divider margin={0} />
      <Row spacing={0} height="100%">
        <Column borderRight width="33%">
          <SectionList sections={sections} />
        </Column>
        {activeSection && (
          <Column borderRight width="33%">
            <OperationsList
              activeSection={activeSection}
              sectionOperations={sectionOperations}
            />
          </Column>
        )}
        {activeOperation && (
          <Column>
            <ActivitiesList operationActivities={operationActivities} />
          </Column>
        )}
      </Row>
    </Column>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const { sections, operations, activities, isFetching } =
    selectSectionsDataStructure(entities);
  const activeSection = sections.find((section: ISection) => section.active);
  const sectionOperations = activeSection
    ? operations.filter(
        (operation: IOperation) =>
          operation.sectionId === activeSection.sectionId,
      )
    : [];

  const activeOperation = sectionOperations.find(
    (operation: IOperation) => operation.active,
  );

  const operationActivities = activeOperation
    ? activities.filter(
        (activity: IActivity) =>
          activity.sectionsOperationId === activeOperation.sectionsOperationId,
      )
    : [];

  return {
    sections,
    isFetching,
    activeSection,
    sectionOperations,
    activeOperation,
    operationActivities,
  };
};
const mapDispatchToProps = {
  getSectionsDataStructure,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(SectionsDataStructure));

export { Container as SectionsDataStructure };
