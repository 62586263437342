import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  cartesianCoordinateToDMS,
  DMSCoordinateToCartesian,
} from '~common/gis/coordinate-conversion-utils';
import { getDatumEPSG, isOffsetType } from '~common/gis/gis-library-utils';
import LocationSystemSelectors from '~common/components/gis/location-system-selectors';
import LocationCoordinate from '~common/components/gis/location-coordinate';
import { ComponentRenderingType } from '~src/enums/rendering-enums';
import { transformLocationObject } from '~common/gis/transform-location-object';
import { FormRow } from '@oliasoft-open-source/react-ui-library';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

/**
 * @typedef {0|1|2} LocationUiState - see ComponentRenderingType enum
 */

/**
 * @typedef {Object} LocationInputsUIControls
 * @property {LocationUiState} [locationSelectors] - state of epsg code selectors
 * @property {LocationUiState} [locationType] - state of location type radio-button
 * @property {LocationUiState} [locationOffset] - state of offset inputs
 * @property {LocationUiState} [locationCoordinate] - state of coordinate inputs (in cartesian and DMS format)
 * @property {LocationUiState} [locationNorthReference] - state of north reference inputs
 * @property {LocationUiState} [locationApplyScale] - state of apply scale inputs
 * @property {LocationUiState} [referenceLocationAccordion] - state of collapsible reference location (read-only), used in well and target location UIs
 * */

/**
 * @type {LocationInputsUIControls}
 */
const defaultLocationUiSettings = Object.freeze({
  locationSelectors: ComponentRenderingType.Disabled,
  locationType: ComponentRenderingType.Hidden,
  locationOffset: ComponentRenderingType.Hidden,
  locationCoordinate: ComponentRenderingType.Hidden,
  locationNorthReference: ComponentRenderingType.Hidden,
  locationApplyScale: ComponentRenderingType.Hidden,
  referenceLocationAccordion: ComponentRenderingType.Hidden,
});

/**
 * @typedef {import('common/interfaces/dataset-db.interface').ICoordinates} ICoordinates
 */

/**
 * @param {ICoordinates} location - describes location point
 * @param {ICoordinates} [referenceLocation] - describes site location, which is reference for offset locations
 * @param {function} onChange
 * @param {LocationInputsUIControls} locationUiSettings - object defines whether related location section is hidden/enabled/disabled. By default, EPSG selection is disabled, other fields are hidden
 * @param {string} [fieldEpsg] - epsg code of field location, used only for offset locations in order to calculate distance (calculateOffsetVector)
 * @param {string} [locationUnit] - location unit defined by unit template
 * @returns {JSX.Element}
 */
const LocationInput = ({
  location,
  referenceLocation,
  onChange,
  locationUiSettings,
  isPageDisabled,
}) => {
  const currentLocationEpsgCode = location.epsgCode;
  const referenceLocationEpsgCode = referenceLocation?.epsgCode;

  const currentLocationXY = [location.x, location.y];

  const isLocationOffsetType = isOffsetType(currentLocationEpsgCode);

  // DMS SETUP
  const createAbsoluteLocation = (location, referenceLocation) => {
    const absoluteLocation = isOffsetType(location.epsgCode)
      ? transformLocationObject(
          location,
          referenceLocation.epsgCode,
          referenceLocation,
        )
      : location;
    return absoluteLocation;
  };
  const createDMSArray = (location, referenceLocation) => {
    const absoluteLocation = createAbsoluteLocation(
      location,
      referenceLocation,
    );
    const absoluteLocationXY = [absoluteLocation.x, absoluteLocation.y];
    const locationDMSFormat = cartesianCoordinateToDMS(
      absoluteLocationXY,
      absoluteLocation.epsgCode,
      getDatumEPSG(absoluteLocation.epsgCode),
    );

    return locationDMSFormat;
  };

  const currentLocationDMS = createDMSArray(location, referenceLocation);

  const absoluteCurrentLocation = createAbsoluteLocation(
    location,
    referenceLocation,
  );
  const absoluteCurrentLocationXY = [
    absoluteCurrentLocation.x,
    absoluteCurrentLocation.y,
  ];

  const {
    locationSelectors,
    locationOffset,
    locationCoordinate,
    referenceLocationAccordion,
  } = { ...defaultLocationUiSettings, ...locationUiSettings };

  const isHidden = ComponentRenderingType.Hidden;
  const isDisabled = ComponentRenderingType.Disabled;

  const handleEpsgChange = (newEpsg) => {
    if (Object.keys(location).length === 1 && location.epsgCode) {
      onChange({
        ...location,
        epsgCode: newEpsg,
      });
    } else {
      const newCoordinates = transformLocationObject(
        location,
        newEpsg,
        referenceLocation,
      );
      onChange(newCoordinates);
    }
  };

  const onXYValuesChange = (updatedValuePair) => {
    const newCoordinates = {
      ...location,
      x: Number(updatedValuePair[0]),
      y: Number(updatedValuePair[1]),
    };

    onChange(newCoordinates);
  };

  const onLatLongChange = (updatedValues) => {
    const fromEPSG = getDatumEPSG(location.epsgCode);
    const toEPSG = location.epsgCode;
    const [easting, northing] = DMSCoordinateToCartesian(
      updatedValues,
      fromEPSG,
      toEPSG,
    );

    const newCoordinates = {
      ...location,
      x: easting,
      y: northing,
    };

    onChange(newCoordinates);
  };

  return (
    <FormRow>
      {locationSelectors !== isHidden &&
        referenceLocationAccordion === isHidden && (
          <LocationSystemSelectors
            locationEpsg={
              isLocationOffsetType
                ? referenceLocationEpsgCode
                : currentLocationEpsgCode
            }
            isReferenceLocation={isLocationOffsetType}
            onEpsgChange={handleEpsgChange}
            disableLocationSelectors={isPageDisabled}
          />
        )}

      <LocationCoordinate
        locationEpsg={currentLocationEpsgCode}
        currentLocationEpsg={currentLocationEpsgCode}
        referenceLocationEpsg={referenceLocationEpsgCode}
        eastingNorthing={
          locationOffset ? absoluteCurrentLocationXY : currentLocationXY
        }
        dmsRepresentation={currentLocationDMS}
        onEastingNorthingChange={onXYValuesChange}
        onLongLatChange={onLatLongChange}
        disabled={locationCoordinate === isDisabled || isPageDisabled}
      />
    </FormRow>
  );
};

const mapStateToProps = () => ({
  locationUnit: 'm',
});

LocationInput.defaultProps = {
  referenceLocation: {},
  onChange: () => {},
  locationUiSettings: defaultLocationUiSettings,
};

LocationInput.propTypes = {
  location: PropTypes.object.isRequired,
  referenceLocation: PropTypes.object,
  fieldEpsg: PropTypes.string,
  locationUiSettings: PropTypes.objectOf(PropTypes.oneOf([0, 1, 2])),
  onChange: PropTypes.func,
};
export default withErrorBoundary(connect(mapStateToProps)(LocationInput));
