import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Table } from '@oliasoft-open-source/react-ui-library';
import { Toggle } from '~common/form-inputs';
import { Sections } from '~src/enums/sections';
import { autoSaveWait } from '~src/config/config';
import { saveWellEvents } from '~store/entities/well-details/well-details';
import translations from '~src/internationalisation/translation-map.json';
import { useParams } from 'react-router';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const AdditionalSectionsTable = ({
  additionalSections,
  control,
  isPageDisabled,
  wellEvents,
  saveWellEvents,
  eventsSectionValues,
}) => {
  const { t } = useTranslation();
  const { project } = useParams();
  const [eventsValue, setEventsValue] = useState(eventsSectionValues);

  useEffect(() => {
    setEventsValue(eventsSectionValues);
  }, [eventsSectionValues]);

  const debounceSaveWellEvents = useRef(debounce(saveWellEvents, autoSaveWait));

  const sectionsMap = {
    [Sections.RigMove]: 'rigMove',
    [Sections.Interface]: 'interface',
    [Sections.LowerCompletion]: 'lowerCompletion',
    [Sections.MiddleCompletion]: 'middleCompletion',
    [Sections.UpperCompletion]: 'upperCompletion',
    [Sections.XMT]: 'xmt',
  };

  const rows = additionalSections.map((section) => {
    return {
      cells: [
        {
          width: '200px',
          value: (
            <Toggle
              name={sectionsMap[section.name]}
              label={section.name}
              control={control}
              disabled={isPageDisabled}
              noMargin
            />
          ),
        },
        {
          type: 'Select',
          width: '240px',
          multi: true,
          value: eventsValue[section.sectionId],
          onChange: (e) => {
            const { value } = e.target;
            setEventsValue({ ...eventsValue, [section.sectionId]: value });
            debounceSaveWellEvents.current(project, {
              sectionId: section.sectionId,
              wellEvents: value,
            });
          },
          options: wellEvents.map((wellEvent) => ({
            label: wellEvent.name,
            value: wellEvent.wellEventId,
          })),
        },
      ],
    };
  });

  const table = {
    fixedWidth: 'auto',
    headers: [
      {
        cells: [
          {
            value: t(translations.wellDetails_tubularType),
            width: '140px',
          },
          {
            value: t(translations.wellDetails_events),
            width: '140px',
          },
        ],
      },
    ],
    rows,
  };

  return <Table table={table} />;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = ({ entities }, ownProps) => {
  const eventsSectionValues = {};
  ownProps.additionalSections.forEach((item) => {
    eventsSectionValues[item.sectionId] = item.wellEvents;
  });

  return { eventsSectionValues };
};

const mapDispatchToProps = { saveWellEvents };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(AdditionalSectionsTable),
);

export { Container as AdditionalSectionsTable };
