import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import {
  Table,
  Spacer,
  Flex,
  Message,
} from '@oliasoft-open-source/react-ui-library';
import {
  getDurationOrCost,
  getMetersPerDay,
} from '~src/store/entities/simulations/simulations';
import translations from '~src/internationalisation/translation-map.json';
import { useTranslation } from 'react-i18next';
import { IActiveCampaign } from '~src/common/interfaces/campaigns.interfaces';
import { displayNumber, round } from '@oliasoft-open-source/units';
import { probabilities } from '~src/enums/simulations';
import { SimulationChartType } from '~src/enums/campaigns';

interface SummaryTableProps {
  campaign: IActiveCampaign;
  probability: string | number;
  chartType: string;
  dataEntity: number;
  primaryCurrency: string;
}

const RawSummaryTable = ({
  campaign,
  chartType,
  dataEntity,
  primaryCurrency,
}: SummaryTableProps) => {
  const { t } = useTranslation();
  const boldStyle = { fontWeight: 'bold' };
  const cumulativeDurations: number[] = Array(
    Object.keys(probabilities).length,
  ).fill(0);

  const estimateRows = campaign.estimates.map((estimate) => {
    const values = Object.values(probabilities).map((percentile, index) => {
      const duration =
        getDurationOrCost(estimate.simulation, percentile, dataEntity) ?? 0;
      if (chartType === SimulationChartType.METERS_PER_DAY) {
        const maxDepthPerDay = getMetersPerDay(
          estimate.simulation,
          duration,
          percentile,
        );
        return { value: round(maxDepthPerDay, 2) };
      }
      cumulativeDurations[index] += duration;
      return {
        value: dataEntity === 0 ? displayNumber(round(duration, 1), { scientific: false }) : displayNumber(round(duration, 0), { scientific: false }),
      };
    });

    return {
      cells: [{ value: estimate.name }, ...values],
    };
  });

  const additionalRows = [
    {
      active: true,
      cells: [
        {
          value: t(translations.campaigns_cumulativeTotal),
          style: boldStyle,
        },
        ...cumulativeDurations.map((d) => ({
          value: dataEntity ===0 ?  displayNumber(round(d, 1), { scientific: false }) : displayNumber(round(d, 0), { scientific: false }),
          style: boldStyle,
        })),
      ],
    },
    ...(campaign?.campaignTotal?.simulation
      ? [
          {
            active: true,
            cells: [
              {
                value: t(translations.campaigns_campaignTotal),
                style: boldStyle,
              },
              ...Object.values(probabilities).map((percentile) => {
                const duration =
                  getDurationOrCost(
                    campaign.campaignTotal.simulation,
                    percentile,
                    dataEntity,
                  ) ?? 0;
                return {
                  style: boldStyle,
                  value: dataEntity ===0 ? displayNumber(round(duration, 1) , {
                    scientific: false,
                  }): displayNumber(round(duration, 0) , {
                    scientific: false,
                  }) ,
                };
              }),
            ],
          },
        ]
      : []),
  ];

  const tableSubHeaderName = (() => {
    let name = '';
    if (chartType === SimulationChartType.METERS_PER_DAY) {
      name = t(translations.meters);
    } else if (dataEntity === 0) {
      name = t(translations.days);
    } else {
      name = primaryCurrency;
    }
    return <span className="table-subheader-name">{name}</span>;
  })();

  const table = {
    fixedWidth: '90%',
    headers: [
      {
        cells: [
          {
            value: t(translations.estimate), style:{width: '50%'}
          },
          { value: 'Mean' },
          { value: 'P10' },
          { value: 'P50' },
          { value: 'P90' },
        ],
      },
      {
        cells: [
          { value: '' },
          { value: tableSubHeaderName },
          { value: tableSubHeaderName },
          { value: tableSubHeaderName },
          { value: tableSubHeaderName },
        ],
      },
    ],
    rows: [
      ...estimateRows,
      ...(chartType === SimulationChartType.TOTAL_DAYS ? additionalRows : []),
    ],
  };
  return (
    <>
      <Table table={table} />
      {!campaign.campaignTotal.simulation && (
        <>
          <Spacer />
          <Flex direction="column">
            <Message
              message={{
                content: t(translations.campaigns_campaignTotalIsLoading),
                type: 'Info',
                visible: true,
              }}
            />
          </Flex>
        </>
      )}
    </>
  );
};

export const SummaryTable = withErrorBoundary(RawSummaryTable);
