import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
  Heading,
  Flex,
  Spacer,
  Text,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { getCompanies } from '~store/entities/companies/companies';
import { getCompanySettings } from '~store/entities/company-settings/company-settings';
import {
  selectCompanyKpiList,
  selectCompanyTemplates,
} from '~store/entities/company-settings/selectors';
import { Select, CheckBoxList } from '~common/form-inputs';

const ImportFromModal = ({
  isVisible,
  onCloseModal,
  onConfirmModal,
  getCompanySettings,
  getCompanies,
  companiesList,
  itemsList,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!companiesList) {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    reset();
  }, [isVisible]);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyId: companiesList[0].value,
      items: [],
    },
    mode: 'onChange',
  });
  const onSubmit = handleSubmit((data) => {
    onConfirmModal(data);
  });

  const { items, companyId } = watch();

  useEffect(() => {
    if (companyId) {
      getCompanySettings(companyId);
    }
  }, [companyId]);

  const optionList = companyId ? itemsList : [];
  return (
    <Modal visible={isVisible} centered fullScreen>
      <Dialog
        dialog={{
          heading: 'Copy from Company settings',
          width: '600px',
          content: (
            <form>
              {companiesList.length > 1 && (
                <Field label={t(translations.company)}>
                  <Select
                    options={companiesList}
                    name="companyId"
                    control={control}
                    errors={errors}
                  />
                </Field>
              )}

              <Flex justifyContent="space-between">
                <Heading>{t(translations.activityModel_operations)}</Heading>
                <div>
                  <Button
                    label={t(translations.checkAll)}
                    onClick={() =>
                      setValue(
                        'items',
                        optionList.map((item) => item.id),
                      )
                    }
                    small
                    disabled={!optionList?.length}
                  />

                  <Spacer width="var(--padding-xs)" />

                  <Button
                    label={t(translations.uncheckAll)}
                    onClick={() => setValue('items', [])}
                    small
                    disabled={!items?.length}
                  />
                </div>
              </Flex>
              <Spacer height="var(--padding-xs)" />
              {!items?.length && (
                <>
                  <Text faint>
                    {t(translations.activityModel_noOperationsHaveBeenAddedYet)}
                  </Text>
                  <Spacer height="var(--padding-xs)" />
                </>
              )}
              <CheckBoxList
                items={optionList || []}
                control={control}
                errors={errors}
                name="items"
              />
            </form>
          ),
          footer: (
            <>
              <Button
                label={'OK'}
                colored
                onClick={onSubmit}
                disabled={!items?.length}
              />
              <Button label={t(translations.cancel)} onClick={onCloseModal} />
            </>
          ),
          onCloseModal,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }, { importType }) => {
  const {
    companies: { list, isFetching },
  } = entities;
  const companiesList = list.map((company) => ({
    label: company.name,
    value: company.id,
  }));
  const companyKpiList = selectCompanyKpiList(entities);
  const companyTemplateList = selectCompanyTemplates(entities);
  const mappedTemplateList = companyTemplateList?.map((template, idx) => ({
    name: template.highLevel.name,
    id: idx,
  }));
  return {
    companiesList,
    isFetching,
    itemsList: importType === 'KPI' ? companyKpiList : mappedTemplateList,
  };
};

const mapDispatchToProps = { getCompanies, getCompanySettings };

const Container = connect(mapStateToProps, mapDispatchToProps)(ImportFromModal);

export { Container as ImportFromModal };
