import { axisLeft, axisTop } from 'd3-axis';
import { ChartDrawerParameters } from '../utils/types';

export const drawAxes = ({
  chart,
  chartData,
  drawUtils,
}: ChartDrawerParameters): void => {
  const { depthReference, depthUnit, diameterUnit } = chartData.meta;
  const {
    axisColor,
    axisLabelColor,
    height: gridHeight,
    paddingLeft: gridPaddingLeft,
    paddingTop: gridPaddingTop,
    width: gridWidth,
  } = chartData.grid;

  const axisGroup = chart.append('g').attr('class', 'axes');

  /**
   * X-Axis (Diameter)
   */
  const xNumTicks = Math.floor(gridWidth / 30);
  const xAxisGrid = axisTop(drawUtils.diameterToGridOffset)
    .ticks(xNumTicks)
    .tickSize(-gridHeight);
  const xAxisGroup = axisGroup
    .append('g')
    .attr('class', 'xAxis')
    .attr('stroke', axisColor)
    .attr('fill', axisColor)
    .style('font-size', 12)
    .style('font-family', 'inherit')
    .attr('transform', `translate(0, ${gridPaddingTop})`)
    .call(xAxisGrid);

  // Alter grid border color
  xAxisGroup.select('.domain').attr('stroke', 'inherit');

  // Style axis lines (Except borders)
  xAxisGroup
    .selectAll('line')
    .style('opacity', 0.1)
    .style('pointer-events', 'none');

  // Style axis labels
  const xAxisTextElements = xAxisGroup
    .selectAll('text')
    .attr('transform', 'translate(0, -5)')
    .attr('text-anchor', 'middle')
    .style('stroke', 'none')
    .style('fill', axisLabelColor)
    .style('opacity', 1);

  // Add unit to middle (0) axis label
  xAxisTextElements.each((axisValue, axisElementIx, axisElements) => {
    const axisElement = axisElements[axisElementIx];
    if (axisElement && 'text' in axisElement && axisValue === 0) {
      axisElement.text = `0 ${diameterUnit}`;
    }
  });

  /**
   * Y Axis (Depth)
   */
  const yNumTicks = Math.floor(gridHeight / 75);
  const yAxisGrid = axisLeft(drawUtils.depthBelowSeabedToGridOffset)
    .ticks(yNumTicks)
    .tickSize(-gridWidth);
  const yAxisGroup = axisGroup
    .append('g')
    .attr('class', 'yAxis')
    .attr('stroke', axisColor)
    .attr('fill', axisColor)
    .style('font-size', 12)
    .style('font-family', 'inherit')
    .attr('transform', `translate(${gridPaddingLeft}, 0)`)
    .call(yAxisGrid);

  // Alter grid border color
  yAxisGroup.select('.domain').attr('stroke', 'inherit');

  // Style axis lines (Except borders)
  yAxisGroup
    .selectAll('line')
    .style('opacity', 0.1)
    .style('pointer-events', 'none');

  // Style axis labels
  yAxisGroup
    .selectAll('text')
    .attr('transform', 'translate(7, 9)')
    .attr('text-anchor', 'start')
    .style('stroke', 'none')
    .style('fill', axisLabelColor);

  // Add label with depth reference to top-left of grid
  yAxisGroup
    .append('text')
    .attr('fill', 'currentColor')
    .attr('x', 5)
    .attr('y', gridPaddingTop + 12)
    .attr('text-anchor', 'start')
    .style('stroke', 'none')
    .style('fill', axisLabelColor)
    .text(`${0} ${depthUnit}${depthReference}`);

  // Remove zero label if exists (Will exist for charts with seabed = 0)
  yAxisGroup
    .selectAll('text')
    .each((axisValue, axisElementIx, axisElements) => {
      const axisElement = axisElements[axisElementIx];
      if (axisElement && 'text' in axisElement && axisValue === 0) {
        axisElement.text = '';
      }
    });
};
