import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Spacer,
  Text,
  Flex,
  Button,
  List,
} from '@oliasoft-open-source/react-ui-library';
import emptyIcon from '~src/assets/empty.svg';
import { offsetWellsTabs } from '~src/enums/well-details';
import translations from '~src/internationalisation/translation-map.json';

const SelectedEstimatesList = ({ setActiveTabIndex, items }) => {
  const { t } = useTranslation();

  return (
    <>
      <List list={{ items }} noHeader />
      <Flex
        alignItems="center"
        justifyContent="center"
        direction="column"
        height="100%"
      >
        {items.length === 0 && (
          <>
            <Spacer height={200} />
            <Icon icon={emptyIcon} />
            <Spacer height="var(--padding-xs)" />
            <Text faint>
              {t(translations.wellDetails_noOffsetWellsSelectedYet)}
            </Text>
          </>
        )}
        <Spacer />
        <Flex direction="row">
          <Button
            name="selectFromList"
            label={t(translations.wellDetails_selectFromList)}
            onClick={() => setActiveTabIndex(offsetWellsTabs.LIST)}
            colored
          />
          <Spacer width="var(--padding-xs)" />
          <Button
            name="selectFromMap"
            label={t(translations.wellDetails_selectFromMap)}
            onClick={() => setActiveTabIndex(offsetWellsTabs.MAP)}
          />
        </Flex>
      </Flex>
    </>
  );
};

export { SelectedEstimatesList };
