import { useState } from 'react';
import {
  Table,
  Button,
  Spacer,
  Spinner,
  Icon,
} from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import {
  ICompareActiveEstimate,
  ICompareDesignItem,
  ICompareEstimateListItem,
} from '~src/common/interfaces/compare-estimates.interfaces';
import {
  removeCompareItem,
  removeConceptDesign,
  runConceptCompare,
} from '~src/store/entities/compare-estimates/compare-estimates';
import { ConnectedProps, connect } from 'react-redux';
import { pick } from 'lodash';
import { HierarchyType } from '~src/enums/compare-estimates';
import { selectUserTempaltes } from '~src/store/entities/user-settings/selectors';
import { selectCompanyTemplates } from '~src/store/entities/company-settings/selectors';
import { settingEntity } from '~src/enums/settings';
import { useParams } from 'react-router';
import { simulationStatuses } from '~src/enums/simulations';
import type { ChangeEvent } from 'react';
import { ITemplate } from '~src/common/interfaces/templates.interfaces';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import type { TRootState } from '~src/store/store-types';

interface ICompareEstimatesInputTable extends PropsFromRedux {
  filteredEstimates: ICompareEstimateListItem[];
  showCompareModalUpdated: (value: boolean) => void;
  showCompareEstimatesModalUpdated: (value: boolean) => void;
  isConcept: boolean;
  compareId: string;
  compareDesigns: ICompareDesignItem[];
  name: string;
  activeEstimates: ICompareActiveEstimate[];
  subjectId: string | null;
}

interface ISelectedTemplate {
  datasetid: number;
  value: string;
}

const CompareEstimatesInputTable = ({
  filteredEstimates,
  showCompareModalUpdated,
  showCompareEstimatesModalUpdated,
  isConcept,
  removeCompareItem,
  compareId,
  compareDesigns,
  templateOptions,
  removeConceptDesign,
  runConceptCompare,
  name,
  activeEstimates,
  subjectId,
}: ICompareEstimatesInputTable) => {
  const { t } = useTranslation();
  const { company } = useParams();

  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [selectedPage, setSelectedPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedTemplate, setSelectedTemplate] = useState<ISelectedTemplate[]>(
    [],
  );

  const headerLabels = [
    ...(isConcept && compareDesigns.length > 0
      ? [
          {
            label: t(translations.compare_automationMethod),
            identifier: HierarchyType.AutomationMethod,
          },
        ]
      : []),
    {
      label: isConcept ? t(translations.design) : t(translations.estimate),
      identifier: HierarchyType.Name,
    },
    { label: t(translations.well), identifier: HierarchyType.WellName },
    { label: t(translations.wellbore), identifier: HierarchyType.WellboreName },
    { label: t(translations.site), identifier: HierarchyType.SiteName },
    { label: t(translations.country), identifier: HierarchyType.CountryName },
    ...(isConcept && compareDesigns.length > 0
      ? [{ label: t(translations.user), identifier: HierarchyType.UserName }]
      : []),
  ];

  const headerRow = headerLabels?.map(({ label }) => {
    const width =
      label === t(translations.compare_automationMethod) ? '20%' : 'auto';
    return {
      value: label,
      width,
    };
  });

  const headerFilter = headerLabels?.map((header) => {
    const { identifier } = header;
    const filterValue = filters[identifier] ?? '';
    return header.identifier === HierarchyType.AutomationMethod
      ? {}
      : {
          key: identifier,
          value: filterValue,
          type: 'Input',
          placeholder: t(translations.search),
          onChange: (ev: React.ChangeEvent<HTMLInputElement>) => {
            setFilters((p) => ({
              ...p,
              [identifier]: ev.target.value,
            }));
          },
        };
  });

  const startIndex = (selectedPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const estimates: (ICompareEstimateListItem | ICompareDesignItem)[] =
    filteredEstimates.length > 0 ? filteredEstimates : compareDesigns;

  const filteredData = estimates.filter((design) =>
    Object.keys(filters).every(
      (key: any) =>
        filters[key] === '' ||
        design[key as keyof typeof design]
          ?.toLowerCase()
          .includes(filters[key].toLowerCase()),
    ),
  );

  const rows = [
    ...filteredData?.map((estimate, idx: number) => {
      let rowCells = Object.entries(
        pick(estimate, [
          HierarchyType.Name,
          HierarchyType.WellName,
          HierarchyType.WellboreName,
          HierarchyType.SiteName,
          HierarchyType.CountryName,
          HierarchyType.UserName,
        ]),
      ).map((value) => ({
        value: value[1],
      }));

      if (isConcept && compareDesigns.length > 0) {
        const additionalCell = {
          options: templateOptions,
          value:
            selectedTemplate.find(
              (template) =>
                template.datasetid ===
                (estimate as ICompareDesignItem).datasetId,
            )?.value || null,
          type: 'Select',
          placeholder: t(translations.compare_selectRuleset),
          onChange: (ev: ChangeEvent<HTMLSelectElement>) => {
            setSelectedTemplate((prev) => {
              const index = prev.findIndex(
                (item) =>
                  item.datasetid === (estimate as ICompareDesignItem).datasetId,
              );
              if (index !== -1) {
                prev[index].value = ev.target.value;
                return [...prev];
              } else {
                return [
                  ...prev,
                  {
                    datasetid: (estimate as ICompareDesignItem).datasetId,
                    value: ev.target.value,
                  },
                ];
              }
            });
          },
        };

        rowCells = [additionalCell, ...rowCells];
      }

      return {
        cells: rowCells,
        actions: [
          {
            label: 'Delete',
            icon: 'minus',
            onClick: () =>
              isConcept && compareDesigns.length > 0
                ? removeConceptDesign(
                    compareId,
                    (estimate as ICompareDesignItem).datasetId,
                  )
                : removeCompareItem(
                    compareId,
                    (estimate as ICompareEstimateListItem).projectId,
                  ),
          },
          isConcept && activeEstimates.length > 0
            ? {
                icon:
                  activeEstimates[idx]?.isFetching ||
                  (activeEstimates[idx]?.simulation &&
                    activeEstimates[idx].simulation.status !==
                      simulationStatuses.FINISHED) ? (
                    <Spinner colored tiny />
                  ) : (
                    <Icon
                      color="var(--color-background-success)"
                      icon="success"
                    />
                  ),
              }
            : {},
        ],
      };
    }),
  ];

  const filteredRows = rows.slice(startIndex, endIndex);

  const table = {
    fixedWidth: '100%',
    testId: 'compare-estimates-table',
    headers: [
      {
        cells: headerRow,
        actions: [
          !isConcept || compareDesigns.length > 0
            ? {
                primary: true,
                label: 'Add',
                icon: 'add',
                onClick: () =>
                  isConcept
                    ? showCompareModalUpdated(true)
                    : showCompareEstimatesModalUpdated(true),
              }
            : {},
        ],
      },
      { cells: headerFilter },
    ],
    rows: filteredRows,
    footer: {
      pagination: {
        rowCount: rows.length,
        selectedPage,
        rowsPerPage: {
          onChange: (evt: React.ChangeEvent<HTMLSelectElement>) =>
            setRowsPerPage(Number(evt.target.value)),
          options: [
            { label: `10 / ${t(translations.page)}`, value: 10 },
            { label: `20 / ${t(translations.page)}`, value: 20 },
            { label: `50 / ${t(translations.page)}`, value: 50 },
          ],
          value: rowsPerPage,
        },
        onSelectPage: setSelectedPage,
        small: true,
      },
    },
  };
  const designs = selectedTemplate?.map((template) => template.datasetid);

  const showButton = designs.length === estimates.length;

  return (
    <>
      <Table table={table} />
      {isConcept && (
        <>
          <Spacer />
          <Button
            label={t(translations.confirmSelection)}
            colored
            disabled={!showButton}
            onClick={() => {
              setSelectedTemplate([]);
              runConceptCompare({
                companyId: company,
                compareId,
                designs,
                name,
                templates: selectedTemplate,
                wellDesignData: filteredData,
                subjectId,
              });
            }}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  removeCompareItem,
  removeConceptDesign,
  runConceptCompare,
};

const mapStateToProps = ({ entities }: TRootState) => {
  const userTemplates = selectUserTempaltes(entities);
  const companyTemplates = selectCompanyTemplates(entities);

  const templateOptions = [
    {
      label: i18n.t(translations.userRulesets),
      type: 'Heading',
    },
    ...userTemplates.map((template: ITemplate, idx: number) => ({
      label: template.highLevel.name,
      value: `${settingEntity.USER}-${idx}`,
    })),
    {
      label: i18n.t(translations.companyRulesets),
      type: 'Heading',
    },
    ...companyTemplates?.map((template: ITemplate, idx: number) => ({
      label: template.highLevel.name,
      value: `${settingEntity.COMPANY}-${idx}`,
    })),
  ];

  return {
    templateOptions,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(CompareEstimatesInputTable));

export { Container as CompareEstimatesInputTable };
