import { useState } from 'react';
import { Flex, Text } from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import translations from '~src/internationalisation/translation-map.json';
import { selectSubjectId } from '~src/store/entities/authorization/selectors';
import { INote } from '~src/common/interfaces/notes.interfaces';
import { NoteEditor } from './notes-editor';
import styles from './notes.module.less';
import { MetaText } from './helpers/meta-text';
import { getNoteModuleName, sameDate } from './notes-utils';
import { NotesDeleteModal } from './notes-delete-modal';
import { NoteMarkDown } from './notes-markdown';
import type { TRootState } from '~src/store/store-types';

type TNoteItemProps = {
  noteItem: INote;
  onUpdate: (noteToUpdate: Pick<INote, 'noteid' | 'note'>) => void;
  onDelete: (id: INote['noteid']) => void;
  currentSubjectId: string;
};

const NoteItem = ({
  // Props
  noteItem,
  onUpdate,
  onDelete,
  // State
  currentSubjectId,
}: TNoteItemProps) => {
  const { t } = useTranslation();
  const [isEditing, setEditing] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const isSameUser = noteItem.subjectid === currentSubjectId;

  const handleSubmit = (updatedNote: INote) => {
    const { note } = updatedNote;
    onUpdate({
      ...noteItem,
      note,
    });
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const onConfirmDelete = () => {
    onDelete(noteItem.noteid);
    setDeleteModalVisible(false);
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const openDeleteNoteModal = () => setDeleteModalVisible(true);

  if (isEditing) {
    return (
      <>
        <NoteEditor
          noteItem={noteItem}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          openDeleteNoteModal={openDeleteNoteModal}
          onConfirmDelete={onConfirmDelete}
          key={noteItem.noteid}
        />
        <NotesDeleteModal
          noteItem={noteItem}
          onConfirmDelete={onConfirmDelete}
          isOpen={deleteModalVisible}
          onClose={() => setDeleteModalVisible(false)}
        />
      </>
    );
  }

  return (
    <div className={styles.noteItem}>
      <NoteMarkDown note={noteItem.note} />
      <Flex gap="4px">
        {/* Authors name */}
        <MetaText>{noteItem.author ?? ''}</MetaText>
        {/* Module */}
        <MetaText>{getNoteModuleName(noteItem.noteModule, t)}</MetaText>
        {/* Updated date */}
        <MetaText>{new Date(noteItem.updated).toLocaleDateString()}</MetaText>
        {/* Add edited if it was updated */}
        {!sameDate(noteItem.created, noteItem.updated) && (
          <MetaText>(Edited)</MetaText>
        )}
        {/* Add edit option if user made the note */}
        {isSameUser && (
          <div className={styles.autoLeftMargin}>
            <Text link onClick={handleEditClick}>
              {t(translations.edit)}
            </Text>
          </div>
        )}
      </Flex>
    </div>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  return {
    currentSubjectId: selectSubjectId(entities),
  };
};

const Container = connect(mapStateToProps)(NoteItem);

export { Container as NoteItem };
