export const DEFAULT_SCALE_FACTOR = 1;

export const EPSG_OFFSET = 'EPSG:OFFSET';
export const EPSG_OFFSET_POLAR = 'EPSG:OFFSETPOLAR';

export const DEFAULT_LOCATION_ITEM = {
  code: 'EPSG:32631', // "datumEpsg": "EPSG:4326",
  kind: 'CRS-PROJCRS',
  name: 'WGS 84 / UTM zone 31N',
  proj4: '+proj=utm +zone=31 +datum=WGS84 +units=m +no_defs',
  bbox: [84, 0, 0, 6],
  area: 'Between 0°E and 6°E, northern hemisphere between equator and 84°N, onshore and offshore. Algeria. Andorra. Belgium. Benin. Burkina Faso. Denmark - North Sea. France. Germany - North Sea. Ghana. Luxembourg. Mali. Netherlands. Niger. Nigeria. Norway. Spain. Togo. United Kingdom (UK) - North Sea.',
  unit: {
    key: 'meter',
    label: 'm',
  },
  scaleFactor: '0.9996',
};

export const DEFAULT_COUNTRY_COORDINATE = {
  epsgCode: DEFAULT_LOCATION_ITEM.code,
};

/**
 * @type {import('common/interfaces/dataset-db.interface').IFieldLocation}
 */
export const DEFAULT_FIELD_COORDINATE = {
  epsgCode: DEFAULT_LOCATION_ITEM.code,
};

/**
 * @type {import('common/interfaces/dataset-db.interface').ISiteLocation}
 */
export const DEFAULT_SITE_COORDINATE = {
  epsgCode: DEFAULT_LOCATION_ITEM.code,
  x: 0,
  y: 0,
  northReference: 'gn',
  applyScale: true,
};

/**
 * Only well and target can be offset location (offset from site coordinate)
 */
export const DEFAULT_OFFSET_COORDINATE = {
  epsgCode: EPSG_OFFSET,
  x: 0,
  y: 0,
};
/**
 * @typedef LocationTypeOption
 * @property {string} value
 * @property {string} translationKey
 */

/**
 * Enum for location types
 * @readonly
 * @enum {LocationTypeOption}
 */
export const OFFSET_TYPES = Object.freeze({
  None: {
    value: 'none',
    translationKey: 'generalInputs_coordinates',
  },
  Offset: {
    value: EPSG_OFFSET,
    translationKey: 'generalInputs_cartesianOffset',
  },
  PolarOffset: {
    value: EPSG_OFFSET_POLAR,
    translationKey: 'generalInputs_polarOffset',
  },
});

export const LOCATION_PARAMETER_KEYS = {
  applyScale: 'applyScale',
  northReference: 'northReference',
};

export const FALLBACK_VALUE = NaN;

/**
 * @type {import('common/gis/gis.interfaces').TDMS}
 */
export const FALLBACK_DMS_VALUE = [
  FALLBACK_VALUE,
  FALLBACK_VALUE,
  FALLBACK_VALUE,
  1,
];

export const DMS_COORDINATE_LENGTH = FALLBACK_DMS_VALUE.length;

export const LEGACY_MAP_SYSTEM_KEY = 'geographic_coordinate_system';
