import React from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { createMarkerIcon } from './helpers';

const clamp = (v, min, max) => {
  if (v < min) {
    return min;
  }
  if (v > max) {
    return max;
  }
  return v;
};

const clampLat = (v) => clamp(v, -85, 85);
const clampLong = (v) => clamp(v, -180, 180);

const CustomMarker = ({ marker, markerIx }) => {
  const { label, onClick, latitude, longitude, markerColor, opacity } = marker;
  return (
    <Marker
      eventHandlers={{
        click: onClick,
      }}
      position={[clampLat(latitude), clampLong(longitude)]}
      key={markerIx}
      opacity={opacity ?? 1}
      icon={createMarkerIcon(markerColor)}
    >
      <Tooltip>{label}</Tooltip>
    </Marker>
  );
};

export default CustomMarker;
