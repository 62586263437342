import { useAuth } from '@oliasoft/authentication/pkce';
import Portal from './portal';

const PortalWithAuth = () => {
  const auth = useAuth();
  const userEmail = auth?.user?.profile?.email;
  const name = auth?.user?.profile?.name;

  if (!userEmail || !name) {
    return null;
  }

  return (
    <>
      <Portal email={userEmail} name={name} />
    </>
  );
};

export default PortalWithAuth;
