import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import { removeCampaign } from '~store/entities/campaigns/campaigns';
import translations from '~src/internationalisation/translation-map.json';

const DeleteCampaignModal = ({
  setModalVisible,
  selectedId,
  removeCampaign,
}) => {
  const { t } = useTranslation();
  const onClose = () => setModalVisible(false);
  const onDelete = () => {
    removeCampaign(selectedId);
    setModalVisible(false);
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: `${t(translations.delete)} ${t(translations.campaign)}`,
          content: (
            <>
              {t(translations.campaigns_areYouSureYouWantToDeleteThisCampaign)}
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = { removeCampaign };
const Container = connect(null, mapDispatchToProps)(DeleteCampaignModal);

export { Container as DeleteCampaignModal };
