import React, { useEffect } from 'react';
import { Circle, useMap } from 'react-leaflet';

const redColor = { color: 'red' };

const BoundariesBox = ({ centerLocation, radius, bounds }) => {
  const map = useMap();

  useEffect(() => {
    map.fitBounds(bounds);
  }, [bounds]);
  if (radius) {
    return (
      <Circle center={centerLocation} radius={radius} pathOptions={redColor} />
    );
  }
  return null;
};

export default BoundariesBox;
