import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { debounce } from 'lodash';
import { Table, Heading, Flex } from '@oliasoft-open-source/react-ui-library';
import {
  removeTask,
  cellValueUpdated,
  updateTask,
  onTaskListReorder,
  reorderTimeTrackerList,
  taskAdded,
  addTask,
  addTasks,
} from '~store/entities/time-tracker/time-tracker';
import { round } from '@oliasoft-open-source/units';
import { autoSaveWait } from '~src/config/config';
import translations from '~src/internationalisation/translation-map.json';
import {
  buildOperation,
  buildActivity,
  buildHeaders,
} from '~views/time-tracker/operations-table-builder';
import { selectTimeTrackerLabelCofig } from '~store/entities/company-settings/selectors';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import { selectSimulationsResult } from '~store/entities/simulations/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { actualDepthArr } from './utils/actual-depth-propose';
import {
  downloadTimeTrackerExcel,
  uploadTimeTrackerExcel,
} from './utils/time-tracker-download';
import { initialTimeTrackerItem } from './utils/initial-time-tracker-item';
import { addTaskOptions } from './utils/consts';
import { findSectionItemNameById } from './utils/find-section-item-name';
import { getColumnAlignment } from './utils/get-tt-column-alignment';
import { getColumnWidth } from './utils/get-tt-column-width';
import { EditColumns } from './edit-columns';

const OperationsTable = ({
  itemsList,
  removeTask,
  cellValueUpdated,
  updateTask,
  disabledFields,
  labelConfig,
  onTaskListReorder,
  reorderTimeTrackerList,
  isPageDisabled,
  taskAdded,
  addTask,
  firstOperation,
  lastOperation,
  totalAFE,
  totalVariance,
  well,
  sections,
  operations,
  activities,
  simulationsResult,
}) => {
  const { t } = useTranslation();
  const { project: projectId } = useParams();
  const dispatch = useDispatch();
  const debounceUpdateTask = useRef(debounce(updateTask, 500));
  const debounceReorderTrackerList = useRef(
    debounce(reorderTimeTrackerList, autoSaveWait),
  );
  const debounceAddTask = useRef(debounce(addTask, autoSaveWait));

  const rows = [];

  const getActionP90 = (operationId, activityId = '') => {
    if (!operationId || !simulationsResult?.operations) return null;
    const operation = simulationsResult?.operations?.find(
      (operation) => operation.id === operationId,
    );
    if (!activityId) {
      return round(operation?.percentiles[90], 2);
    } else {
      const activity = operation?.actions?.find((act) => act.id === activityId);
      return round(activity?.percentiles[2], 2);
    }
  };

  const [selectedRowId, setSelectedRowId] = useState('');
  const [expandedOperationIds, setExpandedOperationIds] = useState([]);

  const onAddTask = ({
    insertType,
    operation,
    activityIndex,
    operationIndex,
  }) => {
    const newActivity = initialTimeTrackerItem(
      insertType,
      operation,
      activityIndex,
      operation.activities[activityIndex],
      t,
    );
    taskAdded({
      insertType,
      activityIndex,
      operationIndex,
      newItem: newActivity,
    });
    debounceAddTask.current(newActivity);
  };

  useEffect(() => {
    if (!expandedOperationIds?.length)
      setExpandedOperationIds(itemsList.map((o) => o.timeTrackerItemId));
  }, [itemsList]);

  const toggleSelectedRow = (activityId) =>
    selectedRowId === activityId
      ? setSelectedRowId('')
      : setSelectedRowId(activityId);

  const toggleExpandedRow = (operationId) =>
    expandedOperationIds.includes(operationId)
      ? setExpandedOperationIds(
          expandedOperationIds.filter((id) => id !== operationId),
        )
      : setExpandedOperationIds([...expandedOperationIds, operationId]);

  itemsList.forEach((operation, operationIndex) => {
    rows.push({
      key: operation.timeTrackerItemId,
      noDrag: true,
      cells: buildOperation(
        {
          ...operation,
          operationName: findSectionItemNameById(
            operations,
            operation.sectionsOperationId,
            'sectionsOperationId',
          ),
          sectionName:
            sections?.find(({ sectionId }) => sectionId === operation.sectionId)
              ?.name || '',
          p90: getActionP90(operation.originId),
        },
        disabledFields,
        expandedOperationIds,
      ),
      onRowClick: () => toggleExpandedRow(operation.timeTrackerItemId),
    });

    operation.activities.forEach((activity, activityIndex) => {
      if (expandedOperationIds.includes(activity.parentId)) {
        const actualDepthsPropose = actualDepthArr(operation);
        rows.push({
          taskId: activity.timeTrackerItemId,
          parentId: activity?.parentId,
          active: selectedRowId === activity.timeTrackerItemId,
          onRowClick: () => toggleSelectedRow(activity.timeTrackerItemId),
          cells: buildActivity(
            {
              ...activity,
              section: operation?.section?.name ?? null,
              activityName: findSectionItemNameById(
                activities,
                activity.sectionsOperationActivityId,
                'sectionsOperationActivityId',
              ),
              operationName: findSectionItemNameById(
                operations,
                operation.sectionsOperationId,
                'sectionsOperationId',
              ),
              sectionName:
                sections?.find(
                  ({ sectionId }) => sectionId === operation.sectionId,
                )?.name || '',
              p90: getActionP90(operation.originId, activity.originId),
            },
            actualDepthsPropose[activityIndex],
            debounceUpdateTask,
            cellValueUpdated,
            disabledFields,
            projectId,
            isPageDisabled,
          ),
          actions: [
            {
              label: t(translations.delete),
              subActions: [
                {
                  icon: 'add above',
                  label: t(translations.timeTracker_addTaskAbove),
                  onClick: () =>
                    onAddTask({
                      insertType: addTaskOptions.ABOVE,
                      operation,
                      activityIndex,
                      operationIndex,
                    }),
                  disabled: isPageDisabled,
                },
                {
                  icon: 'add below',
                  label: t(translations.timeTracker_addTaskBelow),
                  onClick: () =>
                    onAddTask({
                      insertType: addTaskOptions.BELOW,
                      operation,
                      activityIndex,
                      operationIndex,
                    }),
                  disabled: isPageDisabled,
                },
                {
                  icon: 'minus',
                  label: t(translations.delete),
                  onClick: () =>
                    removeTask(
                      activity.timeTrackerItemId,
                      operation.timeTrackerItemId,
                    ),
                  disabled: isPageDisabled,
                },
              ],
            },
          ],
        });
      }
    });
  });
  const onListReorder = (reorderData) => {
    const { from, to } = reorderData;
    const { taskId: fromTaskId, parentId: fromParentId } = rows[from];
    const { taskId: toTaskId, parentId: toParentId } = rows[to];
    if (fromParentId === toParentId) {
      onTaskListReorder({ fromTaskId, toTaskId, parentId: toParentId });
      debounceReorderTrackerList.current(projectId, {
        fromTaskId,
        toTaskId,
        parentId: toParentId,
      });
    }
  };
  const tableWithoutData = {
    draggable: !isPageDisabled,
    actions: [
      {
        icon: 'upload',
        tooltip: t(translations.timeTracker_upload),
        onClick: () => uploadTimeTrackerExcel(dispatch, addTasks, projectId),
      },
    ],

    name: 'TIME TRACKER',
    actionsRight: true,
  };

  const table = {
    draggable: !isPageDisabled,
    actions: [
      {
        icon: 'download',
        tooltip: t(translations.timeTracker_download),
        onClick: () =>
          downloadTimeTrackerExcel({
            wellName: well,
            totalAFE,
            startTime: firstOperation?.start,
            estimatedFinish: lastOperation?.finish,
            variance: totalVariance,
            itemsList,
            disabledFields,
            operations,
            activities,
          }),
      },
      {
        icon: 'upload',
        tooltip: t(translations.timeTracker_upload),
        onClick: () => uploadTimeTrackerExcel(dispatch, addTasks, projectId),
      },
      {
        childComponent: <EditColumns />,
      },
    ],

    name: 'TIME TRACKER',
    actionsRight: true,
    columnWidths: getColumnWidth(disabledFields),
    columnAlignment: getColumnAlignment(disabledFields),
    headers: buildHeaders(disabledFields, labelConfig),
    rows,
  };
  return itemsList.length ? (
    <Table table={table} onListReorder={onListReorder} />
  ) : (
    <>
      <Table table={tableWithoutData} />
      <Flex
        alignItems="center"
        justifyContent="center"
        height="calc(100% - 127px)"
      >
        <Heading top>{t(translations.timeTracker_noOperationsFound)}</Heading>
      </Flex>
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const labelConfig = selectTimeTrackerLabelCofig(entities);
  const simulationsResult = selectSimulationsResult(entities);
  const { list, isFetching, disabledFields } = entities.timeTracker;
  const { sections, operations, activities } =
    selectSectionsDataStructure(entities);
  const { well } = entities.projects.overview;
  const firstOperation = list[0];
  const lastOperation = list?.slice(-1)[0];
  const totalAFE = round(
    list.reduce((acc, operation) => acc + operation.afe, 0) / 24,
    2,
  );
  const totalVariance = round(
    list?.reduce(
      (sum, operation) =>
        sum +
        operation.activities.reduce(
          (acc, activity) =>
            acc + (activity.actual ? activity.actual - activity.afe : 0),
          0,
        ),
      0,
    ),
    2,
  );

  return {
    itemsList: list,
    isFetching,
    well,
    firstOperation,
    lastOperation,
    totalAFE,
    totalVariance,
    disabledFields,
    labelConfig,
    sections,
    operations,
    activities,
    simulationsResult,
  };
};

const mapDispatchToProps = {
  removeTask,
  cellValueUpdated,
  updateTask,
  onTaskListReorder,
  reorderTimeTrackerList,
  taskAdded,
  addTask,
  addTasks,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(OperationsTable),
);

export { Container as OperationsTable };
