import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import {
  Heading,
  Spacer,
  Button,
  Loader,
  Spinner,
  Row,
  Flex,
  Empty,
} from '@oliasoft-open-source/react-ui-library';
import {
  runSimulations,
  getSimulations,
  updateSimulationStatus,
  calcReceived,
  simulationDone,
  simulationCleanup,
} from '~store/entities/simulations/simulations';
import translations from '~src/internationalisation/translation-map.json';
import { ChartLayoutSwitcher } from '~common/chart-contols/chart-layout-switcher';
import { selectIsPageDisabled } from '~src/store/entities/projects/selectors';
import { projectPages } from '~src/enums/projects';
import {
  useSocket,
  WEBSOCKET_EVENTS,
} from '~src/common/socket-contex/socket-context';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import ResultContent from './result-content';
import { chartKeys } from './result-utils';
import './style.less';

const ResultChartGrid = ({
  header,
  simulateButtons,
  showResultHeader,
  runSimulations,
  getSimulations,
  simulations,
  updateSimulationStatus,
  isPageDisabled,
  charts,
  simulationCleanup,
}) => {
  const { t } = useTranslation();
  const { socket } = useSocket();
  const { company: companyId, project: projectId } = useParams();
  useEffect(() => {
    if (projectId) {
      getSimulations(projectId);
    }

    return () => simulationCleanup();
  }, [projectId]);

  useEffect(() => {
    if (simulations.simulationStatus.status === 'Finished') {
      simulationDone();
      getSimulations(projectId);
    }
  }, [simulations.simulationStatus]);

  const onSubmit = async () => {
    function handleNotificationMessage(msg) {
      const { notification } = msg;
      updateSimulationStatus(notification);
    }
    socket.on(WEBSOCKET_EVENTS.notification_message, handleNotificationMessage);

    await runSimulations(projectId, companyId);
  };

  const createLoader = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '50vw',
          height: '60vh',
          margin: 'auto',
        }}
      >
        <Loader
          testId="simulation-results-loader"
          details={simulations.simulationStatus?.message || ''}
          theme="light"
        >
          <Spinner dark />
        </Loader>
      </div>
    );
  };

  const resultHeader = () => {
    return (
      <div className="flex result-header">
        <div className="flex">
          {header && (
            <>
              <Heading top marginBottom={0}>
                {header}
              </Heading>
              <Spacer width="var(--padding-sm)" />
            </>
          )}

          {simulations?.simulationsResult &&
          !isEmpty(simulations?.simulationsResult) ? (
            <ChartLayoutSwitcher />
          ) : null}
        </div>

        <div>
          {simulateButtons && (
            <>
              <Button
                name="Simulate"
                testId="simulation-results-button"
                label={t(translations.simulate)}
                onClick={() => {
                  onSubmit();
                }}
                disabled={
                  simulations.simulationStatus.isSimulating || isPageDisabled
                }
                colored
              />
            </>
          )}
        </div>
      </div>
    );
  };

  if (simulations.simulationStatus.isSimulating) {
    return (
      <div className="result-charts">
        {resultHeader()}
        {createLoader()}
      </div>
    );
  }

  return (
    <div className="result-charts">
      {showResultHeader ? resultHeader() : null}
      {simulations?.simulationsResult &&
      !isEmpty(simulations?.simulationsResult) ? (
        <>
          <div
            className={`grid-container grid-container-${charts.numberOfCharts}`}
          >
            {[...Array(charts.numberOfCharts)].map((x, i) => (
              <div key={i} className={`grid-item`}>
                <ResultContent
                  simulationsResult={simulations?.simulationsResult}
                  key={i}
                  chartKey={chartKeys[i]}
                  charts={charts}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <Row width="100%" justifyContent="center">
          <Flex alignItems="center" justifyContent="center" height="400px">
            <Empty
              height="100%"
              text="Results will appear here once simulation has been run"
              width="100%"
            ></Empty>
          </Flex>
        </Row>
      )}
    </div>
  );
};

const mapStateToProps = ({ entities }) => {
  const { simulations, activityModel, ui } = entities;

  return {
    simulations,
    operations: activityModel.operations,
    charts: ui.chartControls.charts,
    isPageDisabled: selectIsPageDisabled(entities, projectPages.REVIEW_RESULTS),
  };
};

const mapDispatchToProps = {
  runSimulations,
  getSimulations,
  updateSimulationStatus,
  calcReceived,
  simulationCleanup,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(ResultChartGrid),
);

export { Container as ResultChartGrid };
