import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import {
  TbAlertTriangle,
  TbCalculator,
  TbChartLine,
  TbClock,
  TbInfoCircle,
  TbSquareCheck,
} from 'react-icons/tb';
import wellIcon from '~src/assets/icons/well.svg';
import { SideBar } from '@oliasoft-open-source/react-ui-library';
import { routes } from '~views/navigation/routes/routes';
import {
  isActiveRoute,
  generatePath,
  navigateToPath,
} from '~store/navigation/navigation';
import { getOverviewStats } from '~store/entities/projects/projects';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const ProjectSideBar = ({
  sections,
  navigateToPath,
  getOverviewStats,
  overview,
}) => {
  const { company, project } = useParams();
  const { pathname } = useLocation();
  useEffect(() => {
    getOverviewStats(project);
  }, [project]);
  useEffect(() => {
    if (overview?.projectId) {
      localStorage.setItem(
        'activeProject',
        JSON.stringify({
          projectId: overview.projectId,
          name: overview.name,
          companyId: company,
        }),
      );
      window.dispatchEvent(new Event('storage'));
    }
  }, [overview]);

  return (
    <SideBar
      top={48}
      options={{
        title: '',
        sections: sections.map((section, index) => ({
          ...section,
          items: section.items.map((item) => ({
            ...item,
            isActive: isActiveRoute(item.itemPath, pathname),
            onClick: () =>
              navigateToPath(
                generatePath(item.itemPath, {
                  company,
                  project,
                }),
              ),
          })),
          testId: `sidebar-item-${index}`,
        })),
      }}
    />
  );
};

const mapStateToProps = ({ entities }) => {
  const sections = [
    {
      heading: '',
      items: [
        {
          label: routes.overview.title,
          itemPath: routes.overview,
          icon: <TbInfoCircle />,
          value: '#',
        },
        {
          label: routes.wellConfiguration.title,
          itemPath: routes.wellConfiguration,
          icon: wellIcon,
          value: '#',
        },
      ],
    },
    {
      heading: 'Project Setup',
      items: [
        {
          label: routes.activityModel.title,
          itemPath: routes.activityModel,
          icon: <TbSquareCheck />,
          value: '#',
        },
        {
          label: routes.risks.title,
          itemPath: routes.risks,
          icon: <TbAlertTriangle />,
          value: '#',
        },
        {
          label: routes.costSetup.title,
          itemPath: routes.costSetup,
          icon: <TbCalculator />,
          value: '#',
        },
      ],
    },
    {
      heading: 'Simulations',
      items: [
        {
          label: 'Result Overview',
          itemPath: routes.resultOverview,
          icon: <TbChartLine />,
          value: '#',
        },
      ],
    },
    {
      heading: 'Operations tracking',
      items: [
        {
          label: 'Time Tracker',
          itemPath: routes.timeTracker,
          icon: <TbClock />,
          value: '#',
        },
      ],
    },
  ];

  const { overview } = entities.projects;

  return { sections, overview };
};

const mapDispatchToProps = {
  navigateToPath,
  getOverviewStats,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(ProjectSideBar),
  { isSideBar: true },
);

export { Container as ProjectSideBar };
