import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Column,
  Row,
  Page,
  Loader,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import { CostElementsList } from '~views/cost-setup/cost-elements.list';
import { CostCategoryForm } from '~views/cost-setup/cost-category.form';
import { NoItems } from '~views/no-items/no-items';
import {
  addInitialCostItem,
  getElements,
  showCreateCostSetupModelModalUpdated,
  createCostSetupModel,
  clearCreateModelFlags,
  costCleanup,
  showImportFromPriceBookModalUpdated,
} from '~store/entities/cost-setup/cost-setup';
import { CostItemForm } from '~views/cost-setup/cost-item.form';
import { CostItemDeleteModal } from '~views/cost-setup/cost-item-delete.modal';
import { CostCategoryDeleteModal } from '~views/cost-setup/cost-category-delete.modal';
import { CostCategoryRenameModal } from '~views/cost-setup/cost-category-rename.modal';
import { selectIsPageDisabled } from '~src/store/entities/projects/selectors';
import { projectPages } from '~src/enums/projects';
import translations from '~src/internationalisation/translation-map.json';
import { CreateModelModal } from '~views/create-model/create-model.modal';
import {
  getOperations,
  getSections,
  operationsCleared,
} from '~store/entities/activity-model/activity-model';
import { getWellDetails } from '~store/entities/well-details/well-details';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { CreateModelErrorsModal } from '../create-model/create-model-errors.modal';
import { CostSetupDrawer } from './drawer/cost-setup.drawer';
import { ImportFromPriceBookModal } from './cost-import-from-price-book.modal';

const CostSetup = ({
  list,
  isFetching,
  isAdding,
  getElements,
  activeCategory,
  activeCostItem,
  addInitialCostItem,
  isPageDisabled,
  showCreateCostSetupModelModalUpdated,
  showCreateCostSetupModelModal,
  createCostSetupModel,
  isSchematicEmpty,
  isTemplatesEmpty,
  clearCreateModelFlags,
  costCleanup,
  getOperations,
  getSections,
  getWellDetails,
  showImportFromPriceBookModalVisible,
  showImportFromPriceBookModalUpdated,
}) => {
  const { t } = useTranslation();
  const { project, company } = useParams();
  const [activeForm, setActiveForm] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [costItemModalVisible, setCostItemModalVisible] = useState(false);
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const [categoryRenameModalVisible, setCategoryRenameModalVisible] =
    useState(false);

  useEffect(() => {
    getElements(project);
    getOperations(project);
    getWellDetails(project);
    getSections(project);

    return () => {
      operationsCleared();
      costCleanup();
    };
  }, [project]);

  const onClickAddItem = (parentId = null) => {
    addInitialCostItem(project, t(translations.newItem), parentId);
    setActiveForm('item');
  };

  const forms = {
    category: (
      <CostCategoryForm
        projectId={project}
        category={activeCategory}
        isPageDisabled={isPageDisabled}
      />
    ),
    item: (
      <CostItemForm
        projectId={project}
        costItem={activeCostItem}
        isPageDisabled={isPageDisabled}
      />
    ),
  };
  let form = null;

  if (list.length === 0 && !isFetching) {
    form = (
      <NoItems
        onClickAdd={onClickAddItem}
        showCreateModelModalUpdated={showCreateCostSetupModelModalUpdated}
        isPageDisabled={isPageDisabled}
        type="costSetup"
      />
    );
  }
  if (activeForm) {
    form = forms[activeForm];
  }

  return (
    <>
      <Page padding={false}>
        {isFetching && (
          <Loader text="Fetching..." theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Row spacing={0} height="100%">
          <CostSetupDrawer />
          <Column scroll width="320px" borderRight>
            <CostElementsList
              setActiveForm={setActiveForm}
              elements={list}
              projectId={project}
              setSelectedId={setSelectedId}
              setCostItemModalVisible={setCostItemModalVisible}
              setCategoryModalVisible={setCategoryModalVisible}
              onClickAddItem={onClickAddItem}
              isPageDisabled={isPageDisabled}
              setCategoryRenameModalVisible={setCategoryRenameModalVisible}
              isAdding={isAdding}
              showImportFromPriceBookModalUpdated={
                showImportFromPriceBookModalUpdated
              }
            />
          </Column>
          <Column scroll padding>
            {form}
          </Column>
        </Row>
      </Page>
      {costItemModalVisible && (
        <CostItemDeleteModal
          setModalVisible={setCostItemModalVisible}
          costItemId={selectedId}
          setActiveForm={setActiveForm}
          projectId={project}
        />
      )}
      {categoryModalVisible && (
        <CostCategoryDeleteModal
          setModalVisible={setCategoryModalVisible}
          costCategoryId={selectedId}
          setActiveForm={setActiveForm}
          projectId={project}
        />
      )}
      {categoryRenameModalVisible && (
        <CostCategoryRenameModal
          setModalVisible={setCategoryRenameModalVisible}
          costCategoryId={selectedId}
          category={activeCategory}
          projectId={project}
        />
      )}
      {showCreateCostSetupModelModal && (
        <CreateModelModal
          projectId={project}
          showCreateModelModalUpdated={showCreateCostSetupModelModalUpdated}
          createModel={createCostSetupModel}
          isFetching={isFetching}
        />
      )}
      {(isSchematicEmpty || isTemplatesEmpty) && (
        <CreateModelErrorsModal
          isSchematicEmpty={isSchematicEmpty}
          isTemplatesEmpty={isTemplatesEmpty}
          company={company}
          project={project}
          clearCreateModelFlags={clearCreateModelFlags}
        />
      )}
      {showImportFromPriceBookModalVisible && (
        <ImportFromPriceBookModal
          parentId={selectedId}
          showImportFromPriceBookModalUpdated={
            showImportFromPriceBookModalUpdated
          }
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    isAdding,
    isFetching,
    list,
    showCreateCostSetupModelModal,
    isSchematicEmpty,
    isTemplatesEmpty,
    showImportFromPriceBookModalVisible,
  } = entities.costSetup;
  const activeCategory = list.find(
    (item) => item.costCategoryId && item.active,
  );
  const activeCostItem = list.find((item) => item.costItemId && item.active);

  return {
    isFetching,
    isAdding,
    list,
    activeCategory,
    activeCostItem,
    isPageDisabled: selectIsPageDisabled(entities, projectPages.COST_MODEL),
    showCreateCostSetupModelModal,
    isSchematicEmpty,
    isTemplatesEmpty,
    showImportFromPriceBookModalVisible,
  };
};

const mapDispatchToProps = {
  getElements,
  addInitialCostItem,
  showCreateCostSetupModelModalUpdated,
  createCostSetupModel,
  clearCreateModelFlags,
  costCleanup,
  getOperations,
  getSections,
  getWellDetails,
  showImportFromPriceBookModalUpdated,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CostSetup),
  { isPage: true },
);

export { Container as CostSetup };
