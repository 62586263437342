import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';

const CostItemDeleteModal = ({
  setModalVisible,
  costItemId,
  onClickDeleteItem,
}) => {
  const { t } = useTranslation();
  const onClose = () => setModalVisible({ selectedId: null, visible: false });
  const onDelete = () => {
    onClickDeleteItem(costItemId);
    setModalVisible(false);
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.costSetup_deleteCostItem),
          content: (
            <>{t(translations.costSetup_areYouSureYouWantToDeleteThisItem)}</>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

export { CostItemDeleteModal };
