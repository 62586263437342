import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListSubheading, List } from '@oliasoft-open-source/react-ui-library';
import { countrySelected } from '~store/entities/projects/projects';
import { project } from '~src/enums/projects';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CountriesList = ({
  countries,
  onClickDelete,
  setCountryModalVisible,
  countrySelected,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ListSubheading
        item={{
          name: t(translations.country),
          actions: [
            {
              label: 'Add',
              icon: 'add',
              testId: 'add-country-button',
              onClick: () => setCountryModalVisible(true),
            },
          ],
        }}
      />
      <List
        testId="country-list"
        list={{
          items: countries.map((country) => ({
            id: country.projectCountryId,
            name: country.name,
            metadata: `${country.fieldsCount} ${
              +country.fieldsCount === 1
                ? t(translations.field)
                : t(translations.fields)
            }`,
            active: country.active,
            onClick: () => countrySelected(country.projectCountryId),
            actions: [
              {
                label: 'Delete',
                icon: 'delete',
                testId: `delete-country-${country.name.replace(/ /g, '-')}`,
                onClick: () =>
                  onClickDelete(
                    country.projectCountryId,
                    project.COUNTRIES_LIST,
                  ),
              },
            ],
          })),
        }}
        noHeader
      />
    </>
  );
};

const mapDispatchToProps = { countrySelected };

const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(CountriesList),
);

export { Container as CountriesList };
