import React from 'react';
import { Drawer } from '@oliasoft-open-source/react-ui-library';

const GenericDrawer = ({
  position = 'right',
  button = false,
  width = '400px',
  defaultTabIndex = 0,
  tabs = [],
  onResize = null,
}) => {
  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [width]);
  return (
    <Drawer
      right={position}
      button={button}
      closedWidth={0}
      width={width}
      defaultTabIndex={defaultTabIndex}
      tabs={tabs.map((tab) => ({
        icon: tab.icon,
        content: tab.content,
      }))}
      onResize={onResize}
    ></Drawer>
  );
};

export { GenericDrawer };
