/**
 * Maps the given data array to an array of options objects.
 *
 * @param {Array} items - The data array to map.
 * @param {string} [labelKey='label'] - The key to use for the label property of each option object.
 * @param {string} [valueKey='value'] - The key to use for the value property of each option object.
 *
 * @returns {Array} - An array of option objects.
 */
export const getSelectOptions = (
  items,
  labelKey = 'label',
  valueKey = 'value',
) => {
  return items.map((item) => {
    return typeof item === 'object'
      ? { label: item[labelKey], value: item[valueKey] }
      : { label: item, value: item };
  });
};
