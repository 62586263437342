import { Icon } from '@oliasoft-open-source/react-ui-library';
import { GenericDrawer } from '~src/common/components/generic-drawer/generic-drawer';
import { EstimatesList } from '~views/compare-estimates/estimates.list';
import {
  ICompareEstimates,
  ICompareEstimateListItem,
} from '~src/common/interfaces/compare-estimates.interfaces';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

interface ICompareEstimatesDrawer {
  estimates: ICompareEstimates[];
  filteredEstimates: ICompareEstimateListItem;
  estimateSelected: boolean;
}

const UnprotectedCompareEstimatesDrawer = ({
  estimates,
  filteredEstimates,
  estimateSelected
}: ICompareEstimatesDrawer) => {
  const drawerProps = {
    position: 'right',
    width: '400px',
    defaultTabIndex: 1,
    tabs: [
      {
        icon: <Icon icon="list" />,
        content: (
          <EstimatesList
            estimateSelected={estimateSelected}
            estimates={estimates}
            filteredEstimates={filteredEstimates}
          />
        ),
      },
    ],
  };

  return <GenericDrawer {...drawerProps} />;
};

export const CompareEstimatesDrawer = withErrorBoundary(
  UnprotectedCompareEstimatesDrawer,
  { isRightSideBar: true },
);
