import React from 'react';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import Map from '~src/views/map/map';
import { transformLocationObject } from '~common/gis/transform-location-object';
import { getDatumEPSG } from '~common/gis/gis-library-utils';
import { getConvergence } from '~common/gis/get-convergence';
import {
  ListSubheading,
  Message,
} from '@oliasoft-open-source/react-ui-library';
import { round } from '@oliasoft-open-source/units';
import { connect } from 'react-redux';
import { selectWellLocation } from '~store/entities/well-details/selectors';
import { isLocationObjectValid } from '~common/gis/validate-location';
import { EstimatesFilter } from './estimates.filter';

const EstimatesMap = ({ items, filter, setFilter, currentWellLocation }) => {
  const { t } = useTranslation();

  const currentEstimateMarker = () => {
    if (currentWellLocation) {
      const { x, y } = transformLocationObject(
        currentWellLocation,
        getDatumEPSG(currentWellLocation?.epsgCode),
      );
      const latitude = y;
      const longitude = x;
      if (latitude !== undefined && longitude !== undefined) {
        return {
          latitude,
          longitude,
          label: 'Current',
          markerColor: '#f54257',
        };
      }
    }
  };
  const markers = [currentEstimateMarker()];
  items.forEach((estimate) => {
    if (estimate?.location) {
      const { x, y } = transformLocationObject(
        estimate.location,
        getDatumEPSG(estimate.location?.epsgCode),
      );
      const latitude = y;
      const longitude = x;
      if (latitude == undefined || longitude == undefined) {
        return;
      }

      markers.push({
        latitude,
        longitude,
        label: estimate?.name,
        markerColor: estimate.active ? '#f08330' : '#277FCA',
        onClick: estimate.onClick,
      });
    }
  });
  const trueNorthAngleRadians = -getConvergence(items[0]?.location);
  const trueNorthAngle = round((trueNorthAngleRadians * 180) / Math.PI, 2);

  return (
    <div
      style={{
        height: 'calc(100vh - 148px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '16px',
      }}
    >
      <EstimatesFilter filter={filter} setFilter={setFilter} />
      <div style={{ padding: '0 var(--padding)' }}>
        <Message
          message={{
            content: t(translations.wellDetails_invalidOffsetLocations),
            icon: true,
            type: 'Warning',
            visible: items?.length !== markers?.length - 1,
          }}
        />
      </div>

      <ListSubheading
        item={{ name: t(translations.estimate), metaCount: items.length }}
      />
      {isLocationObjectValid(currentWellLocation) ? (
        <Map
          markers={markers}
          distanceFilter={filter.distance}
          legend={{
            labels: [
              t(translations.current),
              t(translations.available),
              t(translations.selected),
            ],
            colors: ['#f54257', '#277FCA', '#f08330'],
          }}
          northReferenceArrows={[
            { hoverLabel: t(translations.gridNorth), angle: 0 },
            { hoverLabel: t(translations.trueNorth), angle: trueNorthAngle },
          ]}
          currentWellLocation={currentWellLocation}
        />
      ) : (
        <div style={{ padding: 'var(--padding)' }}>
          <Message
            message={{
              content: t(translations.wellDetails_invalidLocation),
              icon: true,
              type: 'Warning',
              visible: true,
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ entities }) => ({
  currentWellLocation: selectWellLocation({ entities }),
});

const Container = connect(mapStateToProps)(EstimatesMap);

export { Container as EstimatesMap };
