import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Input,
  Row,
  Card,
  Spacer,
  Field,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import {
  cellValueUpdated,
  updateTask,
  updateStartTime,
  updateTrackerStartTime,
} from '~store/entities/time-tracker/time-tracker';
import { autoSaveWait } from '~src/config/config';
import { debounce } from 'lodash';
import { useParams } from 'react-router';
import {
  formatFromApiDate,
  formatFromApiTime,
  formatToApiDateTime,
} from '../utils/date/dateUtils';

const DateTimePicker = ({
  firstOperation,
  updateTrackerStartTime,
  updateStartTime,
  close,
}) => {
  const { t } = useTranslation();

  const startDate = firstOperation?.start;
  const { project: projectId } = useParams();
  const [datePickerValue, setDatePickerValue] = useState('');
  const [timePickerValue, setTimePickerValue] = useState('');
  const [editMode, setEditMode] = useState(true);

  useEffect(() => {
    setDatePickerValue(formatFromApiDate(startDate));

    setTimePickerValue(formatFromApiTime(startDate));
  }, [startDate]);

  const onDatePickerChange = (evt) => setDatePickerValue(evt.target.value);
  const onTimePickerChange = (evt) => {
    setTimePickerValue(evt.target.value);
  };
  const debounceUpdateTrackerStartTime = useRef(
    debounce(updateTrackerStartTime, autoSaveWait),
  );

  const onPickerConfirm = () => {
    const startTime = formatToApiDateTime(
      datePickerValue,

      timePickerValue,
    );
    updateStartTime(startTime);
    setEditMode(false);
    debounceUpdateTrackerStartTime.current(projectId, startTime);
    close();
  };
  const onPickerCancel = () => {
    setDatePickerValue(formatFromApiDate(startDate));

    setTimePickerValue(formatFromApiTime(startDate));
    setEditMode(false);
    close();
  };

  return (
    editMode && (
      <Card padding={false} bordered={false}>
        <Row>
          <Field label={t(translations.timeTracker_startDate)}>
            <Input
              type="date"
              value={datePickerValue}
              onChange={onDatePickerChange}
            />
          </Field>

          <Spacer width="var(--padding-sm)" />

          <Field label={t(translations.timeTracker_startTime)}>
            <Input
              type="time"
              size="20"
              onChange={onTimePickerChange}
              value={timePickerValue}
            />
          </Field>
        </Row>
        <Row justifyContent={'center'} marginTop="var(--padding-xs)">
          <Button colored small={true} label="OK" onClick={onPickerConfirm} />
          <Spacer width="var(--padding-sm)" />
          <Button small={true} label="Cancel" onClick={onPickerCancel} />
        </Row>
      </Card>
    )
  );
};

const mapStateToProps = ({ entities }) => {
  const { well } = entities.projects.overview;
  return {
    well,
  };
};

const mapDispatchToProps = {
  cellValueUpdated,
  updateTask,
  updateStartTime,
  updateTrackerStartTime,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(DateTimePicker);

export { Container as DateTimePicker };
