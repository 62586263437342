import React from 'react';
import {
  Flex,
  Spacer,
  Text,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import './style.css';

export const PageCard = ({
  title = '',
  mainValue = '',
  secondaryValue = '',
  isLoading = false,
}) => {
  return (
    <div className="page-card">
      <Flex direction="column" alignItems="center">
        <Spacer />
        <Text center bold>
          {title}
        </Text>
        <Text center>
          {isLoading ? (
            <Spinner dark />
          ) : (
            <div className="page-card-main-text">{mainValue}</div>
          )}
        </Text>
        <Text center faint>
          {secondaryValue}
        </Text>
      </Flex>
    </div>
  );
};
