export const listFilterFields = Object.freeze({
  WELL_CONFIGURATION: 'wellConfiguration',
  DRILLING_UNIT: 'drillingUnit',
  WATER_DEPTH_INEQUALITY: 'waterDepthInequality',
  WATER_DEPTH: 'waterDepth',
  WELLHEAD_DEPTH_INEQUALITY: 'wellheadDepthInequality',
  WELLHEAD_DEPTH: 'wellheadDepth',
  CASING_STRINGS_INEQUALITY: 'casingStringsInequality',
  CASING_STRINGS: 'casingStrings',
  DISTANCE: 'distance',
});
