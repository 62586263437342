import { createSelector } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';
import {
  selectWellDetailsSchematic,
  selectWellDetailsWell,
  selectIsGenerateFromSchematic,
} from '~store/entities/well-details/selectors';
import {
  linkFields as linkFieldsEnum,
  operationCodesTypes,
} from '~src/enums/settings';
import { casingTypes, casingStringType } from '~src/enums/well-details';
import { round, split, unumWithUnit } from '@oliasoft-open-source/units';
import { Sections } from '~src/enums/sections';

const selectActivityModel = ({ entities }) => entities.activityModel;
const selectUserSettingsUnits = ({ entities }) =>
  entities.userSettings.settings.units;

export const selectActivityModelLoaders = createSelector(
  [selectActivityModel],
  ({ isAdding, isFetching, isDeleting, isReordering }) => ({
    isAdding,
    isFetching,
    isDeleting,
    isReordering,
  }),
);

export const selectImportExcelSuccess = createSelector(
  [selectActivityModel],
  (activityModel) => activityModel.importExcelSuccess,
);

export const selectOperationList = createSelector(
  [selectActivityModel],
  (activityModel) => activityModel.operations,
);

export const selectSectionsList = createSelector(
  [selectActivityModel],
  (activityModel) =>
    activityModel.sections
      .filter((section) =>
        activityModel.operations.find(
          (operation) => operation.sectionId === section.sectionId,
        ),
      )
      .sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectGroupedOperationList = createSelector(
  [selectOperationList],
  (operations) =>
    Object.values(groupBy(operations, 'operationHeader'))?.sort(
      (a, b) => a[0].operationHeaderPosition - b[0].operationHeaderPosition,
    ),
);

export const selectActiveOperation = createSelector(
  [selectOperationList],
  (operations) => operations.find((item) => item.active),
);

export const selectLinkFields = createSelector(
  [selectActivityModel],
  (activityModel) => activityModel.linkFields,
);

const addDefaultSchematics = (schematic, wellDetails) => {
  const copiedSchematics = schematic.slice();
  const createDefaultSchematic = (type, name) => {
    return { type, name };
  };

  const defaults = [
    ...(wellDetails.mobilization
      ? [
          createDefaultSchematic(
            casingStringType.MOBILIZATION,
            casingTypes.NONAME,
          ),
        ]
      : []),
    ...copiedSchematics,
    ...(wellDetails.lowerCompletion
      ? [createDefaultSchematic(casingStringType.COMPLETION, casingTypes.LOWER)]
      : []),
    ...(wellDetails.middleCompletion
      ? [
          createDefaultSchematic(
            casingStringType.COMPLETION,
            casingTypes.MIDDLE,
          ),
        ]
      : []),
    ...(wellDetails.upperCompletion
      ? [createDefaultSchematic(casingStringType.COMPLETION, casingTypes.UPPER)]
      : []),
    ...(wellDetails.xmt
      ? [createDefaultSchematic(casingStringType.XMT, casingTypes.NONAME)]
      : []),
    ...(wellDetails.demobilization
      ? [
          createDefaultSchematic(
            casingStringType.DEMOBILIZATION,
            casingTypes.NONAME,
          ),
        ]
      : []),
  ];

  return defaults;
};

const mapAndSortGroups = (groups) =>
  groups
    .map((group, index) =>
      group.map((operation) => ({
        ...operation,
        operationHeaderPosition: index + 1,
      })),
    )
    .sort(
      (a, b) => a[0].operationHeaderPosition - b[0].operationHeaderPosition,
    );

export const selectGroupedOperationListWithDefaults = createSelector(
  [
    selectGroupedOperationList,
    selectWellDetailsSchematic,
    selectWellDetailsWell,
    selectIsGenerateFromSchematic,
  ],
  (groups, schematic, wellDetails, isGenerateFromSchematic) => {
    const schematicWithDefault = addDefaultSchematics(schematic, wellDetails);
    let copiedGroups = groups.slice();
    schematicWithDefault?.forEach((schematicItem, idx) => {
      const operationHeader = `${schematicItem.name || ''}${
        schematicItem.name ? ' ' : ''
      }${schematicItem.type}`;
      const headersList = groups.map((group) => group[0]?.operationHeader);

      if (!headersList?.includes(operationHeader)) {
        const updatedList = copiedGroups.slice();
        updatedList.splice(idx, 0, [
          {
            operationHeader,
            operationHeaderPosition: idx + 1,
          },
        ]);
        copiedGroups = updatedList;
      }
    });
    return isGenerateFromSchematic
      ? mapAndSortGroups(copiedGroups)
      : mapAndSortGroups(groups);
  },
);

export const selectActiveSectionId = createSelector(
  [selectActivityModel],
  (activityModel) => activityModel?.activeSection?.sectionId,
);

export const selectActiveSection = createSelector(
  [selectActivityModel],
  (activityModel) => activityModel?.activeSection,
);

export const selectActiveProjectSectionId = createSelector(
  [selectActivityModel],
  (activityModel) => activityModel.activeSection?.projectSectionId,
);

export const selectOperationListByActiveSection = createSelector(
  [selectOperationList, selectActiveProjectSectionId],
  (operationList, activeProjectSectionId) => {
    return operationList.filter(
      (operation) => operation.projectSectionId === activeProjectSectionId,
    );
  },
);

export const selectCodes = createSelector(
  [selectActivityModel],
  (activityModel) => activityModel.codes,
);

export const selectSubOperationCodes = createSelector(
  [selectCodes, selectActiveOperation],
  (codes, operation) => {
    const activeSubOperationCodes = codes.filter(
      (code) =>
        code.type === operationCodesTypes.SUB_OPERATION &&
        code.parentId === operation?.operationCode,
    );
    return activeSubOperationCodes.map(({ codeId, code, description }) => ({
      value: codeId,
      label: code,
      details: description,
    }));
  },
);

export const isActivityWithSubs = createSelector(
  [
    selectActivityModel,
    (entities, operationId, taskId) => ({ operationId, taskId }),
  ],
  (activityModel, { operationId, taskId }) => {
    let result = false;
    const operation = activityModel.operations.find(
      (operation) => operation.operationId === operationId,
    );
    if (operation) {
      result = operation.tasks.find(
        (task) => task.parentId === taskId && !task.isBranch,
      );
    }

    return result;
  },
);

export const getRiskBranches = createSelector(
  [
    selectActivityModel,
    (entities, operationId, taskId) => ({ operationId, taskId }),
  ],
  (activityModel, { operationId, taskId }) => {
    let result = [];
    const operation = activityModel.operations.find(
      (operation) => operation.operationId === operationId,
    );
    if (operation) {
      result = operation.tasks.filter(
        (task) => task.parentId === taskId && task.isBranch,
      );
    }

    return result;
  },
);

export const selectSectionItems = createSelector(
  [selectActivityModel],
  (activityModel) => activityModel.sectionItems,
);

export const selectExtendedLinkFields = createSelector(
  [
    selectLinkFields,
    selectWellDetailsSchematic,
    selectActiveSection,
    selectSectionItems,
    selectUserSettingsUnits,
  ],
  (linkFields, schematic, selectActiveSection, sections, units) => {
    const { sectionId: activeSectionId, name: activeSectionName } =
      selectActiveSection || {};
    const reservoirRelatedSection = [
      Sections.LowerCompletion,
      Sections.MiddleCompletion,
      Sections.UpperCompletion,
      Sections.XMT,
    ];

    const reservoirSectionId = sections.find(
      (section) => section.name === Sections.Reservoir,
    )?.sectionId;

    const isReservoirRelatedSection =
      reservoirRelatedSection.includes(activeSectionName);

    const schematicItemIndex = schematic.findIndex((schematicItem) => {
      const targetId =
        isReservoirRelatedSection && reservoirSectionId
          ? reservoirSectionId
          : activeSectionId;
      return schematicItem.sectionId === targetId;
    });

    const lastIndexWithSectionTd = schematic.findLastIndex(
      (schema) => schema.sectiontd !== null,
    );

    const schematicItem =
      isReservoirRelatedSection && !reservoirSectionId
        ? schematic[lastIndexWithSectionTd]
        : schematic[schematicItemIndex];

    const previousSchematicItem =
      isReservoirRelatedSection && !reservoirSectionId
        ? schematic[lastIndexWithSectionTd - 1]
        : schematic[schematicItemIndex - 1];

    const extendedLinkFields = [
      ...linkFields,
      {
        label: linkFieldsEnum.PREVIOUS_CASING_SHOE,
        value: round(
          unumWithUnit(previousSchematicItem?.bottom, units.length, 'm'),
        ),
        valueKey: linkFieldsEnum.PREVIOUS_CASING_SHOE,
      },
      {
        label: linkFieldsEnum.SECTION_TD,
        value: round(unumWithUnit(schematicItem?.sectiontd, units.length, 'm')),
        valueKey: linkFieldsEnum.SECTION_TD,
      },
      {
        label: linkFieldsEnum.CASING_SHOE,
        value: round(unumWithUnit(schematicItem?.bottom, units.length, 'm')),
        valueKey: linkFieldsEnum.CASING_SHOE,
      },
      {
        label: linkFieldsEnum.PREVIOUS_SECTION_TD,
        value: round(
          unumWithUnit(previousSchematicItem?.sectiontd, units.length, 'm'),
        ),
        valueKey: linkFieldsEnum.PREVIOUS_SECTION_TD,
      },
    ];
    const filteredLinkFieldsMap = extendedLinkFields?.filter(
      (item) =>
        item.value !== null &&
        item.value !== undefined &&
        split(item.value)[0] !== '',
    );

    return filteredLinkFieldsMap;
  },
);

export const selectSectionOperations = createSelector(
  [selectSectionItems, selectOperationList],
  (sections, operationList) => {
    return sections.reduce((acc, { projectSectionId, name }) => {
      const sectionOperations = operationList
        .filter((operation) => projectSectionId === operation.projectSectionId)
        .map(({ name, operationId }) => ({ name, id: operationId }));
      if (sectionOperations.length > 0) {
        acc.push({ projectSectionId, name, operations: sectionOperations });
      }
      return acc;
    }, []);
  },
);
