import { v4 as uuidv4 } from 'uuid';

/**
 *  Traverse and find connected cost category
 *
 * @param categoryId
 * @param costCategory
 */
const findConnected = (categoryId, categories) => {
  return categories
    .filter((category) => category.parentId === categoryId)
    .flatMap((category) => [
      category.costCategoryId,
      ...findConnected(category.costCategoryId, categories),
    ]);
};

/**
 *  Traverse and find connected cost category
 *
 * @param costSetup
 * @param targetCategoryId
 */
export const findConnectedCategories = (
  { costCategory, costItem },
  targetCategoryId,
) => {
  const connectedCategories = findConnected(targetCategoryId, costCategory);
  connectedCategories.push(targetCategoryId);

  const resultItem = costItem.filter(
    (item) => !connectedCategories.includes(item.parentId),
  );
  const resultCategory = costCategory.filter(
    (item) =>
      !connectedCategories.includes(item.costCategoryId || item.parentId),
  );
  return { costCategory: resultCategory, costItem: resultItem };
};

/**
 * @description Splits a string on last occurrence of given separator
 *
 * @param {string} str
 * @param {string} separator
 * @return {string[]|null}
 */
export const splitOnLastOccurrence = (str, separator) => {
  const lastIndex = str.lastIndexOf(separator);
  if (lastIndex >= 0) {
    return [str.slice(0, lastIndex), str.slice(lastIndex + separator.length)];
  }
  return null;
};

/**
 * @description Check if integer is positive
 *
 * @param {string} str
 * @returns {boolean}
 */
const isPositiveInteger = (str) => {
  const num = Number(str);
  return Number.isInteger(num) && num > 0;
};

/**
 *  Calculate next name
 *
 * @param {string[]} existingNames
 * @param {string}fromName
 * @returns {string}
 */
export const nextName = (existingNames, fromName) => {
  if (!existingNames?.includes(fromName) || !fromName) {
    return fromName;
  }

  const [originalName, startIndex] = (() => {
    const nameSplit = splitOnLastOccurrence(fromName, ' ');

    if (!nameSplit || !isPositiveInteger(nameSplit[1])) {
      return [fromName, 2];
    }
    return [nameSplit[0], parseInt(nameSplit[1], 10)];
  })();

  for (let i = startIndex; ; i += 1) {
    const proposedNextName = `${originalName} ${i}`;
    if (!existingNames.includes(proposedNextName)) {
      return proposedNextName;
    }
  }
};

/**
 * @description Update parent ids for costSetup category and items
 *
 * @param {object[]} categories
 * @param {uuid} oldId
 * @param {uuid} newId
 * @returns {object[]} categories
 */
export const updateCostSetupIds = (categories, oldId, newId) => {
  return categories.map((category) => {
    if (category.parentId === oldId) {
      category.parentId = newId;
    }
    return category;
  });
};

/**
 * @description Update ids for activity model activities
 *
 * @param {object[]} activities
 * @returns {object[]} activities
 */
export const updateActivityIds = (activities) => {
  return activities.map((activity) => {
    if (activity?.activities.length !== 0) {
      const newActivityId = uuidv4();
      activity.id = newActivityId;

      activity.activities = activity.activities.map((childActivity) => {
        if (childActivity?.parentId && childActivity.parentId === activity.id) {
          childActivity.parentId = newActivityId;
        }
        return childActivity;
      });
    }
    return activity;
  });
};
