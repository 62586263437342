import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { pageStatuses, project } from '~src/enums/projects';

const selectListItem = (list, idField, value) =>
  list.map((item) => {
    item.active = item[idField] === value;
    return item;
  });

const deselectListItems = (list) =>
  list.map((item) => {
    item.active = false;
    return item;
  });

const deselectProjectItems = (lists, level) => {
  const { fields, sites, wells, wellbores, designs, estimates } = lists;

  if (level === project.COUNTRIES_LIST) {
    deselectListItems(fields);
    deselectListItems(sites);
    deselectListItems(wells);
    deselectListItems(wellbores);
    deselectListItems(designs);
    deselectListItems(estimates);
  }

  if (level === project.FIELDS_LIST) {
    deselectListItems(sites);
    deselectListItems(wells);
    deselectListItems(wellbores);
    deselectListItems(designs);
    deselectListItems(estimates);
  }

  if (level === project.SITES_LIST) {
    deselectListItems(wells);
    deselectListItems(wellbores);
    deselectListItems(designs);
    deselectListItems(estimates);
  }

  if (level === project.WELLS_LIST) {
    deselectListItems(wellbores);
    deselectListItems(designs);
    deselectListItems(estimates);
  }

  if (level === project.WELLBORES_LIST) {
    deselectListItems(designs);
    deselectListItems(estimates);
  }

  if (level === project.DESIGNS_LIST) {
    deselectListItems(estimates);
  }
};

const slice = createSlice({
  name: 'projects',
  initialState: {
    isFetching: false,
    isAdding: false,
    lists: {
      countries: [],
      fields: [],
      sites: [],
      wells: [],
      wellbores: [],
      designs: [],
      estimates: [],
    },
    overview: {},
    countriesList: [],
  },
  reducers: {
    projectsRequested: (projects) => {
      projects.isFetching = true;
    },
    projectsReceived: (projects, action) => {
      projects.lists = action.payload.projects;
      projects.countriesList = action.payload.countriesList;
      projects.isFetching = false;
      const { countries, fields, sites, wells, wellbores, designs, estimates } =
        projects.lists;

      const estimate = estimates.find(
        (estimate) => estimate.projectId === action.payload.activeProjectId,
      );

      if (estimate) {
        const design = designs.find(
          (design) => design.projectDesignId === estimate.projectDesignId,
        );
        selectListItem(designs, 'projectDesignId', design.projectDesignId);
        const wellbore = wellbores.find(
          (wellbore) => wellbore.projectWellboreId === design.projectWellboreId,
        );
        selectListItem(
          wellbores,
          'projectWellboreId',
          wellbore.projectWellboreId,
        );
        const well = wells.find(
          (well) => well.projectWellId === wellbore.projectWellId,
        );
        selectListItem(wells, 'projectWellId', well.projectWellId);
        const site = sites.find(
          (site) => site.projectSiteId === well.projectSiteId,
        );
        selectListItem(sites, 'projectSiteId', site.projectSiteId);
        const field = fields.find(
          (field) => field.projectFieldId === site.projectFieldId,
        );
        selectListItem(fields, 'projectFieldId', field.projectFieldId);
        const country = countries.find(
          (country) => country.projectCountryId === field.projectCountryId,
        );
        selectListItem(countries, 'projectCountryId', country.projectCountryId);
      }
    },
    overviewRequested: (projects) => {
      projects.overview = {};
    },
    overviewReceived: (projects, action) => {
      projects.overview = action.payload;
    },
    projectNameUpdated: (projects, action) => {
      projects.overview.name = action.payload;
    },
    projectHierarchySaveRequested: (projects) => {
      projects.isAdding = true;
    },
    projectHierarchySaved: (projects, action) => {
      // Countries list
      if (action.payload.country) {
        projects.lists.countries = projects.lists.countries.map((item) => {
          item.active = false;
          return item;
        });
        deselectProjectItems(projects.lists, project.COUNTRIES_LIST);
        action.payload.country.active = true;
        projects.lists.countries.push(action.payload.country);
      }

      // Fields list
      if (action.payload.field) {
        const { countries, fields } = projects.lists;
        const { field } = action.payload;
        const existingIndex = fields.findIndex(
          (item) => item.projectFieldId === field.projectFieldId,
        );
        deselectProjectItems(projects.lists, project.FIELDS_LIST);
        field.active = true;
        if (existingIndex === -1) {
          // Add new
          projects.lists.fields = fields.map((item) => {
            item.active = false;
            return item;
          });
          projects.lists.fields.push(field);
          const activeCountryIndex = countries.findIndex((item) => item.active);
          // Increment items counter in the parent list
          if (activeCountryIndex !== -1) {
            projects.lists.countries[activeCountryIndex].fieldsCount =
              +countries[activeCountryIndex].fieldsCount + 1;
          }
        } else {
          // Update existing one
          projects.lists.fields[existingIndex] = field;
        }
      }

      // Sites list
      if (action.payload.site) {
        const { sites, fields } = projects.lists;
        const { site } = action.payload;
        const existingIndex = sites.findIndex(
          (item) => item.projectSiteId === site.projectSiteId,
        );
        deselectProjectItems(projects.lists, project.SITES_LIST);
        site.active = true;
        if (existingIndex === -1) {
          // Add new
          projects.lists.sites = sites.map((item) => {
            item.active = false;
            return item;
          });
          projects.lists.sites.push(site);
          const activeFieldIndex = fields.findIndex((item) => item.active);
          // Increment items counter in the parent list
          if (activeFieldIndex !== -1) {
            projects.lists.fields[activeFieldIndex].sitesCount =
              +fields[activeFieldIndex].sitesCount + 1;
          }
        } else {
          // Update existing one
          projects.lists.sites[existingIndex] = site;
        }
      }

      // Wells list
      if (action.payload.well) {
        const { sites, wells } = projects.lists;
        const { well } = action.payload;
        const existingIndex = wells.findIndex(
          (item) => item.projectWellId === well.projectWellId,
        );
        deselectProjectItems(projects.lists, project.WELLS_LIST);
        well.active = true;
        if (existingIndex === -1) {
          // Add new
          projects.lists.wells = wells.map((item) => {
            item.active = false;
            return item;
          });
          projects.lists.wells.push(well);
          const activeSiteIndex = sites.findIndex((item) => item.active);
          // Increment items counter in the parent list
          if (activeSiteIndex !== -1) {
            projects.lists.sites[activeSiteIndex].wellsCount =
              +sites[activeSiteIndex].wellsCount + 1;
          }
        } else {
          // Update existing one
          projects.lists.wells[existingIndex] = well;
        }
      }

      // Wellbores list
      if (action.payload.wellbore) {
        const { wellbores, wells } = projects.lists;
        const { wellbore } = action.payload;
        const existingIndex = wellbores.findIndex(
          (item) => item.projectWellboreId === wellbore.projectWellboreId,
        );
        deselectProjectItems(projects.lists, project.WELLBORES_LIST);
        wellbore.active = true;
        if (existingIndex === -1) {
          // Add new
          projects.lists.wellbores = wellbores.map((item) => {
            item.active = false;
            return item;
          });
          projects.lists.wellbores.push(wellbore);
          const activeWellIndex = wells.findIndex((item) => item.active);
          // Increment items counter in the parent list
          if (activeWellIndex !== -1) {
            projects.lists.wells[activeWellIndex].wellboresCount =
              +wells[activeWellIndex].wellboresCount + 1;
          }
        } else {
          // Update existing one
          projects.lists.wellbores[existingIndex] = wellbore;
        }
      }

      // Designs list
      if (action.payload.design) {
        const { wellbores, designs } = projects.lists;
        const { design } = action.payload;
        const existingIndex = designs.findIndex(
          (item) => item.projectDesignId === design.projectDesignId,
        );
        deselectProjectItems(projects.lists, project.DESIGNS_LIST);
        design.active = true;
        if (existingIndex === -1) {
          // Add new
          projects.lists.designs = designs.map((item) => {
            item.active = false;
            return item;
          });
          projects.lists.designs.push(design);
          const activeWellboreIndex = wellbores.findIndex(
            (item) => item.active,
          );
          // Increment items counter in the parent list
          if (activeWellboreIndex !== -1) {
            projects.lists.wellbores[activeWellboreIndex].designsCount =
              +wellbores[activeWellboreIndex].designsCount + 1;
          }
        } else {
          // Update existing one
          projects.lists.designs[existingIndex] = design;
        }
      }

      // Estimate list
      if (action.payload.estimate) {
        const { wellbores, estimates } = projects.lists;
        const { estimate } = action.payload;
        const existingIndex = estimates.findIndex(
          (item) => item.projectId === estimate.projectId,
        );
        estimate.active = true;
        if (existingIndex === -1) {
          // Add new
          projects.lists.estimates = estimates.map((item) => {
            item.active = false;
            return item;
          });
          projects.lists.estimates.push(estimate);
          const activeWellboreIndex = wellbores.findIndex(
            (item) => item.active,
          );
          // Increment items counter in the parent list
          if (activeWellboreIndex !== -1) {
            projects.lists.wellbores[activeWellboreIndex].estimatesCount =
              +wellbores[activeWellboreIndex].estimatesCount + 1;
          }
        } else {
          // Update existing one
          projects.lists.estimates[existingIndex] = estimate;
        }
      }
      projects.isAdding = false;
    },
    projectHierarchySaveFailed: (projects) => {
      projects.isAdding = false;
    },
    projectDataRemoved: (projects, action) => {
      if (action.payload.list === project.COUNTRIES_LIST) {
        const { countries } = projects.lists;
        const index = countries.findIndex(
          (country) => country.projectCountryId === action.payload.id,
        );
        delete countries[index];
        projects.lists.countries = countries.filter((i) => i);
        deselectProjectItems(projects.lists, project.COUNTRIES_LIST);
      }
      if (action.payload.list === project.FIELDS_LIST) {
        const { fields, countries } = projects.lists;
        const index = fields.findIndex(
          (item) => item.projectFieldId === action.payload.id,
        );
        delete fields[index];
        projects.lists.fields = fields.filter((i) => i);
        deselectProjectItems(projects.lists, project.FIELDS_LIST);
        // Decrement items counter in the parent list
        const activeCountryIndex = countries.findIndex((item) => item.active);
        if (activeCountryIndex !== -1) {
          projects.lists.countries[activeCountryIndex].fieldsCount =
            +countries[activeCountryIndex].fieldsCount - 1;
        }
      }
      if (action.payload.list === project.SITES_LIST) {
        const { fields, sites } = projects.lists;
        const index = sites.findIndex(
          (item) => item.projectSiteId === action.payload.id,
        );
        delete sites[index];
        projects.lists.sites = sites.filter((i) => i);
        deselectProjectItems(projects.lists, project.SITES_LIST);
        // Decrement items counter in the parent list
        const activeFieldIndex = fields.findIndex((item) => item.active);
        if (activeFieldIndex !== -1) {
          projects.lists.fields[activeFieldIndex].sitesCount =
            +fields[activeFieldIndex].sitesCount - 1;
        }
      }
      if (action.payload.list === project.WELLS_LIST) {
        const { wells, sites } = projects.lists;
        const index = wells.findIndex(
          (item) => item.projectWellId === action.payload.id,
        );
        delete wells[index];
        projects.lists.wells = wells.filter((i) => i);
        deselectProjectItems(projects.lists, project.WELLS_LIST);
        // Decrement items counter in the parent list
        const activeSiteIndex = sites.findIndex((item) => item.active);
        if (activeSiteIndex !== -1) {
          projects.lists.sites[activeSiteIndex].wellsCount =
            +sites[activeSiteIndex].wellsCount - 1;
        }
      }
      if (action.payload.list === project.WELLBORES_LIST) {
        const { wells, wellbores } = projects.lists;
        const index = wellbores.findIndex(
          (item) => item.projectWellboreId === action.payload.id,
        );
        delete wellbores[index];
        projects.lists.wellbores = wellbores.filter((i) => i);
        deselectProjectItems(projects.lists, project.WELLBORES_LIST);
        // Decrement items counter in the parent list
        const activeWellIndex = wells.findIndex((item) => item.active);
        if (activeWellIndex !== -1) {
          projects.lists.wells[activeWellIndex].wellboresCount =
            +wells[activeWellIndex].wellboresCount - 1;
        }
      }
      if (action.payload.list === project.DESIGNS_LIST) {
        const { designs, wellbores } = projects.lists;
        const index = designs.findIndex(
          (item) => item.projectDesignId === action.payload.id,
        );
        delete designs[index];
        projects.lists.designs = designs.filter((i) => i);
        deselectProjectItems(projects.lists, project.DESIGNS_LIST);
        // Decrement items counter in the parent list
        const activeWellboreIndex = wellbores.findIndex((item) => item.active);
        if (activeWellboreIndex !== -1) {
          projects.lists.wellbores[activeWellboreIndex].designsCount =
            +wellbores[activeWellboreIndex].designsCount - 1;
        }
      }
      if (action.payload.list === project.ESTIMATES_LIST) {
        const { estimates, wellbores } = projects.lists;
        const index = estimates.findIndex(
          (item) => item.projectId === action.payload.id,
        );
        delete estimates[index];
        projects.lists.estimates = estimates.filter((i) => i);
        deselectProjectItems(projects.lists, project.ESTIMATES_LIST);
        // Decrement items counter in the parent list
        const activeWellboreIndex = wellbores.findIndex((item) => item.active);
        if (activeWellboreIndex !== -1) {
          projects.lists.wellbores[activeWellboreIndex].estimatesCount =
            +wellbores[activeWellboreIndex].estimatesCount - 1;
        }
      }
    },
    countrySelected: (projects, action) => {
      const { countries, fields, sites, wells, wellbores, designs } =
        projects.lists;
      selectListItem(countries, 'projectCountryId', action.payload);
      deselectProjectItems(projects.lists, project.COUNTRIES_LIST);
      // Preselect project chain if it's only one item
      const currentFields = fields.filter(
        (item) => item.projectCountryId === action.payload,
      );
      if (currentFields.length === 1) {
        const fieldIndex = fields.findIndex(
          (item) => item.projectFieldId === currentFields[0].projectFieldId,
        );
        fields[fieldIndex].active = true;
        const currentSites = sites.filter(
          (item) => item.projectFieldId === fields[fieldIndex].projectFieldId,
        );
        if (currentSites.length === 1) {
          const siteIndex = sites.findIndex(
            (item) => item.projectSiteId === currentSites[0].projectSiteId,
          );
          sites[siteIndex].active = true;
          const currentWells = wells.filter(
            (item) => item.projectSiteId === sites[siteIndex].projectSiteId,
          );
          if (currentWells.length === 1) {
            const wellIndex = wells.findIndex(
              (item) => item.projectWellId === currentWells[0].projectWellId,
            );
            wells[wellIndex].active = true;
            const currentWellbores = wellbores.filter(
              (item) => item.projectWellId === wells[wellIndex].projectWellId,
            );
            if (currentWellbores.length === 1) {
              const wellboreIndex = wellbores.findIndex(
                (item) =>
                  item.projectWellboreId ===
                  currentWellbores[0].projectWellboreId,
              );
              wellbores[wellboreIndex].active = true;
              const currentDesigns = designs.filter(
                (item) =>
                  item.projectWellboreId ===
                  wellbores[wellboreIndex].projectWellboreId,
              );
              if (currentDesigns.length === 1) {
                const designIndex = designs.findIndex(
                  (item) =>
                    item.projectDesignId === currentDesigns[0].projectDesignId,
                );
                designs[designIndex].active = true;
              }
            }
          }
        }
      }
    },
    fieldSelected: (projects, action) => {
      const { fields, sites, wells, wellbores, designs } = projects.lists;
      selectListItem(fields, 'projectFieldId', action.payload);
      deselectProjectItems(projects.lists, project.FIELDS_LIST);
      // Preselect project chain if it's only one item
      const currentSites = sites.filter(
        (item) => item.projectFieldId === action.payload,
      );
      if (currentSites.length === 1) {
        const siteIndex = sites.findIndex(
          (item) => item.projectSiteId === currentSites[0].projectSiteId,
        );
        sites[siteIndex].active = true;
        const currentWells = wells.filter(
          (item) => item.projectSiteId === sites[siteIndex].projectSiteId,
        );
        if (currentWells.length === 1) {
          const wellIndex = wells.findIndex(
            (item) => item.projectWellId === currentWells[0].projectWellId,
          );
          wells[wellIndex].active = true;
          const currentWellbores = wellbores.filter(
            (item) => item.projectWellId === wells[wellIndex].projectWellId,
          );
          if (currentWellbores.length === 1) {
            const wellboreIndex = wellbores.findIndex(
              (item) =>
                item.projectWellboreId ===
                currentWellbores[0].projectWellboreId,
            );
            wellbores[wellboreIndex].active = true;
            const currentDesigns = designs.filter(
              (item) =>
                item.projectWellboreId ===
                wellbores[wellboreIndex].projectWellboreId,
            );
            if (currentDesigns.length === 1) {
              const designIndex = designs.findIndex(
                (item) =>
                  item.projectDesignId === currentDesigns[0].projectDesignId,
              );
              designs[designIndex].active = true;
            }
          }
        }
      }
    },
    siteSelected: (projects, action) => {
      const { sites, wells, wellbores, designs } = projects.lists;
      selectListItem(sites, 'projectSiteId', action.payload);
      deselectProjectItems(projects.lists, project.SITES_LIST);
      // Preselect project chain if it's only one item
      const currentWells = wells.filter(
        (item) => item.projectSiteId === action.payload,
      );
      if (currentWells.length === 1) {
        const wellIndex = wells.findIndex(
          (item) => item.projectWellId === currentWells[0].projectWellId,
        );
        wells[wellIndex].active = true;
        const currentWellbores = wellbores.filter(
          (item) => item.projectWellId === wells[wellIndex].projectWellId,
        );
        if (currentWellbores.length === 1) {
          const wellboreIndex = wellbores.findIndex(
            (item) =>
              item.projectWellboreId === currentWellbores[0].projectWellboreId,
          );
          wellbores[wellboreIndex].active = true;
          const currentDesigns = designs.filter(
            (item) =>
              item.projectWellboreId ===
              wellbores[wellboreIndex].projectWellboreId,
          );
          if (currentDesigns.length === 1) {
            const designIndex = designs.findIndex(
              (item) =>
                item.projectDesignId === currentDesigns[0].projectDesignId,
            );
            designs[designIndex].active = true;
          }
        }
      }
    },
    wellSelected: (projects, action) => {
      const { wells, wellbores, designs } = projects.lists;
      selectListItem(wells, 'projectWellId', action.payload);
      deselectProjectItems(projects.lists, project.WELLS_LIST);
      // Preselect project chain if it's only one item
      const currentWellbores = wellbores.filter(
        (item) => item.projectWellId === action.payload,
      );
      if (currentWellbores.length === 1) {
        const wellboreIndex = wellbores.findIndex(
          (item) =>
            item.projectWellboreId === currentWellbores[0].projectWellboreId,
        );
        wellbores[wellboreIndex].active = true;
        const currentDesigns = designs.filter(
          (item) =>
            item.projectWellboreId ===
            wellbores[wellboreIndex].projectWellboreId,
        );
        if (currentDesigns.length === 1) {
          const designIndex = designs.findIndex(
            (item) =>
              item.projectDesignId === currentDesigns[0].projectDesignId,
          );
          designs[designIndex].active = true;
        }
      }
    },
    wellboreSelected: (projects, action) => {
      const { wellbores, designs } = projects.lists;
      selectListItem(wellbores, 'projectWellboreId', action.payload);
      deselectProjectItems(projects.lists, project.WELLBORES_LIST);
      // Preselect project chain if it's only one item
      const currentDesigns = designs.filter(
        (item) => item.projectWellboreId === action.payload,
      );
      if (currentDesigns.length === 1) {
        const designIndex = designs.findIndex(
          (item) => item.projectDesignId === currentDesigns[0].projectDesignId,
        );
        designs[designIndex].active = true;
      }
    },
    designSelected: (projects, action) => {
      const { designs } = projects.lists;
      selectListItem(designs, 'projectDesignId', action.payload);
      deselectProjectItems(projects.lists, project.DESIGNS_LIST);
    },
    estimateSelected: (projects, action) => {
      const { estimates } = projects.lists;
      selectListItem(estimates, 'projectId', action.payload);
    },
    updatePageStatusRequested: (projects, actions) => {
      const { pages } = projects.overview;

      // Temporary logic for beta / external testing.
      switch (pages[actions.payload]) {
        case pageStatuses.ACTIVE:
          projects.overview.pages[actions.payload] = pageStatuses.FINISHED;
          break;
        case pageStatuses.FINISHED:
          projects.overview.pages[actions.payload] = pageStatuses.ACTIVE;
          break;
        case pageStatuses.DISABLED:
          projects.overview.pages[actions.payload] = pageStatuses.FINISHED;
          break;
        default:
      }
    },
  },
});

export const {
  projectsRequested,
  projectsReceived,
  overviewRequested,
  overviewReceived,
  projectHierarchySaveRequested,
  projectHierarchySaved,
  projectHierarchySaveFailed,
  projectDataRemoved,
  countrySelected,
  fieldSelected,
  siteSelected,
  wellSelected,
  wellboreSelected,
  designSelected,
  estimateSelected,
  projectNameUpdated,
  updatePageStatusRequested,
} = slice.actions;
export default slice.reducer;

/**
 * Get projects list
 */
export const getProjects = (companyId, activeProjectId) =>
  apiCallBegan({
    url: `/api/projects/${companyId}`,
    method: 'GET',
    onStart: projectsRequested.type,
    onSuccess: (response) => ({
      type: projectsReceived.type,
      payload: { ...response, activeProjectId },
    }),
  });

/**
 * Get overview stats
 */
export const getOverviewStats = (projectId) =>
  apiCallBegan({
    url: `/api/projects/overview/${projectId}`,
    method: 'GET',
    onStart: overviewRequested.type,
    onSuccess: overviewReceived.type,
  });

/**
 * Save project hierarchy
 * like countries, fields, sites, etc
 *
 * @param data
 */
export const saveProjectHierarchy = (data) =>
  apiCallBegan({
    url: '/api/projects',
    method: 'POST',
    data,
    onStart: projectHierarchySaveRequested.type,
    onSuccess: (response) => ({
      type: projectHierarchySaved.type,
      payload: response,
    }),
    onError: projectHierarchySaveFailed.type,
  });

/**
 * Remove project data from different lists
 * like countries, fields, sites, etc
 *
 * @param id
 * @param list
 */
export const removeProjectHierarchy = (list, id) =>
  apiCallBegan({
    url: `/api/projects/${list}/${id}`,
    method: 'DELETE',
    onSuccess: () => ({
      type: projectDataRemoved.type,
      payload: { id, list },
    }),
  });

/**
 *  Export project to json file
 *
 * @param id
 * @param name
 */
export const exportProject = (id, name) =>
  apiCallBegan({
    url: `/api/projects/export/${id}`,
    method: 'GET',
    onSuccess: (response) => {
      const dataString = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(response),
      )}`;
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataString);
      downloadAnchorNode.setAttribute('download', `${name}.json`);
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
  });

/**
 * Format data from create estimate modal
 *
 * @param data
 */
export const formatProjectData = (data) => {
  const result = {
    projectData: data.projectData,
    existingEstimate: data.existingEstimate,
    country: data.country,
    companyId: data.companyId,
    project: {
      projectDesignId: data.existingDesign,
      name: data.estimate,
    },
  };
  if (!data.existingField) {
    result.field = {
      projectCountryId: data.existingCountry || data.country,
      name: data.field,
    };
  }
  if (!data.existingSite) {
    result.site = {
      projectFieldId: data.existingField,
      name: data.site,
    };
  }
  if (!data.existingWell) {
    result.well = {
      projectSiteId: data.existingSite,
      name: data.well,
    };
  }
  if (!data.existingWellbore) {
    result.wellbore = {
      projectWellId: data.existingWell,
      name: data.wellbore,
    };
  }
  if (!data.existingDesign) {
    result.design = {
      projectWellboreId: data.existingWellbore,
      name: data.design,
    };
  }

  return result;
};

export const updatePageStatus = (projectId, page) =>
  apiCallBegan({
    url: `/api/projects/page/${projectId}`,
    method: 'POST',
    data: { page },
    onStart: () => ({
      type: updatePageStatusRequested.type,
      payload: page,
    }),
  });

/**
 * Group estimates into groups of planned or actual
 *
 * @param estimates
 */
export const groupedEstimates = (estimates) => {
  const groups = {};
  estimates.forEach((estimate) => {
    const { category } = estimate;
    groups[category] = groups[category] || [];
    groups[category].push(estimate);
  });
  return groups;
};

/**
 *  Duplicate project
 *
 * @param id
 * @param name
 */
export const duplicateProject = (id, data) => {
  return apiCallBegan({
    url: `/api/projects/duplicate/${id}`,
    method: 'POST',
    data,
    onSuccess: projectHierarchySaved.type,
  });
};
