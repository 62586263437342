import EPSGLookup from '@oliasoft/gis-library/results/epsg-lookup.json';
import { unum } from '@oliasoft-open-source/units';
import { fromLocal } from '~common/gis/transform';
import { NorthReferenceType } from '~src/enums/LocationEnums.js';
import { getConvergence } from '~common/gis/get-convergence';
import { calculateScaleFactor } from '~common/gis/calculate-scale-factor';
import {
  isOffsetType,
  isGCSLocation,
  getProjectionUnit,
} from '~common/gis/gis-library-utils';
import {
  isLocationObjectValid,
  isEpsgCodeValid,
} from '~common/gis/validate-location';
import { transformLocationCoordinate } from '~common/gis/transform-location-coordinate';

/**
 * @typedef {`EPSG:${number}` | `EPSG:OFFSET` | `EPSG:OFFSETPOLAR`} EPSGIndex
 */

/**
 * @typedef locationObject
 * @property {EPSGIndex} epsgCode EPSG Index Code
 * @property {number} x User defined input in 2D space
 * @property {number} y User defined input in 2D space
 */

/**
 * Covert location object from one format to the other
 *
 * @param {locationObject} locationObject
 * @param {EPSGIndex} to
 * @param {locationObject} [referenceLocation]
 *
 * @return {locationObject}
 */

/**
 * @typedef {import('../interfaces/dataset-db.interface').ICoordinates} ICoordinates
 */

/**
 * @param {ICoordinates} locationObject - location coordinate
 * @param {string} to - epsg code to convert into
 * @param {ICoordinates} [referenceLocation] - site location object
 * @returns {ICoordinates}
 */
export const transformLocationObject = (
  locationObject,
  to,
  referenceLocation,
) => {
  const isTargetEPSGFormatValid = isEpsgCodeValid(to);
  const targetEPSGExists = !!EPSGLookup[to]?.definition?.[1];

  if (!isLocationObjectValid(locationObject)) {
    throw new Error('Invalid location object');
  }

  if (!isTargetEPSGFormatValid) {
    throw new Error('Invalid target EPSG code');
  }

  if (!targetEPSGExists) {
    throw new Error('Target EPSG does not exist in our database');
  }

  if (isOffsetType(locationObject.epsgCode)) {
    if (!isLocationObjectValid(referenceLocation)) {
      throw new Error('Invalid reference location');
    }
    let convergence = 0;

    if (referenceLocation.northReference === NorthReferenceType.TrueNorth) {
      convergence = getConvergence(referenceLocation);
    }

    const scaleFactor = calculateScaleFactor(
      referenceLocation.applyScale,
      referenceLocation,
    );
    const gridPosition = [referenceLocation.x, referenceLocation.y, 0];

    const projectionUnit = getProjectionUnit(referenceLocation.epsgCode);
    let offsetArray = [0, 0, 0];
    if (projectionUnit && !isGCSLocation(referenceLocation.epsgCode)) {
      offsetArray = [
        unum(locationObject.y, projectionUnit, 'm'),
        unum(locationObject.x, projectionUnit, 'm'),
        0,
      ];
    }

    const [x, y] = fromLocal(
      gridPosition,
      scaleFactor,
      convergence,
      offsetArray,
    );
    const inputArray = [x, y];
    const [newX, newY] = transformLocationCoordinate(
      referenceLocation.epsgCode,
      to,
      inputArray,
    );

    return {
      epsgCode: to,
      x: newX,
      y: newY,
    };
  }

  const fromDatumCode = locationObject.epsgCode;
  const { x, y } = locationObject;
  const inputArray = [x, y];
  const [newX, newY] = transformLocationCoordinate(
    fromDatumCode,
    to,
    inputArray,
  );

  return {
    ...locationObject,
    epsgCode: to,
    x: newX,
    y: newY,
  };
};
