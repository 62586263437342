import {
  TArrayRepresentedGridCoordinate,
  TArrayRepresentedGridCoordinates,
} from '../utils/types';

/**
 * Calculates points on a sine wave between two points.
 *
 * Note: Currently only works for horizontal lines
 *
 * @param amplitude How far away from the line should wave deviate
 * @param frequency How often should the sine wave fluctuate
 * @param calculationsPerUnit How many sinus line segments should be calculated per 1 unit (Start/end could be pixels for simplicity)
 */
export const createSinePoints = (
  start: TArrayRepresentedGridCoordinate,
  end: TArrayRepresentedGridCoordinate,
  amplitude: number = 0.4,
  frequency: number = 1.3,
  calculationsPerUnit: number = 2,
): TArrayRepresentedGridCoordinates => {
  const [startX, startY] = start;
  const endY = end[1];
  const dy = endY - startY;
  const numberOfPts = calculationsPerUnit * dy;
  const horSlope = dy / numberOfPts;

  /**
   * @type {[x: number, y: number][]}
   */
  const pts: TArrayRepresentedGridCoordinates = [];
  for (let i = 0; i < numberOfPts; i += 1) {
    const nextY = startY + horSlope * i;
    pts.push([startX + amplitude * Math.sin(frequency * nextY), nextY]);
  }

  return pts;
};
