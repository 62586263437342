import React from 'react';
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { transformLocationObject } from '~common/gis/transform-location-object';
import { getDatumEPSG } from '~common/gis/gis-library-utils';
import Legend from './legend';
import Marker from './marker';
import NorthReferenceArrows from './north-reference-arrows';
import BoundariesBox from './boundaries-box';

/** @type {import('react').CSSProperties} */
const containerStyle = {
  border: '1px solid #999',
  position: 'relative',
  height: '100%',
  minHeight: '300px',
};

/**
 * Leaflet positioning: https://stackoverflow.com/questions/29773850 (variable height)
 *
 * @type {import('react').CSSProperties}
 */
const mapStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
};

/** @type {import('leaflet').LatLngTuple} */
const defaultEmptyMapCenter = [
  58.97,
  5.7331, //Stavanger
];

// Zoom out unless we have more than one marker (so people see where we are
// in the world):
const DEFAULTBOUNDS = 1500000;

/**
 * Leaflet map component
 * Note: bbox stands for border box in EPSG system. It is represented by [lat0,long0,lat1,long1] parameters, which are projected as box on the map, showing boundaries of the projection
 * @param {{
 *   legend: {
 *     labels: string[],
 *     colors: string[]
 *   },
 *   markers: {
 *     label?: string | object,
 *     latitude: number,
 *     longitude: number,
 *     markerColor?: string,
 *   }[],
 *   northReferenceArrows?: {
 *     hoverLabel: string,
 *     angle: number, // Degrees
 *   }[],
 *   bbox?: [number,number,number,number] | []}
 * } params
 */
const Map = ({
  legend,
  markers = [],
  northReferenceArrows,
  distanceFilter,
  currentWellLocation,
}) => {
  const { x: curLon, y: curLat } = transformLocationObject(
    currentWellLocation,
    getDatumEPSG(currentWellLocation?.epsgCode),
  );

  const getMapBounds = () => {
    if (currentWellLocation && !distanceFilter) {
      return Leaflet.latLng([curLat, curLon]).toBounds(DEFAULTBOUNDS);
    }
    if ((!currentWellLocation && markers.length === 0) || !distanceFilter) {
      return Leaflet.latLng(defaultEmptyMapCenter).toBounds(DEFAULTBOUNDS); //show North Sea around Stavanger (no marker default)
    }
    if (currentWellLocation && distanceFilter) {
      return Leaflet.latLng([curLat, curLon]).toBounds(
        (distanceFilter || 1) * 2,
      );
    }
    /** @type {import('leaflet').LatLngTuple[]} */
    const latLongs = markers.map((marker) => [
      marker.latitude,
      marker.longitude,
    ]);
    return new Leaflet.LatLngBounds(latLongs);
  };

  return (
    <>
      <div style={containerStyle}>
        <MapContainer style={mapStyle}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup>
            {markers.map((marker, markerIx) => (
              <Marker marker={marker} markerIx={markerIx} key={markerIx} />
            ))}
          </MarkerClusterGroup>
          {legend && <Legend legend={legend} />}
          <BoundariesBox
            centerLocation={[curLat, curLon]}
            radius={distanceFilter}
            bounds={getMapBounds()}
          />
        </MapContainer>
        <NorthReferenceArrows northReferenceArrows={northReferenceArrows} />
      </div>
    </>
  );
};

export default Map;
