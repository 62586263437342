import { IPriceBookItem } from '~src/common/interfaces/price-book.interfaces';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import { CostType, QuantityType } from '~src/enums/cost-setup';
import ExcelJS from 'exceljs';
import { toast } from '@oliasoft-open-source/react-ui-library';

export const headings = ['name', 'vendor', 'price', 'currency', 'priceType'];

const headerTranslationsMap: { [key: string]: string } = {
  name: i18n.t(translations.name),
  vendor: i18n.t(translations.priceBook_vendor),
  price: i18n.t(translations.priceBook_price),
  currency: i18n.t(translations.currency),
  priceType: i18n.t(translations.priceBook_priceType),
};

export const typeList = [
  { label: i18n.t(translations.costSetup_dayRate), value: CostType.DayRate },
  { label: i18n.t(translations.costSetup_lumpSum), value: CostType.LumpSum },
  {
    label: `${i18n.t(translations.costSetup_quantity)}: ${i18n.t(
      translations.costSetup_length,
    )}`,
    value: `${CostType.Quantity}-${QuantityType.Length}`,
  },
  {
    label: `${i18n.t(translations.costSetup_quantity)}: ${i18n.t(
      translations.costSetup_volume,
    )}`,
    value: `${CostType.Quantity}-${QuantityType.Volume}`,
  },
];
export const filterAndSortDataRows = (
  dataRows: IPriceBookItem[],
  filters: IPriceBookItem | Record<string, never>,
  sorts: { [key in keyof IPriceBookItem]?: string },
) =>
  dataRows
    .filter((row) =>
      (Object.keys(filters) as (keyof typeof filters)[]).every((key) => {
        return filters[key] === ''
          ? true
          : row[key]
            ?.toString()
            .toLowerCase()
            .includes((filters[key] || '').toString().toLowerCase());
      }),
    )
    .sort((a, b) => {
      for (const [key, value] of Object.entries(sorts)) {
        const keyTyped = key as keyof IPriceBookItem;
        switch (value) {
          case 'up': {
            return typeof a[keyTyped] === 'string' &&
              typeof b[keyTyped] === 'string'
              ? (a[keyTyped] as string)?.localeCompare(b[keyTyped] as string)
              : (a[keyTyped] as number) - (b[keyTyped] as number);
          }
          case 'down': {
            return typeof a[keyTyped] === 'string' &&
              typeof b[keyTyped] === 'string'
              ? (b[keyTyped] as string)?.localeCompare(a[keyTyped] as string)
              : (b[keyTyped] as number) - (a[keyTyped] as number);
          }
          default:
            return 0;
        }
      }
      return 0;
    });

export const dataHeaders = (
  dataRowsKeys: string[],
  filters: IPriceBookItem | Record<string, never>,
  setFilters: (args: IPriceBookItem) => void,
  sorts: { [key in keyof IPriceBookItem]?: string },
  setSorts: (args: { [key in keyof IPriceBookItem]?: string }) => void,
) => {
  const dataSortCells = dataRowsKeys.map((key) => {
    const keyTyped = key as keyof IPriceBookItem;
    const sort = Object.keys(sorts).includes(key) ? sorts[keyTyped] : '';
    const prettifyHeaderValue = headerTranslationsMap[keyTyped];
    return {
      key,
      value: prettifyHeaderValue,
      hasSort: true,
      sort,
      onSort: () => {
        const newSort = sort === 'up' ? 'down' : 'up';
        setSorts({
          [keyTyped]: newSort,
        });
      },
    };
  });
  const dataFilterCells = dataRowsKeys.map((key) => {
    const keyTyped = key as keyof IPriceBookItem;
    const filterValue = Object.keys(filters).includes(key)
      ? filters[keyTyped]
      : '';
    return {
      key,
      value: filterValue,
      type: 'Input',
      placeholder: 'Filter',
      onChange: (ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
        setFilters({
          ...filters,
          [keyTyped]: ev.target.value,
        } as IPriceBookItem),
    };
  });
  return {
    dataSortCells,
    dataFilterCells,
  };
};

async function writeExcelFile(workBook: ExcelJS.Workbook, fileName: any) {
  const buffer = await workBook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

const borderStyle = { style: 'thin', color: { argb: 'D9D9D9' } };

const colorHeaderRow = (row: any) =>
  row.eachCell((cell: any) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E2E2DC' },
    };
    cell.border = {
      top: borderStyle,
      left: borderStyle,
      bottom: borderStyle,
      right: borderStyle,
    };
  });

export const downloadPriceBookExcel = ({
  priceBook,
  currenciesList
}: {
  priceBook: IPriceBookItem[];
  currenciesList: any[]
}) => {

  const workBook = new ExcelJS.Workbook();
  const workSheet = workBook.addWorksheet(i18n.t(translations.priceBook));
  const helperSheet = workBook.addWorksheet('helper');

  // add type list to helper sheet
  helperSheet.getColumn(5).values = typeList.map(item => item.label);

  // add currency list to helper sheet
  helperSheet.getColumn(4).values = currenciesList.map(item => item.label);;

  workSheet.addRow(headings.map((header) => headerTranslationsMap[header]));
  colorHeaderRow(workSheet.lastRow);

  const getCells = (row: any) => {
    return Object.keys(row).slice(1)
      .map((key) => {
        if (key === 'priceType') {
          return typeList.find((item) => item.value === row[key])?.label || '';
        } else {
          return row[key] || '';
        }
      });
  };
  priceBook.forEach((priceItem) => {
    workSheet.addRow(getCells(priceItem));
  });
  workSheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      row.eachCell((cell, colNumber) => {
        if (colNumber === 5) {
          cell.dataValidation = {
            type: 'list',
            formulae: [`helper!$E$1:$E$${typeList.length}`],
            operator: 'equal',
            showErrorMessage: true,
          };
        }
        if (colNumber === 4) {
          cell.dataValidation = {
            type: 'list',
            formulae: [`helper!$D$1:$D${currenciesList?.length}`],
            operator: 'equal',
            showErrorMessage: true,
          };
        }
      });
    }
  });
  writeExcelFile(workBook, 'price-book');
};

export const uploadPriceBookExcel = async ( uploadPriceBook: (data: IPriceBookItem[]) => void) => {
  const input = document.createElement('input');
  input.type = 'file';

  input.onchange = (fileEvent: any) => {
    const file = fileEvent.target?.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async (readEvent) => {
      const data = readEvent.target?.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data as ArrayBuffer);
      const worksheet = workbook.worksheets[0];
      const priceList: any[] = [];
      worksheet.eachRow((row) => priceList.push(row.values))
      const parsedPriceBook = priceList.slice(1).map((price) => {
        const importedItem = headings.reduce((acc, item, index) => {
          const priceValue = item === 'priceType' ? typeList.find(typeItem => typeItem.label === price[index + 1])?.value : price[index + 1]
          return ({ ...acc, [item]: priceValue })
        }, {});
        return importedItem;
      })
      if (parsedPriceBook.length === 0) {
        toast({
          message: {
            type: 'Error',
            content: 'No price book items',
          },
        });
      } else {
        uploadPriceBook(parsedPriceBook as IPriceBookItem[]);
      }
    };
  };
  input.click();
};