import { createSlice } from '@reduxjs/toolkit';
import { simulationChartType } from '~src/enums/simulations';
import i18n from '~src/internationalisation/i18n';
import translations from '~src/internationalisation/translation-map.json';

const simulationChartDefaults = {
  chartOne: {
    dataType: {
      label: i18n.t(translations.simulations_timeDepthPlot),
      value: simulationChartType.TIME_DEPTH,
    },
    viewType: 0,
    timeCostType: 0,
  },
  chartTwo: {
    dataType: {
      label: i18n.t(translations.simulations_costDepthPlot),
      value: simulationChartType.TIME_DEPTH,
    },
    viewType: 0,
    timeCostType: 1,
  },
  chartThree: {
    dataType: {
      label: i18n.t(translations.simulations_operationDurations),
      value: simulationChartType.OPERATION_DURATIONS,
    },
    viewType: 0,
    timeCostType: 0,
  },
  chartFour: {
    dataType: {
      label: i18n.t(translations.simulations_operationCosts),
      value: simulationChartType.OPERATION_DURATIONS,
    },
    viewType: 0,
    timeCostType: 1,
  },
};

const defaultSimulationChartLayout = {
  numberOfCharts: 1,
  chartOne: simulationChartDefaults.chartOne,
  chartTwo: simulationChartDefaults.chartTwo,
  chartThree: simulationChartDefaults.chartThree,
  chartFour: simulationChartDefaults.chartFour,
};

const defaultTwoChartLayout = {
  numberOfCharts: 2,
  chartOne: simulationChartDefaults.chartOne,
  chartTwo: simulationChartDefaults.chartThree,
};

const slice = createSlice({
  name: 'chartControls',
  initialState: {
    charts: defaultTwoChartLayout,
  },
  reducers: {
    updateNumberOfCharts: (chartControls, action) => {
      switch (action.payload) {
        case 2:
          chartControls.charts = defaultTwoChartLayout;
          break;
        case 4:
          chartControls.charts = {
            ...defaultSimulationChartLayout,
            numberOfCharts: 4,
          };
          break;
        default:
          chartControls.charts = defaultSimulationChartLayout;
      }
    },
    dataTypeUpdated: (chartControls, action) => {
      const { key, values } = action.payload;
      chartControls.charts[key].dataType = values;
    },
    viewTypeUpdated: (chartControls, action) => {
      const { key, values } = action.payload;
      chartControls.charts[key].viewType = values;
    },
    timeCostTypeUpdated: (chartControls, action) => {
      const { key, values } = action.payload;
      chartControls.charts[key].timeCostType = values;
    },
  },
});

export const {
  updateNumberOfCharts,
  dataTypeUpdated,
  viewTypeUpdated,
  timeCostTypeUpdated,
} = slice.actions;

export default slice.reducer;
