import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Heading, Field } from '@oliasoft-open-source/react-ui-library';
import { updateCategory } from '~store/entities/cost-setup/cost-setup';
import { getResolver } from '~src/validation/resolver';
import translations from '~src/internationalisation/translation-map.json';
import { categoriesSchemaValidator } from '~schemas/ajv-validators';
import { Input } from '~common/form-inputs';
import { useAutoSave } from '~common/auto-save/use-auto-save';
import { autoSaveWaitShort } from '~src/config/config';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CostCategoryForm = ({
  projectId,
  category,
  updateCategory,
  isPageDisabled,
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      costCategoryId: category?.costCategoryId || null,
      name: category?.name || '',
      parentId: null,
    },
    resolver: getResolver(categoriesSchemaValidator),
  });

  useEffect(() => {
    reset(category);
  }, [category?.costCategoryId]);

  const debounceUpdateCategory = useRef(
    debounce(updateCategory, autoSaveWaitShort),
  );
  const onSubmit = handleSubmit((data) => {
    data.projectId = projectId;
    debounceUpdateCategory.current(data);
  });
  useAutoSave(onSubmit, watch);

  return (
    <>
      <Heading top>{t(translations.categoryDetails)}</Heading>
      <form>
        <Field label={t(translations.name)}>
          <Input
            name="name"
            control={control}
            errors={errors}
            width="258px"
            disabled={isPageDisabled}
          />
        </Field>
      </form>
    </>
  );
};

const mapDispatchToProps = { updateCategory };

const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(CostCategoryForm),
);

export { Container as CostCategoryForm };
