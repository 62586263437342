import { produce } from 'immer';

export const reorderOperation = (reorder, settings) => {
  const { fromId, toId } = reorder;
  if (fromId === toId || fromId === undefined || toId === undefined)
    return null;

  let activeTemplateIndex;

  const updatedSettings = produce(settings, (draft) => {
    activeTemplateIndex = draft.templates.findIndex(
      (template) => template.active === true,
    );
    if (activeTemplateIndex === -1) return null;
    else {
      const operations = draft.templates[activeTemplateIndex].activityModel;
      const from = operations.findIndex((item) => item.id === fromId);
      const to = operations.findIndex((item) => item.id === toId);
      operations.splice(to, 0, operations.splice(from, 1)[0]);
    }
  });

  return { updatedSettings, activeTemplateIndex };
};
