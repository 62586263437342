import React from 'react';
import { connect } from 'react-redux';
import { Loader, Spinner } from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import {
  showDeleteKpiModalUpdated,
  showKpiFormUpdated,
} from '~store/entities/company-settings/company-settings';
import { Kpi } from '~views/settings/kpi/kpi';
import { settingEntity } from '~src/enums/settings';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CompanyKpi = ({
  companySettings,
  showDeleteKpiModalUpdated,
  showKpiFormUpdated,
}) => {
  const { t } = useTranslation();
  const { isFetching } = companySettings;

  if (isFetching) {
    return (
      <Loader text={t(translations.fetching)} theme="light" cover>
        <Spinner dark />
      </Loader>
    );
  }
  return (
    <Kpi
      showDeleteKpiModalUpdated={showDeleteKpiModalUpdated}
      showKpiFormUpdated={showKpiFormUpdated}
      entitySettings={companySettings}
      entity={settingEntity.COMPANY}
    />
  );
};

const mapStateToProps = ({ entities }) => {
  const { companySettings } = entities;
  return {
    companySettings,
  };
};

const mapDispatchToProps = {
  showDeleteKpiModalUpdated,
  showKpiFormUpdated,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CompanyKpi),
);
export { Container as CompanyKpi };
