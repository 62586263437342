import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { CheckBox, Text } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import './style.css';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const RawCheckBoxList = ({
  items,
  name,
  control,
  errors,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <Text error>
            {translations[errors[name]]
              ? t(errors[name])
              : errors[name] || null}
          </Text>

          <div className="custom-grid">
            {items.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <CheckBox
                    onChange={(e) => {
                      if (e.target.checked) {
                        field.onChange([...field.value, item.id]);
                      } else {
                        field.onChange(
                          field.value.filter((value) => value !== item.id),
                        );
                      }
                    }}
                    label={item.name}
                    value={item.id}
                    checked={
                      field?.value ? field.value.includes(item.id) : false
                    }
                    disabled={disabled}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
    />
  );
};

export const CheckBoxList = withErrorBoundary(RawCheckBoxList);
