import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { CheckBox, Text, Flex } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import './style.css';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const RawSingleCheckBox = ({
  label,
  name,
  control,
  errors,
  disabled = false,
  labelAtLeft = true,
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <Text error>
            {translations[errors[name]]
              ? t(errors[name])
              : errors[name] || null}
          </Text>

          {labelAtLeft ? (
            <Flex alignItems="center" gap="5px">
              <label htmlFor={name}>{label}</label>
              <span style={{ paddingBottom: '1px' }}>
                <CheckBox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  label={''}
                  checked={field.value || false}
                  disabled={disabled}
                />
              </span>
            </Flex>
          ) : (
            <CheckBox
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
              label={label}
              checked={field.value || false}
              disabled={disabled}
            />
          )}
        </>
      )}
    />
  );
};

export const SingleCheckBox = withErrorBoundary(RawSingleCheckBox);
