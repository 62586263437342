import { useEffect, useState } from 'react';
import axios from 'axios';

const WithRuntimeConfig = (props) => {
  const { children } = props;
  const [runtimeLoaded, setRuntimeLoaded] = useState(false);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/runtimeConfigStatic.js');
      window.__runtimeConfigStatic = data;
      setRuntimeLoaded(true);
    })();
  }, []);

  if (!runtimeLoaded) {
    return <div />;
  }

  return children;
};

export default WithRuntimeConfig;
