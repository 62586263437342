import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LineChart } from '@oliasoft-open-source/charts-library';
import { displayNumber, round, isValidNum } from '@oliasoft-open-source/units';
import translations from '~src/internationalisation/translation-map.json';
import { generateScatterChartViewData } from '~views/simulations/simulations';
import { selectSimulationsResult } from '~store/entities/simulations/selectors';
import { HOURS_PER_DAY } from '~src/enums/general';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const TimeTrackerChart = ({ operationList, simulationsResult }) => {
  const { t } = useTranslation();

  const calcDuration = (list, curIdx) => {
    return round(
      list
        .slice(0, curIdx + 1)
        .reduce((sum, el) => sum + Number(el.actual || 0), 0) / HOURS_PER_DAY,
    );
  };
  const generateActualChartLine = (list) => {
    const filteredOperationList = list?.filter(
      (operation) =>
        isValidNum(operation?.actual) && isValidNum(operation?.actualDepth),
    );
    return {
      label: t(translations.actual),
      data: [
        { x: 0, y: filteredOperationList?.[0]?.actualDepth, label: '' },
        ...filteredOperationList.map((operation, idx) => {
          return operation.activities.every(
            (activity) => activity.actualDepth === null,
          )
            ? {}
            : {
                x: calcDuration(filteredOperationList, idx),
                y: Number(operation.actualDepth),
                label: operation.name,
              };
        }),
      ],
    };
  };

  const operationVsDepth = simulationsResult?.operation_vs_depth;
  const chartViewData = generateScatterChartViewData(operationVsDepth, 0);
  const data = {
    datasets: [...chartViewData, generateActualChartLine(operationList)],
  };
  const chartOptions = {
    options: {
      chartStyling: {
        height: '100%',
      },
      additionalAxesOptions: {
        reverse: true,
      },
      axes: {
        x: [
          {
            label: t(`${t(translations.time)} [${t(translations.days)}]`),
          },
        ],
        y: [
          {
            label: t(translations.depth),
          },
        ],
      },

      scales: {
        x: {
          ticks: {
            callback: (val) => {
              return displayNumber(val, { scientific: false });
            },
          },
        },
      },
    },
    data,
  };

  return (
    <div style={{ height: 'calc(100% - 92px)' }}>
      <LineChart chart={chartOptions} />
    </div>
  );
};

const mapStateToProps = ({ entities }) => {
  const simulationsResult = selectSimulationsResult(entities);
  return {
    simulationsResult,
  };
};

export default withErrorBoundary(connect(mapStateToProps)(TimeTrackerChart));
