import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { getWellDetails } from '~store/entities/well-details/well-details';
import { isEmpty } from 'lodash';
import { generateChart } from './generate-chart/generate-chart';
import styles from './utils/casing-renderer.module.less';
import { TCasingRendererDimensions } from './utils/types';
import { calculateChartData } from './calculate-chart-data/calculate-chart-data';
import { useResizeCasingRenderer } from './utils/use-resize-casing-renderer';

interface TCasingRendererComponentProps extends PropsFromRedux {
  getWellDetails: any;
  dimensions: TCasingRendererDimensions;
}

const CasingRendererComponent = ({
  schematic,
  well,
  getWellDetails,
  dimensions,
  sectionTypes,
  sections
}: TCasingRendererComponentProps) => {
  const { project } = useParams();
  const [chart, setChart] = useState<React.ReactNode>(null);

  useEffect(() => {
    if (project && (isEmpty(well) || isEmpty(schematic))) {
      getWellDetails(project);
    }
  }, [project]);

  useEffect(() => {
    if (isEmpty(schematic)) {
      return;
    }
    (async () => {
      const chartData = calculateChartData(schematic, well, dimensions, sectionTypes, sections);
      setChart(generateChart({ chartData }));
    })();
  }, [schematic, well, dimensions]);

  if (!(schematic && well)) {
    console.error('Missing data');
    return null;
  }
  if (!chart) {
    return null;
  }

  return <div className={styles.mainWrapper}>{chart}</div>;
};

const mapStateToProps = ({ entities }: any) => {
  const {
    wellDetails: { well, schematic, sectionTypes, sections },
  } = entities;
  return { schematic, well, sectionTypes, sections };
};

const mapDispatchToProps = {
  getWellDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = connector(CasingRendererComponent);

export { Container as CasingRendererComponent };

export const CasingRenderer = () => {
  const [dimensions, setDimensions] = useState<TCasingRendererDimensions>();
  const { width, height, ref } = useResizeCasingRenderer();

  useEffect(() => {
    setDimensions(
      width && height
        ? {
            width,
            height,
          }
        : undefined,
    );
  }, [width, height]);

  return (
    <div className={styles.responsiveWrapper} ref={ref}>
      {dimensions && <Container dimensions={dimensions} />}
    </div>
  );
};
