export const estimateTypes = Object.freeze({
  OPERATION_SPEED: 1,
  OPERATION_TIME: 2,
});

export const activityStatuses = Object.freeze({
  IN_PROGRESS: 'in-progress',
  FINISHED: 'finished',
  NEW: 'new',
});

export const distributionTypes = Object.freeze({
  SPIKE: 'spike',
  UNIFORM: 'uniform',
  PERT: 'pert',
});
