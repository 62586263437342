import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ListSubheading,
  List,
  Loader,
  Spinner,
  Menu,
  Button,
} from '@oliasoft-open-source/react-ui-library';
import {
  duplicateProject,
  estimateSelected,
  exportProject,
  groupedEstimates,
} from '~store/entities/projects/projects';
import { project, ProjectCategoryType } from '~src/enums/projects';
import translations from '~src/internationalisation/translation-map.json';
import { routes } from '~routes/routes';
import { generatePath, navigateToPath } from '~store/navigation/navigation';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const EstimateList = ({
  estimates,
  onClickDelete,
  setEstimateModalVisible,
  setEditItem,
  navigateToPath,
  estimateSelected,
  addButtonDisabled,
  exportProject,
  isFetching,
  duplicateProject,
  onUpdateProjectCategory,
}) => {
  const { t } = useTranslation();

  const listItems = [];
  const categoryLabels = {
    [ProjectCategoryType.Actual]: t(translations.actual),
    [ProjectCategoryType.Prototype]: t(translations.prototype),
  };

  const listItemFromEstimate = (estimate) => {
    return {
      id: estimate.projectId,
      name: estimate.name,
      metadata: estimate.design,
      active: estimate.active,
      onClick: () => {
        estimateSelected(estimate.projectId);

        navigateToPath(
          generatePath(routes.overview.fullPath, {
            company: estimate.companyId,
            project: estimate.projectId,
          }),
        );
      },
      actions: [
        {
          childComponent: (
            <Menu
              menu={{
                label: t(translations.more),
                sections: [
                  {
                    label: t(translations.update),
                    icon: 'edit',
                    type: 'Option',
                    onClick: () => {
                      setEditItem(estimate);
                      setEstimateModalVisible(true);
                    },
                  },
                  {
                    label: t(translations.duplicate),
                    icon: 'duplicate',
                    type: 'Option',
                    onClick: () => {
                      duplicateProject(estimate.projectId, estimate);
                    },
                  },
                  {
                    type: 'Divider',
                  },
                  {
                    label: t(translations.downloadAsJson),
                    icon: 'download',
                    type: 'Option',
                    onClick: () =>
                      exportProject(estimate.projectId, estimate.name),
                  },
                  {
                    type: 'Divider',
                  },
                  {
                    label: t(translations.delete),
                    icon: 'delete',
                    type: 'Option',
                    onClick: () =>
                      onClickDelete(estimate.projectId, project.ESTIMATES_LIST),
                  },
                  {
                    type: 'Divider',
                  },
                  estimate.category === ProjectCategoryType.Prototype && {
                    label: t(translations.projects_setAsIdentifier, {
                      identifier: t(translations.actual),
                    }),
                    type: 'Option',
                    onClick: () =>
                      onUpdateProjectCategory(estimate.projectId, {
                        category: ProjectCategoryType.Actual,
                      }),
                  },
                  estimate.category !== ProjectCategoryType.Prototype && {
                    label: t(translations.projects_setAsIdentifier, {
                      identifier: t(translations.prototype),
                    }),
                    type: 'Option',
                    onClick: () =>
                      onUpdateProjectCategory(estimate.projectId, {
                        category: ProjectCategoryType.Prototype,
                      }),
                  },
                ],
                trigger: 'Component',
                component: (
                  <Button small basic round colored="muted" icon="menu" />
                ),
              }}
            />
          ),
        },
      ],
    };
  };
  const estimateGroups = groupedEstimates(estimates);

  Object.values(ProjectCategoryType).forEach((category) => {
    const estimateGroup = estimateGroups[category];
    if (!estimateGroup) {
      return;
    }
    listItems.push({
      id: `heading-${category}`,
      name: categoryLabels[category],
      type: 'Heading',
      metaCount: estimateGroup.length,
    });
    listItems.push(...estimateGroup.map(listItemFromEstimate));
  });

  return (
    <>
      <ListSubheading
        item={{
          name: t(translations.estimate),
          actions: [
            {
              label: 'Add',
              icon: 'add',
              onClick: () => setEstimateModalVisible(true),
              disabled: addButtonDisabled,
            },
          ],
        }}
      />
      {isFetching && (
        <Loader text={t(translations.fetching)} theme="light" cover>
          <Spinner dark />
        </Loader>
      )}
      <List testId="estimate-list" list={{ items: listItems }} noHeader />
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  return {
    isFetching: entities.projectSettings.isFetching,
  };
};

const mapDispatchToProps = {
  estimateSelected,
  navigateToPath,
  exportProject,
  duplicateProject,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(EstimateList),
);

export { Container as EstimateList };
