import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List } from '@oliasoft-open-source/react-ui-library';
import {
  getRisks,
  riskSelected,
  reorderRisks,
  risksCleanup,
} from '~store/entities/risks/risks';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const RisksList = ({
  risks,
  getRisks,
  risksList,
  riskSelected,
  reorderRisks,
  setShowForm,
  setRiskModalVisible,
  setSelectedId,
  isAdding,
  onClickAdd,
  projectId,
  isPageDisabled,
  risksCleanup,
}) => {
  useEffect(() => {
    if (projectId) {
      getRisks(projectId);
    }

    return () => risksCleanup();
  }, [projectId]);
  const { t } = useTranslation();
  const onClickDelete = (id) => {
    setSelectedId(id);
    setRiskModalVisible(true);
    setShowForm(false);
  };
  const onClickItem = (index) => {
    riskSelected(index);
    setShowForm(true);
  };

  return (
    <List
      list={{
        name: `${t(translations.projectModifiers_title)} (${risksList.length})`,
        actions: [
          {
            label: 'Add',
            icon: 'add',
            onClick: onClickAdd,
            disabled: isAdding || isPageDisabled,
          },
        ],
        items: risksList.map((operation, i) => ({
          ...operation,
          onClick: () => onClickItem(i),
          actions: operation.actions.map((action) => ({
            ...action,
            onClick: () => onClickDelete(operation.id),
            disabled: isPageDisabled,
          })),
        })),
      }}
      draggable={!isPageDisabled}
      onListReorder={(params) => reorderRisks(params, risks)}
    />
  );
};

const mapStateToProps = ({ entities }) => ({
  risksList: entities.risks.list.map((item) => ({
    id: item.riskId,
    name: item.name,
    draggable: true,
    active: item.active,
    actions: [
      {
        label: 'Delete',
        icon: 'delete',
      },
    ],
  })),
  risks: entities.risks.list,
});

const mapDispatchToProps = {
  getRisks,
  riskSelected,
  reorderRisks,
  risksCleanup,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(RisksList),
);

export { Container as RisksList };
