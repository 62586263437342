/* eslint-disable no-plusplus */
import { ChartDrawerParameters, IDepthLabel } from '../utils/types';

type TCollapsedDepthLabel = IDepthLabel & { gridX: number; gridY: number };

export const drawDepthLabels = ({
  chart,
  chartData,
  drawUtils,
}: ChartDrawerParameters) => {
  const { maxX } = chartData.grid;
  const { labels: depthLabels, meta: depthLabelsMeta } = chartData.depthLabels;
  const {
    lineHeight,
    lineLengthPart1,
    lineLengthPart2,
    lineLengthPart3,
    maxScreenSpaceItems,
    minSpacing,
  } = depthLabelsMeta;
  const { depthToGridOffset, diagramLine, diameterToGridOffset } = drawUtils;

  // Labels which are close to each other should be drawn at same depth
  const collapsedDepthLabels: TCollapsedDepthLabel[] = [];
  let lastDepth,
    count = 0;
  for (let i = 0; i < depthLabels.length; i++) {
    const currentLabel: TCollapsedDepthLabel = {
      gridX: NaN,
      gridY: NaN,
      ...depthLabels[i],
    };
    collapsedDepthLabels.push(currentLabel);

    if (!lastDepth) {
      lastDepth = currentLabel.depth;
    }

    if (currentLabel.depth < lastDepth + minSpacing) {
      count++;
    } else {
      // Too many, reduce to only show the first number:
      if (count > maxScreenSpaceItems) {
        while (count-- > 0) collapsedDepthLabels.pop();
        const lastLabel = collapsedDepthLabels[collapsedDepthLabels.length - 1];
        lastLabel.depth = lastDepth;
      }
      count = 0;
      lastDepth = currentLabel.depth;
    }
  }

  // Translate radius/depth of labels to grid coordinates
  let i = 0;
  while (collapsedDepthLabels[i]) {
    const depthLabel = collapsedDepthLabels[i];

    // Calculate max radius of labels with same depth
    let maxRadius = depthLabel.radius ?? maxX;
    let j = i + 1;
    while (
      collapsedDepthLabels[j] &&
      collapsedDepthLabels[j].depth - depthLabel.depth < 0.01
    ) {
      const radius = collapsedDepthLabels[j].radius ?? maxX;
      if (radius > maxRadius) {
        maxRadius = radius;
      }
      j++;
    }

    // Set gridX and gridY of all labels with same depth
    const gridX = diameterToGridOffset(maxRadius);
    const gridY = depthToGridOffset(depthLabel.depth);
    for (let k = i; k < j; k++) {
      collapsedDepthLabels[k].gridX = gridX;
      collapsedDepthLabels[k].gridY = gridY;
    }

    i = j;
  }

  // Move items around untill no labels overlap.
  let moved = true;
  while (moved) {
    moved = false;
    for (let i1 = 0; i1 < collapsedDepthLabels.length - 1; i1++) {
      for (let i2 = i1 + 1; i2 < collapsedDepthLabels.length; i2++) {
        const depthLabel1 = collapsedDepthLabels[i1];
        const depthLabel2 = collapsedDepthLabels[i2];

        const diffY = depthLabel2.gridY - depthLabel1.gridY;
        if (diffY < lineHeight) {
          const move = Math.round(lineHeight - diffY) || 1;
          depthLabel1.gridY -= move / 2;
          depthLabel2.gridY += move / 2;
          moved = true;
        }
      }
    }
  }

  // Draw depth labels
  const depthLabelsGroup = chart.append('g').attr('class', 'depthLabels');

  collapsedDepthLabels.forEach((depthLabelDescriptor) => {
    const {
      depth,
      depthLabel,
      depthColor,
      description,
      descriptionColor,
      gridX,
      gridY,
      fontSize,
      lineColor,
    } = depthLabelDescriptor;

    const depthLabelGroup = depthLabelsGroup
      .append('g')
      .attr('class', 'depthLabel');

    const linePart1X = gridX;
    const linePart2X = linePart1X + lineLengthPart1;
    const linePart3X = linePart2X + lineLengthPart2;
    const linePart4X = linePart3X + lineLengthPart3;

    // Draw text label
    const textGroup = depthLabelGroup
      .append('text')
      .attr('text-anchor', 'begin')
      .attr('alignment-baseline', 'central')
      .attr('x', linePart4X)
      .attr('y', gridY)
      .attr('stroke-width', 3)
      .attr('font-size', fontSize);

    textGroup
      .append('tspan')
      .attr('alignment-baseline', 'central')
      .attr('fill', descriptionColor)
      .attr('font-weight', 'bold')
      .text(`${description} `);

    textGroup
      .append('tspan')
      .attr('alignment-baseline', 'central')
      .attr('fill', depthColor)
      .text(depthLabel);

    // Draw line
    depthLabelGroup
      .append('path')
      .attr(
        'd',
        diagramLine([
          [gridX, depthToGridOffset(depth)],
          [linePart2X, depthToGridOffset(depth)],
          [linePart3X, gridY],
          [linePart4X, gridY],
        ]),
      )
      .attr('fill', 'none')
      .attr('stroke', lineColor);
  });
};
