import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tree, Text } from '@oliasoft-open-source/react-ui-library';
import {
  addInitialCategory,
  elementSelected,
  reorderElements,
} from '~store/entities/cost-setup/cost-setup';
import { FiFolder } from 'react-icons/fi';
import { FaRegFolderOpen } from 'react-icons/fa';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CostElementsList = ({
  projectId,
  elements,
  isAdding,
  setActiveForm,
  addInitialCategory,
  elementSelected,
  reorderElements,
  setSelectedId,
  setCostItemModalVisible,
  setCategoryModalVisible,
  onClickAddItem,
  isPageDisabled,
  setCategoryRenameModalVisible,
  showImportFromPriceBookModalUpdated,
}) => {
  const { t } = useTranslation();

  const onClickAddCategory = (parentId = null) => {
    addInitialCategory(projectId, t(translations.newCategory), parentId);
    setActiveForm(null);
  };
  const onClickCostItem = (index) => {
    elementSelected(index);
    setActiveForm('item');
  };
  const onClickDeleteItem = (id) => {
    setSelectedId(id);
    setCostItemModalVisible(true);
  };
  const onClickDeleteCategory = (id) => {
    setSelectedId(id);
    setCategoryModalVisible(true);
  };
  const onClickRenameCategory = (id) => {
    elementSelected(id);
    setCategoryRenameModalVisible(true);
  };
  const onClickImportFromPriceBook = ({ id }) => {
    setSelectedId(id);
    showImportFromPriceBookModalUpdated(true);
  };

  const elementsList = elements.map((item, i) => ({
    id: item.costCategoryId || item.costItemId,
    name: <Text muted={!!item.costCategoryId}>{item.name}</Text>,
    droppable: !!item.costCategoryId,
    active: item.active,
    parent: item.parentId || 0,
    itemType: item.costCategoryId ? 'category' : 'costItem',
    ...(item.costCategoryId ? null : { onClick: () => onClickCostItem(i) }),
    actions: [
      ...(item.costCategoryId
        ? [
            {
              label: t(translations.more),
              subActions: [
                {
                  label: t(translations.addItem),
                  icon: 'add',
                  onClick: () => onClickAddItem(item.costCategoryId),
                  disabled: isPageDisabled,
                },
                {
                  label: t(translations.addSubCategory),
                  icon: 'add',
                  onClick: () => onClickAddCategory(item.costCategoryId),
                  disabled: isPageDisabled,
                },
                {
                  label: t(translations.rename),
                  icon: 'edit',
                  onClick: () => onClickRenameCategory(i),
                  disabled: isPageDisabled,
                },
                {
                  label: t(translations.delete),
                  icon: 'delete',
                  onClick: () => onClickDeleteCategory(item.costCategoryId),
                  disabled: isPageDisabled,
                },
                {
                  label: t(translations.priceBook_importFromPriceBook),
                  onClick: () =>
                    onClickImportFromPriceBook({ id: item.costCategoryId }),
                  disabled: isAdding || isPageDisabled,
                  icon: 'upload',
                },
              ],
            },
          ]
        : [
            ...(item.vendor
              ? [
                  {
                    icon: 'link',
                    disabled: isPageDisabled,
                    tooltip: `Price Book: ${item.vendor}`,
                  },
                ]
              : []),
            {
              label: t(translations.delete),
              icon: 'delete',
              onClick: () => onClickDeleteItem(item.costItemId),
              disabled: isPageDisabled,
            },
          ]),
    ],
  }));

  return (
    <>
      <Tree
        icons={{
          expand: <FiFolder />,
          collapse: <FaRegFolderOpen />,
        }}
        itemHasChild={(e) => {
          return elements.some((element) => element.costCategoryId === e.id);
        }}
        list={{
          name: t(translations.costSetup_costSetup),
          actions: [
            {
              label: 'Manage',
              subActions: [
                {
                  label: t(translations.addItem),
                  onClick: () => onClickAddItem(),
                  disabled: isAdding || isPageDisabled,
                  icon: 'add',
                },
                {
                  label: t(translations.addCategory),
                  onClick: () => onClickAddCategory(),
                  disabled: isAdding || isPageDisabled,
                  icon: 'folder',
                },
                {
                  label: t(translations.costSetup_deleteAllCost),
                  onClick: () => onClickDeleteItem(null),
                  disabled: isAdding || isPageDisabled,
                  icon: 'delete',
                },
                {
                  label: t(translations.priceBook_importFromPriceBook),
                  onClick: onClickImportFromPriceBook,
                  disabled: isAdding || isPageDisabled,
                  icon: 'upload',
                },
              ],
            },
          ],
          items: elementsList,
        }}
        onItemToggle={() => {
          setActiveForm(null);
        }}
        draggable={!isPageDisabled}
        onListReorder={(reordered) => reorderElements(reordered, elements)}
        isInitialOpen={true}
      />
    </>
  );
};

const mapDispatchToProps = {
  elementSelected,
  addInitialCategory,
  reorderElements,
};

const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(CostElementsList),
);

export { Container as CostElementsList };
