import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  List,
  Drawer,
  ListSubheading,
  ListHeading,
  toast,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import {
  addInitialCampaign,
  campaignSelected,
  getCampaignResults,
} from '~store/entities/campaigns/campaigns';
import { useParams } from 'react-router';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CampaignsList = ({
  campaigns,
  addInitialCampaign,
  campaignSelected,
  campaignModalOpened,
  onClickDelete,
  withWelldesignLicense,
  setActiveTabIndex,
  getCampaignResults,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();

  const [estimatesExpanded, setEstimatesExpanded] = useState(true);
  const [designsExpanded, setDesignsExpanded] = useState(true);

  const handleOnClickCampaign = (campaign) => {
    campaignSelected(campaign.campaignId);
    const { campaignTotal, designs, estimates, campaignId } = campaign || {};
    if (
      campaignTotal === undefined &&
      (!designs || designs.length === 0) &&
      estimates?.length > 0
    ) {
      getCampaignResults(campaignId);
    }
  };

  const buildItems = (list, isConcept) =>
    list
      .filter((item) => item.isConcept === isConcept)
      .map((campaign) => ({
        id: campaign.campaignId,
        name: campaign.name,
        active: campaign.active,
        onClick: () => handleOnClickCampaign(campaign),
        actions: [
          {
            label: t(translations.more),
            subActions: [
              {
                label: t(translations.edit),
                icon: 'edit',
                onClick: () => campaignModalOpened(campaign.campaignId),
              },
              {
                label: t(translations.delete),
                icon: 'delete',
                onClick: () => onClickDelete(campaign.campaignId),
              },
            ],
          },
        ],
      }));

  const estimateItems = buildItems(campaigns, false);
  const conceptItems = buildItems(campaigns, true);

  return (
    <Drawer border button closedWidth="100px" open width="320px">
      <ListHeading name={t(translations.campaigns)} />
      <ListSubheading
        item={{
          name: t(translations.campaigns_campaignForecastEstimates),
          metaCount: estimateItems.length,
          expanded: estimatesExpanded,
          onClick: () => setEstimatesExpanded(!estimatesExpanded),
          actions: [
            {
              label: t(translations.add),
              primary: true,
              icon: 'add',
              onClick: () => {
                setActiveTabIndex(0);
                addInitialCampaign(
                  company,
                  t(translations.campaigns_newCampaign),
                );
              },
            },
          ],
        }}
      />
      {estimatesExpanded && <List noHeader list={{ items: estimateItems }} />}
      <ListSubheading
        item={{
          name: t(translations.campaigns_campaignWelldesignDesigns),
          metaCount: conceptItems.length,
          expanded: designsExpanded,
          onClick: () => setDesignsExpanded(!designsExpanded),
          actions: [
            {
              label: t(translations.add),
              primary: true,
              icon: 'add',
              onClick: () =>
                withWelldesignLicense
                  ? addInitialCampaign(
                      company,
                      t(translations.campaigns_newCampaign),
                      true,
                    )
                  : toast({
                      message: {
                        type: 'Error',
                        content: t(
                          translations.thisFeatureRequiresAWellDesignLicense,
                        ),
                      },
                    }),
            },
          ],
        }}
      />
      {designsExpanded && <List noHeader list={{ items: conceptItems }} />}
    </Drawer>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    authorization: { withWelldesignLicense },
  } = entities;
  return {
    withWelldesignLicense,
  };
};

const mapDispatchToProps = {
  addInitialCampaign,
  campaignSelected,
  getCampaignResults,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CampaignsList),
  { style: { width: '400px' } },
);

export { Container as CampaignsList };
