import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
  Heading,
  Flex,
  Spacer,
  Text,
} from '@oliasoft-open-source/react-ui-library';
import { Select, CheckBoxList } from '~common/form-inputs';
import { getResolver } from '~src/validation/resolver';
import { settingsOperationsImportSchemaValidator } from '~schemas/ajv-validators';
import translations from '~src/internationalisation/translation-map.json';

const ImportOperationModal = ({
  showImportOperationModalUpdated,
  activeTemplate,
  activeSection,
  templatesOperations,
  templateOptions,
  isAdding,
  settings,
  importOperations,
  sectionTypes,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      template: null,
      operations: [],
    },
    resolver: getResolver(settingsOperationsImportSchemaValidator),
    mode: 'onChange',
  });
  const onClose = () => showImportOperationModalUpdated(false);
  const onSubmit = handleSubmit((data) => {
    importOperations(data, activeTemplate, activeSection, settings, companyId);
  });
  const operations = templatesOperations[watch('template')];

  const operationsBySectionType = operations
    ? sectionTypes
        .map((section) => {
          const operationsForSection = operations?.filter(
            (operation) =>
              operation.linkedSectionType === section.linkedSectionId,
          );

          return {
            linkedSectionId: section.linkedSectionId,
            sectionName: section.sectionName,
            sectionTypeName: section.sectionTypeName,
            operations: operationsForSection,
          };
        })
        .filter((section) => section.operations.length > 0)
    : [];

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          width: '600px',
          heading: t(translations.settings_importFromTemplate),
          content: (
            <div style={{ position: 'relative' }}>
              <form>
                <Field label={t(translations.ruleset)}>
                  <Select
                    options={templateOptions}
                    name="template"
                    control={control}
                    errors={errors}
                  />
                </Field>
                <Flex justifyContent="space-between">
                  <Heading>{t(translations.activityModel_operations)}</Heading>
                  <div>
                    <Button
                      label={t(translations.checkAll)}
                      onClick={() =>
                        setValue(
                          'operations',
                          operations.map((operation) => operation.id),
                        )
                      }
                      small
                    />
                    <Button
                      label={t(translations.uncheckAll)}
                      onClick={() => setValue('operations', [])}
                      small
                    />
                  </div>
                </Flex>
                <Spacer height="var(--padding-xs)" />
                {(!operations || operations.length === 0) && (
                  <>
                    <Text faint>
                      {t(
                        translations.activityModel_noOperationsHaveBeenAddedYet,
                      )}
                    </Text>
                    <Spacer height="var(--padding-xs)" />
                  </>
                )}
                {operationsBySectionType?.map((section) => {
                  return (
                    <div key={section.linkedSectionId}>
                      <Spacer height="var(--padding-xs)" />
                      <Text faint>{`${section.sectionName} ${
                        section.sectionTypeName ? section.sectionTypeName : ''
                      }`}</Text>
                      <CheckBoxList
                        items={section.operations}
                        control={control}
                        errors={errors}
                        name="operations"
                      />
                    </div>
                  );
                })}
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label={t(translations.activityModel_importSelectedOperations)}
                colored
                onClick={onSubmit}
                disabled={isAdding}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }, { entitySettings }) => {
  const {
    isAdding,
    settings: { templates },
  } = entitySettings;
  const { sectionTypes } = entities.userSettings;
  const templateOptions = templates.map((template, i) => ({
    label: template.highLevel.name,
    value: i,
  }));
  const templatesOperations = templates.map((template) =>
    template.activityModel.map((activity) => ({
      id: activity.id,
      name: activity.name,
      linkedSectionType: activity.linkedSectionType,
    })),
  );

  return {
    templateOptions,
    templatesOperations,
    isAdding,
    sectionTypes,
  };
};

const Container = connect(mapStateToProps)(ImportOperationModal);

export { Container as ImportOperationModal };
