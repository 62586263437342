/* eslint-disable no-restricted-properties */
/* eslint-disable camelcase */
/**
 * @typedef {object} Ellipsoid
 * @property {number} rf - Inverse flattening
 * @property {number} a - Semi-major axis
 */

/**
 * Compute the scale factor for an oblique mercator projection at a point
 * following the method described in (Snyder, 1987).
 * @param {number} lat - Latitude of point where we computing grid convergence in radians
 * @param {number} lon - Longitude in radians
 * @param {number} lat_0 - Latitude of projection center in radians
 * @param {number} lon_c -  Longitude of projection center in radians
 * @param {number} alpha_c - Azimuth of initial line east of north
 * @param {number} k_0 - Scale factor at projection center
 * @param {Ellipsoid} ellipsoid - Underlying ellipsoid used in projection
 * @returns {number} k - scale factor
 */
const obliqueScaleFactor = (
  lat,
  lon,
  lat_0,
  lon_c,
  alpha_c,
  k_0,
  ellipsoid,
) => {
  // TODO: @GIS-V2 - function is currently not in use, stays for reference
  const { semiMajorAxis, inverseFlattening } = ellipsoid;
  const f = 1 / inverseFlattening;
  const esq = (2 - f) * f;
  const e = Math.sqrt(esq);
  const B = Math.sqrt(1 + (e ** 2 * Math.cos(lat_0) ** 4) / (1 - esq));
  const A =
    (semiMajorAxis * B * k_0 * Math.sqrt(1 - e ** 2)) /
    (1 - e ** 2 * Math.sin(lat_0) ** 2);
  const t_0 =
    Math.tan(Math.PI / 4 - lat_0 / 2) /
    Math.pow((1 - e * Math.sin(lat_0)) / (1 + e * Math.sin(lat_0)), e / 2);
  const D_ =
    (B * Math.sqrt(1 - e ** 2)) /
    (Math.cos(lat_0) * Math.sqrt(1 - e ** 2 * Math.sin(lat_0) ** 2));
  const D = D_ * D_ < 1 ? 1 : D_;
  const F = D + Math.sqrt(D ** 2 - 1) * Math.sign(lat_0);
  const H = F * Math.pow(t_0, B);
  const G = (F - 1 / F) / 2;
  const gamma_0 = Math.asin(Math.sin(alpha_c) / D);
  const lon_0 = lon_c - Math.asin(G * Math.tan(gamma_0)) / B;

  const t =
    Math.tan(Math.PI / 4 - lat / 2) /
    Math.pow((1 - e * Math.sin(lat)) / (1 + e * Math.sin(lat)), e / 2);
  const Q = H * Math.pow(t, -B);
  const S = (Q - 1 / Q) / 2;
  const V = Math.sin(B * (lon - lon_0));

  const u =
    (A *
      Math.atan2(
        S * Math.cos(gamma_0) + V * Math.sin(gamma_0),
        Math.cos(B * (lon - lon_0)),
      )) /
    B;

  const k =
    (A * Math.cos((B * u) / A) * Math.sqrt(1 - esq * Math.sin(lat) ** 2)) /
    (semiMajorAxis * Math.cos(lat) * Math.cos(B * (lon - lon_0)));
  return k;
};

export default obliqueScaleFactor;
