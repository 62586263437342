const baseGuideUrl = 'https://documentation.oliasoft.com';

const links = {
  projects: [
    {
      translationKey: 'helpMenu_gettingStarted',
      link: `${baseGuideUrl}`,
    },
  ],
};

export { links };

export default links;
