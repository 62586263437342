import i18n from 'i18next';
import { itemFields } from '~src/enums/time-tracker';
import translations from '~src/internationalisation/translation-map.json';

export const nptWowValidator = (field, timeTrackerItem) => {
  if (field === itemFields.NPT || field === itemFields.WOW) {
    if (timeTrackerItem.npt || timeTrackerItem.wow) {
      return +timeTrackerItem.npt + +timeTrackerItem.wow >=
        timeTrackerItem.actual
        ? i18n.t(translations.timeTracker_nptWowValidation)
        : '';
    }
  } else return '';
};
