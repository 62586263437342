import React, { useState } from 'react';
import { Icon } from '@oliasoft-open-source/react-ui-library';
import { GenericDrawer } from '~common/components/generic-drawer/generic-drawer';
import { casingRenderTab } from '~src/common/components/casing-renderer/casing-renderer-tab';
import { getNotesTab } from '~src/common/components/notes/get-notes-tab';
import { NoteModule } from '~src/enums/notes-enums';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { OffsetWells } from './offset-wells/offset-wells';

const RawWellDetailsDrawer = () => {
  const [drawerWidth, setDrawerWidth] = useState(400);
  const drawerProps = {
    position: 'right',
    width: drawerWidth,
    defaultTabIndex: 1,
    tabs: [
      {
        icon: <Icon icon="target" />,
        content: <OffsetWells />,
      },
      casingRenderTab,
      getNotesTab(NoteModule.WellDetails),
    ],
    onResize: (width) => setDrawerWidth(width),
  };
  return <GenericDrawer {...drawerProps} />;
};

export const WellDetailsDrawer = withErrorBoundary(RawWellDetailsDrawer, {
  isRightSideBar: true,
});
