import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Heading,
  Field,
  Spacer,
  InputGroup,
} from '@oliasoft-open-source/react-ui-library';
import { initialOperation } from '~store/entities/user-settings/user-settings';
import { getCodesList } from '~store/entities/activity-model/activity-model';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import translations from '~src/internationalisation/translation-map.json';
import { useForm } from 'react-hook-form';
import { getResolver } from '~src/validation/resolver';
import { settingsOperationsSchemaValidator } from '~schemas/ajv-validators';
import { Select, TextArea, InputWithVariable } from '~common/form-inputs';
import {
  convertToInputUnits,
  convertToStorageUnits,
} from '~common/units/units';

const AddOperationFromSectionsDataModal = ({
  setModalVisible,
  unitSettings,
  codes,
  operationCodesList,
  addOperationFromSectionsData,
  settings,
  isAdding,
  operations,
  activeSection,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();
  const activeOperations = operations.filter(
    (operation) => operation.sectionId === activeSection.sectionId,
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: { ...initialOperation, name: activeOperations[0]?.name },
    resolver: getResolver(settingsOperationsSchemaValidator),
  });

  useEffect(() => {
    const operationData = {
      ...initialOperation,
      name: activeOperations[0]?.name,
    };
    const convertedOperation = convertToInputUnits(
      operationData,
      ['from', 'to'],
      'm',
      unitSettings.length,
      true,
    );
    reset(convertedOperation);
  }, [reset, unitSettings?.length]);

  const onSubmit = handleSubmit((data) => {
    const convertedOperation = convertToStorageUnits(data, ['from', 'to'], {
      from: 'm',
      to: 'm',
    });

    addOperationFromSectionsData(
      convertedOperation,
      settings,
      activeSection.sectionId,
      companyId,
    );
  });
  const onClose = () => setModalVisible(false);

  const operationCode = watch('operationCode');
  const subOperationCodes = operationCode
    ? getCodesList('subOperation', codes, operationCode)
    : [];
  const operationsList = activeOperations.map((operation) => ({
    label: operation.name,
    value: operation.name,
  }));

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.settings_addFromDataStructure),
          content: (
            <>
              <form>
                <Field label={t(translations.activityModel_operationName)}>
                  <Select
                    name="name"
                    control={control}
                    errors={errors}
                    options={operationsList}
                  />
                </Field>
                <Field
                  label={t(translations.activityModel_operationCode)}
                  labelWidth="120px"
                  labelLeft
                >
                  <Select
                    name="operationCode"
                    control={control}
                    errors={errors}
                    options={operationCodesList}
                    onChange={() => {
                      setValue('subOperationCode', null);
                    }}
                  />
                </Field>
                <Field
                  label={t(translations.activityModel_subOperationCode)}
                  labelWidth="120px"
                  labelLeft
                >
                  <Select
                    name="subOperationCode"
                    control={control}
                    errors={errors}
                    options={subOperationCodes}
                  />
                </Field>
                <Spacer />
                <Heading>{t(translations.depth)}</Heading>
                <Spacer />
                <InputGroup>
                  <Field label={t(translations.from)}>
                    <InputWithVariable
                      name="from"
                      control={control}
                      unitkey={'length'}
                      unit={unitSettings?.length}
                      errors={errors}
                    />
                  </Field>
                  <Spacer width="var(--padding-sm)" />
                  <Field label={t(translations.to)}>
                    <InputWithVariable
                      name="to"
                      control={control}
                      unitkey={'length'}
                      unit={unitSettings?.length}
                      errors={errors}
                    />
                  </Field>
                </InputGroup>
                <Field label={t(translations.comments)}>
                  <TextArea
                    name="comments"
                    control={control}
                    errors={errors}
                    rows={5}
                  />
                </Field>
              </form>
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.save)}
                colored
                onClick={onSubmit}
                disabled={isAdding}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    userSettings: { codes },
  } = entities;
  const { operations } = selectSectionsDataStructure(entities);

  return {
    codes,
    operations,
    operationCodesList: getCodesList('operation', codes),
  };
};

const Container = connect(mapStateToProps)(AddOperationFromSectionsDataModal);

export { Container as AddOperationFromSectionsDataModal };
