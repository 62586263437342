import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import { removeCompare } from '~store/entities/compare-estimates/compare-estimates';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const DeleteCompareModal = ({ setModalVisible, selectedId, removeCompare }) => {
  const { t } = useTranslation();
  const onClose = () => setModalVisible(false);
  const onDelete = () => {
    removeCompare(selectedId);
    setModalVisible(false);
    localStorage.removeItem('activeProject');
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: `${t(translations.delete)} ${t(translations.compare)}`,
          content: (
            <>
              {t(translations.projects_areYouSureYouWantToDeleteThis, {
                selectedList: t(translations.compare),
              })}
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = { removeCompare };
const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(DeleteCompareModal),
  { isModal: true },
);

export { Container as DeleteCompareModal };
