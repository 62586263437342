import Leaflet from 'leaflet';
import { transformLocationObject } from '~common/gis/transform-location-object';
import { getDatumEPSG, isOffsetType } from '~common/gis/gis-library-utils';
import { isNumeric } from '@oliasoft-open-source/units';

/**
 * SVG marker with given color
 *
 * @param {string} color
 */
export const markerIconSVGTemplate = (color) => `
  <svg width="25" height="41" xmlns="http://www.w3.org/2000/svg">
    <g fill="${
      color || '#277FCA'
    }" transform="matrix(0.06927095942679841,0,0,0.07972642179422267,-598.1872338276421,-1131.573960717005) ">
      <path d="m8815.91934,14297.61793c-39.429,0 -71.505,32.077 -71.505,71.505c0,39.429 32.077,71.505 71.505,71.505c39.428,0 71.505,-32.077 71.505,-71.505c0,-39.428 -32.076,-71.505 -71.505,-71.505z"/>
      <path d="m8815.91934,14194.33993c-97.893,0 -177.535,79.642 -177.535,177.535c0,40.042 28.089,106.034 83.486,196.143c40.502,65.88 81.577,121.48 81.987,122.033l12.062,16.289l12.062,-16.289c0.41,-0.553 41.485,-56.153 81.987,-122.033c55.397,-90.109 83.486,-156.101 83.486,-196.143c0,-97.893 -79.642,-177.535 -177.535,-177.535zm0,276.306c-55.98,0 -101.522,-45.543 -101.522,-101.522c0,-55.98 45.543,-101.522 101.522,-101.522s101.522,45.543 101.522,101.522c0,55.979 -45.542,101.522 -101.522,101.522z"/>
    </g>
  </svg>
`;

export const createMarkerIcon = (color) => {
  const icon = Leaflet.divIcon({
    className: 'marker',
    html: markerIconSVGTemplate(color),
    iconSize: [25, 41],
  });
  return icon;
};

/**
 * Generates marker for React Leaflet using GIS coordinates
 * @param {{
 *   coordinates: import('common/gis/gis.interfaces').ILocationObject,
 *   referenceCoordinates?: import('common/gis/gis.interfaces').ISiteLocationObject,
 *   name: string,
 *   color: string
 * }} markerPayload
 * @returns {{}|{markerColor: string, latitude: number, label: string, longitude: number}}
 */
export const generateMarker = ({
  coordinates,
  referenceCoordinates,
  name,
  color,
}) => {
  let x, y;

  if (isOffsetType(coordinates.epsgCode) && !referenceCoordinates?.epsgCode) {
    return {};
  }

  if (referenceCoordinates) {
    const { x: newX, y: newY } = transformLocationObject(
      coordinates,
      getDatumEPSG(referenceCoordinates.epsgCode),
      referenceCoordinates,
    );
    x = newX;
    y = newY;
  } else {
    const { x: newX, y: newY } = transformLocationObject(
      coordinates,
      getDatumEPSG(coordinates.epsgCode),
    );
    x = newX;
    y = newY;
  }

  if (!isNumeric(x) && !isNumeric(y)) {
    return {};
  }

  return {
    latitude: y,
    longitude: x,
    label: name,
    markerColor: color,
  };
};
