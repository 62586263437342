/**
 * @readonly
 * @enum {number}
 */
export const ObjectEditModeType = Object.freeze({
  Move: 0, // 3D translate
  Rotate: 1, // 3D rotation
  Scale: 2, // 3D scale
});

/**
 * @readonly
 * @enum {number}
 */
export const ComponentRenderingType = Object.freeze({
  Hidden: 0,
  Enabled: 1,
  Disabled: 2,
});
