import { IGrid, IMeta, casingRenderStyle } from '../utils/types';

export const calculateGrid = (meta: IMeta): IGrid => {
  const { height, width, totalDepth, totalDiameter } = meta;

  const {
    axisColor,
    axisLabelColor,
    chartPaddingScalar,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
  } = casingRenderStyle;

  return {
    height: height - paddingBottom,
    width: width - paddingRight,
    minDepth: 0,
    maxDepth: totalDepth,
    minX: -totalDiameter,
    maxX: totalDiameter,
    axisColor,
    axisLabelColor,
    chartPaddingScalar,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
  };
};
