import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@oliasoft-open-source/react-ui-library';
import { connect } from 'react-redux';
import { selectPrimaryCurrency } from '~store/entities/company-settings/selectors';
import {
  displayNumber,
  round,
  cleanNum,
  roundToFixed,
} from '@oliasoft-open-source/units';
import translations from '~src/internationalisation/translation-map.json';
import { HOURS_PER_DAY } from '~src/enums/general';

const CombinedTable = ({ primaryCurrency, estimates, estimateSelected }) => {
  const { t } = useTranslation();
  const translationMap = {
    Time: t(translations.time),
    Cost: t(translations.cost),
  };

  const calculateCombinedValue = (data, isTime = null) => {
    if (data) {
      const sum = (arr) => {
        const allZeros = arr.every((num) => num === 0);
        if (allZeros) {
          return null;
        }
        return arr.reduce((acc, curr) => acc + curr, 0);
      };

      return isTime
        ? displayNumber(roundToFixed(sum(data) / HOURS_PER_DAY, 1))
        : displayNumber(round(sum(data), 0), { scientific: false });
    } else return null;
  };

  const tableSubHeaderName = (name) => {
    return <span className="table-subheader-name">{name}</span>;
  };
  const generateRows = (data) => {
    return data?.map((row) => {
      const arr = [
        { value: row.name },
        {
          value: calculateCombinedValue(
            row.simulationResult?.operation_durations?.durations['10'],
            true,
          ),
        },

        {
          value: calculateCombinedValue(
            row.simulationResult?.operation_costs?.costs['10'],
          ),
        },
        {
          value: calculateCombinedValue(
            row.simulationResult?.operation_durations?.durations['50'],
            true,
          ),
        },

        {
          value: calculateCombinedValue(
            row.simulationResult?.operation_costs?.costs['50'],
          ),
        },
        {
          value: calculateCombinedValue(
            row.simulationResult?.operation_durations?.durations['90'],
            true,
          ),
        },

        {
          value: calculateCombinedValue(
            row.simulationResult?.operation_costs?.costs['90'],
          ),
        },
      ];
      return {
        cells: arr,
      };
    });
  };
  const [sort, setSort] = useState({ Section: 'up' });
  const createdRowsFromTheEstimates = generateRows(estimates);

  const headings = [
    'Name',
    'P10 Time',
    'P10 Cost',
    'P50 Time',
    'P50 Cost',
    'P90 Time',
    'P90 Cost',
  ];

  const keyedData = createdRowsFromTheEstimates?.map((item) => {
    const obj = {};
    item.cells.forEach((cell, index) => {
      obj[headings[index]] = cell.value;
    });
    return obj;
  });
  const sortDataRows = (dataRows, sort) =>
    dataRows.sort((a, b) =>
      Object.entries(sort)
        .map(([key, value]) => {
          switch (value) {
            case 'up': {
              return a[key] - b[key];
            }
            case 'down': {
              return b[key] - a[key];
            }
            default:
              return 0;
          }
        })
        .reduce((a, acc) => a || acc, 0),
    );

  const dataHeaders = (dataRowsKeys, sort, setSort) => {
    const dataSortCells = dataRowsKeys?.map((key) => {
      const sortDirection = Object.keys(sort).includes(key) ? sort[key] : '';
      const prettifyHeaderValue = key.split(' ')[1];
      return {
        key,
        value: translationMap[prettifyHeaderValue] || '',
        hasSort: prettifyHeaderValue ?? false,
        sort: sortDirection,
        onSort: () => {
          const newSortDirection = sortDirection === 'up' ? 'down' : 'up';
          setSort({ [key]: newSortDirection });
        },
      };
    });
    return {
      dataSortCells,
    };
  };

  const { dataSortCells } = dataHeaders(headings, sort, setSort);

  const sortedData = sortDataRows(keyedData, sort);

  const dataRows = [
    ...sortedData?.map((dataRow) => {
      const rowsCells = Object.entries(dataRow)?.map(([key, value]) => ({
        key,
        value,
      }));
      return {
        cells: rowsCells,
      };
    }),
  ];
  const highlightTheLowestInColumn = (data) => {
    const dataCopy = data.map((row) => ({
      cells: row.cells.map((cell) => ({ ...cell })),
    }));

    const transposedData = dataCopy[0].cells.map((_, colIndex) =>
      dataCopy.map((row) => row.cells[colIndex]),
    );

    transposedData.slice(1).forEach((column) => {
      const filterOutNullValues = column.filter(
        (cell) => cell.value !== null && cell.value !== '',
      );
      const minValue = Math.min(
        ...filterOutNullValues.map((cell) => cleanNum(cell.value)),
      );
      column.forEach((cell) => {
        if (cleanNum(cell.value) === minValue) {
          cell.style = { backgroundColor: 'var(--color-success-100)' };
        }
      });
    });
    return dataCopy;
  };

  const table = {
    columnAlignment: [
      'left',
      'right',
      'right',
      'right',
      'right',
      'right',
      'right',
    ],
    columnHeaderAlignments: [['center', 'center', 'center', 'center']],
    headers: [
      {
        cells: [
          {
            value: estimateSelected
              ? t(translations.estimate)
              : t(translations.design),
          },
          { value: 'P10', colSpan: 2 },
          { value: 'P50', colSpan: 2 },
          { value: 'P90', colSpan: 2 },
        ],
      },
      {
        cells: dataSortCells,
      },
      {
        cells: [
          { value: '' },
          {
            value: tableSubHeaderName('Days'),
          },
          {
            value: tableSubHeaderName(primaryCurrency),
          },
          {
            value: tableSubHeaderName('Days'),
          },
          {
            value: tableSubHeaderName(primaryCurrency),
          },
          {
            value: tableSubHeaderName('Days'),
          },
          {
            value: tableSubHeaderName(primaryCurrency),
          },
        ],
      },
    ],
    rows: highlightTheLowestInColumn(dataRows),
  };

  return (
    <>
      <Table table={table} />
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const primaryCurrency = selectPrimaryCurrency(entities);

  return {
    primaryCurrency,
  };
};

const Container = connect(mapStateToProps)(CombinedTable);

export { Container as CombinedTable };
