import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  Field,
  Button,
  Column,
  Message,
  Table,
  Spacer,
} from '@oliasoft-open-source/react-ui-library';
import { toNum } from '@oliasoft-open-source/units';
import { validateNumber } from '~src/validation/common/validate-number';
import {
  saveCurrencies,
  getCurrencies,
} from '~store/entities/company-settings/company-settings';
import translations from '~src/internationalisation/translation-map.json';
import { useParams } from 'react-router';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const Currency = ({
  settings,
  isAdding,
  additionalCurrencyOptions,
  getCurrencies,
  saveCurrencies,
}) => {
  const { t } = useTranslation();
  const [additionalCurrenciesList, setAdditionalCurrenciesList] = useState([]);
  const { company } = useParams();
  useEffect(() => {
    getCurrencies();
  }, []);

  const mappedAdditionalCurrencyOptions = additionalCurrencyOptions.map(
    (c) => ({
      ...c,
      disabled: additionalCurrenciesList.some(
        (cur) => cur.currency === c.value,
      ),
    }),
  );

  const additionalCurrenciesStore =
    settings.currencies?.additionalCurrencies || [];
  const isCurrenciesListChanged =
    JSON.stringify(additionalCurrenciesStore) !=
    JSON.stringify(additionalCurrenciesList);
  const hasErros = additionalCurrenciesList.some((cur) =>
    validateNumber(cur.exchangeRate),
  );

  useEffect(() => {
    setAdditionalCurrenciesList(additionalCurrenciesStore);
  }, [settings]);

  const onAddCurrency = () =>
    setAdditionalCurrenciesList([
      ...additionalCurrenciesList,
      {
        currency: mappedAdditionalCurrencyOptions.filter((o) => !o.disabled)[0]
          ?.value,
        exchangeRate: 100,
      },
    ]);

  const onChangeAdditionalCurrency = (ev, i) => {
    const newElement = [...additionalCurrenciesList];
    if (ev.target.name === 'currency') {
      newElement[i] = {
        ...newElement[i],
        currency: ev.target.value,
      };
    } else if (ev.target.name === 'exchangeRate') {
      newElement[i] = {
        ...newElement[i],
        exchangeRate: toNum(ev.target.value),
      };
    }
    setAdditionalCurrenciesList(newElement);
  };

  const onRemoveCurrency = (i) => {
    const newCurrencies = [...additionalCurrenciesList];
    newCurrencies.splice(i, 1);
    setAdditionalCurrenciesList(newCurrencies);
  };

  const onSubmit = () => {
    saveCurrencies(company, additionalCurrenciesList, settings);
  };

  return (
    <Column scroll padding spacing={0}>
      <Heading top>{t(translations.currency)}</Heading>
      <>
        <Field label={t(translations.projectSettings_primaryCurrency)}>
          <Message
            message={{
              content: '(USD) United States Dollar',
              type: 'Info',
              visible: true,
            }}
          />
        </Field>
        <Table
          table={{
            fixedWidth: '100%',
            columnAlignment: ['left', 'right', 'left'],
            headers: [
              {
                cells: [
                  {
                    value: t(translations.projectSettings_additionalCurrency),
                  },
                  {
                    value: t(translations.projectSettings_exchangeRate),
                    style: { width: '120px' },
                  },
                  { value: '', style: { width: '90px' } },
                ],
                actions: [
                  {
                    primary: true,
                    label: t(translations.add),
                    icon: 'add',
                    disabled: additionalCurrenciesList.length >= 10,
                    onClick: () => {
                      onAddCurrency();
                    },
                  },
                ],
              },
            ],
            rows: additionalCurrenciesList.map((element, i) => ({
              cells: [
                {
                  name: 'currency',
                  type: 'Select',
                  options: mappedAdditionalCurrencyOptions,
                  value: element.currency,
                  onChange: (ev) => {
                    onChangeAdditionalCurrency(ev, i);
                  },
                  autoLayerWidth: true,
                },
                {
                  name: 'exchangeRate',
                  type: 'Input',
                  error: validateNumber(element.exchangeRate),
                  value: element.exchangeRate,
                  onChange: (ev) => {
                    onChangeAdditionalCurrency(ev, i);
                  },
                },
                {
                  value: `= 1 USD`,
                  disabled: true,
                  style: {
                    opacity: '0.5',
                    cursor: 'default',
                  },
                },
              ],
              actions: [
                {
                  label: 'Delete',
                  icon: 'minus',
                  onClick: () => {
                    onRemoveCurrency(i);
                  },
                },
              ],
            })),
          }}
        />
        <Spacer />
        <Button
          label={t(translations.save)}
          onClick={onSubmit}
          disabled={isAdding || !isCurrenciesListChanged || hasErros}
          colored
        />
      </>
    </Column>
  );
};

const mapStateToProps = ({ entities }) => {
  const { isAdding, currenciesList, settings } = entities.companySettings;

  const additionalCurrencyOptions = currenciesList
    .filter((item) => item.code !== 'USD')
    .map((item) => ({
      label: `(${item.code}) ${item.name}`,
      value: item.code,
    }));

  return { isAdding, settings, additionalCurrencyOptions };
};
const mapDispatchToProps = { saveCurrencies, getCurrencies };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(Currency),
);

export { Container as Currency };
