import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { NumberInput as GuiNumberInput } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { onChangeNumberInput } from './helpers';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const RawNumberInput = ({
  name,
  control,
  errors,
  error = null,
  warning = null,
  width = null,
  onChange,
  disabled = false,
  placeholder = '',
  allowEmpty = true,
  left = true,
  small = false,
  ...inputProps
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange: fieldOnChange, ...props } = field;
        return (
          <GuiNumberInput
            {...props}
            {...inputProps}
            onChange={(e) => {
              onChangeNumberInput(e, fieldOnChange, onChange);
            }}
            error={
              translations[errors[name]]
                ? t(errors[name])
                : errors[name] || error
            }
            warning={warning}
            width={width}
            disabled={disabled}
            placeholder={placeholder}
            allowEmpty={allowEmpty}
            left={left}
            small={small}
          />
        );
      }}
    />
  );
};

export const NumberInput = withErrorBoundary(RawNumberInput);
