import React from 'react';
import { Controller } from 'react-hook-form';
import { ButtonGroup as GuiButtonGroup } from '@oliasoft-open-source/react-ui-library';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const RawButtonGroup = ({
  name,
  items,
  control,
  header = '',
  disabled = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange: fieldOnChange, ...props } = field;
        return (
          <GuiButtonGroup
            {...props}
            items={items}
            header={header}
            onSelected={(e) => {
              fieldOnChange(e);
            }}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export const ButtonGroup = withErrorBoundary(RawButtonGroup);
