import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
} from '@oliasoft-open-source/react-ui-library';
import { saveProjectHierarchy } from '~store/entities/projects/projects';
import { Input } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { sitesSchemaValidator } from '~schemas/ajv-validators';
import { project } from '~src/enums/projects';
import { isNameIdentical } from '../utils';

const SiteModal = ({
  setModalVisible,
  saveProjectHierarchy,
  activeField,
  editItem,
  setEditItem,
  isAdding,
  sites,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      field: activeField?.name,
      projectFieldId: activeField?.projectFieldId,
      name: editItem?.name || '',
      projectSiteId: editItem?.projectSiteId || null,
    },
    resolver: getResolver(sitesSchemaValidator),
  });
  const onSubmit = handleSubmit(async (data) => {
    if (
      isNameIdentical(
        data,
        sites,
        project.SITES_LIST,
        activeField,
        setError,
        'name',
      )
    ) {
      return;
    }
    delete data.field;
    await saveProjectHierarchy({ site: { ...data }, companyId: company });
    setModalVisible(false);
    setEditItem(false);
  });
  const onClose = () => {
    if (!isAdding) {
      setModalVisible(false);
      setEditItem(false);
    }
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: editItem
            ? t(translations.projects_editSite)
            : t(translations.projects_addNewSite),
          width: '600px',
          content: (
            <form>
              <Field label={t(translations.field)} labelLeft labelWidth="80px">
                <Input
                  name="field"
                  control={control}
                  errors={errors}
                  disabled={true}
                />
              </Field>
              <Field label={t(translations.site)} labelLeft labelWidth="80px">
                <Input
                  name="name"
                  control={control}
                  errors={errors}
                  disabled={isAdding}
                />
              </Field>
            </form>
          ),
          footer: (
            <>
              <Button
                label={t(translations.done)}
                colored
                onClick={onSubmit}
                loading={isAdding}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => ({
  isAdding: entities.projects.isAdding,
  sites: entities.projects.lists.sites,
});
const mapDispatchToProps = { saveProjectHierarchy };
const Container = connect(mapStateToProps, mapDispatchToProps)(SiteModal);

export { Container as SiteModal };
