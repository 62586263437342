import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListSubheading, List } from '@oliasoft-open-source/react-ui-library';
import { siteSelected } from '~store/entities/projects/projects';
import { project } from '~src/enums/projects';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const SitesList = ({
  sites,
  onClickDelete,
  setSiteModalVisible,
  setEditItem,
  siteSelected,
  addButtonDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ListSubheading
        item={{
          name: t(translations.site),
          actions: [
            {
              label: 'Add',
              icon: 'add',
              onClick: () => setSiteModalVisible(true),
              disabled: addButtonDisabled,
            },
          ],
        }}
      />
      <List
        testId="site-list"
        list={{
          items: sites.map((site) => ({
            id: site.projectSiteId,
            name: site.name,
            metadata: `${site.wellsCount} ${
              +site.wellsCount === 1
                ? t(translations.well)
                : t(translations.wells)
            }`,
            active: site.active,
            onClick: () => siteSelected(site.projectSiteId),
            actions: [
              {
                label: 'Update',
                icon: 'edit',
                onClick: () => {
                  setEditItem(site);
                  setSiteModalVisible(true);
                },
              },
              {
                label: 'Delete',
                icon: 'delete',
                onClick: () =>
                  onClickDelete(site.projectSiteId, project.SITES_LIST),
              },
            ],
          })),
        }}
        noHeader
      />
    </>
  );
};

const mapDispatchToProps = { siteSelected };

const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(SitesList),
);

export { Container as SitesList };
