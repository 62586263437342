import { useState } from 'react';
import {
  Table,
  Button,
  Spacer,
  Spinner,
  Icon,
  CheckBox,
} from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import { ConnectedProps, connect } from 'react-redux';
import { pick } from 'lodash';
import { HierarchyType } from '~src/enums/compare-estimates';
import { selectUserTempaltes } from '~src/store/entities/user-settings/selectors';
import { selectCompanyTemplates } from '~src/store/entities/company-settings/selectors';
import { settingEntity } from '~src/enums/settings';
import { useParams } from 'react-router';
import { simulationStatuses } from '~src/enums/simulations';
import type { ChangeEvent } from 'react';
import {
  runConceptCampaign,
  removeConceptDesign,
  removeEstimate,
} from '~src/store/entities/campaigns/campaigns';
import { selectSubjectId } from '~store/entities/authorization/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import {
  ICampaignActiveEstimate,
  ICampaignDesignItem,
  ICampaignEstimate,
  ITemplateMeta,
} from '~src/common/interfaces/campaigns.interfaces';
import { formatTimeToUI } from '~src/views/time-tracker/utils/date/dateUtils';
import { ITemplate } from '~src/common/interfaces/templates.interfaces';
import type { TRootState } from '~src/store/store-types';

interface IHierarchyInputTable extends PropsFromRedux {
  filteredEstimates: ICampaignEstimate[];
  welldesignModalUpdated: (value: boolean) => void;
  estimateModalUpdated: (value: boolean) => void;
  isConcept: boolean;
  conceptDesigns: ICampaignDesignItem[];
  name: string;
  activeEstimates: ICampaignActiveEstimate[];
  subjectId: string | null;
  campaignId: string;
  templates: ITemplateMeta[] | undefined;
}

interface ISelectedTemplate {
  datasetid: number;
  value: string;
  projectId?: string;
}

const HierarchyInputTable = ({
  filteredEstimates,
  welldesignModalUpdated,
  estimateModalUpdated,
  isConcept,
  removeEstimate,
  campaignId,
  templateOptions,
  removeConceptDesign,
  runConceptCampaign,
  name,
  activeEstimates,
  subjectId,
  conceptDesigns,
  templates,
}: IHierarchyInputTable) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [selectedPage, setSelectedPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedTemplate, setSelectedTemplate] = useState<ISelectedTemplate[]>(
    [],
  );

  const headerLabels = [
    ...(isConcept && conceptDesigns?.length > 0
      ? [
          {
            identifier: HierarchyType.UseExisting,
          },
        ]
      : []),
    ...(isConcept && conceptDesigns?.length > 0
      ? [
          {
            label: t(translations.compare_automationMethod),
            identifier: HierarchyType.AutomationMethod,
          },
        ]
      : []),
    {
      label: isConcept ? t(translations.design) : t(translations.estimate),
      identifier: HierarchyType.Name,
    },
    { label: t(translations.well), identifier: HierarchyType.WellName },
    { label: t(translations.wellbore), identifier: HierarchyType.WellboreName },
    { label: t(translations.site), identifier: HierarchyType.SiteName },
    { label: t(translations.country), identifier: HierarchyType.CountryName },
    ...(isConcept && conceptDesigns?.length > 0
      ? [{ label: t(translations.user), identifier: HierarchyType.UserName }]
      : []),
  ];

  const headerRow = headerLabels?.map(({ label, identifier }) => {
    let width;

    if (label === t(translations.compare_automationMethod)) {
      width = '20%';
    } else if (identifier === HierarchyType.UseExisting) {
      width = '4%';
    } else {
      width = 'auto';
    }
    return {
      value: label,
      width,
    };
  });

  const headerFilter = headerLabels?.map((header) => {
    const { identifier } = header;
    const filterValue = filters[identifier] ?? '';
    return header.identifier === HierarchyType.AutomationMethod ||
      header.identifier === HierarchyType.UseExisting
      ? {}
      : {
          key: identifier,
          value: filterValue,
          type: 'Input',
          placeholder: t(translations.search),
          onChange: (ev: React.ChangeEvent<HTMLInputElement>) => {
            setFilters((p) => ({
              ...p,
              [identifier]: ev.target.value,
            }));
          },
        };
  });

  const startIndex = (selectedPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const estimates: any[] =
    filteredEstimates?.length > 0 ? filteredEstimates : conceptDesigns;

  const filteredData = estimates?.filter(
    (design: ICampaignDesignItem | ICampaignEstimate) =>
      Object.keys(filters).every(
        (key) =>
          filters[key] === '' ||
          design[key as keyof typeof design]
            ?.toLowerCase()
            .includes(filters[key].toLowerCase()),
      ),
  );

  const rows = [
    ...filteredData?.map(
      (estimate: ICampaignDesignItem | ICampaignEstimate, idx: number) => {
        let rowCells = Object.entries(
          pick(estimate, [
            HierarchyType.Name,
            HierarchyType.WellName,
            HierarchyType.WellboreName,
            HierarchyType.SiteName,
            HierarchyType.CountryName,
            HierarchyType.UserName,
          ]),
        ).map((value) => ({
          value: value[1],
        }));

        if (isConcept && conceptDesigns?.length > 0) {
          const additionalCell = {
            options: templateOptions,
            value:
              selectedTemplate.find(
                (template) =>
                  template.datasetid ===
                  (estimate as ICampaignDesignItem).datasetId,
              )?.value || null,
            type: 'Select',
            placeholder: t(translations.compare_selectRuleset),
            onChange: (ev: ChangeEvent<HTMLSelectElement>) => {
              setSelectedTemplate((prev) => {
                const index = prev.findIndex(
                  (item) =>
                    item.datasetid ===
                    (estimate as ICampaignDesignItem).datasetId,
                );
                if (index !== -1) {
                  prev[index].value = ev.target.value;
                  return [...prev];
                } else {
                  return [
                    ...prev,
                    {
                      datasetid: (estimate as ICampaignDesignItem).datasetId,
                      value: ev.target.value,
                      projectId: '',
                    },
                  ];
                }
              });
            },
          };
          const index = selectedTemplate.findIndex(
            (template) =>
              template?.datasetid ===
              (estimate as ICampaignDesignItem)?.datasetId,
          );
          const existingEstimate = templates?.find(
            (template) =>
              template?.datasetId === selectedTemplate[index]?.datasetid &&
              template?.templateId === selectedTemplate[index]?.value &&
              template?.subjectId === subjectId,
          );
          const checked = selectedTemplate?.find(
            (item) => item?.projectId === existingEstimate?.projectId,
          );
          const checkBox: any = existingEstimate
            ? {
                value: (
                  <CheckBox
                    checked={checked}
                    helpText={
                      <>
                        {t(translations.campaigns_clickToUseExistingSimulation)}
                        <br />
                        <br />
                        {t(translations.campaigns_lastSimulation)}:{' '}
                        {formatTimeToUI(templates?.[index]?.created)}
                      </>
                    }
                    onChange={() => {
                      const newProjectId = checked
                        ? ''
                        : existingEstimate.projectId;
                      setSelectedTemplate((prev) =>
                        prev.map((item) =>
                          item.datasetid === existingEstimate.datasetId
                            ? { ...item, projectId: newProjectId }
                            : item,
                        ),
                      );
                    }}
                  />
                ),
              }
            : {};
          rowCells = [checkBox, additionalCell, ...rowCells];
        }

        return {
          cells: rowCells,
          actions: [
            {
              label: 'Delete',
              icon: 'minus',
              onClick: () =>
                isConcept && conceptDesigns?.length > 0
                  ? removeConceptDesign(
                      campaignId,
                      conceptDesigns[idx].datasetId,
                    )
                  : removeEstimate(
                      filteredEstimates[idx].campaignsEstimatesId,
                      filteredEstimates[idx].campaignId,
                    ),
            },
            isConcept && activeEstimates?.length > 0
              ? {
                  icon:
                    activeEstimates[idx]?.isFetching ||
                    (activeEstimates[idx]?.simulation?.status &&
                      activeEstimates[idx].simulation.status !==
                        simulationStatuses.FINISHED) ? (
                      <Spinner colored tiny />
                    ) : (
                      <Icon
                        color="var(--color-background-success)"
                        icon="success"
                      />
                    ),
                }
              : {},
          ],
        };
      },
    ),
  ];

  const filteredRows = rows.slice(startIndex, endIndex);

  const table = {
    fixedWidth: '100%',
    headers: [
      {
        cells: headerRow,
        actions: [
          !isConcept || conceptDesigns?.length > 0
            ? {
                primary: true,
                label: 'Add',
                icon: 'add',
                onClick: () =>
                  isConcept
                    ? welldesignModalUpdated(true)
                    : estimateModalUpdated(true),
              }
            : {},
        ],
      },
      { cells: headerFilter },
    ],
    rows: filteredRows,
    footer: {
      pagination: {
        rowCount: rows?.length,
        selectedPage,
        rowsPerPage: {
          onChange: (evt: React.ChangeEvent<HTMLSelectElement>) =>
            setRowsPerPage(Number(evt.target.value)),
          options: [
            { label: `10 / ${t(translations.page)}`, value: 10 },
            { label: `20 / ${t(translations.page)}`, value: 20 },
            { label: `50 / ${t(translations.page)}`, value: 50 },
          ],
          value: rowsPerPage,
        },
        onSelectPage: setSelectedPage,
        small: true,
      },
    },
  };
  const designs = selectedTemplate?.map((template) => template.datasetid);

  const showButton = designs?.length === estimates?.length;

  return (
    <>
      <Table table={table} />
      {isConcept && (
        <>
          <Spacer />
          <Button
            label={t(translations.confirmSelection)}
            colored
            disabled={!showButton}
            onClick={() => {
              setSelectedTemplate([]);
              runConceptCampaign({
                companyId: company,
                campaignId,
                designs,
                name,
                templates: selectedTemplate,
                wellDesignData: filteredData,
                subjectId,
              });
            }}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const userTemplates = selectUserTempaltes(entities);
  const companyTemplates = selectCompanyTemplates(entities);

  const subjectId = selectSubjectId(entities);

  const templateOptions = [
    {
      label: i18n.t(translations.userRulesets),
      type: 'Heading',
    },
    ...userTemplates.map((template: ITemplate, idx: number) => ({
      label: template.highLevel.name,
      value: `${settingEntity.USER}-${idx}`,
    })),
    {
      label: i18n.t(translations.companyRulesets),
      type: 'Heading',
    },
    ...companyTemplates?.map((template: ITemplate, idx: number) => ({
      label: template.highLevel.name,
      value: `${settingEntity.COMPANY}-${idx}`,
    })),
  ];

  return {
    templateOptions,
    subjectId,
  };
};

const mapDispatchToProps = {
  runConceptCampaign,
  removeConceptDesign,
  removeEstimate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(HierarchyInputTable));

export { Container as HierarchyInputTable };
