import { useEffect } from 'react';

/**
 * Auto save form data
 *
 * @param onSubmit
 * @param watch
 */
export const useAutoSave = (onSubmit, watch) => {
  useEffect(() => {
    const subscription = watch(onSubmit);
    return () => subscription.unsubscribe();
  }, [onSubmit, watch]);
};
