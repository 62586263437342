import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
} from '@oliasoft-open-source/react-ui-library';
import { Input } from '~common/form-inputs';
import {
  addCampaign,
  campaignModalClosed,
} from '~store/entities/campaigns/campaigns';
import { getResolver } from '~src/validation/resolver';
import { campaignSchemaValidator } from '~schemas/ajv-validators';
import translations from '~src/internationalisation/translation-map.json';

const CampaignModal = ({
  campaign,
  campaignModalClosed,
  isAdding,
  addCampaign,
  companyId,
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: getResolver(campaignSchemaValidator),
    mode: 'onChange',
  });
  useEffect(() => {
    const { campaignId, name } = campaign;
    reset({ campaignId, name });
  }, [campaign]);
  const onClose = () => campaignModalClosed();
  const onSubmit = handleSubmit((data) => {
    addCampaign(companyId, {
      ...data,
      isConcept: campaign.isConcept,
      designs: campaign.designs,
    });
    campaignModalClosed();
  });

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          width: '600px',
          heading: campaign.name,
          content: (
            <div style={{ position: 'relative' }}>
              <form>
                <Field label={t(translations.name)}>
                  <Input name="name" control={control} errors={errors} />
                </Field>
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label={t(translations.update)}
                colored
                onClick={onSubmit}
                disabled={isAdding}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const { isAdding } = entities.campaigns;

  return {
    isAdding,
  };
};

const mapDispatchToProps = {
  addCampaign,
  campaignModalClosed,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(CampaignModal);

export { Container as CampaignModal };
