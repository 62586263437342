import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  Button,
  Column,
  Table,
  Spacer,
} from '@oliasoft-open-source/react-ui-library';
import { saveTimeTracker } from '~store/entities/company-settings/company-settings';
import translations from '~src/internationalisation/translation-map.json';
import { useParams } from 'react-router';
import { probabilities } from '~src/enums/simulations';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const TimeTracker = ({ settings, isAdding, saveTimeTracker }) => {
  const { t } = useTranslation();
  const { timeTracker } = settings;
  const [timeTrackerSettings, setTimeTrackerSettings] = useState(timeTracker);
  const { company } = useParams();

  const timeTrackerStore = settings.timeTracker || [];
  const isTimeTrackerChanged =
    JSON.stringify(timeTrackerStore) != JSON.stringify(timeTrackerSettings);

  useEffect(() => {
    setTimeTrackerSettings(timeTracker);
  }, [settings]);

  const onChange = (ev, i) => {
    const { name, value } = ev.target;
    const updatedSettings = [...timeTrackerSettings];
    updatedSettings[i] = {
      ...updatedSettings[i],
      [name]: value,
    };
    setTimeTrackerSettings(updatedSettings);
  };

  const onSubmit = () => {
    saveTimeTracker(company, timeTrackerSettings, settings);
  };

  const timeVariableMap = {
    afe: 'AFE',
    estimate: 'DSV Estimate',
  };

  const probabilitiesOptions = Object.keys(probabilities).map((key) => ({
    label: key,
    value: probabilities[key],
  }));

  return (
    <Column scroll padding spacing={0}>
      <Heading top>{t(translations.timeTracker_timeTracker)}</Heading>
      <>
        <Table
          table={{
            fixedWidth: '100%',
            columnAlignment: ['left', 'left', 'left'],
            headers: [
              {
                cells: [
                  {
                    value: t(translations.timeTracker_timeVariable),
                  },
                  {
                    value: t(translations.timeTracker_columnLalel),
                  },
                  {
                    value: t(translations.timeTracker_percentile),
                  },
                ],
              },
            ],
            rows: timeTrackerSettings?.map((item, i) => ({
              cells: [
                {
                  value: timeVariableMap[item.timeVariable],
                  disabled: true,
                },
                {
                  name: 'label',
                  type: 'Input',
                  value: item.label,
                  onChange: (e) => onChange(e, i),
                },
                {
                  name: 'percentile',
                  type: 'Select',
                  options: probabilitiesOptions,
                  width: '120px',
                  value: item.percentile,
                  onChange: (e) => onChange(e, i),
                },
              ],
            })),
          }}
        />
        <Spacer />
        <Button
          label={t(translations.save)}
          onClick={onSubmit}
          disabled={isAdding || !isTimeTrackerChanged}
          colored
        />
      </>
    </Column>
  );
};

const mapStateToProps = ({ entities }) => {
  const { isAdding, settings } = entities.companySettings;

  return { isAdding, settings };
};
const mapDispatchToProps = { saveTimeTracker };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(TimeTracker),
);

export { Container as TimeTracker };
