import { ConnectedProps, connect } from 'react-redux';
import { ListSubheading, List } from '@oliasoft-open-source/react-ui-library';
import { operationSelected } from '~src/store/entities/sections-data-structure/sections-data-structure';
import {
  IOperation,
  ISection,
} from '~src/common/interfaces/sections-data-structure.interfaces';
import translations from '~src/internationalisation/translation-map.json';
import { useTranslation } from 'react-i18next';

interface OperationsListProps extends PropsFromRedux {
  activeSection: ISection;
  sectionOperations: IOperation[];
}

const OperationsList = ({
  activeSection,
  sectionOperations,
  operationSelected,
}: OperationsListProps) => {
  const { t } = useTranslation();
  const operationsList = sectionOperations.map((operation: IOperation) => ({
    id: operation.sectionsOperationId,
    name: operation.name,
    active: operation.active,
    onClick: () => operationSelected(operation.sectionsOperationId),
    metaCount: operation.metaCount,
  }));
  return (
    <>
      <ListSubheading
        item={{
          name: `${activeSection.name}: ${t(translations.operations)}`,
        }}
      />
      <List
        list={{
          items: operationsList,
        }}
        noHeader
      />
    </>
  );
};

const mapDispatchToProps = { operationSelected };

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = connector(OperationsList);

export { Container as OperationsList };
