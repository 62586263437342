import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListSubheading, List } from '@oliasoft-open-source/react-ui-library';
import { wellboreSelected } from '~store/entities/projects/projects';
import { project } from '~src/enums/projects';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const WellboresList = ({
  wellbores,
  onClickDelete,
  setWellboreModalVisible,
  setEditItem,
  wellboreSelected,
  addButtonDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ListSubheading
        item={{
          name: t(translations.wellbore),
          actions: [
            {
              label: 'Add',
              icon: 'add',
              onClick: () => setWellboreModalVisible(true),
              disabled: addButtonDisabled,
            },
          ],
        }}
      />
      <List
        testId="wellbore-list"
        list={{
          items: wellbores.map((wellbore) => ({
            id: wellbore.projectWellboreId,
            name: wellbore.name,
            metadata: `${wellbore.designsCount} ${
              +wellbore.designsCount === 1
                ? t(translations.design)
                : t(translations.designs)
            }`,
            active: wellbore.active,
            onClick: () => wellboreSelected(wellbore.projectWellboreId),
            actions: [
              {
                label: 'Update',
                icon: 'edit',
                onClick: () => {
                  setEditItem(wellbore);
                  setWellboreModalVisible(true);
                },
              },
              {
                label: 'Delete',
                icon: 'delete',
                onClick: () =>
                  onClickDelete(
                    wellbore.projectWellboreId,
                    project.WELLBORES_LIST,
                  ),
              },
            ],
          })),
        }}
        noHeader
      />
    </>
  );
};

const mapDispatchToProps = { wellboreSelected };

const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(WellboresList),
);

export { Container as WellboresList };
