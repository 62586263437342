import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { TextArea as GuiTextArea } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const RawTextArea = ({
  name,
  control,
  errors,
  rows = 10,
  cols = 20,
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { ...props } = field;
        return (
          <GuiTextArea
            {...props}
            error={
              translations[errors[name]]
                ? t(errors[name])
                : errors[name] || null
            }
            resize="none"
            rows={rows}
            cols={cols}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export const TextArea = withErrorBoundary(RawTextArea);
