import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
  InputGroup,
  Spacer,
  Loader,
  Spinner,
  readFile,
  Divider,
  RadioButton,
} from '@oliasoft-open-source/react-ui-library';
import {
  saveProjectHierarchy,
  formatProjectData,
} from '~store/entities/projects/projects';
import { Input, Select } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { projectsSchemaValidator } from '~schemas/ajv-validators';
import { createEstimateOptions, project } from '~src/enums/projects';
import { generatePath, navigateToPath } from '~store/navigation/navigation';
import { routes } from '~routes/routes';
import { FileInput } from '~common/form-inputs/file-input';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { isNameIdentical } from './utils';

const CreateModal = ({
  setModalVisible,
  saveProjectHierarchy,
  countriesOptions,
  existingCountriesOptions,
  lists,
  isAdding,
  navigateToPath,
  isImport,
  labelWidth = '50px',
  inputWidth = 495,
  selectWidth = 460,
  existingEstimateOptions,
  activeCountry,
  activeField,
  activeSite,
  activeWell,
  activeWellbore,
  activeDesign,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const createOptions = [
    { label: 'Create new', value: createEstimateOptions.NEW },
    { label: 'Copy existing', value: createEstimateOptions.COPY },
    { label: 'File import', value: createEstimateOptions.IMPORT },
  ];
  const [createOptionsValue, setCreateOptionsValue] = useState(
    createOptions[0],
  );
  const createOptionsOnChange = (e) => {
    const { value } = e.target;
    setCreateOptionsValue({ value });
  };
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    trigger,
    formState: { errors },
    formState,
  } = useForm({
    defaultValues: {
      projectData: null,
      country: null,
      existingCountry: null,
      field: '',
      existingField: null,
      site: '',
      existingSite: null,
      well: '',
      existingWell: null,
      wellbore: '',
      existingWellbore: null,
      design: '',
      existingDesign: null,
      estimate: '',
      existingEstimate: null,
    },
    resolver: getResolver(projectsSchemaValidator),
    mode: 'onChange',
  });

  const [showCountry, setShowCountry] = useState(false);
  const [showField, setShowField] = useState(false);
  const [showSite, setShowSite] = useState(false);
  const [showWell, setShowWell] = useState(false);
  const [showWellbore, setShowWellbore] = useState(false);
  const [showDesign, setShowDesign] = useState(false);
  const [file, setFile] = useState(null);

  const clearFields = (level) => {
    if (level === project.COUNTRIES_LIST) {
      setValue('field', '');
      setValue('existingField', null);
      setValue('site', '');
      setValue('existingSite', null);
      setValue('well', '');
      setValue('existingWell', null);
      setValue('wellbore', '');
      setValue('existingWellbore', null);
      setValue('design', '');
      setValue('existingDesign', null);
    }

    if (level === project.FIELDS_LIST) {
      setValue('site', '');
      setValue('existingSite', null);
      setValue('well', '');
      setValue('existingWell', null);
      setValue('wellbore', '');
      setValue('existingWellbore', null);
      setValue('design', '');
      setValue('existingDesign', null);
    }

    if (level === project.SITES_LIST) {
      setValue('well', '');
      setValue('existingWell', null);
      setValue('wellbore', '');
      setValue('existingWellbore', null);
      setValue('design', '');
      setValue('existingDesign', null);
    }

    if (level === project.WELLS_LIST) {
      setValue('wellbore', '');
      setValue('existingWellbore', null);
      setValue('design', '');
      setValue('existingDesign', null);
    }

    if (level === project.WELLBORES_LIST) {
      setValue('design', '');
      setValue('existingDesign', null);
    }
  };

  useEffect(() => {
    const estimate = lists.estimates.find((item) => item.active);
    if (estimate) {
      navigateToPath(
        generatePath(
          routes.overview,
          {
            company: estimate.companyId,
            project: estimate.projectId,
          },
          true,
        ),
      );
    }
  }, [lists.estimates]);

  useEffect(() => {
    if (formState.errors.field) {
      setShowField(true);
    }
    if (formState.errors.site) {
      setShowSite(true);
    }
    if (formState.errors.well) {
      setShowWell(true);
    }
    if (formState.errors.wellbore) {
      setShowWellbore(true);
    }
    if (formState.errors.design) {
      setShowDesign(true);
    }
  }, [formState]);

  const projectData = watch('projectData');
  // Preselect hierarchy on import from file
  useEffect(() => {
    if (
      createOptionsValue.value === createEstimateOptions.IMPORT &&
      projectData
    ) {
      clearFields(project.COUNTRIES_LIST);
      if (
        projectData?.hierarchy &&
        lists.countries.find(
          (country) =>
            country.projectCountryId === projectData.hierarchy.projectCountryId,
        )
      ) {
        const {
          projectCountryId,
          projectFieldId,
          projectSiteId,
          projectWellId,
          projectWellboreId,
          projectDesignId,
        } = projectData.hierarchy;
        setValue('existingCountry', projectCountryId);
        setValue('existingField', projectFieldId);
        setValue('existingSite', projectSiteId);
        setValue('existingWell', projectWellId);
        setValue('existingWellbore', projectWellboreId);
        setValue('existingDesign', projectDesignId);
      } else if (
        lists.countries.find(
          (country) =>
            country.projectCountryId === activeCountry?.projectCountryId,
        )
      ) {
        setValue('existingCountry', activeCountry.projectCountryId);
        if (activeField) {
          setValue('existingField', activeField.projectFieldId);
        }
        if (activeSite) {
          setValue('existingSite', activeSite.projectSiteId);
        }
        if (activeWell) {
          setValue('existingWell', activeWell.projectWellId);
        }
        if (activeWellbore) {
          setValue('existingWellbore', activeWellbore.projectWellboreId);
        }
        if (activeDesign) {
          setValue('existingDesign', activeDesign.projectDesignId);
        }
      }
    }
  }, [projectData, createOptionsValue]);

  const onSubmit = handleSubmit(async (data) => {
    if (
      isNameIdentical(
        { ...data, name: data.estimate, projectDesignId: data.existingDesign },
        lists.estimates,
        project.ESTIMATES_LIST,
        { projectDesignId: data.existingDesign },
        setError,
        'estimate',
      )
    ) {
      return;
    }
    await saveProjectHierarchy(
      formatProjectData({ ...data, companyId: company }),
    );
    setModalVisible(false);
  });
  const onClose = () => setModalVisible(false);
  const onImportFile = async (e) => {
    const file = e.target.value;
    if (!file) {
      return;
    }

    try {
      const text = await readFile(file);
      const json = JSON.parse(text);

      setFile(file);
      setValue('projectData', json);
    } catch (error) {
      setError('projectFile', { type: 'custom', message: 'Invalid file' });
    }
  };

  const country = watch('country');
  const existingCountry = watch('existingCountry');
  const countryNotSelected = !country && !existingCountry;
  const field = watch('field');
  const existingField = watch('existingField');
  const fieldNotSelected = !field && !existingField;
  const site = watch('site');
  const existingSite = watch('existingSite');
  const siteNotSelected = !site && !existingSite;
  const well = watch('well');
  const existingWell = watch('existingWell');
  const wellNotSelected = !well && !existingWell;
  const wellbore = watch('wellbore');
  const existingWellbore = watch('existingWellbore');
  const wellboreNotSelected = !wellbore && !existingWellbore;

  const existingFieldsOptions = lists.fields
    .filter((item) => item.projectCountryId === existingCountry)
    .map((item) => ({ label: item.name, value: item.projectFieldId }));
  const existingSitesOptions = lists.sites
    .filter((item) => item.projectFieldId === existingField)
    .map((item) => ({ label: item.name, value: item.projectSiteId }));
  const existingWellsOptions = lists.wells
    .filter((item) => item.projectSiteId === existingSite)
    .map((item) => ({ label: item.name, value: item.projectWellId }));
  const existingWellboresOptions = lists.wellbores
    .filter((item) => item.projectWellId === existingWell)
    .map((item) => ({ label: item.name, value: item.projectWellboreId }));
  const existingDesignsOptions = lists.designs
    .filter((item) => item.projectWellboreId === existingWellbore)
    .map((item) => ({ label: item.name, value: item.projectDesignId }));

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: isImport
            ? t(translations.projects_importEstimate)
            : t(translations.projects_createEstimate),
          width: '600px',
          content: (
            <div
              style={{
                position: 'relative',
                margin: 'calc(var(--padding) * -1)',
              }}
            >
              {isAdding && (
                <Loader text={t(translations.adding)} theme="light" cover>
                  <Spinner dark />
                </Loader>
              )}
              <form style={{ padding: 'var(--padding)' }}>
                <RadioButton
                  options={createOptions}
                  value={createOptionsValue}
                  onChange={createOptionsOnChange}
                  inline
                />
                <Divider />
                {createOptionsValue.value === createEstimateOptions.IMPORT && (
                  <Field
                    label={t(translations.fileToImport)}
                    labelLeft
                    labelWidth={labelWidth}
                  >
                    <FileInput
                      name="projectFile"
                      file={file}
                      control={control}
                      errors={errors}
                      onChange={onImportFile}
                    />
                  </Field>
                )}
                {createOptionsValue.value === createEstimateOptions.COPY && (
                  <>
                    <Field
                      label={t(translations.projects_copyExisting)}
                      labelLeft
                      labelWidth={labelWidth}
                    >
                      <Select
                        options={existingEstimateOptions}
                        name="existingEstimate"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                      />
                    </Field>
                  </>
                )}
                <Field
                  label={t(translations.country)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingCountriesOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingCountriesOptions}
                        name="existingCountry"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        onChange={() => {
                          setShowCountry(false);
                          setValue('country', null);
                          clearFields('country');
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowCountry(true);
                          setValue('existingCountry', null);
                          clearFields('country');
                        }}
                        icon="add"
                      />
                    </InputGroup>
                  )}
                  {(existingCountriesOptions.length === 0 || showCountry) && (
                    <>
                      <Spacer height={5} />
                      <Select
                        options={countriesOptions}
                        name="country"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        onChange={() => {
                          setValue('existingCountry', null);
                          trigger(['existingCountry']);
                        }}
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.field)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingFieldsOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingFieldsOptions}
                        name="existingField"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        disabled={countryNotSelected}
                        onChange={async () => {
                          setValue('field', null);
                          await trigger(['field']);
                          setShowField(false);
                          clearFields('field');
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowField(true);
                          setValue('existingField', null);
                          clearFields('field');
                        }}
                        icon="add"
                        disabled={countryNotSelected}
                      />
                    </InputGroup>
                  )}
                  {(showField || existingFieldsOptions.length === 0) && (
                    <>
                      <Spacer height={5} />
                      <Input
                        name="field"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        disabled={countryNotSelected}
                        onChange={() => setValue('existingField', null)}
                        placeholder={
                          countryNotSelected
                            ? t(translations.projects_selectCountryFirst)
                            : ''
                        }
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.site)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingSitesOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingSitesOptions}
                        name="existingSite"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        disabled={fieldNotSelected}
                        onChange={async () => {
                          setValue('site', null);
                          await trigger(['site']);
                          setShowSite(false);
                          clearFields('site');
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowSite(true);
                          setValue('existingSite', null);
                          clearFields('site');
                        }}
                        icon="add"
                        disabled={fieldNotSelected}
                      />
                    </InputGroup>
                  )}
                  {(showSite || existingSitesOptions.length === 0) && (
                    <>
                      <Spacer height={5} />
                      <Input
                        name="site"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        disabled={fieldNotSelected}
                        onChange={() => setValue('existingSite', null)}
                        placeholder={
                          fieldNotSelected
                            ? t(translations.projects_selectFieldFirst)
                            : ''
                        }
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.well)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingWellsOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingWellsOptions}
                        name="existingWell"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        disabled={siteNotSelected}
                        onChange={async () => {
                          setValue('well', null);
                          await trigger(['well']);
                          setShowWell(false);
                          clearFields('well');
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowWell(true);
                          setValue('existingWell', null);
                          clearFields('well');
                        }}
                        icon="add"
                        disabled={siteNotSelected}
                      />
                    </InputGroup>
                  )}
                  {(showWell || existingWellsOptions.length === 0) && (
                    <>
                      <Spacer height={5} />
                      <Input
                        name="well"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        disabled={siteNotSelected}
                        onChange={() => setValue('existingWell', null)}
                        placeholder={
                          siteNotSelected
                            ? t(translations.projects_selectSiteFirst)
                            : ''
                        }
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.wellbore)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingWellboresOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingWellboresOptions}
                        name="existingWellbore"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        disabled={wellNotSelected}
                        onChange={async () => {
                          setValue('wellbore', null);
                          await trigger(['wellbore']);
                          setShowWellbore(false);
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowWellbore(true);
                          setValue('existingWellbore', null);
                        }}
                        icon="add"
                        disabled={wellNotSelected}
                      />
                    </InputGroup>
                  )}
                  {(showWellbore || existingWellboresOptions.length === 0) && (
                    <>
                      <Spacer height={5} />
                      <Input
                        name="wellbore"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        disabled={wellNotSelected}
                        onChange={() => setValue('existingWellbore', null)}
                        placeholder={
                          wellNotSelected
                            ? t(translations.projects_selectWellFirst)
                            : ''
                        }
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.design)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingDesignsOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingDesignsOptions}
                        name="existingDesign"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        disabled={wellboreNotSelected}
                        onChange={async () => {
                          setValue('design', null);
                          await trigger(['design']);
                          setShowDesign(false);
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowDesign(true);
                          setValue('existingDesign', null);
                        }}
                        icon="add"
                        disabled={wellboreNotSelected}
                      />
                    </InputGroup>
                  )}
                  {(showDesign || existingDesignsOptions.length === 0) && (
                    <>
                      <Spacer height={5} />
                      <Input
                        name="design"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        disabled={wellboreNotSelected}
                        onChange={() => setValue('existingDesign', null)}
                        placeholder={
                          wellboreNotSelected
                            ? t(translations.projects_selectWellboreFirst)
                            : ''
                        }
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.estimate)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  <Input
                    name="estimate"
                    control={control}
                    errors={errors}
                    width={inputWidth}
                  />
                </Field>
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label={t(translations.done)}
                colored
                onClick={onSubmit}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }, ownProps) => {
  const { lists, isAdding } = entities.projects;
  const existingCountriesOptions = ownProps.countriesList.map((country) => ({
    label: country.name,
    value: country.projectCountryId,
  }));
  const existingEstimateOptions = ownProps.estimates.map((estimate) => ({
    label: estimate.name,
    value: estimate.projectId,
  }));

  return { existingCountriesOptions, existingEstimateOptions, lists, isAdding };
};
const mapDispatchToProps = { saveProjectHierarchy, navigateToPath };
const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CreateModal),
  { isModal: true },
);

export { Container as CreateModal };
