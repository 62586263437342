import React, { useState } from 'react';
import { Text, Tooltip } from '@oliasoft-open-source/react-ui-library';
import styles from './map.module.less';

const ARROW_LENGTH = 75;
const ARROW_TIP_LENGTH = 15;

/**
 * @param {number} cx
 * @param {number} cy
 * @param {number} x
 * @param {number} y
 * @param {number} angle - Radians
 * @returns {[rx: number, ry: number]} - Rotated point
 */
const rotate2dPoint = (cx, cy, x, y, angle) => {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  const dx = x - cx;
  const dy = y - cy;
  return [cx + (cos * dx + sin * dy), cy + (cos * dy - sin * dx)];
};

/**
 * @param {{
 *   northReferenceArrows?: {
 *     hoverLabel: string,
 *     angle: number, // Degrees
 *   }[],
 * }} params
 */
const NorthReferenceArrows = ({ northReferenceArrows }) => {
  const [tooltip, setTooltip] = useState(null);

  if (!northReferenceArrows) {
    return null;
  }

  /**
   * @type {[
   *  x1: number,
   *  y1: number,
   *  x2: number,
   *  y2: number,
   *  angle: number,
   *  hoverLabel: string,
   * ][]}
   */
  const arrowShafts = northReferenceArrows.map(({ angle, hoverLabel }) => {
    const [endX, endY] = rotate2dPoint(
      0,
      0,
      0,
      -ARROW_LENGTH,
      (angle * Math.PI) / 180,
    );
    return [0, 0, endX, endY, angle, hoverLabel];
  });

  let minX = 0;
  let maxX = 0;
  let minY = 0;
  let maxY = 0;
  arrowShafts.forEach((arrow) => {
    const [x1, y1, x2, y2] = arrow;

    if (x1 < minX) minX = x1;
    if (x2 < minX) minX = x2;
    if (y1 < minY) minY = y1;
    if (y2 < minY) minY = y2;

    if (x1 > maxX) maxX = x1;
    if (x2 > maxX) maxX = x2;
    if (y1 > maxY) maxY = y1;
    if (y2 > maxY) maxY = y2;
  });

  // Adjust for 3px stroke width
  minX -= 1.5;
  maxX += 1.5;
  minY -= 1.5;
  maxY += 1.5;

  const svgWidth = maxX - minX;
  const svgHeight = maxY - minY;

  const tooltipStyle = tooltip
    ? {
        left: tooltip.x,
        top: tooltip.y,
      }
    : undefined;

  return (
    <div className={styles.northReferenceArrows}>
      <div className={styles.arrowTooltip} style={tooltipStyle}>
        <Tooltip text={tooltip?.text} forceOpen={!!tooltip} />
      </div>
      <svg
        viewBox={`${minX} ${minY} ${svgWidth} ${svgHeight}`}
        width={svgWidth}
        height={svgHeight}
      >
        {arrowShafts.map((arrow, arrowIx) => {
          const [x1, y1, x2, y2, angle, hoverLabel] = arrow;

          // Arrow tips
          const [a1x, a1y] = rotate2dPoint(
            x2,
            y2,
            x2 + ARROW_TIP_LENGTH,
            y2,
            ((angle - 110) * Math.PI) / 180,
          );
          const [a2x, a2y] = rotate2dPoint(
            x2,
            y2,
            x2 - ARROW_TIP_LENGTH,
            y2,
            ((angle + 110) * Math.PI) / 180,
          );

          const arrowPointXOffset = x2 - Math.min(x1, x2, a1x, a2x);
          const arrowPointYOffset = y2 - Math.min(y1, y2, a1y, a2y);
          const onMouseEnter = (evt) => {
            const arrowBounds = evt.target.getBoundingClientRect();
            const { x, y } = arrowBounds;

            setTooltip({
              x: x + arrowPointXOffset,
              y: y + arrowPointYOffset,
              text: (
                <>
                  <Text>{hoverLabel}</Text>
                  <br />
                  <div className={styles.tooltipAngleLabel}>
                    <Text faint>{`${angle} deg`}</Text>
                  </div>
                </>
              ),
            });
          };

          return (
            <path
              d={`M${x1},${y1} L${x2},${y2} L${a1x},${a1y} L${x2},${y2} L${a2x},${a2y} L${x2},${y2}`}
              className={styles.arrow}
              key={`arrow-${arrowIx}`}
              onMouseEnter={onMouseEnter}
              onMouseLeave={() => setTooltip(null)}
            />
          );
        })}
      </svg>
    </div>
  );
};

export default NorthReferenceArrows;
