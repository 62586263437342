/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import Leaflet from 'leaflet';
import { markerIconSVGTemplate } from './helpers';

/**
 * @param {{
 *   legend,
 * }} params
 * @returns {JSX.Element}
 */
const MapInfo = ({ legend }) => {
  const createControl = () => {
    const MapInfo = Leaflet.Control.extend({
      onAdd: (_map) => {
        const panelDiv = Leaflet.DomUtil.create('div', 'map-legend');
        const labels = [...legend.labels];
        const htmlLabels = [];

        for (let i = 0; i < labels.length; i++) {
          const el = labels[i];
          const color = legend.colors[i];
          htmlLabels.push(
            `<div class="map-legend-item">${markerIconSVGTemplate(
              color || null,
            )} ${el}</div>`,
          );
        }

        panelDiv.innerHTML = htmlLabels.join('');
        return panelDiv;
      },
    });
    return new MapInfo({ position: 'bottomright' });
  };

  const map = useMap();
  useEffect(() => {
    const control = createControl();
    control.addTo(map);
  }, []);

  return null;
};

export default MapInfo;
