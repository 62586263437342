import { Empty } from '@oliasoft-open-source/react-ui-library';
import { connect } from 'react-redux';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import {
  createNote,
  updateNote,
  deleteNote,
} from '~src/store/entities/notes/notes';
import { useParams } from 'react-router';
import { NoteItem } from './note-item';
import { NoteEditor } from './notes-editor';
import styles from './notes.module.less';
import { IAddNote, INote } from '../../interfaces/notes.interfaces';
import { getNoteModuleName } from './notes-utils';

type TNotesProps = {
  noteItems: INote[];
  currentModule: string;
  createNote: (newNote: IAddNote) => void;
  updateNote: (noteToUpdate: Pick<INote, 'noteid' | 'note'>) => void;
  deleteNote: (noteid: string) => void;
};

const Notes = ({
  // Props
  noteItems,
  currentModule,
  // Dispatch
  createNote,
  updateNote,
  deleteNote,
}: TNotesProps) => {
  const { t } = useTranslation();
  const { project } = useParams();

  const handleNewNote = (noteItem: INote) => {
    if (project) {
      const newNote = {
        ...noteItem,
        projectid: project,
        noteModule: currentModule,
      };
      createNote(newNote);
    }
  };

  return (
    <>
      <NoteEditor onSubmit={handleNewNote} onCancel={noop} />
      {noteItems?.length > 0 ? (
        noteItems.map((note) => (
          <NoteItem
            key={note.noteid}
            noteItem={note}
            onUpdate={updateNote}
            onDelete={deleteNote}
          />
        ))
      ) : (
        <div className={styles.emptyWrapper}>
          <Empty
            height="100%"
            width="100%"
            text={t(translations.notes_noNotes, {
              noteLocation: getNoteModuleName(currentModule, t),
            })}
          />
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  createNote,
  updateNote,
  deleteNote,
};

const Container = connect(null, mapDispatchToProps)(Notes);

export { Container as Notes };
