import { toNum } from '@oliasoft-open-source/units';
import { casingStringType } from '~src/enums/well-details';
import { Sections } from '~src/enums/sections';
import {
  IWellSection,
  TWellSchematic,
  IMeta,
  IFluid,
  IHole,
  IShoe,
  IWellString,
  TFluidRectangles,
  casingRenderStyle,
  ISectionType,
  ISection,
} from '../utils/types';
import { formatRadiusValue } from './helpers';

export const calculateSections = (
  wellSchematics: TWellSchematic[],
  meta: IMeta,
  sectionTypes: ISectionType[],
  casingSections: ISection[],
): IWellSection[] => {
  const { densityUnit, depthReference, depthUnit, diameterUnit } = meta;

  const sectionTypeMap = new Map(
    sectionTypes.map((section) => [section.name, section.sectionTypeId]),
  );

  const casingSectionMap = new Map(
    casingSections.map((section) => [section.name, section.sectionId]),
  );

  const {
    cementColor,
    holeColor,
    spaceColor,
    stringColor,
    holeEdgeWidth,
    shoeSize,
  } = casingRenderStyle;

  const sections: IWellSection[] = [];

  wellSchematics.forEach((schematic, index) => {
    const isTop = index === 0;
    const chartId = `section-${index}`;

    const {
      sectionTypeId,
      bottom,
      top,
      toc,
      name: sectionName,
      section: casingSizeRaw,
      displacementFluidDens: mudDensity,
      sectiontd: holeDepthRaw,
      holeSize: holeSizeRaw,
      sectionId,
    } = schematic;

    const casingSize = toNum(casingSizeRaw || 0);
    const casingSizePretty = formatRadiusValue(casingSizeRaw, diameterUnit);
    const holesize = toNum(holeSizeRaw);
    const holesizePretty = formatRadiusValue(holeSizeRaw, diameterUnit);
    const holeDepth = Math.max(holeDepthRaw, bottom);

    const fluids: IFluid[] = [];

    if (
      sectionTypeId === sectionTypeMap.get(casingStringType.CASING) ||
      sectionTypeId === sectionTypeMap.get(casingStringType.LINER)
    ) {
      if (toc > 0 && mudDensity > 0) {
        const rectangles: TFluidRectangles = [
          [
            [casingSize, holeDepth],
            [casingSize, toc],
            [holesize, toc],
            [holesize, holeDepth],
          ],
        ];

        // Fill fluid in hole of previous part
        if (
          !isTop &&
          Math.max(
            wellSchematics[index - 1].sectiontd,
            wellSchematics[index - 1].bottom,
          ) > toc &&
          toc > 0
        ) {
          const previousSchematic = wellSchematics[index - 1];
          const previousCasingSize = toNum(previousSchematic.section);
          const previousHoleDepth = Math.max(
            previousSchematic.sectiontd,
            previousSchematic.bottom,
          );
          rectangles.push([
            [casingSize, previousHoleDepth],
            [casingSize, toc],
            [previousCasingSize, toc],
            [previousCasingSize, previousHoleDepth],
          ]);
        }

        fluids.push({
          top,
          bottom,
          chartId: chartId.concat('-displacement-fluid'),
          color: cementColor,
          density: mudDensity,
          rectangles,
        });
      }
    }

    const hole: IHole | null =
      holesize > 0 &&
      sectionId !== casingSectionMap.get(Sections.UpperCompletion)
        ? {
            top: isTop ? top : wellSchematics[index - 1].bottom,
            bottom: holeDepth,
            depthReference,
            depthUnit,
            holesize,
            diameterUnit,
            chartId: chartId.concat('-hole'),
            width: holeEdgeWidth,
            color: holeColor,
          }
        : null;

    const shoe: IShoe | null =
      sectionTypeId === sectionTypeMap.get(casingStringType.CASING) ||
      sectionTypeId === sectionTypeMap.get(casingStringType.LINER)
        ? {
            chartId: chartId.concat('-shoe'),
            color: stringColor,
            depth: bottom,
            radius: casingSize,
            height: shoeSize,
            width: shoeSize,
          }
        : null;

    const strings: IWellString[] =
      casingSize > 0
        ? [
            {
              bottom,
              chartId: chartId.concat('-string'),
              color: stringColor,
              id: casingSize - 1,
              od: casingSize,
              odPretty: casingSizePretty,
              stringIx: 0,
              top,
            },
          ]
        : [];

    const section: IWellSection = {
      chartId,
      sectionIx: index,
      sectionName,
      sectionTypeId,
      bottom,
      holeDepth,
      top,
      toc,
      casingSize,
      casingSizePretty,
      holesize,
      holesizePretty,
      mudDensity,
      densityUnit,
      depthReference,
      depthUnit,
      fluids,
      shoe,
      strings,
      hole,
      spaceColor,
    };
    sections.push(section);
  });

  return sections;
};
