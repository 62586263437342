import { useEffect, useState } from 'react';
import { Heading, Toggle } from '@oliasoft-open-source/react-ui-library';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { loadProjectNotes } from '~src/store/entities/notes/notes';
import { useParams } from 'react-router';
import { INote } from '~src/common/interfaces/notes.interfaces';
import { Notes } from './notes';
import { getVisibleNotesSortered } from './helpers/get-visible-notes';
import styles from './notes.module.less';
import type { TRootState } from '~src/store/store-types';

type TNotesTabContentProps = {
  noteItems: INote[];
  currentModule: string;
  loadProjectNotes: (projectid: string) => void;
};

const NotesTabContent = ({
  noteItems,
  currentModule,
  loadProjectNotes,
}: TNotesTabContentProps) => {
  const { t } = useTranslation();

  const { project } = useParams();

  useEffect(() => {
    if (project) {
      loadProjectNotes(project);
    }
  }, [project]);

  const [showAll, setShowAll] = useState(false);

  const visibleNotes = getVisibleNotesSortered(
    noteItems,
    currentModule,
    showAll,
  );

  const handleShowAllToggle = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      <div className={styles.notesDrawerHeader}>
        <Heading top marginBottom="0">
          {t(translations.notes_notes)}
        </Heading>
      </div>

      <div className={styles.notesCheckboxContainers}>
        <Toggle
          label={t(translations.showAll)}
          checked={showAll}
          onChange={handleShowAllToggle}
          noMargin
        />
      </div>

      <Notes noteItems={visibleNotes} currentModule={currentModule} />
    </>
  );
};

const mapStateToProps = ({ entities: { notes } }: TRootState) => ({
  noteItems: notes?.noteItems || [],
});

const mapDispatchToProps = {
  loadProjectNotes,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(NotesTabContent);

export { Container as NotesTabContent };
