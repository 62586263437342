import ReactDOM from 'react-dom';
import WithRuntimeConfig from '~src/hoc/with-runtime-config';
import App from './app';

const Root = () => {
  return (
    <WithRuntimeConfig>
      <App />
    </WithRuntimeConfig>
  );
};

ReactDOM.render(<Root />, document.getElementById('content'));