import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  ListHeading,
  ListSubheading,
  Tree,
  Flex,
  Badge,
  Icon,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';

const ActivitiesList = ({
  entitySettings,
  activeOperation,
  addInitialActivity,
  showDeleteActivitiesModalUpdated,
  reorderActivities,
  showActivitiesForm,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();

  const { settings, isAdding } = entitySettings;

  const [expanded, setExpanded] = useState(true);
  const treeRef = useRef(null);
  const expandAllItems = () => treeRef.current.openAll();
  const collapseAllItems = () => treeRef.current.closeAll();
  const openNode = (nodeId) => treeRef.current.open(nodeId);
  const onClickExpandCollapse = () => {
    if (expanded) {
      collapseAllItems();
    } else {
      expandAllItems();
    }
    setExpanded(!expanded);
  };

  const activitiesList = activeOperation?.activities
    ? activeOperation.activities
        .filter((a) => !a?.isBranch)
        .map((activity) => ({
          id: activity.id,
          name: activity.parentId ? (
            <Flex gap="var(--padding-xs)" alignItems="center" wrap={false}>
              <Badge title="RISK" small />
              {activity.name}
              {activity.withBranch ? <Icon icon="fork" /> : null}
            </Flex>
          ) : (
            activity.name
          ),
          droppable: true,
          active: activity.active,
          parent: activity.parentId || 0,
          onClick: () => showActivitiesForm(activity.id),
          actions: [
            {
              label: t(translations.more),
              subActions: [
                {
                  label: t(translations.delete),
                  icon: 'delete',
                  onClick: () =>
                    showDeleteActivitiesModalUpdated({
                      selectedId: activity.id,
                      visible: true,
                    }),
                },
                !activity?.withBranch && {
                  label: t(translations.activityModel_addRisk),
                  icon: 'add',
                  onClick: () => {
                    addInitialActivity(
                      activity.name,
                      settings,
                      activity.id,
                      activity.sectionsOperationActivityId,
                      companyId,
                    );
                    openNode(activity.id);
                  },
                },
              ].filter(Boolean),
            },
          ],
        }))
    : [];

  return (
    <>
      <ListHeading
        name={
          activeOperation?.name || t(translations.activityModel_newOperation)
        }
      />
      <ListSubheading
        item={{
          name: `${t(translations.activities)} (${activitiesList.length})`,
          actions: [
            {
              label: 'Expand/collapse',
              icon: expanded ? 'collapse all' : 'expand all',
              onClick: onClickExpandCollapse,
              disabled: isAdding,
            },
            {
              label: 'Add',
              icon: 'add',
              subActions: [
                {
                  label: t(translations.add),
                  onClick: () =>
                    addInitialActivity(
                      t(translations.activityModel_newActivity),
                      settings,
                      0,
                      null,
                      companyId,
                    ),
                },
              ],
              disabled: isAdding,
            },
          ],
        }}
      />
      <Tree
        list={{
          items: activitiesList,
        }}
        draggable
        noHeader
        onListReorder={(reordered) =>
          reorderActivities(reordered, settings, companyId)
        }
        isInitialOpen={true}
        treeRef={treeRef}
      />
    </>
  );
};

export { ActivitiesList };
