import { useRef, useEffect } from 'react';
import {
  Spacer,
  Flex,
  Field,
  Card,
  Icon,
  Text,
  Button,
} from '@oliasoft-open-source/react-ui-library';
import emptyIcon from '~src/assets/empty.svg';
import { Input } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { getResolver } from '~src/validation/resolver';
import { debounce } from 'lodash';
import { useAutoSave } from '~src/common/auto-save/use-auto-save';
import { autoSaveWait } from '~src/config/config';
import { campaignSchemaValidator } from '~schemas/ajv-validators.ts';
import {
  addCampaign,
  estimateModalUpdated,
  getDesigns,
  welldesignModalUpdated,
} from '~store/entities/campaigns/campaigns';
import { ConnectedProps, connect } from 'react-redux';
import { HierarchyInputTable } from '~src/views/campaigns/hierarchy-table/hierarchy-input-table';
import {
  IActiveCampaign,
  ICampaignActiveEstimate,
  ICampaignDesignItem,
} from '~src/common/interfaces/campaigns.interfaces';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

type TCampaignInputTab = PropsFromRedux & {
  companyId: string;
  activeCampaign: IActiveCampaign;
  conceptDesigns: ICampaignDesignItem[];
  visibleEstimates: ICampaignActiveEstimate[];
};

const CampaignInputTab = ({
  addCampaign,
  companyId,
  activeCampaign,
  estimateModalUpdated,
  welldesignModalUpdated,
  conceptDesigns,
  visibleEstimates,
  getDesigns,
}: TCampaignInputTab) => {
  const { isConcept, estimates, designs, campaignId, name, templates } =
    activeCampaign;
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      campaignId: campaignId || null,
      name: name || '',
      designs,
    },
    resolver: getResolver(campaignSchemaValidator),
  });

  useEffect(() => {
    const { campaignId, name } = activeCampaign;
    reset({ campaignId, name });
  }, [activeCampaign]);

  const debounceUpdateCampaign = useRef(debounce(addCampaign, autoSaveWait));

  const onSubmit = handleSubmit((data) => {
    debounceUpdateCampaign.current(companyId, {
      ...data,
      isConcept,
      designs,
    });
  });

  useAutoSave(onSubmit, watch);

  const showTable = estimates?.length > 0 || conceptDesigns?.length > 0;

  const handleOnClick = () => {
    welldesignModalUpdated(true);
    getDesigns(companyId);
  };

  return (
    <>
      <Spacer />
      <Flex>
        <Field label={t(translations.campaigns_campaignName)}>
          <Input
            name="name"
            control={control}
            errors={errors}
            width={300}
            placeholder={t(translations.campaigns_campaignName)}
          />
        </Field>
      </Flex>
      <Spacer />
      {!showTable && (
        <Card bordered>
          <Flex
            alignItems="center"
            justifyContent="center"
            direction="column"
            height="25vh"
          >
            <Icon icon={emptyIcon} />
            <Spacer height="var(--padding-xs)" />
            <Text faint>{t(translations.campaigns_noEstimatesSelected)}</Text>
            <Spacer height="var(--padding-xs)" />
            <Button
              name="addEstimates"
              label={t(translations.addItem)}
              colored
              onClick={() =>
                isConcept ? handleOnClick() : estimateModalUpdated(true)
              }
            />
          </Flex>
        </Card>
      )}
      {showTable && (
        <HierarchyInputTable
          filteredEstimates={estimates}
          isConcept={isConcept}
          estimateModalUpdated={estimateModalUpdated}
          conceptDesigns={conceptDesigns}
          campaignId={campaignId}
          name={name}
          welldesignModalUpdated={welldesignModalUpdated}
          activeEstimates={visibleEstimates}
          templates={templates}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  addCampaign,
  estimateModalUpdated,
  welldesignModalUpdated,
  getDesigns,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(CampaignInputTab));

export { Container as CampaignInputTab };
