import React from 'react';
import { Page, Heading } from '@oliasoft-open-source/react-ui-library';

export const NotFound = () => {
  return (
    <Page>
      <Heading top>Not Found</Heading>
      <div>This page cannot be found</div>
    </Page>
  );
};
