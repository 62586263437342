import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Tree, Text } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';

const CostElementsList = ({
  elements,
  onClickAddItem,
  showDeleteCostItemModalUpdated,
  setActiveForm,
  costItemSelected,
  onClickAddCategory,
  costCategorySelected,
  selectedId,
  showDeleteCostCategoryModalUpdated,
  reorderElements,
  settings,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();

  const onClickCostItem = (id) => {
    costItemSelected(id);
    setActiveForm('item');
  };

  const onClickCostCategory = (id) => {
    costCategorySelected(id);
    setActiveForm('category');
  };

  const elementsList = elements?.map((item) => ({
    id: item.costCategoryId || item.costItemId,
    name: <Text muted={!!item.costCategoryId}>{item.name}</Text>,
    droppable: !item.costItemId,
    active: selectedId
      ? item.costItemId === selectedId || item.costCategoryId === selectedId
      : null,
    parent: item.parentId || 0,
    itemType: item.costCategoryId ? 'category' : 'costItem',
    onClick: () =>
      item.costCategoryId
        ? onClickCostCategory(item.costCategoryId)
        : onClickCostItem(item.costItemId),
    actions: [
      ...(item.costCategoryId
        ? [
            {
              label: t(translations.more),
              subActions: [
                {
                  label: t(translations.addItem),
                  icon: 'add',
                  onClick: () => onClickAddItem(item.costCategoryId),
                },
                {
                  label: t(translations.addSubCategory),
                  icon: 'add',
                  onClick: () => onClickAddCategory(item.costCategoryId),
                },
                {
                  label: t(translations.delete),
                  icon: 'delete',
                  onClick: () =>
                    showDeleteCostCategoryModalUpdated({
                      selectedId: item.costCategoryId,
                      visible: true,
                    }),
                },
              ],
            },
          ]
        : [
            {
              label: t(translations.delete),
              icon: 'delete',
              onClick: () =>
                showDeleteCostItemModalUpdated({
                  selectedId: item.costItemId,
                  visible: true,
                }),
            },
          ]),
    ],
  }));

  return (
    <Tree
      list={{
        name: t(translations.costSetup_costSetup),
        actions: [
          {
            label: 'Manage',
            icon: 'add',
            subActions: [
              {
                label: t(translations.addCategory),
                onClick: () => onClickAddCategory(),
              },
              {
                label: t(translations.addItem),
                onClick: () => onClickAddItem(),
              },
            ],
          },
        ],
        items: elementsList,
      }}
      draggable={true}
      onListReorder={(reorder) =>
        reorderElements(reorder, elements, settings, companyId)
      }
      isInitialOpen={true}
    />
  );
};

export { CostElementsList };
