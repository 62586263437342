import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import {
  removeSchematic,
  removeAllSchematics,
} from '~store/entities/well-details/well-details';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const SchematicDeleteModal = ({
  setModalVisible,
  removeSchematic,
  schematicId,
  projectId,
  removeAllSchematics,
}) => {
  const { t } = useTranslation();
  const onClose = () => setModalVisible(false);
  const onDelete = () => {
    if (schematicId) {
      removeSchematic(schematicId, { projectId });
    } else {
      removeAllSchematics(projectId);
    }

    setModalVisible(false);
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(
            schematicId
              ? translations.wellDetails_deleteSection
              : translations.wellDetails_deleteSections,
          ),
          content: (
            <>
              {t(
                schematicId
                  ? translations.wellDetails_areYouSureYouWantToDeleteThisSection
                  : translations.wellDetails_areYouSureYouWantToDeleteAllSections,
              )}
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = { removeSchematic, removeAllSchematics };
const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(SchematicDeleteModal),
  { isModal: true },
);

export { Container as SchematicDeleteModal };
