import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ListHeading,
  ListSubheading,
  List,
} from '@oliasoft-open-source/react-ui-library';
import {
  codeSelected,
  getCodeName,
  activeFormUpdated,
  addCodeClicked,
} from '~store/entities/operation-codes/operation-codes';
import { operationCodesForms } from '~src/enums/settings';
import translations from '~src/internationalisation/translation-map.json';

const OperationCodesList = ({
  activeCodesList,
  operationCodes,
  codeSelected,
  onClickDelete,
  activeFormUpdated,
  addCodeClicked,
}) => {
  const { t } = useTranslation();

  const operationCodesList = operationCodes.map((code) => ({
    id: code.codeId,
    name: getCodeName(code),
    active: code.active,
    onClick: () => codeSelected(code.codeId),
    ...(activeCodesList?.default
      ? {}
      : {
          actions: [
            {
              label: t(translations.delete),
              icon: 'delete',
              onClick: () => onClickDelete(code.codeId),
            },
          ],
        }),
  }));

  return (
    <>
      <ListHeading
        name={activeCodesList?.name || ''}
        actions={
          activeCodesList?.default
            ? []
            : [
                {
                  label: 'Edit',
                  icon: 'edit',
                  onClick: () =>
                    activeFormUpdated(operationCodesForms.CODES_LISTS),
                },
              ]
        }
      />
      <ListSubheading
        item={{
          name: 'Operations',
          metaCount: operationCodes.length,
          ...(activeCodesList?.default
            ? {}
            : {
                actions: [
                  {
                    label: 'Add',
                    primary: true,
                    icon: 'add',
                    onClick: () => addCodeClicked(),
                  },
                ],
              }),
        }}
      />
      <List
        list={{
          items: operationCodesList,
        }}
        noHeader
      />
    </>
  );
};

const mapDispatchToProps = {
  codeSelected,
  addCodeClicked,
  activeFormUpdated,
};

const Container = connect(null, mapDispatchToProps)(OperationCodesList);

export { Container as OperationCodesList };
