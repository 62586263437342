import { project } from '~src/enums/projects';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';

const parentIdMap = {
  [project.FIELDS_LIST]: 'projectCountryId',
  [project.SITES_LIST]: 'projectFieldId',
  [project.WELLS_LIST]: 'projectSiteId',
  [project.WELLBORES_LIST]: 'projectWellId',
  [project.DESIGNS_LIST]: 'projectWellboreId',
  [project.ESTIMATES_LIST]: 'projectDesignId',
};

export const isNameIdentical = (
  data,
  items,
  type,
  parent,
  setError,
  errorName,
) => {
  const id = parentIdMap[type];
  for (const item of items) {
    if (item.name === data.name && item[id] === parent[id]) {
      setError(errorName, {
        type: 'manual',
        message: i18n.t(translations.projects_duplicateNameError),
      });
      return true;
    }
  }
  return false;
};
