import {
  TArrayRepresentedDiagramCoordinates,
  TArrayRepresentedGridCoordinates,
  ChartDrawerParameters,
} from '../utils/types';

export const drawSpaceInsideSections = ({
  chart,
  chartData,
  drawUtils,
}: ChartDrawerParameters) => {
  const { gridLine } = drawUtils;
  const {
    meta: { seabed, wellheadDepth },
    sections,
  } = chartData;

  const spaceGroup = chart.append('g').attr('class', 'space');

  // Draw space inside casing strings (Must be done before drawing anything else)
  sections.forEach((section, sectionIx) => {
    const { hole, spaceColor, strings } = section;

    const sectionGroup = spaceGroup.append('g').attr('class', 'section');

    const drawSpace = (top: number, bottom: number, id: number) =>
      sectionGroup
        .append('path')
        .attr('d', () =>
          gridLine([
            [-id, top],
            [id, top],
            [id, bottom],
            [-id, bottom],
          ]),
        )
        .attr('class', 'emptySpace')
        .attr('fill', spaceColor)
        .attr('stroke', spaceColor)
        .attr('stroke-width', 0.1)
        .attr('shape-rendering', 'crispEdges');

    if (hole) {
      const { bottom, holesize, top } = hole;
      drawSpace(top, bottom, holesize);

      if (sectionIx === 0 && wellheadDepth > seabed) {
        drawSpace(seabed, wellheadDepth, holesize);
      }
    }

    strings?.forEach((string) => {
      const { bottom, id, top } = string;
      drawSpace(top, bottom, id);
    });
  });
};

export const drawSections = ({
  chart,
  chartData,
  drawUtils,
}: ChartDrawerParameters) => {
  const {
    depthToGridOffset,
    diagramLine,
    diameterToGridOffset,
    gridLine,
    gridMirror,
    mirror,
  } = drawUtils;

  // Draw casing strings with shoes
  const sectionsGroup = chart.append('g').attr('class', 'sections');

  chartData.sections.forEach(({ shoe, strings }) => {
    const sectionGroup = sectionsGroup.append('g').attr('class', 'section');

    strings.forEach((string, stringIx) => {
      const { bottom, color, id, od, top } = string;

      // Draw x-over
      const prevString = strings[stringIx - 1];
      if (prevString && Math.abs(prevString.bottom - top) < 0.1) {
        const drawXOver = (wall: TArrayRepresentedGridCoordinates) =>
          sectionGroup
            .append('path')
            .attr('d', () => diagramLine(wall))
            .attr('class', 'x-over')
            .attr('fill', color)
            .attr('stroke', color)
            .attr('stroke-width', 0.1)
            .attr('shape-rendering', 'crispEdges');

        const prevOdX = diameterToGridOffset(prevString.od);
        const prevIdX = diameterToGridOffset(prevString.id);
        const odX = diameterToGridOffset(od);
        const idX = diameterToGridOffset(id);

        const topY = depthToGridOffset(top);

        const prevThickness = prevOdX - prevIdX;
        const thickness = odX - idX;

        const xOverWall: TArrayRepresentedGridCoordinates = [
          [prevOdX, topY - prevThickness],
          [idX, topY],
          [idX, topY + thickness],
          [prevOdX, topY],
        ];

        drawXOver(xOverWall);
        drawXOver(gridMirror(xOverWall));
      }

      // Draw string
      const midDiameter = (od + id) / 2;
      const stringWall: TArrayRepresentedDiagramCoordinates = [
        [midDiameter, top],
        [midDiameter, bottom],
      ];
      const width = diameterToGridOffset(od) - diameterToGridOffset(id);

      const drawString = (wall: TArrayRepresentedDiagramCoordinates) =>
        sectionGroup
          .append('path')
          .attr('d', () => gridLine(wall))
          .attr('class', 'string')
          .attr('stroke', color)
          .attr('stroke-width', width)
          .attr('shape-rendering', 'crispEdges');

      drawString(stringWall);
      drawString(mirror(stringWall));
    });

    if (shoe) {
      const { color, depth, height, radius, width } = shoe;
      const leftShoeCoordinates: TArrayRepresentedGridCoordinates = [
        [diameterToGridOffset(-radius), depthToGridOffset(depth)],
        [diameterToGridOffset(-radius) - width, depthToGridOffset(depth)],
        [diameterToGridOffset(-radius), depthToGridOffset(depth) - height],
      ];
      const rightShoeCoordinates: TArrayRepresentedGridCoordinates = [
        [diameterToGridOffset(radius), depthToGridOffset(depth)],
        [diameterToGridOffset(radius) + width, depthToGridOffset(depth)],
        [diameterToGridOffset(radius), depthToGridOffset(depth) - height],
      ];

      const drawShoe = (coordinates: TArrayRepresentedGridCoordinates) =>
        sectionGroup
          .append('path')
          .attr('class', 'shoe')
          .attr('d', () => diagramLine(coordinates))
          .attr('fill', color)
          .attr('shape-rendering', 'crispEdges');

      drawShoe(leftShoeCoordinates);
      drawShoe(rightShoeCoordinates);
    }
  });
};
