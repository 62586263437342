import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Button,
  Flex,
  Spacer,
  Text,
} from '@oliasoft-open-source/react-ui-library';
import emptyIcon from '~src/assets/empty.svg';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const RawNoItems = ({
  showCreateModelModalUpdated,
  showImportModelModalUpdated,
  isPageDisabled,
  type,
  onClickAdd,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      direction="column"
      height="100%"
    >
      <Icon icon={emptyIcon} />
      <Spacer height="var(--padding-xs)" />
      <Text faint>
        {type === 'activityModel'
          ? t(translations.activityModel_noOperationsHaveBeenAddedYet)
          : t(translations.costSetup_noCostItemsHaveBeenAddedYet)}
      </Text>
      <Spacer height="var(--padding-xs)" />
      <Flex direction="column">
        <Button
          name="generateFromTemplate"
          label={t(translations.activityModel_generateFromRuleset)}
          onClick={() => showCreateModelModalUpdated(true)}
          disabled={isPageDisabled}
          colored
        />
        <Spacer width="var(--padding-xs)" />
        {type === 'activityModel' && (
          <>
            <Button
              name="importFromExistingEstimate"
              label={t(translations.activityModel_importFromExistingEstimate)}
              onClick={() => showImportModelModalUpdated(true)}
              disabled={isPageDisabled}
            />
            <Spacer width="var(--padding-xs)" />
          </>
        )}
        <Button
          name="generateManually"
          label={t(translations.activityModel_generateManually)}
          onClick={() => onClickAdd()}
          disabled={isPageDisabled}
        />
      </Flex>
    </Flex>
  );
};

export const NoItems = withErrorBoundary(RawNoItems);
