/* eslint-disable prefer-template */
/* eslint-disable prefer-const */
import { isNumeric, round } from '@oliasoft-open-source/units';
import { getDatumEPSG, isGCSLocation } from '~common/gis/gis-library-utils';
import {
  areAllArgumentsNumeric,
  getFallbackCoordinate,
  getFallbackDmsCoordinate,
} from '~common/gis/helpers';
import { transformLocationCoordinate } from '~common/gis/transform-location-coordinate';
import {
  DMS_COORDINATE_LENGTH,
  FALLBACK_DMS_VALUE,
  FALLBACK_VALUE,
} from '~common/gis/constants';

/**
 * Convert cartesian or geographic coordinate into DMS format
 * @param {import('common/gis/gis.interfaces').TCoordinate} coordinate
 * @param {string} fromEpsg - EPSG code in format 'EPSG:xxxxxx'
 * @param {string} [toEpsg] - datum EPSG code in format 'EPSG:xxxxxx', will be determined based on GIS_DATA json if not provided
 * @returns {import('common/gis/gis.interfaces').TDMSCoordinate} - coordinate in DMS format
 */
export const cartesianCoordinateToDMS = (
  coordinate = [0, 0],
  fromEpsg,
  toEpsg,
) => {
  const isCoordinateInDDFormat = isGCSLocation(fromEpsg);
  if (isCoordinateInDDFormat) {
    // convert DD into DMS format (no coordinate transformation!)
    return DDCoordinateToDMS(coordinate);
  }

  const datumEpsg = toEpsg ?? getDatumEPSG(fromEpsg);
  if (!datumEpsg) {
    return getFallbackDmsCoordinate();
  }
  const coordinateInDD = transformLocationCoordinate(
    fromEpsg,
    datumEpsg,
    coordinate,
  );
  return areAllArgumentsNumeric(coordinateInDD?.[0], coordinateInDD?.[1])
    ? DDCoordinateToDMS(coordinateInDD)
    : getFallbackDmsCoordinate();
};

/**
 * Convert geographic coordinate in DMS format into cartesian coordinate
 * @param {import('common/gis/gis.interfaces').TDMSCoordinate} dmsCoordinate - coordinate in DMS format, where first element is longitude and second is latitude
 * @param {string|null} fromEpsg - datum EPSG code in format 'EPSG:xxxxxx'
 * @param {string|null} toEpsg -  EPSG code of projected CRS in format 'EPSG:xxxxxx'
 * @returns {import('common/gis/gis.interfaces').TCartesianCoordinate} - cartesian coordinate [easting, northing]
 */
export const DMSCoordinateToCartesian = (dmsCoordinate, fromEpsg, toEpsg) => {
  const isInputValid =
    fromEpsg &&
    toEpsg &&
    dmsCoordinate.every((arr) => arr.length === DMS_COORDINATE_LENGTH);
  if (isInputValid) {
    const [longitudeDMS, latitudeDMS] = dmsCoordinate;
    const coordinateInDDFormat = [
      DMSValueToDD(longitudeDMS),
      DMSValueToDD(latitudeDMS),
    ];
    const cartesianCoordinate = transformLocationCoordinate(
      fromEpsg,
      toEpsg,
      coordinateInDDFormat,
    );
    return areAllArgumentsNumeric(
      cartesianCoordinate?.[0],
      cartesianCoordinate?.[1],
    )
      ? cartesianCoordinate
      : getFallbackCoordinate();
  } else {
    return getFallbackCoordinate();
  }
};

/**
 * @param {import('common/gis/gis.interfaces').TDMS} dmsValue - value of longitude or latitude in DMS format
 * @returns {number|NaN} - longitude or latitude in DD format, direction reference included
 */
export function DMSValueToDD(dmsValue) {
  const isInputValid =
    dmsValue.length === DMS_COORDINATE_LENGTH &&
    areAllArgumentsNumeric(...dmsValue);
  if (isInputValid) {
    let [deg, mins, secs, directionRef] = dmsValue;
    deg = parseFloat(deg) || 0;
    mins = parseFloat(mins) / 60 || 0;
    secs = parseFloat(secs) / 3600 || 0;
    let ddValue = Math.abs(deg) + Math.abs(mins) + Math.abs(secs);
    return ddValue * directionRef;
  } else {
    return FALLBACK_VALUE;
  }
}

/**
 * @param {number|NaN} dd - longitude or latitude in DD format (decimal degrees)
 * @returns {import('common/gis/gis.interfaces').TDMS} - longitude or latitude in DMS format
 */
function DDValueToDMS(dd) {
  if (!isNumeric(dd)) {
    return FALLBACK_DMS_VALUE;
  }
  const d = Math.trunc(dd);
  const mTemp = Math.trunc((dd - d) * 60);
  const m = Math.abs(mTemp);
  const sTemp = (dd - d - m / 60) * 3600;
  const s = Math.abs(round(sTemp));
  if (s === 60) {
    return [d + 1, m + 1, 0];
  }
  return [d, m, s];
}

/**
 * @description - converts coordinate in DD format into to DMS format
 * @param {import('common/gis/gis.interfaces').TDDCoordinate} ddCoordinate - easting and northing in decimal degrees
 * @returns {import('common/gis/gis.interfaces').TDMSCoordinate} - easting and northing coordinates as longitude and latitude in degree/minutes/seconds format, where sign of degree indicates reference (E/W, N/S)
 */
export const DDCoordinateToDMS = (ddCoordinate) => {
  const [ddX, ddY] = ddCoordinate;
  if (!areAllArgumentsNumeric(ddX, ddY)) {
    return getFallbackDmsCoordinate();
  }
  const DEFAULT_SIGN = 1; //prevents returning 0, which is not assigned to any directionRef
  const xSign = Math.sign(ddX) || DEFAULT_SIGN;
  const ySign = Math.sign(ddY) || DEFAULT_SIGN;
  const xInDms = [...DDValueToDMS(Math.abs(ddX)), xSign];
  const yInDms = [...DDValueToDMS(Math.abs(ddY)), ySign];

  return [xInDms, yInDms];
};

/**
 * @param { import('common/gis/gis.interfaces').TDMS } dmsValue
 * @return {string} e.g. "7° 38′ 54.1718″"
 */
export const DMSToString = (dmsValue) => {
  const [degrees, minutes, seconds, directionRef] = dmsValue;
  return (
    degrees * directionRef +
    '° ' +
    minutes +
    '′ ' +
    Math.round(seconds * 1e5) / 1e5 +
    '″'
  );
};

/**
 * @param { string } dmsString - dms coordinate as string (e.g. "7° 38′ 54.1718″", "7 38 54.1718")
 * @return { import('common/gis/gis.interfaces').TDMS } dmsValue
 */
export const DMSStringToArray = (dmsString) => {
  const sanitizedDMS = dmsString
    .replaceAll('°', '')
    .replaceAll('′', '')
    .replaceAll('″', '')
    .split(' ');

  const dms = sanitizedDMS.slice(0, 3).map((el) => Number(el));
  const directionReference =
    sanitizedDMS?.[3]?.toUpperCase() === 'S' ||
    sanitizedDMS?.[3]?.toUpperCase() === 'W'
      ? -1
      : 1;

  return [...dms, directionReference];
};
