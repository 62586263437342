import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Grid,
  RichTextInput,
} from '@oliasoft-open-source/react-ui-library';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import styles from './notes.module.less';
import { INote } from '../../interfaces/notes.interfaces';
import type { TRootState } from '~src/store/store-types';

type TNotesEditorProps = {
  noteItem?: INote;
  onSubmit: (noteItem: INote) => void;
  onCancel: () => void;
  onConfirmDelete?: () => void;
  openDeleteNoteModal?: () => void;
  loadingNotes: boolean;
};

const NoteEditor = ({
  // Props
  noteItem,
  onSubmit,
  onCancel,
  onConfirmDelete,
  openDeleteNoteModal,
  // State
  loadingNotes,
}: TNotesEditorProps) => {
  const { t } = useTranslation();

  const editorRef = React.useRef(null);
  const [inputValue, setInputValue] = useState(noteItem?.note || '');

  const clearContent = (ref: any) => {
    setInputValue('');
    ref.current.clearContent();
  };

  useEffect(() => {
    setInputValue(noteItem?.note || '');
  }, [noteItem]);

  const handleSubmit = async () => {
    const newNoteItem = { ...noteItem, note: inputValue } as INote;
    onSubmit(newNoteItem);
    clearContent(editorRef);
  };

  const handleCancel = () => {
    clearContent(editorRef);
    onCancel();
  };

  const onDeleteClick = ({ shiftKey }: React.MouseEvent<HTMLElement>) => {
    if (shiftKey) {
      onConfirmDelete?.();
    } else {
      openDeleteNoteModal?.();
    }
  };

  const handleChange = (value: string) => {
    setInputValue(value);
  };

  const coloredButtonLabel = noteItem?.noteid
    ? t(translations.notes_save)
    : t(translations.add);

  return (
    <div className={styles.noteEdit}>
      <Grid gap="12px">
        <RichTextInput
          value={inputValue}
          onChange={handleChange}
          placeholder={t(translations.notes_placeholder)}
          ref={editorRef}
        />
        {!!inputValue && (
          <Flex gap="12px" alignItems="center">
            <Button
              colored
              label={coloredButtonLabel}
              onClick={handleSubmit}
              disabled={inputValue === noteItem?.note}
              loading={loadingNotes}
            />
            <Button label={t(translations.cancel)} onClick={handleCancel} />

            {noteItem?.noteid && (
              <div className={styles.autoLeftMargin}>
                <Button
                  label={t(translations.delete)}
                  onClick={onDeleteClick}
                  basic
                  colored="danger"
                />
              </div>
            )}
          </Flex>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ entities: { notes } }: TRootState) => {
  return {
    loadingNotes: notes.loading,
  };
};

const Container = connect(mapStateToProps)(NoteEditor);

export { Container as NoteEditor };
