import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListSubheading, List } from '@oliasoft-open-source/react-ui-library';
import { wellSelected } from '~store/entities/projects/projects';
import { project } from '~src/enums/projects';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const WellsList = ({
  wells,
  onClickDelete,
  setWellModalVisible,
  setEditItem,
  wellSelected,
  addButtonDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ListSubheading
        item={{
          name: t(translations.well),
          actions: [
            {
              label: 'Add',
              icon: 'add',
              onClick: () => setWellModalVisible(true),
              disabled: addButtonDisabled,
            },
          ],
        }}
      />
      <List
        testId="well-list"
        list={{
          items: wells.map((well) => ({
            id: well.projectWellId,
            name: well.name,
            metadata: `${well.wellboresCount} ${
              +well.wellboresCount === 1
                ? t(translations.wellbore)
                : t(translations.wellbores)
            }`,
            active: well.active,
            onClick: () => wellSelected(well.projectWellId),
            actions: [
              {
                label: 'Update',
                icon: 'edit',
                onClick: () => {
                  setEditItem(well);
                  setWellModalVisible(true);
                },
              },
              {
                label: 'Delete',
                icon: 'delete',
                onClick: () =>
                  onClickDelete(well.projectWellId, project.WELLS_LIST),
              },
            ],
          })),
        }}
        noHeader
      />
    </>
  );
};

const mapDispatchToProps = { wellSelected };

const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(WellsList),
);

export { Container as WellsList };
