import React from 'react';
import { useTranslation } from 'react-i18next';
import { toggleChatOpen } from '~src/store/entities/zendesk/zendesk';
import translations from '~src/internationalisation/translation-map.json';
import { connect } from 'react-redux';
import { Button, Menu } from '@oliasoft-open-source/react-ui-library';
import { links } from './HelpMenuLinks';

const HelpMenu = ({ zendesk, toggleChatOpen, currentPage }) => {
  const { t } = useTranslation();
  const openLinkInNewTab = (url) => {
    window.open(url, '_blank')?.focus();
  };

  const getCurrentLinks = () => {
    if (!currentPage) return [];

    return (
      links[currentPage]?.map(({ translationKey, link, hasVideoGuide }, i) => ({
        label: t(translations[translationKey]),
        type: 'Option',
        icon: hasVideoGuide ? 'video' : 'file',
        order: i + 1,
        onClick: () => openLinkInNewTab(link),
      })) ?? []
    );
  };

  const hasLinks = getCurrentLinks().length > 0;

  const getHelpChat = () => {
    if (!zendesk.isLoaded) return [];

    return [
      ...(hasLinks
        ? [
            {
              type: 'Divider',
            },
          ]
        : []),
      {
        type: 'Heading',
        label: t(translations.helpMenu_support),
      },
      {
        icon: 'chat',
        type: 'Option',
        label: t(translations.helpMenu_contactSupport),
        onClick: () => {
          if (window.zE && typeof window.zE.activate === 'function') {
            window.zE.activate({ hideOnClose: true });
            toggleChatOpen(true);
          } else {
            console.error(
              'Zendesk widget (zE) is not loaded yet or activate method is unavailable.',
            );
          }
        },
      },
    ];
  };

  const helpMenu = [
    ...(hasLinks
      ? [
          {
            type: 'Heading',
            label: t(translations.helpMenu_howToGuides),
          },
        ]
      : []),
    ...getCurrentLinks(),
    ...getHelpChat(),
  ];

  return (
    <Menu
      maxHeight="80vh"
      menu={{
        trigger: 'Component',
        placement: 'bottom-end',
        fullHeightTrigger: true,
        component: <Button icon="question" round />,
        sections: helpMenu,
      }}
    />
  );
};

const mapDispatchToProps = {
  toggleChatOpen,
};

const mapStateToProps = ({ entities }) => {
  const { zendesk } = entities;
  const { currentPage } = entities.navigation;
  return { zendesk, currentPage };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpMenu);
