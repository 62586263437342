import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Heading,
  Column,
  Loader,
  Spinner,
  Row,
  Drawer,
} from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import translations from '~src/internationalisation/translation-map.json';
import { KpiList } from '~views/settings/kpi/kpi.list';
import { KpiDeleteModal } from '~views/settings/kpi/kpi-delete.modal';
import { KpiForm } from '~views/settings/kpi/kpi.form';
import { ImportFromModal } from '~views/settings/user-settings/import-from.modal';
import { selectCompanyKpiList } from '~store/entities/company-settings/selectors';
import { selectUserSettings } from '~store/entities/user-settings/selectors';
import { saveUserSettings } from '~store/entities/user-settings/user-settings';

const Kpi = ({
  entitySettings,
  showDeleteKpiModalUpdated,
  showKpiFormUpdated,
  entity,
  companyKpiList,
  userSettings,
  saveUserSettings,
}) => {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState(null);

  const { settings, isAdding, showDeleteKpiModal, showKpiForm } =
    entitySettings;
  const activeKpi = settings?.kpi?.find((item) => item.active);

  const [showCopyModal, setShowCopyModal] = useState(false);

  const onConfirmCopyFromUserSettings = ({ items }) => {
    const selectedKpi = companyKpiList
      .filter((kpi) => items.includes(kpi.id))
      .map((el) => ({ ...el, id: uuidv4() }));
    const updatedSettings = {
      ...userSettings,
      kpi: [...userSettings.kpi, ...selectedKpi],
    };
    setShowCopyModal(false);
    saveUserSettings(updatedSettings);
  };

  const onClickDelete = (id) => {
    setSelectedId(id);
    showDeleteKpiModalUpdated(true);
    showKpiFormUpdated(false);
  };

  const [drawerWidth, setDrawerWidth] = useState(300);
  return (
    <>
      <Column scroll spacing={0}>
        {isAdding && (
          <Loader text={t(translations.fetching)} theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Row spacing={0} height="100%">
          <Drawer
            width={drawerWidth}
            closedWidth={50}
            open
            button
            border
            onResize={(width) => setDrawerWidth(width)}
          >
            <Column scroll width="250px" borderRight spacing={0}>
              <KpiList
                onClickDelete={onClickDelete}
                onShowCopyModal={() => setShowCopyModal(true)}
                settings={settings}
                isAdding={isAdding}
                entity={entity}
              />
            </Column>
          </Drawer>
          <Column scroll padding>
            {showKpiForm && (
              <>
                <Heading top>{activeKpi?.name}</Heading>
                <KpiForm
                  entity={entity}
                  activeKpi={activeKpi}
                  settings={settings}
                />
              </>
            )}
          </Column>
        </Row>
      </Column>
      {showDeleteKpiModal && (
        <KpiDeleteModal
          kpiId={selectedId}
          setModalVisible={showDeleteKpiModalUpdated}
          settings={settings}
          entity={entity}
        />
      )}
      {showCopyModal && (
        <ImportFromModal
          isVisible={showCopyModal}
          onCloseModal={() => setShowCopyModal(false)}
          onConfirmModal={onConfirmCopyFromUserSettings}
          importType={'KPI'}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => ({
  companyKpiList: selectCompanyKpiList(entities),
  userSettings: selectUserSettings(entities),
});

const mapDispatchToProps = { saveUserSettings };

const Container = connect(mapStateToProps, mapDispatchToProps)(Kpi);

export { Container as Kpi };
