import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Heading, Field, Button } from '@oliasoft-open-source/react-ui-library';
import {
  saveCode,
  initialCode,
} from '~store/entities/operation-codes/operation-codes';
import { getResolver } from '~src/validation/resolver';
import translations from '~src/internationalisation/translation-map.json';
import { Input } from '~common/form-inputs';
import { codesSchemaValidator } from '~schemas/ajv-validators';
import { operationCodesTypes } from '~src/enums/settings';
import { ButtonGroup } from '~common/form-inputs/button-group';

const OperationCodesForm = ({
  operationCode,
  saveCode,
  isSaving,
  codesList,
  codeType,
  parentId,
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialCode,
    resolver: getResolver(codesSchemaValidator),
  });

  useEffect(() => {
    reset(operationCode || { ...initialCode, type: codeType });
  }, [operationCode, codeType]);

  const onSubmit = handleSubmit((data) => {
    saveCode(data, codesList?.codesListId, parentId);
  });

  const code = watch('code').toUpperCase();
  const description = watch('description');
  const types = [
    {
      key: operationCodesTypes.SUB_OPERATION,
      label: t(translations.settings_subOperation),
      value: operationCodesTypes.SUB_OPERATION,
    },
    {
      key: operationCodesTypes.ACTIVITY,
      label: t(translations.settings_activity),
      value: operationCodesTypes.ACTIVITY,
    },
  ];

  return (
    <>
      <Heading top>
        {code}
        {code && description ? ': ' : ''}
        {description}
      </Heading>
      <form>
        <Field label={t(translations.code)}>
          <Input
            name="code"
            control={control}
            errors={errors}
            width="258px"
            disabled={codesList?.default}
          />
        </Field>
        <Field label={t(translations.description)}>
          <Input
            name="description"
            control={control}
            errors={errors}
            width="258px"
            disabled={codesList?.default}
          />
        </Field>
        {codeType !== operationCodesTypes.OPERATION && (
          <Field label={t(translations.type)}>
            <ButtonGroup
              name="type"
              control={control}
              items={types}
              disabled={codesList?.default}
            />
          </Field>
        )}
        {!codesList?.default && (
          <Button
            name="save"
            label={
              operationCode?.codeId ? t(translations.save) : t(translations.add)
            }
            onClick={onSubmit}
            colored
            disabled={isSaving}
          />
        )}
      </form>
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const { isSaving, codeType } = entities.operationCodes;

  return {
    isSaving,
    codeType,
  };
};

const mapDispatchToProps = { saveCode };

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OperationCodesForm);

export { Container as OperationCodesForm };
