import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListSubheading, List } from '@oliasoft-open-source/react-ui-library';
import { designSelected } from '~store/entities/projects/projects';
import { project } from '~src/enums/projects';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const DesignsList = ({
  designs,
  onClickDelete,
  setDesignModalVisible,
  setEditItem,
  designSelected,
  addButtonDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ListSubheading
        item={{
          name: t(translations.design),
          actions: [
            {
              label: 'Add',
              icon: 'add',
              onClick: () => setDesignModalVisible(true),
              disabled: addButtonDisabled,
            },
          ],
        }}
      />
      <List
        testId="design-list"
        list={{
          items: designs.map((design) => ({
            id: design.projectDesignId,
            name: design.name,
            metadata: `${design.estimatesCount} ${
              +design.estimatesCount === 1
                ? t(translations.estimate)
                : t(translations.estimates)
            }`,
            active: design.active,
            onClick: () => designSelected(design.projectDesignId),
            actions: [
              {
                label: 'Update',
                icon: 'edit',
                onClick: () => {
                  setEditItem(design);
                  setDesignModalVisible(true);
                },
              },
              {
                label: 'Delete',
                icon: 'delete',
                onClick: () =>
                  onClickDelete(design.projectDesignId, project.DESIGNS_LIST),
              },
            ],
          })),
        }}
        noHeader
      />
    </>
  );
};

const mapDispatchToProps = { designSelected };

const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(DesignsList),
);

export { Container as DesignsList };
