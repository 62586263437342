import React from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import {
  InputGroup,
  UnitInput as GuiUnitInput,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { linkFields } from '~src/enums/settings';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const linkFieldMapDefault = {
  from: 'depthFromLink',
  to: 'depthToLink',
};
const RawUnitInputConvertable = ({
  name,
  control,
  unit,
  unitkey = '',
  errors,
  error = null,
  warning = null,
  onPredefinedChange,
  width,
  linkFieldMap = linkFieldMapDefault,
  ...inputProps
}) => {
  const { t } = useTranslation();
  const { field } = useController({
    name,
    control,
  });

  const { onChange, ...props } = field;
  const custumOnChange = (e) => {
    const { name, predefinedSelected, predefinedOption } = e.target;
    if (predefinedSelected && predefinedOption.valueKey) {
      onPredefinedChange(linkFieldMap[name], predefinedOption.valueKey);
      onChange(e);
    } else {
      onPredefinedChange(linkFieldMap[name], linkFields.CUSTOM);
      onChange(e);
    }
  };

  return (
    <InputGroup width={width}>
      <GuiUnitInput
        {...props}
        {...inputProps}
        onChange={custumOnChange}
        error={
          translations[errors[name]] ? t(errors[name]) : errors[name] || error
        }
        warning={warning}
        initUnit={unit}
        unitkey={unitkey}
      />
    </InputGroup>
  );
};

export const UnitInputConvertable = withErrorBoundary(RawUnitInputConvertable);
