import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  Tabs,
  Loader,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import {
  getOffsetWells,
  selectOffsetWell,
  filterList,
} from '~store/entities/offset-wells/offset-wells';
import { getWellDetails } from '~store/entities/well-details/well-details';
import { offsetWellsTabs } from '~src/enums/well-details';
import { depthInequality, ANY_OPTION } from '~src/enums/settings';
import { listFilterFields } from '~src/enums/offset-wells';
import translations from '~src/internationalisation/translation-map.json';
import { selectWellLocation } from '~store/entities/well-details/selectors';
import { SelectedEstimatesList } from './selected-estimates.list';
import { EstimatesList } from './estimates.list';
import { EstimatesMap } from './estimates.map';

const OffsetWells = ({
  isFetching,
  list,
  selected,
  selectOffsetWell,
  currentWellLocation,
  getOffsetWells,
  getWellDetails,
}) => {
  const { t } = useTranslation();
  const { project, company } = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState(
    offsetWellsTabs.SELECTED,
  );

  useEffect(() => {
    getWellDetails(project);
    getOffsetWells(company, project);
  }, []);

  const [filter, setFilter] = useState({
    [listFilterFields.WELL_CONFIGURATION]: ANY_OPTION,
    [listFilterFields.DRILLING_UNIT]: ANY_OPTION,
    [listFilterFields.WATER_DEPTH_INEQUALITY]:
      depthInequality.MORE_THAN_OR_EQUAL,
    [listFilterFields.WATER_DEPTH]: 0,
    [listFilterFields.WELLHEAD_DEPTH_INEQUALITY]:
      depthInequality.MORE_THAN_OR_EQUAL,
    [listFilterFields.WELLHEAD_DEPTH]: 0,
    [listFilterFields.CASING_STRINGS_INEQUALITY]:
      depthInequality.MORE_THAN_OR_EQUAL,
    [listFilterFields.CASING_STRINGS]: 0,
    [listFilterFields.DISTANCE]: '',
  });

  const listItems = filterList(list, filter, currentWellLocation).map(
    (item) => ({
      id: item.projectId,
      name: item.name,
      metadata: item.well,
      location: item.location,
      active: item.active,
      onClick: () => selectOffsetWell(project, item.projectId, item.active),
    }),
  );
  const selectedItems = selected.map((item) => ({
    id: item.projectId,
    name: item.name,
    metadata: item.well,
    location: item.location,
    actions: [
      {
        label: 'Delete',
        icon: 'delete',
        onClick: () => selectOffsetWell(project, item.projectId, true),
      },
    ],
  }));

  return (
    <>
      <div style={{ padding: 'var(--padding)' }}>
        {isFetching && (
          <Loader text={t(translations.fetching)} theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Heading top>{t(translations.wellDetails_offsetWells)}</Heading>
        <Tabs
          value={activeTabIndex}
          onChange={(ev) => setActiveTabIndex(ev.target.value)}
          margin={0}
          options={[
            {
              badge: selectedItems.length,
              value: offsetWellsTabs.SELECTED,
              label: t(translations.selected),
            },
            { value: offsetWellsTabs.LIST, label: t(translations.list) },
            { value: offsetWellsTabs.MAP, label: t(translations.map) },
          ]}
        />
      </div>
      {activeTabIndex === offsetWellsTabs.SELECTED && (
        <SelectedEstimatesList
          setActiveTabIndex={setActiveTabIndex}
          items={selectedItems}
        />
      )}
      {activeTabIndex === offsetWellsTabs.LIST && (
        <EstimatesList
          items={listItems}
          filter={filter}
          setFilter={setFilter}
        />
      )}
      {activeTabIndex === offsetWellsTabs.MAP && (
        <EstimatesMap items={listItems} filter={filter} setFilter={setFilter} />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    offsetWells: { isFetching, list, selected },
  } = entities;

  return {
    isFetching,
    list,
    selected,
    currentWellLocation: selectWellLocation({ entities }),
  };
};

const mapDispatchToProps = { getOffsetWells, selectOffsetWell, getWellDetails };

const Container = connect(mapStateToProps, mapDispatchToProps)(OffsetWells);

export { Container as OffsetWells };
