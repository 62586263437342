import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
  Modal,
  Dialog,
  Button,
  Loader,
  Spinner,
  Field,
  CheckBox,
  Message,
} from '@oliasoft-open-source/react-ui-library';
import {
  getOperations,
  operationsCleared,
} from '~store/entities/activity-model/activity-model';
import { Select } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { createModelSchemaValidator } from '~schemas/ajv-validators';
import { AUTO_SELECT_TEMPLATE } from '~src/enums/settings';
import { selectUserTempaltes } from '~store/entities/user-settings/selectors';
import { selectCompanyTemplates } from '~store/entities/company-settings/selectors';
import { selectOperationList } from '~store/entities/activity-model/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CreateModelModal = ({
  showCreateModelModalUpdated,
  isFetching,
  operations,
  templatesOptions,
  projectId,
  selectedTemplateId,
  isActivityModel = false,
  createModel,
  getOperations,
  operationsCleared,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();

  useEffect(() => {
    if (projectId && !isActivityModel) {
      getOperations(projectId);
    }
    return () => {
      operationsCleared();
    };
  }, [projectId, isActivityModel]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      template: selectedTemplateId || AUTO_SELECT_TEMPLATE,
      includeCostSetup: true,
    },
    resolver: getResolver(createModelSchemaValidator),
    mode: 'onChange',
  });
  const onClose = () => showCreateModelModalUpdated(false);
  const onSubmit = handleSubmit((data) => {
    createModel(projectId, data.template, data.includeCostSetup, companyId);
  });
  const { includeCostSetup } = watch();

  const noActivityModel = !operations?.length;
  const isActivityModelManualyCreated =
    operations?.length && !selectedTemplateId;

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          width: '600px',
          heading: t(translations.automationRulesets),
          content: (
            <div
              style={{
                position: 'relative',
                margin: 'calc(var(--padding) * -1)',
              }}
            >
              {isFetching && (
                <Loader text={t(translations.adding)} theme="light" cover>
                  <Spinner dark />
                </Loader>
              )}
              <form style={{ padding: 'var(--padding)' }}>
                <Field label={t(translations.ruleset)}>
                  <Select
                    options={templatesOptions}
                    name="template"
                    control={control}
                    errors={errors}
                    disabled={!isActivityModel}
                  />
                </Field>
                {isActivityModel && (
                  <CheckBox
                    onChange={() =>
                      setValue('includeCostSetup', !includeCostSetup)
                    }
                    label={'Include Cost setup'}
                    checked={includeCostSetup}
                    helpText={
                      'Cost setup will be removed and generated new one from template'
                    }
                  />
                )}
                <Message
                  message={{
                    content: t(translations.costSetup_generateBeforeWarning),
                    icon: true,
                    type: 'Warning',
                    visible: !isActivityModel && noActivityModel,
                  }}
                />
                <Message
                  message={{
                    content: t(translations.costSetup_manuallyCreatedWarning),
                    icon: true,
                    type: 'Warning',
                    visible: !isActivityModel && isActivityModelManualyCreated,
                  }}
                />
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label="Create"
                colored
                onClick={onSubmit}
                disabled={
                  isFetching ||
                  (!isActivityModel &&
                    (noActivityModel || isActivityModelManualyCreated))
                }
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const operations = selectOperationList({ entities });
  const userTemplates = selectUserTempaltes(entities);
  const companyTemplates = selectCompanyTemplates(entities);
  const getSelectedTemplateId = () => {
    const tempalateOperationIds = operations?.map(
      (operation) => operation?.templateOperationId,
    );
    const templateId = [...userTemplates, ...companyTemplates]?.find(
      (template) =>
        template?.activityModel?.some((operation) =>
          tempalateOperationIds?.includes(operation?.id),
        ),
    )?.templateId;
    return templateId;
  };
  const templatesOptions = [
    {
      label: i18n.t(translations.automaticallySelect),
      value: AUTO_SELECT_TEMPLATE,
    },
    {
      label: i18n.t(translations.userRulesets),
      type: 'Heading',
    },
    ...userTemplates.map((template) => ({
      label: template.highLevel.name,
      value: template.templateId,
    })),
    {
      label: i18n.t(translations.companyRulesets),
      type: 'Heading',
    },
    ...companyTemplates?.map((template) => ({
      label: template.highLevel.name,
      value: template.templateId,
    })),
  ];

  return {
    templatesOptions,
    operations,
    selectedTemplateId: getSelectedTemplateId(),
  };
};

const mapDispatchToProps = { getOperations, operationsCleared };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CreateModelModal),
  { isModal: true },
);

export { Container as CreateModelModal };
