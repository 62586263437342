import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import {
  ISection,
  IOperation,
  IActivity,
  IDataStructureState,
} from '~src/common/interfaces/sections-data-structure.interfaces';

const initialState: IDataStructureState = {
  isFetching: false,
  sections: [],
  operations: [],
  activities: [],
};

const sectionsDataStructure = createSlice({
  name: 'sectionsDataStructure',
  initialState,
  reducers: {
    sectionsDataStructureRequested: (sectionsDataStructure) => {
      sectionsDataStructure.isFetching = true;
    },
    sectionsDataStructureRecieved: (
      sectionsDataStructure,
      action: PayloadAction<{
        sections: ISection[];
        operations: IOperation[];
        activities: IActivity[];
      }>,
    ) => {
      const { sections, operations, activities } = action.payload;
      sectionsDataStructure.isFetching = false;
      sectionsDataStructure.sections = sections;
      sectionsDataStructure.operations = operations;
      sectionsDataStructure.activities = activities;
    },
    sectionSelected: (sectionsDataStructure, action) => {
      sectionsDataStructure.sections = sectionsDataStructure.sections.map(
        (item: ISection) => {
          item.active = item.sectionId === action.payload;
          return item;
        },
      );
    },
    operationSelected: (sectionsDataStructure, action) => {
      sectionsDataStructure.operations = sectionsDataStructure.operations.map(
        (item: IOperation) => {
          item.active = item.sectionsOperationId === action.payload;
          return item;
        },
      );
    },
  },
});

export const {
  sectionsDataStructureRequested,
  sectionsDataStructureRecieved,
  sectionSelected,
  operationSelected,
} = sectionsDataStructure.actions;

export default sectionsDataStructure.reducer;

export const getSectionsDataStructure = () =>
  apiCallBegan({
    url: '/api/sections-data-structure',
    method: 'GET',
    onStart: sectionsDataStructureRequested.type,
    onSuccess: sectionsDataStructureRecieved.type,
  });
