import {
  TArrayRepresentedGridCoordinates,
  ChartDrawerParameters,
} from '../utils/types';
import { createSinePoints } from './helpers';

export const drawHoles = ({
  chart,
  chartData,
  drawUtils,
}: ChartDrawerParameters) => {
  const { depthToGridOffset, diagramLine, diameterToGridOffset } = drawUtils;
  const holesGroup = chart.append('g').attr('class', 'holes');

  chartData.sections.forEach((section) => {
    if (!section.hole) {
      return;
    }

    const { bottom, color, holesize, top, width } = section.hole;

    const leftHolePoints = createSinePoints(
      [diameterToGridOffset(holesize), depthToGridOffset(top)],
      [diameterToGridOffset(holesize), depthToGridOffset(bottom)],
    );
    const rightHolePoints = createSinePoints(
      [diameterToGridOffset(-holesize), depthToGridOffset(top)],
      [diameterToGridOffset(-holesize), depthToGridOffset(bottom)],
    );

    const drawHole = (holePoints: TArrayRepresentedGridCoordinates) =>
      holesGroup
        .append('path')
        .datum(holePoints)
        .attr('class', 'hole')
        .attr('d', diagramLine)
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', width);

    drawHole(leftHolePoints);
    drawHole(rightHolePoints);
  });
};
