import { get, isString } from 'lodash';
import { push } from 'redux-first-history';
import { generatePath as generatePathBase, matchPath } from 'react-router';

export const generatePath = (route, params, isFull = false) => {
  const path = get(
    route,
    isFull ? 'fullPath' : 'pattern.path',
    isString(route) ? route : undefined,
  );
  return generatePathBase(path, params);
};

export const isActiveRoute = (route, url) => {
  const path = get(route, 'fullPath', isString(route) ? route : undefined);
  return !!matchPath(path, url);
};

export const navigateToPath = (path) => (dispatch) => dispatch(push(path));

export const navigateToRoute = (route, params) => (dispatch) => {
  const path = generatePath(route, params, true);
  dispatch(push(path));
};
