/* eslint-disable import/no-cycle */
/* eslint-disable no-plusplus */
import GIS_DATA from '@oliasoft/gis-library/results/data-in-custom-groups.json';
import EPSG_LOOKUP from '@oliasoft/gis-library/results/epsg-lookup.json';
import {
  EPSG_OFFSET,
  EPSG_OFFSET_POLAR,
  FALLBACK_VALUE,
  LEGACY_MAP_SYSTEM_KEY,
} from '~common/gis/constants';
import { transformLocationCoordinate } from '~common/gis/transform-location-coordinate';
import { round } from '@oliasoft-open-source/units';
import { isEpsgCodeValid } from '~common/gis/validate-location';

/**
 * Convert string to camelCase
 * @param {string} str
 * @return {string}
 */
const toCamelCase = (str) => {
  if (typeof str !== 'string') {
    return null;
  }

  let i = 0;
  const res = str
    .replaceAll(/\W+|_+/gi, ' ')
    .trim()
    .toLowerCase()
    .split(' ')
    .map((el) => {
      if (i > 0) {
        i++;
        return Number(el?.[0]) ? el : el?.[0].toUpperCase() + el.slice(1);
      } else {
        i++;
        return el;
      }
    })
    .join('');

  return res;
};

/**
 * Extract location name as substring of standard CRS name
 * @param {string} standardCRSName - original CRS name (e.g. "Datum / map_system zone N") provided by `index-epsg` library
 * @return {string} - location name (e.g. "zone N")
 */
const getLocationName = (standardCRSName) => {
  // clean up datum substring
  const datumSeparators = ['/', 'StatePlane', 'UTM'];
  const datumSeparator = datumSeparators.find((separator) =>
    standardCRSName.includes(separator),
  );
  const locationWithoutDatum = datumSeparator
    ? standardCRSName.split(datumSeparator)[1]
    : standardCRSName;

  // clean up unit substring
  const unitStrings = ['(ft)', '(ftUS)', '(ch)', '(m)', '(ftSe)', 'Feet'];
  const unit = unitStrings.find((unitsString) =>
    locationWithoutDatum.includes(unitsString),
  );
  const locationName = unit
    ? locationWithoutDatum.replace(unit, '')
    : locationWithoutDatum;

  return locationName.trim();
};

/**
 * @param {string} epsgCode - EPSG code in format 'EPSG:xxxxxx'
 * @returns {string|null} - returns mapSystemKey from EPSG_LOOKUP
 *
 */
export const getMapSystemKey = (epsgCode) => {
  return EPSG_LOOKUP?.[epsgCode]?.customGroupKeyName ?? null;
};

/**
 * @param {string} epsgCode - EPSG code in format 'EPSG:xxxxxx'
 * @returns {string|null} - returns datumKey from EPSG_LOOKUP
 */
export const getDatumKeyByEPSG = (epsgCode) => {
  return EPSG_LOOKUP?.[epsgCode]?.datumKey ?? null;
};

/**
 * @param {string} mapSystemKey - top level property name of GIS_DATA json
 * @returns {string|null} - returns first datum key that belongs to provided mapSystem
 */
export const getFirstDatumKeyByMapSystem = (mapSystemKey) => {
  if (!mapSystemKey) {
    return null;
  }
  return Object.keys(GIS_DATA?.[mapSystemKey]?.itemsByDatum)?.[0] ?? null;
};

/**
 * Checks if provided EPSG code indicates offset location type.
 * Only well and target location can be offset (from reference point, which is site location)
 * @param {string} epsgCode - EPSG code in format 'EPSG:xxxxxx'
 * @returns {boolean}
 */
export const isOffsetType = (epsgCode) => {
  return epsgCode === EPSG_OFFSET || epsgCode === EPSG_OFFSET_POLAR;
};

/**
 * Checks if provided EPSG code indicates location in legacy DD format.
 * If so, it has to be updated by user form UI location selectors.
 * @param {string} epsgCode - EPSG code in format 'EPSG:xxxxxx'
 * @returns {boolean}
 */
export const isGCSLocation = (epsgCode) => {
  const customGroupKeyName = getMapSystemKey(epsgCode);
  return customGroupKeyName === LEGACY_MAP_SYSTEM_KEY;
};

/**
 * Get proj4 string by epsg code
 * @param {string} epsgCode - EPSG code in format 'EPSG:xxxxxx'
 * @returns {string|null}
 */
export const getProjString = (epsgCode) => {
  return EPSG_LOOKUP?.[epsgCode]?.definition?.[1] ?? null;
};

/**
 * Get EPSG code of datum that is used by CRS.
 * Usually used for coordinate conversion from cartesian (2d in units of length) to geographic (3d in decimal degrees).
 * @param {string} epsgCode - EPSG code in format 'EPSG:xxxxxx'
 * @returns {string} - datum EPSG code in format 'EPSG:xxxxxx'
 */
export const getDatumEPSG = (epsgCode) => {
  const { customGroupKeyName, datumKey } = EPSG_LOOKUP?.[epsgCode] || {};
  return GIS_DATA?.[customGroupKeyName]?.itemsByDatum?.[datumKey]?.datumEpsg;
};

/**
 * Get all epsg items that belong to certain map system and datum
 * @param {string} mapSystemKey
 * @param {string} datumKey
 * @returns {import('common/gis/gis.interfaces').IEPSGItem[]|[]}
 */
export const getAllLocationItems = (mapSystemKey, datumKey) => {
  const allLocationItems =
    GIS_DATA?.[mapSystemKey]?.itemsByDatum?.[datumKey]?.elements;
  return allLocationItems || [];
};

/**
 * @returns {import('common/gis/gis.interfaces').IMapSystemOption[]} - sorted by label array of mapSystem options
 */
export const getAllMapSystemOptionsSorted = () => {
  const mapSystemKeys = Object.keys(GIS_DATA);
  const mapSystemOptions = mapSystemKeys.reduce((acc, curMapSystemKey) => {
    const option = {
      label: GIS_DATA[curMapSystemKey]?.customGroupLabel ?? null,
      value: curMapSystemKey,
      disabled: curMapSystemKey === LEGACY_MAP_SYSTEM_KEY,
    };
    return [...acc, option];
  }, []);

  return mapSystemOptions.sort((a, b) => a?.label?.localeCompare(b?.label));
};

/**
 * @param {string} mapSystemKey
 * @returns {import('common/gis/gis.interfaces').IOption[]} - sorted by label array of datum options
 * */
export const getDatumOptions = (mapSystemKey) => {
  if (!mapSystemKey || !GIS_DATA?.[mapSystemKey]?.itemsByDatum) {
    return [];
  }
  const allDatums = Object.values(GIS_DATA[mapSystemKey].itemsByDatum);
  const datumOptions = allDatums.map((el) => {
    return {
      value: el?.datumName?.key,
      label: el?.datumName?.label,
    };
  });
  return datumOptions.sort((a, b) => a?.label?.localeCompare(b?.label));
};

/**
 * @param {import('common/gis/gis.interfaces').IEPSGItem[]} locationElements - array of location items that belong to one map system and datum
 * @return {import('common/gis/gis.interfaces').IOption[]} - array of unique location options
 */
export const getLocationOptions = (locationElements) => {
  if (!locationElements?.[0]?.name) {
    return [];
  }
  const uniqueNames = [
    ...new Set(locationElements.map(({ name }) => getLocationName(name))),
  ];
  const validUniqueNames = uniqueNames.filter((uniqueName) => !!uniqueName);

  const locationOptions = validUniqueNames.map((uniqueName) => {
    return {
      label: uniqueName,
      value: toCamelCase(uniqueName),
    };
  });
  return locationOptions;
};

/***
 * @param {import('common/gis/gis.interfaces').IEPSGItem[]} locationElements - array of location items that belong to one map system and datum
 * @param {import('common/gis/gis.interfaces').IOption} selectedLocationOption - selected location option
 * @returns {import('common/gis/gis.interfaces').IOption[]} - list of unique unit options
 */
export const getUnitOptions = (locationElements, selectedLocationOption) => {
  if (!locationElements?.length || !selectedLocationOption?.value) {
    return [];
  }
  const selectedLocationItems = locationElements.filter((el) => {
    const [elementAsOption] = getLocationOptions([el]) || [];
    return elementAsOption?.value === selectedLocationOption.value;
  });

  const uniqueUnitKeys = [
    ...new Set(selectedLocationItems.map((el) => el.unit.key)),
  ].filter((uniqueKey) => !!uniqueKey);

  const unitOptions = uniqueUnitKeys.map((uniqueKey) => {
    const locationItem = selectedLocationItems.find(
      (el) => el.unit.key === uniqueKey,
    );
    return {
      label: locationItem?.unit?.label,
      value: locationItem?.unit?.key,
    };
  });

  return unitOptions;
};

/**
 * @param {import('common/gis/gis.interfaces').IEPSGItem[]} locationElements - array of location items that belong to one map system and datum
 * @param {import('common/gis/gis.interfaces').IOption} selectedLocationOption - selected location option
 * @param {import('common/gis/gis.interfaces').IOption} selectedLocationUnitOption - selected unit option
 * @return {string|null} - EPSG code
 */
export const getEpsgCode = (
  locationElements,
  selectedLocationOption,
  selectedLocationUnitOption,
) => {
  if (
    !locationElements?.length ||
    !selectedLocationOption?.value ||
    !selectedLocationUnitOption?.value
  ) {
    return null;
  }

  const itemsSelectedByName = locationElements.filter((el) => {
    const [locationOption] = getLocationOptions([el]) || [];
    return locationOption?.value === selectedLocationOption.value;
  });

  const item = itemsSelectedByName.find((el) => {
    const [locationOption] = getLocationOptions([el]) || [];
    const [unitOption] = getUnitOptions([el], locationOption) || [];
    return unitOption?.value === selectedLocationUnitOption.value;
  });

  return item?.code ?? null;
};

/**
 * @param {string} epsgCode - EPSG code in format 'EPSG:xxxxxx'
 * @returns {import('common/gis/gis.interfaces').ISpheroid} - properties of spheroid (elipsoide)
 */
const getSpheroidInfoByEPSG = (epsgCode) => {
  if (!epsgCode || isOffsetType(epsgCode)) {
    return {};
  }
  const mapSystemKey = getMapSystemKey(epsgCode);
  const datumKey = getDatumKeyByEPSG(epsgCode);
  return GIS_DATA?.[mapSystemKey]?.itemsByDatum?.[datumKey]?.spheroid || {};
};

/**
 * @param {string} epsgCode - EPSG code in format 'EPSG:xxxxxx'
 * @return {import('common/gis/gis.interfaces').IEPSGItem|{}} - EPSG code of map system item in format 'EPSG:xxxxxx'
 */
export const getLocationInfoByEpsg = (epsgCode) => {
  if (!epsgCode || isOffsetType(epsgCode)) {
    return {};
  }

  const mapSystem = getMapSystemKey(epsgCode);
  const datum = getDatumKeyByEPSG(epsgCode);
  const allItems = getAllLocationItems(mapSystem, datum);
  const locationInfo = allItems?.find((el) => el.code === epsgCode) || {};
  return {
    ...locationInfo,
    spheroid: getSpheroidInfoByEPSG(epsgCode),
  };
};

/**
 * @param {string} epsgCode - EPSG code in format 'EPSG:xxxxxx'
 * @returns {string} - unit of projected CRS
 */
export const getProjectionUnit = (epsgCode) => {
  const unitMap = {
    meter: 'm',
    clarkeSLink: 'lkCla',
    goldCoastFoot: 'ftGC',
    foot: 'ft',
    usSurveyFoot: 'usft',
    link: 'lk',
    britishChainSears1922Truncated: 'chSe(t)',
    clarkeSFoot: 'ftCla',
    footInternational: 'ft',
    footUs: 'usft',
    indianYard: 'ydInd',
    britishYardSears1922: 'ydSe',
    britishChainSears1922: 'chSe',
  };
  const { projectionUnit } = EPSG_LOOKUP[epsgCode] || {};
  return unitMap?.[projectionUnit];
};

/**
 * Function returns a BBox - box to showcase the boundaries of the map projection
 * @param {string} epsgCode - EPSG code in format 'EPSG:xxxxxx'
 * @returns {[number, number, number, number]|[]} - BBox Array
 */
export const getProjectionBBox = (epsgCode) => {
  if (!epsgCode || isOffsetType(epsgCode)) {
    return [];
  }
  return getLocationInfoByEpsg(epsgCode)?.bbox ?? [];
};

/**
 * Returns value of CRS parameter based proj4 string definition
 * @param {string} projString - CRS definition as proj string (e.g. +proj=lcc +lat_0=-90 +lon_0=-144 +lat_1=-76.6666666666667 +lat_2=-79.3333333333333)
 * @param {string} parameterKey - proj4 parameter key (e.g. +proj, proj, lat_0, lon_0, lat_1, lat_2)
 * @return {*} - parameter value
 */
export const getProjParameterValue = (projString, parameterKey) => {
  return projString
    .split(' ')
    .find((el) => el.includes(parameterKey))
    ?.split('=')?.[1];
};

/**
 * Convert border box in decimal degrees into four cartesian points
 * @param  {string} epsgCode - location epsgCode
 * @return {{y1: number, x1: number, y2: number, x2: number, y3: number, x3: number, y4: number, x4: number} | {}}
 */
export const getCartesianBBoxPoints = (epsgCode) => {
  const bboxPoints = {};
  const bboxKeys = ['x1', 'y1', 'x2', 'y2', 'x3', 'y3', 'x4', 'y4'];

  if (epsgCode && isEpsgCodeValid(epsgCode)) {
    const [lat0, long0, lat1, long1] = getProjectionBBox(epsgCode); // coordinates in DD
    // transform DD coordinate to Cartesian (to match input format)
    const datumEpsg = getDatumEPSG(epsgCode);
    const [x1, y1] = transformLocationCoordinate(datumEpsg, epsgCode, [
      long0,
      lat0,
    ]);
    const [x2, y2] = transformLocationCoordinate(datumEpsg, epsgCode, [
      long0,
      lat1,
    ]);
    const [x3, y3] = transformLocationCoordinate(datumEpsg, epsgCode, [
      long1,
      lat1,
    ]);
    const [x4, y4] = transformLocationCoordinate(datumEpsg, epsgCode, [
      long1,
      lat0,
    ]);
    bboxPoints.x1 = x1 ?? FALLBACK_VALUE;
    bboxPoints.y1 = y1 ?? FALLBACK_VALUE;
    bboxPoints.x2 = x2 ?? FALLBACK_VALUE;
    bboxPoints.y2 = y2 ?? FALLBACK_VALUE;
    bboxPoints.x3 = x3 ?? FALLBACK_VALUE;
    bboxPoints.y3 = y3 ?? FALLBACK_VALUE;
    bboxPoints.x4 = x4 ?? FALLBACK_VALUE;
    bboxPoints.y4 = y4 ?? FALLBACK_VALUE;
  } else {
    bboxKeys.forEach((key) => (bboxPoints[key] = FALLBACK_VALUE));
  }

  return bboxPoints;
};

/**
 * Get limits for coordinate easting and northing inputs
 * @param {string} epsgCode - EPSG code in format 'EPSG:xxxxxx'
 * @return {{eastingLimit: array, northingLimit: array}} - where the first element is the lowest allowed boundary and the second - the highest
 */
export const getCartesianInputLimits = (epsgCode) => {
  const limits = {
    eastingLimit: [],
    northingLimit: [],
  };

  if (!epsgCode || !isEpsgCodeValid(epsgCode)) {
    return limits;
  }

  const roundCallBack = (el) => round(el, 0);
  const { x1, y1, x2, y2, x3, y3, x4, y4 } = getCartesianBBoxPoints(epsgCode);
  const roundedX = [x1, x2, x3, x4].map(roundCallBack);
  const roundedY = [y1, y2, y3, y4].map(roundCallBack);

  limits.eastingLimit = [Math.min(...roundedX), Math.max(...roundedX)];
  limits.northingLimit = [Math.min(...roundedY), Math.max(...roundedY)];
  return limits;
};

/**
 * Calculates arithmetic central point of bbox
 * @param {string} epsgCode - location EPSG code
 * @return {{centralY: number|NaN, centralX: number|NaN}} - returns values rounded to whole number as central point is a recommendation to user
 */
export const getBBoxCentralPoint = (epsgCode) => {
  const { x1, y1, x2, y2, x3, y3, x4, y4 } = getCartesianBBoxPoints(epsgCode);
  const getAverage = (arr) =>
    arr.reduce((sum, value) => sum + value, 0) / arr.length;
  const centralX = getAverage([x1, x2, x3, x4]);
  const centralY = getAverage([y1, y2, y3, y4]);
  return {
    centralX: round(centralX, 0),
    centralY: round(centralY, 0),
  };
};
