import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListSubheading, List } from '@oliasoft-open-source/react-ui-library';
import {
  getCodeName,
  subCodeSelected,
} from '~store/entities/operation-codes/operation-codes';
import translations from '~src/internationalisation/translation-map.json';

const SubCodesList = ({
  title,
  codes,
  subCodeSelected,
  onClickAdd,
  onClickDelete,
  activeCodesList,
}) => {
  const { t } = useTranslation();
  const [listExpanded, setListExpanded] = useState(true);

  const codesList = codes.map((code) => ({
    id: code.codeId,
    name: getCodeName(code),
    active: code.active,
    onClick: () => subCodeSelected(code.codeId),
    ...(activeCodesList?.default
      ? {}
      : {
          actions: [
            {
              label: t(translations.delete),
              icon: 'delete',
              onClick: () => onClickDelete(code.codeId),
            },
          ],
        }),
  }));

  return (
    <>
      <ListSubheading
        item={{
          name: title,
          metaCount: codes.length,
          expanded: listExpanded,
          onClick: () => setListExpanded(!listExpanded),
          ...(activeCodesList?.default
            ? {}
            : {
                actions: [
                  {
                    label: 'Add',
                    primary: true,
                    icon: 'add',
                    onClick: () => onClickAdd(),
                  },
                ],
              }),
        }}
      />
      {listExpanded && (
        <List
          list={{
            items: codesList,
          }}
          noHeader
        />
      )}
    </>
  );
};

const mapDispatchToProps = { subCodeSelected };

const Container = connect(null, mapDispatchToProps)(SubCodesList);

export { Container as SubCodesList };
