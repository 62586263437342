import { cleanNum } from '@oliasoft-open-source/units';

export const onChangeInput = (e, type, fieldOnChange, onChange) => {
  const { value } = e.target;
  if (type === 'number') {
    if (value.endsWith(',') || value.endsWith('.')) {
      fieldOnChange(value);
    } else {
      const replaceComasToDotValue = value.replace(/,/, '.');
      const numberValue = cleanNum(replaceComasToDotValue) || 0;
      fieldOnChange(numberValue);
    }
  } else {
    fieldOnChange(value);
  }

  if (onChange) {
    onChange(e);
  }
};

export const onChangeNumberInput = (event, fieldOnChange, onChange) => {
  const input = event.target;
  const { value } = input;

  if (value.endsWith(',') || value.endsWith('.')) {
    fieldOnChange(value);
  } else {
    const replaceComasToDotValue = value.replace(/,/, '.');
    const numberValue = cleanNum(replaceComasToDotValue) || 0;
    fieldOnChange(numberValue);
  }

  if (onChange) {
    onChange(event);
  }
};
