import { createSelector } from '@reduxjs/toolkit';

const selectUserSettingsStore = (entities) => entities.userSettings;

export const selectUserSettings = createSelector(
  [selectUserSettingsStore],
  (userSettingsStore) => userSettingsStore.settings,
);

export const selectUserTempaltes = createSelector(
  [selectUserSettings],
  (userSettings) => userSettings.templates,
);

export const selectUserUnits = createSelector(
  [selectUserSettings],
  (userSettings) => userSettings.units,
);

export const selectUserSectionTypes = createSelector(
  [selectUserSettingsStore],
  (userSettingsStore) => userSettingsStore.sectionTypes,
);

export const selectActiveTemplateOperations = createSelector(
  [selectUserTempaltes],
  (templates) => templates.find((template) => template.active).activityModel,
);

export const selectGroupedOperations = createSelector(
  [selectUserSectionTypes, selectActiveTemplateOperations],
  (sections, operationList) =>
    sections.reduce(
      (acc, { linkedSectionId, sectionName, sectionTypeName }) => {
        const sectionOperation = operationList.filter(
          ({ linkedSectionType }) => linkedSectionType === linkedSectionId,
        );
        if (sectionOperation.length > 0) {
          acc.push({
            linkedSectionId,
            sectionName,
            operations: sectionOperation,
            sectionTypeName,
          });
        }
        return acc;
      },
      [],
    ),
);
