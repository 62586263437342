export const project = Object.freeze({
  COUNTRIES_LIST: 'country',
  FIELDS_LIST: 'field',
  SITES_LIST: 'site',
  WELLS_LIST: 'well',
  WELLBORES_LIST: 'wellbore',
  DESIGNS_LIST: 'design',
  ESTIMATES_LIST: 'estimate',
});

export const createEstimateOptions = Object.freeze({
  NEW: 'new',
  COPY: 'copy',
  IMPORT: 'import',
});

export const pageStatuses = Object.freeze({
  ACTIVE: 'active',
  FINISHED: 'finished',
  DISABLED: 'disabled',
});

export const projectPages = Object.freeze({
  WELL_DETAILS: 'wellDetails',
  ACTIVITY_MODEL: 'activityModel',
  PROJECT_RISKS: 'projectRisks',
  COST_MODEL: 'costModel',
  REVIEW_RESULTS: 'reviewResults',
  TIME_TRACKER: 'timeTracker',
});

export const ProjectCategoryType = Object.freeze({
  Actual: 'Actual',
  Prototype: 'Prototype',
});
