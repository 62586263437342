import { isNumeric } from '@oliasoft-open-source/units';
import { FALLBACK_DMS_VALUE, FALLBACK_VALUE } from '~common/gis/constants';

/**
 * @param {*} args - any arguments
 * @returns {boolean}
 */
export const areAllArgumentsNumeric = (...args) => {
  return args.every((argument) => isNumeric(argument));
};

/**
 * @returns {import('common/gis/gis.interfaces').TDMSCoordinate}
 */
export const getFallbackDmsCoordinate = () => [
  FALLBACK_DMS_VALUE,
  FALLBACK_DMS_VALUE,
];

export const getFallbackCoordinate = () => [FALLBACK_VALUE, FALLBACK_VALUE];
