import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';

const auditLog = createSlice({
  name: 'auditLog',
  initialState: {
    isFetching: false,
    logs: [],
  },
  reducers: {
    logsRequested: (campaigns) => {
      campaigns.isFetching = true;
    },
    logsFetched: (campaigns, action) => {
      campaigns.isFetching = false;
      campaigns.logs = action.payload.logs;
    },
    logsCleanup: (campaigns) => {
      campaigns.logs = [];
    },
  },
});

export const { logsRequested, logsFetched, logsCleanup } = auditLog.actions;
export default auditLog.reducer;

/**
 * Retrieves the logs of a project from the server.
 *
 * @param {string} projectId - The ID of the project whose logs are to be retrieved.
 * @returns {void}
 */
export const getLogs = (projectId) =>
  apiCallBegan({
    url: `/api/audit-log/${projectId}`,
    method: 'GET',
    onStart: logsRequested.type,
    onSuccess: (response) => ({
      type: logsFetched.type,
      payload: response,
    }),
  });
