import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  Row,
  Column,
  Heading,
  Spacer,
  Text,
  ButtonGroup,
  Flex,
  Popover,
  Button,
} from '@oliasoft-open-source/react-ui-library';
import {
  addTasks,
  getTimeTrackerItems,
  listCleared,
  sycnTimeTracker,
} from '~store/entities/time-tracker/time-tracker';
import { selectIsTimeTrackerLatest } from '~src/store/entities/time-tracker/selectors';
import translations from '~src/internationalisation/translation-map.json';
import { DateTimePicker } from '~src/views/time-tracker/date-time-picker/date-time-picker';
import { round } from '@oliasoft-open-source/units';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { formatTimeToUI } from './utils/date/dateUtils';

const TimeTrackerHeader = ({
  well,
  firstOperation,
  lastOperation,
  totalAFE,
  totalVariance,
  isPageDisabled,
  onViewModeChange,
  viewMode,
  sycnTimeTracker,
  isLatestVersion,
}) => {
  const { t } = useTranslation();
  const { project: projectId } = useParams();
  return (
    <>
      <Flex gap justifyContent="space-between">
        <Flex gap="12px">
          <Heading top>{t(translations.timeTracker_timeTracker)}</Heading>
          <ButtonGroup
            items={[
              { key: 1, icon: 'table' },
              { key: 0, icon: 'chart' },
            ]}
            small
            onSelected={(key) => onViewModeChange(key)}
            value={viewMode ? 1 : 0}
          />
          {!isLatestVersion && (
            <div>
              <Button
                small
                label="Sync Time Tracker to latest simulation"
                icon="sync"
                onClick={() => sycnTimeTracker(projectId)}
              />
            </div>
          )}
        </Flex>
      </Flex>
      <Spacer />
      <Row>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.wellName)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{well}</Text>
          </Row>
        </Column>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.timeTracker_totalAFE)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{`${totalAFE} days`}</Text>
          </Row>
        </Column>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.timeTracker_startDateAndTime)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{formatTimeToUI(firstOperation?.start)}</Text>
            <Spacer width="var(--padding-xxs)" />

            <Popover
              content={
                <DateTimePicker
                  firstOperation={firstOperation}
                  disabled={isPageDisabled}
                />
              }
              placement="top-center"
              disabled={isPageDisabled}
              overflowContainer
            >
              <Text link>{t(translations.edit)} </Text>
            </Popover>
          </Row>
        </Column>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.timeTracker_estimatedFinish)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{formatTimeToUI(lastOperation?.finish)}</Text>
          </Row>
        </Column>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.timeTracker_variance)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{`${totalVariance} hours`}</Text>
          </Row>
        </Column>
      </Row>
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  // TODO: add selectors
  const { list, isFetching, disabledFields } = entities.timeTracker;
  const isLatestVersion = selectIsTimeTrackerLatest(entities);
  const { well } = entities.projects.overview;
  const firstOperation = list[0];
  const lastOperation = list?.slice(-1)[0];
  const totalAFE = round(
    list.reduce((acc, operation) => acc + operation.afe, 0) / 24,
    2,
  );
  const totalVariance = round(
    list?.reduce(
      (sum, operation) =>
        sum +
        operation.activities.reduce(
          (acc, activity) =>
            acc + (activity.actual ? activity.actual - activity.afe : 0),
          0,
        ),
      0,
    ),
    2,
  );
  return {
    itemsList: list,
    isFetching,
    well,
    firstOperation,
    lastOperation,
    totalAFE,
    totalVariance,
    disabledFields,
    isLatestVersion,
  };
};

const mapDispatchToProps = {
  getTimeTrackerItems,
  listCleared,
  addTasks,
  sycnTimeTracker,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(TimeTrackerHeader),
);

export { Container as TimeTrackerHeader };
