import { io } from 'socket.io-client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from '@oliasoft/authentication/pkce';

export const SocketContext = createContext(null);

/**
 * @type {{
 *   change_scope: import('./socket.interface').WebsocketEvents,
 *   notification_message: import('./socket.interface').WebsocketEvents
 * }}
 */
export const WEBSOCKET_EVENTS = {
  change_scope: 'CHANGE_SCOPE',
  notification_message: 'NOTIFICATION_MESSAGE',
};

export const SocketProvider = ({ children }) => {
  const auth = useAuth();
  const hostEnv = window.__runtimeConfigStatic?.host_env;
  const wsBaseUrl =
    window.__runtimeConfigStatic?.notification_websocket_url ||
    'wss://notification-service.test.oliasoft.com';

  const initSocket = io(`${wsBaseUrl}`, {
    addTrailingSlash: false,
    path: '/websockets',
    autoConnect: false,
    withCredentials: true,
    query: {
      hostenv: hostEnv,
    },
    auth: {
      // token: accessToken,
    },
  });
  const [isConnected, setConnected] = useState(false);
  const [socket, setSocket] = useState(initSocket);
  const accessToken = auth?.user?.access_token;

  useEffect(() => {
    let timeoutAfterErrorID;
    let timeoutAfterRenewID;
    const timeoutAfterError = 3000;
    const timeoutAfterRenew = 500;
    function onConnect() {
      console.info('Authenticated and Connected!', socket.id);
      setSocket(socket);
      setConnected(true);
    }
    function onDisconnect(reason) {
      console.info('Disconnected with reason', reason);
      setSocket(initSocket);
      setConnected(false);
    }
    function handleConnectError(err) {
      console.info('Connection has not been established.', err.message);
      timeoutAfterErrorID = setTimeout(() => {
        socket.connect();
      }, timeoutAfterError);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', handleConnectError);

    if (accessToken) {
      socket.auth.token = accessToken;

      if (socket.connected) {
        console.log('socket-context about to be disconnected');
        socket.disconnect();
      }

      timeoutAfterRenewID = setTimeout(() => {
        socket.connect();
      }, timeoutAfterRenew);
    }

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', handleConnectError);
      clearTimeout(timeoutAfterErrorID);
      clearTimeout(timeoutAfterRenewID);
    };
  }, [accessToken]);

  return (
    <SocketContext.Provider value={{ socket, isConnected }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
