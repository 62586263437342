/* eslint-disable camelcase */
import { to } from '@oliasoft-open-source/units';
import { ProjAliasType } from '~src/enums/gis-enums';
import transverseScaleFactor from '~common/gis/transverse-scale-factor';
import { transformLocationCoordinate } from '~common/gis/transform-location-coordinate';
import { areAllArgumentsNumeric } from '~common/gis/helpers';
import {
  getDatumEPSG,
  isOffsetType,
  getLocationInfoByEpsg,
  getProjParameterValue,
} from '~common/gis/gis-library-utils';
import { DEFAULT_SCALE_FACTOR } from '~common/gis/constants';
import obliqueScaleFactor from '~common/gis/oblique-scale-factor';

/**
 * @desc Payload to transverse mercator scale factor calculation
 * @typedef TransversePayload
 * @property {boolean} applyScale
 * @property {number} k_0 - scale factor
 * @property {number} longRad - longitude [radians]
 * @property {number} latRad - latitude [radians]
 * @property {number} centralMeridianRad - central meridian [radians]
 * @property {import('common/gis/gis.interfaces').ISpheroid} spheroid - properties of spheroid (elipsoide)
 */

/**
 * @desc Payload to oblique mercator scale factor calculation
 * @typedef ObliquePayload
 * @property {number} longRad - longitude [radians]
 * @property {number} latRad - latitude [radians]
 * @property {number} lat0Rad - latitude of the projection centre [radians]
 * @property {number} lonCRad - longitude of the projection centre [radians]
 * @property {number} alphaCRad - azimuth of centerline clockwise from north at the center point of the line [radians]
 * @property {boolean} k_0 - scale factor
 * @property {import('common/gis/gis.interfaces').ISpheroid} spheroid - properties of spheroid (elipsoide) */

/**
 * @param {TransversePayload} transversePayload
 * @return {number}
 */
const calculateTransverseScaleFactor = (transversePayload) => {
  const { applyScale, k_0, latRad, longRad, centralMeridianRad, spheroid } =
    transversePayload;

  return applyScale && k_0
    ? transverseScaleFactor(longRad, latRad, centralMeridianRad, k_0, spheroid)
    : DEFAULT_SCALE_FACTOR;
};

/**
 * @param {ObliquePayload} obliquePayload
 * @return {number|number}
 */
const calculateObliqueScaleFactor = (obliquePayload) => {
  const { longRad, latRad, lat0Rad, lonCRad, alphaCRad, k_0, spheroid } =
    obliquePayload;

  return obliqueScaleFactor(
    latRad,
    longRad,
    lat0Rad,
    lonCRad,
    alphaCRad,
    k_0,
    spheroid,
  );
};

/**
 * Calculate scale factor.
 * Note: Scale factor equation is decided by projection type.
 * ATM Transverse Mercator and Oblique Mercator scale factor calculation is supported.
 * Transverse Mercator calculation is used as default for other projection types.
 * @param {boolean} applyScale
 * @param {import('common/gis/gis.interfaces').TLocation} coordinates
 * @returns {number}
 */
export const calculateScaleFactor = (applyScale, coordinates) => {
  const { epsgCode, x, y } = coordinates || {};

  if (isOffsetType(epsgCode) || !epsgCode || !areAllArgumentsNumeric(x, y)) {
    return DEFAULT_SCALE_FACTOR;
  }

  // CRS PARAMETERS
  const crsDetails = getLocationInfoByEpsg(epsgCode);
  const {
    proj4: projString,
    scaleFactor: k_0,
    centralMeridian,
    spheroid,
  } = crsDetails;
  const datumEpsg = getDatumEPSG(epsgCode);
  const [longitude, latitude] = transformLocationCoordinate(
    epsgCode,
    datumEpsg,
    [x, y],
  );

  const projAlias = getProjParameterValue(projString, 'proj');
  const lat0 = getProjParameterValue(projString, 'lat_0');
  const lonC = getProjParameterValue(projString, 'lonc');
  const alphaC = getProjParameterValue(projString, 'alpha');

  const latRad = to(latitude, 'deg', 'rad');
  const longRad = to(longitude, 'deg', 'rad');
  const centralMeridianRad = to(centralMeridian, 'deg', 'rad');
  const lat0Rad = to(lat0, 'deg', 'rad');
  const lonCRad = to(lonC, 'deg', 'rad');
  const alphaCRad = to(alphaC, 'deg', 'rad');

  /**
   * @type {TransversePayload}
   */
  const transversePayload = {
    applyScale,
    k_0,
    latRad,
    longRad,
    centralMeridianRad,
    spheroid,
  };

  /**
   * @type {ObliquePayload}
   */
  const obliquePayload = {
    longRad,
    latRad,
    lat0Rad,
    lonCRad,
    alphaCRad,
    k_0,
    spheroid,
  };

  switch (projAlias) {
    case ProjAliasType.Tmerc:
    case ProjAliasType.Utm:
    case ProjAliasType.Lcc:
    case ProjAliasType.Cass:
    case ProjAliasType.Stere:
    case ProjAliasType.Aea:
    case ProjAliasType.Merc:
    case ProjAliasType.Sterea:
    case ProjAliasType.Krovak:
    case ProjAliasType.Poly:
    case ProjAliasType.Eck1:
    case ProjAliasType.Eck2:
    case ProjAliasType.Eck3:
    case ProjAliasType.Eck4:
    case ProjAliasType.Eck5:
    case ProjAliasType.Eck6:
    case ProjAliasType.Moll:
    case ProjAliasType.Nzmg:
      return calculateTransverseScaleFactor(transversePayload);
    case ProjAliasType.Omerc:
      return calculateObliqueScaleFactor(obliquePayload);
    case ProjAliasType.Longlat:
    case ProjAliasType.Geocent:
      console.log('Scale factor is not used in context of Geographic CRS.');
      return DEFAULT_SCALE_FACTOR;
    default:
      console.log(
        `Scale factor calculation for ${epsgCode} (projection type: ${projAlias}) is not found.`,
      );
      return DEFAULT_SCALE_FACTOR;
  }
};

// TODO: @GIS-V2 handle scale factor calculation for projections other than UTM (Transverse Mercator) and OMERC (Hotine Oblique Mercator, Hotine Oblique Mercator Azimuth Center)
