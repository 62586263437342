import {
  Dialog,
  Modal,
  Text,
  Spacer,
  Button,
} from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { INote } from '../../interfaces/notes.interfaces';
import { NoteMarkDown } from './notes-markdown';

type TNotesDeleteModalProps = {
  noteItem: INote;
  isOpen: boolean;
  onClose: () => void;
  onConfirmDelete: () => void;
};

export const NotesDeleteModal = ({
  noteItem,
  isOpen,
  onClose,
  onConfirmDelete,
}: TNotesDeleteModalProps) => {
  const { t } = useTranslation();

  const content = (
    <>
      <Text>{t(translations.notes_deleteNote)}</Text>
      <Spacer />
      <NoteMarkDown note={noteItem.note} />
    </>
  );

  const footer = (
    <>
      <Button
        label={t(translations.delete)}
        onClick={onConfirmDelete}
        colored="danger"
      />
      <Button label={t(translations.cancel)} onClick={onClose} />
    </>
  );

  return (
    <Modal visible={isOpen} centered>
      <Dialog
        dialog={{
          scroll: true,
          heading: 'Delete',
          content,
          onClose,
          footer,
        }}
      />
    </Modal>
  );
};
