import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { depthInequality, ANY_OPTION } from '~src/enums/settings';
import { calculateDistanceBetweenCoordinates } from '~common/gis/calculate-distance-between-coordinates';

const offsetWells = createSlice({
  name: 'offsetWells',
  initialState: {
    isAdding: false,
    isFetching: false,
    list: [],
    selected: [],
  },
  reducers: {
    offsetWellsRequested: (offsetWells) => {
      offsetWells.isFetching = true;
    },
    offsetWellsReceived: (offsetWells, action) => {
      offsetWells.isFetching = false;
      offsetWells.list = action.payload.list;
      offsetWells.selected = action.payload.selected;
    },
    selectOffsetWellsRequested: (offsetWells, action) => {
      offsetWells.isAdding = true;
      const { selected } = offsetWells;

      const listIndex = offsetWells.list.findIndex(
        (item) => item.projectId === action.payload,
      );
      offsetWells.list[listIndex].active = !offsetWells.list[listIndex].active;
      const selectedIndex = offsetWells.selected.findIndex(
        (item) => item.projectId === action.payload,
      );
      if (selectedIndex === -1) {
        offsetWells.selected.push(offsetWells.list[listIndex]);
      } else {
        delete selected[selectedIndex];
        offsetWells.selected = selected.filter((i) => i);
      }
    },
    selectOffsetWellsReceived: (offsetWells) => {
      offsetWells.isAdding = false;
    },
  },
});

export const {
  offsetWellsRequested,
  offsetWellsReceived,
  selectOffsetWellsRequested,
  selectOffsetWellsReceived,
} = offsetWells.actions;
export default offsetWells.reducer;

/**
 * Get offset wells
 *
 * @param companyId
 * @param projectId
 */
export const getOffsetWells = (companyId, projectId) =>
  apiCallBegan({
    url: `/api/offset-wells/${companyId}/${projectId}`,
    method: 'GET',
    onStart: offsetWellsRequested.type,
    onSuccess: offsetWellsReceived.type,
  });

/**
 * Select offset well
 *
 * @param projectId
 * @param offsetId
 * @param active
 */
export const selectOffsetWell = (projectId, offsetId, active) =>
  apiCallBegan({
    url: `/api/offset-wells/${projectId}`,
    method: active ? 'DELETE' : 'POST',
    data: { offsetId },
    onStart: () => ({
      type: selectOffsetWellsRequested.type,
      payload: offsetId,
    }),
    onSuccess: (response) => ({
      type: selectOffsetWellsReceived.type,
      payload: response,
    }),
  });

/**
 * Filter offset wells list
 *
 * @param list
 * @param filter
 */
export const filterList = (list, filter, currentWellLocation) => {
  let result = list
    .filter((item) =>
      filter.waterDepthInequality === depthInequality.MORE_THAN_OR_EQUAL
        ? filter.waterDepth <= item.waterDepth
        : filter.waterDepth >= item.waterDepth,
    )
    .filter((item) =>
      filter.wellheadDepthInequality === depthInequality.MORE_THAN_OR_EQUAL
        ? filter.wellheadDepth <= item.wellheadDepth
        : filter.wellheadDepth >= item.wellheadDepth,
    )
    .filter((item) =>
      filter.casingStringsInequality === depthInequality.MORE_THAN_OR_EQUAL
        ? filter.casingStrings <= item.casingStrings
        : filter.casingStrings >= item.casingStrings,
    );
  if (filter.wellConfiguration !== ANY_OPTION) {
    result = result.filter(
      (item) => item.wellConfiguration === filter.wellConfiguration,
    );
  }
  if (filter.drillingUnit !== ANY_OPTION) {
    result = result.filter((item) => item.drillingUnit === filter.drillingUnit);
  }
  if (filter.distance) {
    result = result.filter(
      (item) =>
        calculateDistanceBetweenCoordinates(
          item.location,
          currentWellLocation,
        ) <= filter.distance,
    );
  }

  return result;
};
