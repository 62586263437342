import { createSelector } from '@reduxjs/toolkit';

const selectCostSetup = ({ entities }) => entities.costSetup;

const selectCostItemList = createSelector(
  [selectCostSetup],
  (costSetup) => costSetup.list,
);
const selectActiveCostItem = createSelector([selectCostItemList], (costList) =>
  costList.find((cost) => cost.active),
);

export const selectActiveCostItemQuantity = createSelector(
  [selectActiveCostItem],
  (cost) => cost?.quantity,
);
