/* eslint-disable camelcase */
import { Selection } from 'd3-selection';
import { ScaleLinear } from 'd3-scale';

export enum DepthReferenceType {
  TVD = 'TVD',
  MD = 'MD',
}

export enum AnimationPlayOptionTypes {
  Loop = 'Loop',
  Once = 'Once',
  Never = 'Never',
}

export const casingRenderStyle = Object.freeze({
  airColor: 'var(--airColor)',
  axisColor: 'var(--axisColor)',
  axisLabelColor: 'var(--color-text-muted)',
  cementColor: 'var(--cementLeadColor)',
  depthColor: 'var(--color-text-muted)',
  descriptionColor: 'var(--color-text)',
  holeColor: 'var(--holeColor)',
  lineColor: 'var(--color-text)',
  rockColor: 'var(--rockBackgroundColor)',
  spaceColor: 'var(--spaceColor)',
  stringColor: 'var(--stringColor)',
  waterColor: 'var(--waterBackgroundColor)',
  holeEdgeWidth: 2,
  shoeSize: 9,
  chartPaddingScalar: 0.05,
  paddingBottom: 60,
  paddingLeft: 0,
  paddingRight: 120,
  paddingTop: 20,
  fontSize: 12,
  lineHeight: 12,
  lineLengthPart1: 4,
  lineLengthPart2: 4,
  lineLengthPart3: 4,
  maxScreenSpaceItems: 4,
  minSpacing: 16,
  totalLineLength: 12,
});

/**
 * CHART CALCULATION TYPES
 */

export type TFluidRectangle = [
  [number, number],
  [number, number],
  [number, number],
  [number, number],
];
export type TFluidRectangles = TFluidRectangle[];

export interface IFluid {
  bottom: number;
  chartId: string;
  color: string;
  density: number;
  rectangles: TFluidRectangles;
  top: number;
}

export interface IHole {
  bottom: number;
  chartId: string;
  color: string;
  depthReference: DepthReferenceType;
  depthUnit: string;
  diameterUnit: string;
  holesize: number;
  top: number;
  width: number;
}

export interface IShoe {
  chartId: string;
  color: string;
  depth: number;
  height: number;
  radius: number;
  width: number;
}
export interface IWellString {
  bottom: number;
  chartId: string;
  color: string;
  id: number;
  od: number;
  odPretty: string;
  stringIx: number;
  top: number;
}

export interface IWellSection {
  bottom: number;
  casingSize: number;
  casingSizePretty: string;
  chartId: string;
  densityUnit: string;
  depthReference: DepthReferenceType;
  depthUnit: string;
  fluids: IFluid[] | null;
  hole: IHole | null;
  holeDepth: number;
  holesize: number;
  holesizePretty: string;
  mudDensity: number;
  sectionIx: number;
  sectionName: string;
  shoe: IShoe | null;
  spaceColor: string;
  strings: IWellString[];
  toc: number; // Top of cement
  top: number;
  sectionTypeId: string | null;
}

export interface IBackgroundElement {
  bottom: number;
  top: number;
  chartId: string;
  color: string;
}

export interface IBackground {
  backgrounds: IBackgroundElement[];
}

export interface IGrid {
  axisColor: string;
  axisLabelColor: string;
  chartPaddingScalar: number;
  height: number;
  minDepth: number;
  minX: number;
  maxDepth: number;
  maxX: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  width: number;
}

export interface IDepthLabel {
  chartId: string;
  depth: number;
  depthLabel: string; // Depth part of label
  depthColor: string;
  description: string; // Description part of label
  descriptionColor: string;
  fontSize: string | number;
  lineColor: string;
  radius: number;
}

export interface IDepthLabels {
  labels: IDepthLabel[];
  meta: {
    lineHeight: number;
    lineLengthPart1: number;
    lineLengthPart2: number;
    lineLengthPart3: number;
    maxScreenSpaceItems: number;
    minSpacing: number;
    totalLineLength: number;
  };
}

export interface IMeta {
  densityUnit: string;
  depthReference: DepthReferenceType;
  depthUnit: string;
  diameterUnit: string;
  drillFloorHeight: number;
  height: number;
  seabed: number;
  totalDepth: number;
  totalDepthCasings: number;
  totalDiameter: number;
  waterDepth: number;
  wellheadDepth: number;
  width: number;
}

export interface IChartData {
  depthLabels: IDepthLabels;
  grid: IGrid;
  background: IBackground;
  meta: IMeta;
  sections: IWellSection[];
}

export interface ISectionType {
  sectionTypeId: string;
  name: string;
}

export interface ISection {
  sectionId: string;
  name: string;
}

/**
 * CHART GENERATION TYPES
 */

export type TArrayRepresentedDiagramCoordinate =
  | [radius: number, depth: number]
  | number[];
export type TArrayRepresentedDiagramCoordinates =
  TArrayRepresentedDiagramCoordinate[];

export type TArrayRepresentedGridCoordinate =
  | [x: number, y: number]
  | [number, number]
  | number[];
export type TArrayRepresentedGridCoordinates =
  TArrayRepresentedGridCoordinate[];

export type TDrawUtils = {
  depthBelowSeabedToGridOffset: ScaleLinear<number, number>;
  diameterToGridOffset: ScaleLinear<number, number>;
  depthToGridOffset: ScaleLinear<number, number>;

  mirror: (
    coordinates: TArrayRepresentedDiagramCoordinates,
  ) => TArrayRepresentedDiagramCoordinates;
  gridMirror: (
    coordinates: TArrayRepresentedGridCoordinates,
  ) => TArrayRepresentedGridCoordinates;

  gridLine: (gridCoordinates: TArrayRepresentedGridCoordinates) => string;
  curvedGridLine: (gridCoordinates: TArrayRepresentedGridCoordinates) => string;
  diagramLine: (
    diagramCoordinates: TArrayRepresentedDiagramCoordinates,
  ) => string;
  curvedDiagramLine: (
    diagramCoordinates: TArrayRepresentedDiagramCoordinates,
  ) => string;
};

export interface ChartDrawerParameters {
  chart: Selection<SVGSVGElement, unknown, null, undefined>;
  chartData: IChartData;
  drawUtils: TDrawUtils;
}

/**
 * CASING RENDER PARAMETER TYPES
 */

export type TWellDetails = {
  airGap: number;
  completion: boolean;
  datasetId: number;
  drillingUnit: string;
  dst: boolean;
  lastSynced: number | Date;
  location: { [key: string]: any };
  pa: boolean;
  projectId: string;
  waterDepth: number;
  wellConfiguration: string;
  wellDetailsId: string;
  wellheadDepth: number;
};

export type TWellSchematic = {
  bottom: number;
  displacementFluidDens: number;
  holeSize: string | number;
  name: string;
  schematicId: string;
  section: string;
  sectiontd: number;
  toc: number;
  top: number;
  sectionTypeId: string | null;
  sectionId: string;
};

export type TCasingRendererDimensions = {
  width: number;
  height: number;
};
