import { useResizeDetector } from 'react-resize-detector';

export const useResizeCasingRenderer = () => {
  const { width, height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 5,
  });

  return {
    width,
    height,
    ref,
  };
};
