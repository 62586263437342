export const LogsType = Object.freeze({
  CreateEstimate: 'createEstimate',
  SyncWellDetails: 'syncWellDetails',
  AddWellSchematicRow: 'addWellSchematicRow',
  DeleteWellSchematicRow: 'deleteWellSchematicRow',
  AddOperation: 'addOperation',
  UpdateOperation: 'updateOperation',
  DeleteOperation: 'deleteOperation',
  AddActivity: 'addActivity',
  AddActivityBulk: 'addActivityBulk',
  DeleteActivity: 'deleteActivity',
  RunSimulation: 'runSimulation',
  ConfirmModule: 'confirmModule',
  AddCostCategory: 'addCostCategory',
  UpdateCostCategory: 'updateCostCategory',
  DeleteCostCategory: 'deleteCostCategory',
  AddCostItem: 'addCostItem',
  UpdateCostItem: 'updateCostItem',
  DeleteCostItem: 'deleteCostItem',
  AddRisk: 'addRisk',
  UpdateRisk: 'updateRisk',
  DeleteRisk: 'deleteRisk',
});
