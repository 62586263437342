import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { List } from '@oliasoft-open-source/react-ui-library';
import { v4 as uuidv4 } from 'uuid';
import translations from '~src/internationalisation/translation-map.json';
import { selectUserSettings } from '~store/entities/user-settings/selectors';
import { saveUserSettings } from '~store/entities/user-settings/user-settings';
import { settingEntity } from '~src/enums/settings';
import { downloadTemplate, uploadTemplate } from './templates-import-export';

const TemplatesList = ({
  entity,
  settings,
  isAdding,
  addInitialTemplate,
  onClickDelete,
  onClickDuplicate,
  templateSelected,
  reorderTemplate,
  saveUserSettings,
  userSettings,
  onShowCopyModal,
  onClickUpload,
}) => {
  const { t } = useTranslation();

  const { company: companyId } = useParams();

  const onClickItem = (index) => templateSelected(index);

  const onCopyToUserSettings = (index) => {
    const copied = {
      ...settings?.templates[index],
      templateId: uuidv4(),
    };
    const updateUserSettings = {
      ...userSettings,
      templates: [...userSettings.templates, copied],
    };
    saveUserSettings(updateUserSettings);
  };

  const templates =
    settings.templates?.map((item, index) => ({
      id: index,
      name: item.highLevel.name,
      active: item.active,
      actions: [
        {
          label: 'More',
          subActions: [
            {
              icon: 'delete',
              label: 'Delete',
            },
            {
              icon: 'clone',
              label: 'Duplicate',
            },
            entity === settingEntity.COMPANY && {
              icon: 'copy',
              label: 'Copy to user settings',
            },
            {
              icon: 'download',
              label: 'Download',
            },
          ].filter(Boolean),
        },
      ],
    })) || [];

  return (
    <div>
      <List
        list={{
          name: t(translations.automationRulesets),
          actions:
            entity === settingEntity.COMPANY
              ? [
                  {
                    label: 'More',
                    subActions: [
                      {
                        label: 'Add',
                        icon: 'add',
                        onClick: () =>
                          addInitialTemplate(
                            t(translations.settings_newRuleset),
                            settings,
                            companyId,
                          ),
                        disabled: isAdding,
                      },
                      {
                        label: t(translations.uploadRuleset),
                        icon: 'upload',
                        onClick: () =>
                          uploadTemplate((content) =>
                            onClickUpload(content, companyId),
                          ),
                        disabled: isAdding,
                      },
                    ],
                  },
                ]
              : [
                  {
                    label: 'More',
                    subActions: [
                      {
                        label: 'Add',
                        icon: 'add',
                        onClick: () =>
                          addInitialTemplate(
                            t(translations.settings_newRuleset),
                            settings,
                            companyId,
                          ),
                        disabled: isAdding,
                      },
                      {
                        icon: 'copy',
                        label: t(
                          translations.settings_importFromCompanySettings,
                        ),
                        onClick: onShowCopyModal,
                      },
                      {
                        label: t(translations.uploadRuleset),
                        icon: 'upload',
                        onClick: () => uploadTemplate(onClickUpload, companyId),
                        disabled: isAdding,
                      },
                    ],
                  },
                ],
          items: templates?.map((template, i) => ({
            ...template,
            onClick: () => onClickItem(i),
            actions: template.actions.map((action) => ({
              ...action,
              subActions: action.subActions.map((subAction) => ({
                ...subAction,
                onClick: () => {
                  if (subAction.icon === 'delete') {
                    onClickDelete(i);
                  }
                  if (subAction.icon === 'clone') {
                    onClickDuplicate(i, companyId);
                  }
                  if (subAction.icon === 'copy') {
                    onCopyToUserSettings(i);
                  }
                  if (subAction.icon === 'download') {
                    downloadTemplate(settings.templates[i]);
                  }
                },
              })),
            })),
          })),
        }}
        draggable
        onListReorder={({ from, to }) =>
          reorderTemplate(from, to, settings, companyId)
        }
      />
    </div>
  );
};

const mapStateToProps = ({ entities }) => ({
  userSettings: selectUserSettings(entities),
});

const mapDispatchToProps = { saveUserSettings };

const Container = connect(mapStateToProps, mapDispatchToProps)(TemplatesList);

export { Container as TemplatesList };
