import { itemFields } from '~src/enums/time-tracker';
import { getFields } from './get-tt-fields';

const columnAlignment = {
  [itemFields.START]: 'left',
  [itemFields.NAME]: 'left',
  [itemFields.AFE]: 'right',
  [itemFields.ESTIMATE]: 'right',
  [itemFields.P90]: 'right',
  [itemFields.ACTUAL]: 'right',
  [itemFields.NPT]: 'right',
  [itemFields.WOW]: 'right',
  [itemFields.VARIANCE]: 'right',
  [itemFields.FINISH]: 'left',
  [itemFields.ACTUAL_DEPTH]: 'right',
  [itemFields.PLANNED_DEPTH]: 'right',
  [itemFields.ACTIVITY]: 'left',
  [itemFields.OPERATION]: 'left',
  [itemFields.SECTION]: 'left',
};

export const getColumnAlignment = (disabledFields) => {
  const fields = getFields(disabledFields);
  return fields.map((field) => columnAlignment[field]);
};
