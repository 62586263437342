import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  List,
  Text,
  Column,
  Row,
} from '@oliasoft-open-source/react-ui-library';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import translations from '~src/internationalisation/translation-map.json';
import {
  addInitialUserKpi,
  userKpiSelected,
  saveUserSettings,
} from '~store/entities/user-settings/user-settings';
import { selectUserSettings } from '~store/entities/user-settings/selectors';
import {
  addInitialCompanyKpi,
  companyKpiSelected,
} from '~store/entities/company-settings/company-settings';
import { settingEntity } from '~src/enums/settings';

const KpiList = ({
  entity,
  isAdding,
  addInitialUserKpi,
  addInitialCompanyKpi,
  userKpiSelected,
  companyKpiSelected,
  settings,
  onClickDelete,
  userSettings,
  saveUserSettings,
  onShowCopyModal,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();

  const addInitialKpi =
    entity === settingEntity.USER ? addInitialUserKpi : addInitialCompanyKpi;
  const kpiSelected =
    entity === settingEntity.USER ? userKpiSelected : companyKpiSelected;

  const onClickItem = (id) => kpiSelected(id);

  const onCopyToUserSettings = (id) => {
    const kpi = {
      ...settings?.kpi?.find((kpi) => kpi.id === id),
      id: uuidv4(),
    };
    const updateUserSettings = {
      ...userSettings,
      kpi: [...userSettings.kpi, kpi],
    };
    saveUserSettings(updateUserSettings);
  };

  const kpiList = settings?.kpi?.map((item) => ({
    id: item.id,
    name: item.name,
    active: item.active,
    actions: [
      {
        label: 'More',
        subActions: [
          {
            icon: 'delete',
            label: 'Delete',
          },
          entity === settingEntity.COMPANY && {
            icon: 'copy',
            label: 'Copy to user settings',
          },
        ].filter(Boolean),
      },
    ],
  }));

  return (
    <div>
      <List
        list={{
          name: (
            <Column>
              <Row>
                <Text>{t(translations.kpis)}</Text>
              </Row>
            </Column>
          ),
          actions:
            entity === settingEntity.COMPANY
              ? [
                  {
                    label: 'Add',
                    icon: 'add',
                    onClick: () =>
                      addInitialKpi(
                        t(translations.settings_newKpi),
                        settings,
                        company,
                      ),
                    disabled: isAdding,
                  },
                ]
              : [
                  {
                    label: 'More',
                    subActions: [
                      {
                        label: 'Add',
                        icon: 'add',
                        onClick: () =>
                          addInitialKpi(
                            t(translations.settings_newKpi),
                            settings,
                            company,
                          ),
                        disabled: isAdding,
                      },
                      {
                        icon: 'copy',
                        label: 'Copy from company settings',
                        onClick: onShowCopyModal,
                      },
                    ],
                  },
                ],
          items: kpiList?.map((kpi) => ({
            ...kpi,
            onClick: () => onClickItem(kpi.id),
            actions: kpi.actions.map((action) => ({
              ...action,
              subActions: action.subActions.map((subAction, index) => ({
                ...subAction,
                onClick: () =>
                  index === 0
                    ? onClickDelete(kpi.id)
                    : onCopyToUserSettings(kpi.id),
              })),
            })),
          })),
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ entities }) => ({
  userSettings: selectUserSettings(entities),
});

const mapDispatchToProps = {
  addInitialUserKpi,
  addInitialCompanyKpi,
  userKpiSelected,
  companyKpiSelected,
  saveUserSettings,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(KpiList);

export { Container as KpiList };
