import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Row, Column, Drawer } from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import {
  addCostItem as addUserCostItem,
  removeCostItem as removeUserCostItem,
  showDeleteCostItemModalUpdated as showDeleteUserCostItemModalUpdated,
  getCurrencies,
  addCostCategory as addUserCostCategory,
  showDeleteCostCategoryModalUpdated as showDeleteUserCostCategoryUpdated,
  removeCostCategory as removeUserCostCategory,
  costItemSelected as userCostItemSelected,
  costCategorySelected as userCostCategorySelected,
  reorderElements as reorderUserCostElements,
  updateCostCategory as updateUserCostCategory,
  updateCostItem as updateUserCostItem,
} from '~store/entities/user-settings/user-settings';
import {
  addCostItem as addCompanyCostItem,
  removeCostItem as removeCompanyCostItem,
  showDeleteCostItemModalUpdated as showDeleteCompanyCostItemModalUpdated,
  addCostCategory as addCompanyCostCategory,
  showDeleteCostCategoryModalUpdated as showDeleteCompanyCostCategoryUpdated,
  removeCostCategory as removeCompanyCostCategory,
  costItemSelected as companyCostItemSelected,
  costCategorySelected as companyCostCategorySelected,
  reorderElements as reorderCompanyCostElements,
  updateCostCategory as updateCompanyCostCategory,
  updateCostItem as updateCompanyCostItem,
} from '~store/entities/company-settings/company-settings';
import { settingEntity } from '~src/enums/settings';
import { selectGroupedOperations as selectUserGroupedOperations } from '~src/store/entities/user-settings/selectors';
import { selectGroupedOperations as selectCompanyGroupedOperations } from '~src/store/entities/company-settings/selectors';
import { CostElementsList } from './cost-elements.list';
import { CostItemDeleteModal } from './cost-item-delete.modal';
import { CostItemForm } from './cost-item.form';
import { CostCategoryForm } from './cost-category.form';
import { CostCategoryDeleteModal } from './cost-category-delete.modal';
import styles from '../templates.module.less';

const CostSetup = ({
  elements,
  addCostItem,
  entitySettings,
  showDeleteCostItemModal,
  showDeleteCostItemModalUpdated,
  selectedId,
  removeCostItem,
  activeCostItem,
  getCurrencies,
  addCostCategory,
  activeCostCategory,
  showDeleteCostCategoryModalUpdated,
  showDeleteCostCategoryModal,
  removeCostCategory,
  costItemSelected,
  costCategorySelected,
  reorderElements,
  updateCostCategory,
  updateCostItem,
  groupedOperations,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();

  const [activeForm, setActiveForm] = useState(null);
  useEffect(() => {
    getCurrencies();
  }, []);

  const onClickAddItem = (parentId = null) => {
    addCostItem(t(translations.newItem), entitySettings, parentId, companyId);
    setActiveForm('item');
  };
  const onClickDeleteItem = (id) =>
    removeCostItem(id, entitySettings.settings, companyId);

  const onClickAddCategory = (parentId = null) => {
    addCostCategory(
      { name: t(translations.newCategory), parentId },
      entitySettings,
      companyId,
    );
    setActiveForm('category');
  };

  const onClickDeleteCategory = (id) => {
    removeCostCategory(id, entitySettings, companyId);
  };

  const forms = {
    category: (
      <CostCategoryForm
        category={activeCostCategory}
        entitySettings={entitySettings}
        updateCostCategory={updateCostCategory}
      />
    ),
    item: (
      <CostItemForm
        costItem={activeCostItem}
        entitySettings={entitySettings}
        updateCostItem={updateCostItem}
        groupedOperations={groupedOperations}
      />
    ),
  };
  let form = null;

  if (activeForm) {
    form = forms[activeForm];
  }
  const [costDrawerWidth, setCostDrawerWidth] = useState(300);

  return (
    <>
      <div className={styles.activityModelTabWrapper}>
        <Row height="100%" spacing={0}>
          <Drawer
            width={costDrawerWidth}
            closedWidth={50}
            open
            button
            border
            onResize={(width) => setCostDrawerWidth(width)}
          >
            <Column scroll width="350px" borderRight spacing={0}>
              <CostElementsList
                settings={entitySettings.settings}
                selectedId={selectedId}
                elements={elements}
                onClickAddItem={onClickAddItem}
                showDeleteCostItemModalUpdated={showDeleteCostItemModalUpdated}
                showDeleteCostCategoryModalUpdated={
                  showDeleteCostCategoryModalUpdated
                }
                setActiveForm={setActiveForm}
                onClickAddCategory={onClickAddCategory}
                costItemSelected={costItemSelected}
                costCategorySelected={costCategorySelected}
                reorderElements={reorderElements}
              />
            </Column>
          </Drawer>
          {(activeCostItem || activeCostCategory) && (
            <Column scroll padding>
              {form}
            </Column>
          )}
        </Row>
      </div>
      {showDeleteCostItemModal && (
        <CostItemDeleteModal
          setModalVisible={showDeleteCostItemModalUpdated}
          costItemId={selectedId}
          onClickDeleteItem={onClickDeleteItem}
        />
      )}
      {showDeleteCostCategoryModal && (
        <CostCategoryDeleteModal
          setModalVisible={showDeleteCostCategoryModalUpdated}
          costCategoryId={selectedId}
          onClickDeleteCategory={onClickDeleteCategory}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }, { entitySettings, entity }) => {
  const { showDeleteCostItemModal, selectedId, showDeleteCostCategoryModal } =
    entitySettings;
  const activeTemplate = entitySettings.settings.templates.find(
    (item) => item.active,
  );

  const costItems = activeTemplate?.costSetup?.costItem || [];
  const activeCostItem = costItems?.find(
    (item) => item.costItemId === selectedId,
  );
  const costCategory = activeTemplate?.costSetup?.costCategory || [];
  const activeCostCategory = costCategory?.find(
    (item) => item.costCategoryId === selectedId,
  );
  const elements = [...costCategory, ...costItems]?.sort(
    (a, b) => a.position - b.position,
  );
  const selectGroupedOperations =
    entity === settingEntity.USER
      ? selectUserGroupedOperations
      : selectCompanyGroupedOperations;
  const groupedOperations = selectGroupedOperations(entities);
  return {
    elements,
    showDeleteCostItemModal,
    selectedId,
    activeCostItem,
    activeCostCategory,
    showDeleteCostCategoryModal,
    groupedOperations,
  };
};

const mapDispatchToProps = (dispatch, { entity }) =>
  bindActionCreators(
    {
      getCurrencies,
      addCostItem:
        entity === settingEntity.USER ? addUserCostItem : addCompanyCostItem,
      showDeleteCostItemModalUpdated:
        entity === settingEntity.USER
          ? showDeleteUserCostItemModalUpdated
          : showDeleteCompanyCostItemModalUpdated,
      removeCostItem:
        entity === settingEntity.USER
          ? removeUserCostItem
          : removeCompanyCostItem,
      addCostCategory:
        entity === settingEntity.USER
          ? addUserCostCategory
          : addCompanyCostCategory,
      showDeleteCostCategoryModalUpdated:
        entity === settingEntity.USER
          ? showDeleteUserCostCategoryUpdated
          : showDeleteCompanyCostCategoryUpdated,
      removeCostCategory:
        entity === settingEntity.USER
          ? removeUserCostCategory
          : removeCompanyCostCategory,
      costItemSelected:
        entity === settingEntity.USER
          ? userCostItemSelected
          : companyCostItemSelected,
      costCategorySelected:
        entity === settingEntity.USER
          ? userCostCategorySelected
          : companyCostCategorySelected,
      reorderElements:
        entity === settingEntity.USER
          ? reorderUserCostElements
          : reorderCompanyCostElements,
      updateCostCategory:
        entity === settingEntity.USER
          ? updateUserCostCategory
          : updateCompanyCostCategory,
      updateCostItem:
        entity === settingEntity.USER
          ? updateUserCostItem
          : updateCompanyCostItem,
    },
    dispatch,
  );

const Container = connect(mapStateToProps, mapDispatchToProps)(CostSetup);

export { Container as CostSetup };
