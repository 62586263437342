import React from 'react';
import { Button, Flex } from '@oliasoft-open-source/react-ui-library';
import { KpiCard } from '~views/campaigns/kpi/kpi-card';
import './style.css';

export const Kpi = ({ kpiModalUpdated, campaign }) => {
  return (
    <Flex gap={20}>
      {campaign.kpisList.map((kpi) => (
        <React.Fragment key={kpi.id}>
          <KpiCard kpi={kpi} campaign={campaign} />
        </React.Fragment>
      ))}
      <Flex alignItems="center">
        <Button label="Add KPI" small onClick={() => kpiModalUpdated(true)} />
      </Flex>
    </Flex>
  );
};
