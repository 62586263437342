import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  ButtonGroup,
  Flex,
  Column,
  Row,
  Spacer,
} from '@oliasoft-open-source/react-ui-library';
import { simulationChartType } from '~src/enums/compare-estimates';
import { probabilities, timeCostView } from '~src/enums/simulations';
import { CombinedChart } from '~views/compare-estimates/combined-result/combined-chart';
import { CombinedTable } from '~views/compare-estimates/combined-result/combined-table';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CombinedResult = ({ estimates, estimateSelected }) => {
  const { t } = useTranslation();
  const [chartType, setChartType] = useState(
    simulationChartType.OPERATION_VS_DEPTH,
  );
  const [probabilityType, setProbabilityType] = useState(probabilities.P10);
  const [showChart, setShowChart] = useState(true);
  const [timeCostType, setTimeCostType] = useState(timeCostView.TIME);

  const controlsPortalId = `combined-chart-controls-${chartType}`;

  const handleSelection = (key) => {
    if (key === 0) {
      setShowChart(true);
    } else {
      setShowChart(false);
    }
  };

  const chartTypeOptions = [
    {
      label: t(
        timeCostType === timeCostView.TIME
          ? translations.simulations_timeDepthPlot
          : translations.simulations_costDepthPlot,
      ),
      value: simulationChartType.OPERATION_VS_DEPTH,
    },
    {
      label: t(translations.simulations_totalTimeAndCost),
      value: simulationChartType.SIMULATION_TOTAL_TIME_AND_COST,
    },
    {
      label: t(translations.simulations_cumulativeProbability),
      value: simulationChartType.CUMULATIVE_PROBABILITY,
    },
    {
      label: t(translations.costSetup_totalCost),
      value: simulationChartType.TOTAL_COST,
    },
  ];

  useEffect(() => {
    if (chartType !== simulationChartType.SIMULATION_TOTAL_TIME_AND_COST) {
      setShowChart(true);
    }
  }, [chartType]);

  const probabilityOptions = Object.keys(probabilities).map((key) => ({
    label: key,
    value: probabilities[key],
  }));

  return (
    <>
      <Flex gap justifyContent="space-between">
        <Column>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex gap>
              <Select
                name="chart-type"
                width="auto"
                options={chartTypeOptions}
                small
                onChange={(e) => setChartType(e.target.value)}
                value={chartType}
              />
              {chartType ===
                simulationChartType.SIMULATION_TOTAL_TIME_AND_COST && (
                <ButtonGroup
                  items={[
                    { key: 0, icon: 'chart', disabled: showChart },
                    { key: 1, icon: 'table', disabled: !showChart },
                  ]}
                  small
                  onSelected={handleSelection}
                  value={showChart ? 0 : 1}
                />
              )}
              {(chartType === simulationChartType.OPERATION_VS_DEPTH ||
                chartType ===
                  simulationChartType.SIMULATION_TOTAL_TIME_AND_COST ||
                chartType === simulationChartType.TOTAL_COST) &&
                showChart && (
                  <Select
                    name="probability"
                    width="auto"
                    options={probabilityOptions}
                    small
                    onChange={(e) => setProbabilityType(e.target.value)}
                    value={probabilityType}
                  />
                )}
              {chartType !== simulationChartType.TOTAL_COST &&
                chartType !==
                  simulationChartType.SIMULATION_TOTAL_TIME_AND_COST && (
                  <ButtonGroup
                    items={[
                      { key: timeCostView.TIME, label: t(translations.time) },
                      { key: timeCostView.COST, label: t(translations.cost) },
                    ]}
                    small
                    onSelected={(key) => setTimeCostType(key)}
                    value={timeCostType}
                  />
                )}
            </Flex>
            <div id={controlsPortalId} />
          </Flex>
          <Spacer />

          {showChart ? (
            <CombinedChart
              key={`${chartType}`}
              estimates={estimates}
              chartType={chartType}
              probabilityType={probabilityType}
              timeCostType={timeCostType}
              controlsPortalId={controlsPortalId}
            />
          ) : (
            chartType ===
              simulationChartType.SIMULATION_TOTAL_TIME_AND_COST && (
              <Row>
                <CombinedTable
                  estimates={estimates}
                  estimateSelected={estimateSelected}
                />
              </Row>
            )
          )}
        </Column>
      </Flex>
    </>
  );
};

export default withErrorBoundary(CombinedResult);
