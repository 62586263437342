import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Page,
  Row,
  Column,
  List,
  Text,
  Drawer,
} from '@oliasoft-open-source/react-ui-library';
import { routes } from '~routes/routes';
import { isActiveRoute, navigateToRoute } from '~store/navigation/navigation';
import { companySettings } from '~src/enums/company-settings';
import translations from '~src/internationalisation/translation-map.json';
import { useParams } from 'react-router';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CompanySettings = ({ navigateToRoute, pathname }) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const items = companySettings.map((item) => ({
    ...item,
    name:
      item.id === 'kpi' ? (
        <Column>
          <Row>
            <Text>{t(item.name)}</Text>
          </Row>
        </Column>
      ) : (
        t(item.name)
      ),
    active: isActiveRoute(routes.companySettings.nested[item.id], pathname),
    onClick: () =>
      navigateToRoute(routes.companySettings.nested[item.id], { company }),
  }));

  const settingsList = {
    name: t(translations.settings_companySettings),
    items,
  };
  const [drawerWidth, setDrawerWidth] = useState(300);

  return (
    <Page left={0} padding={false}>
      <Row spacing={0} height="100%">
        <Drawer
          width={drawerWidth}
          closedWidth={50}
          open
          button
          border
          onResize={(width) => setDrawerWidth(width)}
        >
          <Column scroll width="250px" spacing={0} borderRight>
            <List list={settingsList} drawer />
          </Column>
        </Drawer>
        <Outlet />
      </Row>
    </Page>
  );
};

const mapStateToProps = ({ router }) => ({
  pathname: router.location.pathname,
});
const mapDispatchToProps = { navigateToRoute };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CompanySettings),
  { style: { position: 'absolute', top: '50px' } },
);

export { Container as CompanySettings };
