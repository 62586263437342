import translations from '~src/internationalisation/translation-map.json';

export const companySettings = [
  { id: 'currency', name: translations.currency },
  { id: 'sectionsDataStructure', name: translations.dataStructure },
  // { id: 'operationCodes', name: translations.operationCodes },
  { id: 'templates', name: translations.automationRulesets },
  { id: 'kpi', name: translations.kpis },
  { id: 'timeTracker', name: translations.timeTracker_timeTracker },
  { id: 'priceBook', name: translations.priceBook },
];
