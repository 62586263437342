import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListSubheading, List } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { EstimatesFilter } from './estimates.filter';

export const EstimatesList = ({ items, filter, setFilter }) => {
  const { t } = useTranslation();
  return (
    <>
      <EstimatesFilter filter={filter} setFilter={setFilter} />
      <ListSubheading
        item={{ name: t(translations.estimate), metaCount: items.length }}
      />
      <List list={{ items }} noHeader />
    </>
  );
};
