export const itemFields = Object.freeze({
  START: 'start',
  NAME: 'name',
  AFE: 'afe',
  ESTIMATE: 'estimate',
  P90: 'P90',
  ACTUAL: 'actual',
  NPT: 'npt',
  WOW: 'wow',
  VARIANCE: 'variance',
  FINISH: 'finish',
  PLANNED_DEPTH: 'plannedDepth',
  ACTUAL_DEPTH: 'actualDepth',
  ACTIVITY: 'activity',
  OPERATION: 'operation',
  SECTION: 'section',
});
