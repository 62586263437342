import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'ui',
  initialState: {
    locales: 'en',
  },
  reducers: {
    setLocale: (ui, action) => {
      ui.locales = action.payload;
    },
  },
});
export const { setLocale } = slice.actions;
export default slice.reducer;
