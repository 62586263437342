import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';

const TemplateDeleteModal = ({
  setModalVisible,
  removeTemplate,
  templateId,
  settings,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();

  const onClose = () => setModalVisible(false);
  const onDelete = () => {
    removeTemplate(templateId, settings, companyId);
    setModalVisible(false);
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.settings_deleteAutomationRuleset),
          content: (
            <>{t(translations.settings_areYouSureYouWantToDeleteThisRuleset)}</>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

export { TemplateDeleteModal };
