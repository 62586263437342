import React from 'react';
import { Flex, Empty } from '@oliasoft-open-source/react-ui-library';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import CompareResultContent from './compare-result-content';

const RawSimulationChart = ({
  estimate,
  chartKey,
  showTable,
  selectOptions,
  selectedItem,
  dataEntity,
}) => {
  return (
    <>
      {estimate?.simulationResult &&
      estimate?.simulationResult.statusCode !== 404 ? (
        <>
          <CompareResultContent
            simulationsResult={estimate?.simulationResult}
            chartKey={chartKey}
            showTable={showTable}
            selectOptions={selectOptions}
            selectedItem={selectedItem}
            dataEntity={dataEntity}
          />
        </>
      ) : (
        <Flex alignItems="center" justifyContent="center" height="200px">
          <Empty
            height="100%"
            text="Results will appear here once simulation has been run"
            width="100%"
          ></Empty>
        </Flex>
      )}
    </>
  );
};

export const SimulationChart = withErrorBoundary(RawSimulationChart);
