import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  ListHeading,
  ListSubheading,
  List,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';

const OperationsList = ({
  settings,
  entitySettings,
  addInitialOperation,
  activityModel,
  operationSelected,
  setOperationModalVisible,
  showDeleteOperationsModalUpdated,
  showImportOperationModalUpdated,
  reorderOperationsInSettings,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();
  const { isAdding } = entitySettings;

  return (
    <>
      <ListHeading name={t(translations.activityModel_title)} />
      <ListSubheading
        item={{
          name: `${t(translations.activityModel_operations)} (${
            activityModel.length
          })`,
          actions: [
            {
              label: 'Manage',
              icon: 'add',
              subActions: [
                {
                  label: t(translations.add),
                  onClick: () =>
                    addInitialOperation(
                      t(translations.activityModel_newOperation),
                      entitySettings,
                      companyId,
                    ),
                  disabled: isAdding,
                },
                {
                  label: t(translations.settings_importFromTemplate),
                  onClick: () => showImportOperationModalUpdated(true),
                  disabled: isAdding,
                },
              ],
            },
          ],
        }}
      />
      <List
        list={{
          items: activityModel.map((item) => ({
            id: item.id,
            name: item.name,
            active: item.active,
            onClick: () => operationSelected(item.id),
            actions: [
              {
                label: 'Update',
                icon: 'edit',
                onClick: () => {
                  operationSelected(item.id);
                  setOperationModalVisible(true);
                },
              },
              {
                label: 'Delete',
                icon: 'delete',
                onClick: () =>
                  showDeleteOperationsModalUpdated({
                    selectedId: item.id,
                    visible: true,
                  }),
              },
            ],
          })),
        }}
        noHeader
        onListReorder={({ from, to }) => {
          if (from === undefined || to === undefined) {
            return;
          }
          reorderOperationsInSettings(
            {
              fromId: activityModel[from]?.id,
              toId: activityModel[to]?.id,
            },
            settings,
            companyId,
          );
        }}
        draggable={true}
      />
    </>
  );
};

export { OperationsList };
