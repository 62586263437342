import React from 'react';
import { connect } from 'react-redux';
import {
  showDeleteKpiModalUpdated,
  showKpiFormUpdated,
} from '~store/entities/user-settings/user-settings';
import { Kpi } from '~views/settings/kpi/kpi';
import { settingEntity } from '~src/enums/settings';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const UserKpi = ({
  userSettings,
  showDeleteKpiModalUpdated,
  showKpiFormUpdated,
}) => {
  return (
    <Kpi
      showDeleteKpiModalUpdated={showDeleteKpiModalUpdated}
      showKpiFormUpdated={showKpiFormUpdated}
      entitySettings={userSettings}
      entity={settingEntity.USER}
    />
  );
};

const mapStateToProps = ({ entities }) => {
  const { userSettings } = entities;
  return {
    userSettings,
  };
};

const mapDispatchToProps = {
  showDeleteKpiModalUpdated,
  showKpiFormUpdated,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(UserKpi),
);
export { Container as UserKpi };
