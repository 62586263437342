export const casingTypes = {
  INTERMEDIATE: 'Intermediate',
  PRODUCTION: 'Production',
  CONDUCTOR: 'Conductor',
  SURFACE: 'Surface',
  RESERVOIR: 'Reservoir',
  PILOT: 'Pilot',
  LOWER: 'Lower',
  MIDDLE: 'Middle',
  UPPER: 'Upper',
  NONAME: '',
};

export const casingStringType = Object.freeze({
  CASING: 'Casing',
  LINER: 'Liner',
  TUBING: 'Tubing',
  TIEBACK: 'Tieback',
  OPEN_HOLE: 'Open Hole',
  COMPLETION: 'Completion',
  MOBILIZATION: 'Mobilization',
  DEMOBILIZATION: 'Demobilization',
  XMT: 'XMT',
});

export const offsetWellsTabs = Object.freeze({
  SELECTED: 'selected',
  LIST: 'list',
  MAP: 'map',
});
